import React from 'react';

import { ThemeProvider } from 'styled-components';

import { AuthProvider } from './auth';
import { AudioProvider } from './audio';
import { ToastProvider } from './toast';
import { OrdersProvider } from './orders';
import { TablesProvider } from './tables';
import { CompanyProvider } from './company';
import { DevicesProvider } from './devices';
import { SidebarProvider } from './sidebar';
import { MessageProvider } from './messages';
import { OverviewProvider } from './overview';
import { ProductsProvider } from './products';
import { OperatorsProvider } from './operators';
import { CategoriesProvider } from './categories';
import { ComplementsProvider } from './complements';
import { ConfirmDialogProvider } from './confim_dialog';
import { DiscountsCouponsProvider } from './discounts_coupons';
import { ScheduledProductsProvider } from './scheduled_products';
import { ProductGroupsProvider } from './product_groups';
import { TeamProvider } from './team';
import { CashierReportProvider } from './cashier_report';
import { BsTicketProvider } from './bsticket';

import lightTheme from '../styles/themes/light';
import { StockProvider } from './stock';

const AppProvider: React.FC = ({ children }) => (
  <ThemeProvider theme={lightTheme}>
    <ToastProvider>
      <SidebarProvider>
        <ConfirmDialogProvider>
          <AudioProvider>
            <CompanyProvider>
              <MessageProvider>
                <OperatorsProvider>
                  <TeamProvider>
                    <DevicesProvider>
                      <AuthProvider>
                        <ScheduledProductsProvider>
                          <CategoriesProvider>
                            <ProductsProvider>
                              <ProductGroupsProvider>
                                <BsTicketProvider>
                                  <StockProvider>
                                    <ComplementsProvider>
                                      <DiscountsCouponsProvider>
                                        <TablesProvider>
                                          <OrdersProvider>
                                            <CashierReportProvider>
                                              <OverviewProvider>
                                                {children}
                                              </OverviewProvider>
                                            </CashierReportProvider>
                                          </OrdersProvider>
                                        </TablesProvider>
                                      </DiscountsCouponsProvider>
                                    </ComplementsProvider>
                                  </StockProvider>
                                </BsTicketProvider>
                              </ProductGroupsProvider>
                            </ProductsProvider>
                          </CategoriesProvider>
                        </ScheduledProductsProvider>
                      </AuthProvider>
                    </DevicesProvider>
                  </TeamProvider>
                </OperatorsProvider>
              </MessageProvider>
            </CompanyProvider>
          </AudioProvider>
        </ConfirmDialogProvider>
      </SidebarProvider>
    </ToastProvider>
  </ThemeProvider>
);

export default AppProvider;
