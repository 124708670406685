import { IFilters } from '../models/ICashierReport';

const cashierSummaryFilterLabels = new Map<string, string>([
  ['cashierNumber', 'Caixa'],
  ['device', 'Dispositivo'],
  ['operator', 'Operador'],
  ['platform', 'Plataforma'],
  ['startAt', 'Início'],
  ['endAt', 'Fim'],
]);

export const formatCashierSummaryFilterLabels = (key: keyof IFilters): string =>
  cashierSummaryFilterLabels.get(key as string) || '';
