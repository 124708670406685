import styled, { css } from 'styled-components';

interface IProductProps {
  active?: boolean;
  actions: boolean;
}

export const Container = styled.button<IProductProps>`
  scroll-margin: 16px;
  height: 166px;
  width: calc(1 / 3 * 100% - (1 - 1 / 3) * 24px);

  display: flex;
  cursor: default;
  position: relative;
  margin-bottom: 24px;

  border: 0;
  background: ${({ theme }) => theme.palette.background_white};

  padding: 8px;

  transition: box-shadow 0.4s;
  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);

  ${({ active }) =>
    !active &&
    css`
      opacity: 0.4;
    `}

  &:hover {
    ${({ actions }) => css`
      box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, ${actions ? '0.3' : '0.2'});
      cursor: ${actions ? 'pointer' : 'default'};
    `}
  }

  @media (max-width: 1780px) {
    width: calc(1 / 2 * 100% - (1 - 1 / 3) * 16px);
  }

  @media (max-width: 1280px) {
    width: 100%;
    flex: 0 100%;
    margin-left: 0;
    margin-bottom: 16px;
  }

  @media (max-width: 500px) {
    width: 100%;
    height: 136px;
    margin-right: 0;
    box-shadow: none;
    margin-bottom: 4%;
  }
`;

export const ProductImage = styled.div`
  position: relative;
  margin-right: 8px;
  img,
  svg {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    object-fit: cover;
  }

  .blue {
    .st0 {
      fill: #85c1e9 !important;
    }
  }

  .green {
    .st0 {
      fill: #94e7b7 !important;
    }
  }

  .orange {
    .st0 {
      fill: #f2be90 !important;
    }
  }

  .red {
    .st0 {
      fill: #f2a59d !important;
    }
  }

  .yellow {
    .st0 {
      fill: #f9e79f !important;
    }
  }

  @media (max-width: 500px) {
    img,
    svg {
      width: 64px;
      height: 64px;
    }
  }
`;

export const PrepareTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 7px;
  font-size: 14px;

  background: #f8f8f8;
  height: 30px;
  padding: 0 8px;

  border-radius: 0 6px 0 6px;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Description = styled.span`
  font-size: 14px;
  text-align: start;
  max-height: 60px;
  margin-top: 4px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media (max-width: 500px) {
    -webkit-line-clamp: 2;
    margin-top: 4px;
    font-size: 12px;
  }
`;

export const Name = styled.strong`
  font-size: 20px;
  text-align: start;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: 500px) {
    font-size: 14px;
    -webkit-line-clamp: 1;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
  }
`;

export const TypesContainer = styled.div`
  display: flex;

  font-size: 12px;

  div + div {
    margin-left: 8px;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

export const CategoryBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 64px;
  background: #3498db40;
  padding: 2px 6px;
`;

export const SubcategoryBadge = styled(CategoryBadge)`
  background: #1abc9c40;
`;

export const Subtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Price = styled.strong`
  font-size: 16px;

  > span {
    font-size: 12px;
    font-weight: 300;
    color: #34495e;
    margin-right: 6px;
  }
`;

export const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Label = styled.span`
  font-size: 12px;
  color: #34495e;
`;

export const ProductQuantity = styled.strong`
  font-size: 16px;
`;

export const Actions = styled.div`
  display: flex;

  position: absolute;

  right: 8px;
  bottom: 6px;

  svg + svg {
    margin-left: 16px;
  }

  svg {
    border-radius: 50%;

    padding: 4px;

    height: 27px;
    width: 27px;

    &:hover {
      background: #1c1d1f50;
    }
  }
`;

export const ListContainer = styled.button<IProductProps>`
  scroll-margin: 16px;
  width: 100%;
  border: none;
  background: ${({ theme }) => theme.palette.background_white};

  cursor: default;

  margin-bottom: 16px;
  margin-left: 16px;

  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.4s;

  ${({ active }) =>
    !active &&
    css`
      opacity: 0.4;
    `}

  &:hover {
    ${({ actions }) => css`
      box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, ${actions ? '0.3' : '0.2'});
      cursor: ${actions ? 'pointer' : 'default'};
    `}
  }

  @media (max-width: 500px) {
    box-shadow: none;
  }
`;

export const Top = styled.div`
  min-height: 76px;

  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr);
  align-items: center;

  padding: 8px;
`;

export const Bottom = styled.div`
  padding: 8px;

  @media (max-width: 1024px) {
    padding: 0;
  }
`;

export const Left = styled.div`
  display: flex;
  grid-column: 1 / 4;

  align-items: center;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const ListQuantityContainer = styled.div`
  display: flex;
  grid-column: 4 / 5;

  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const ListLabel = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.control};
`;

export const ListProductQuantity = styled.strong``;

export const ListImage = styled.div`
  margin-right: 16px;

  width: 60px;
  height: 60px;

  img,
  svg {
    object-fit: cover;
    border-radius: 8px;
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  .blue {
    .st0 {
      fill: #85c1e9 !important;
    }
  }

  .green {
    .st0 {
      fill: #94e7b7 !important;
    }
  }

  .orange {
    .st0 {
      fill: #f2be90 !important;
    }
  }

  .red {
    .st0 {
      fill: #f2a59d !important;
    }
  }

  .yellow {
    .st0 {
      fill: #f9e79f !important;
    }
  }
`;

export const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ListName = styled.strong`
  font-size: 18px;
  text-align: start;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const ListDescription = styled.span`
  font-size: 14px;
  text-align: start;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ListPrice = styled.strong`
  grid-column: 3 / 4;

  margin-left: 16px;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const ListActions = styled.div`
  display: flex;
  grid-column: 5 / 6;

  right: 8px;
  bottom: 6px;

  margin-left: 16px;

  svg + svg {
    margin-left: 16px;
  }

  svg {
    border-radius: 50%;

    padding: 4px;

    height: 27px;
    width: 27px;

    &:hover {
      background: #1c1d1f50;
    }
  }
`;

export const ComboBadge = styled(CategoryBadge)`
  font-weight: 600;
  background: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.text_white};
`;
