import { DateTime } from 'luxon';
import IOrder from '../models/IOrder';

export const getNextStatusMessage = (
  orderStatus:
    | 'PLACED'
    | 'CANCELED'
    | 'COMPLETED'
    | 'PREPARING'
    | 'IN_TRANSIT'
    | undefined,
  tableNo: number,
): string => {
  if (!orderStatus) return '';

  if (tableNo) {
    switch (orderStatus) {
      case 'CANCELED':
        return 'Reativar pedido';
      case 'COMPLETED':
        return 'Pedido concluído';
      case 'PLACED':
        return 'Aprovar pedido';
      default:
        return 'Concluir Pedido';
    }
  }

  switch (orderStatus) {
    case 'CANCELED':
      return 'Reativar pedido';
    case 'COMPLETED':
      return 'Pedido concluído';
    case 'IN_TRANSIT':
      return 'Concluir pedido';
    case 'PLACED':
      return 'Aprovar pedido';
    default:
      return 'Enviar para entrega';
  }
};

export const getNextStatusColor = (
  orderStatus:
    | 'PLACED'
    | 'CANCELED'
    | 'COMPLETED'
    | 'PREPARING'
    | 'IN_TRANSIT'
    | undefined,
  tableNo: number,
): string => {
  if (!orderStatus) return '';

  if (tableNo) {
    return '#27ae60';
  }

  switch (orderStatus) {
    case 'PLACED':
    case 'CANCELED':
    case 'COMPLETED':
    case 'IN_TRANSIT':
      return '#27ae60';
    default:
      return '#34495e';
  }
};

export const getOrderTime = (order?: IOrder | null): string => {
  if (order?.scheduledProduct) {
    return order.scheduledProduct.slice(0, -3);
  }

  if (order && ['DELIVERY'].includes(order.orderType)) {
    DateTime.fromISO(order.createdAt)
      .plus({
        minutes:
          order.orderType === 'DELIVERY'
            ? order.timeToDelivery
            : order.timeToPickup,
      })
      .diffNow('minutes')
      .toObject();

    return DateTime.fromISO(order.createdAt)
      .plus({
        minutes:
          order.orderType === 'DELIVERY'
            ? order.timeToDelivery
            : order.timeToPickup,
      })
      .toFormat('HH:mm');
  }

  return '-';
};
