import { ICouponArtVariant } from '../../models/ICouponArt';
import * as amigoVariants from './amigo';

const defaultVariant: ICouponArtVariant = {
  ...amigoVariants.defaultVariant,
  imageUri: '/couponArts/cliente/1080x1080.webp',
  logo: {
    ...amigoVariants.defaultVariant.logo,
  },
  description: {
    ...amigoVariants.defaultVariant.description,
  },
  amount: {
    ...amigoVariants.defaultVariant.amount,
  },
  ref: {
    ...amigoVariants.defaultVariant.ref,
    color: '#2e8eaa',
  },
  maxUses: {
    ...amigoVariants.defaultVariant.maxUses,
  },
  disclaimer: {
    ...amigoVariants.defaultVariant.disclaimer,
  },
  minAmount: {
    ...amigoVariants.defaultVariant.minAmount,
  },
  maxDiscount: {
    ...amigoVariants.defaultVariant.maxDiscount,
  },
};

const storiesVariant: ICouponArtVariant = {
  ...amigoVariants.storiesVariant,
  imageUri: '/couponArts/cliente/1080x1920.webp',
  logo: {
    ...amigoVariants.storiesVariant.logo,
    x: 709,
    y: 1624,
  },
  description: {
    ...amigoVariants.storiesVariant.description,
  },
  amount: {
    ...amigoVariants.storiesVariant.amount,
    width: 207,
    height: 95,
    x: 786,
    y: 323,
  },
  ref: {
    ...amigoVariants.storiesVariant.ref,
    width: 733,
    height: 157,
    x: 173,
    y: 795,
    color: '#2e8eaa',
  },
  maxUses: {
    ...amigoVariants.storiesVariant.maxUses,
  },
  disclaimer: {
    ...amigoVariants.storiesVariant.disclaimer,
    y: 880,
  },
  minAmount: {
    ...amigoVariants.storiesVariant.minAmount,
    x: 293,
    y: 1452,
  },
  maxDiscount: {
    ...amigoVariants.storiesVariant.maxDiscount,
    x: 627,
    y: 1452,
  },
};

export const cliente_variants: ICouponArtVariant[] = [
  defaultVariant,
  storiesVariant,
];
