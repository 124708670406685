import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.control};
  border-bottom: 1px solid ${({ theme }) => theme.palette.control};
  padding: 6px 0;

  label {
    font-size: 12px;
    margin-bottom: 4px;
  }

  &:focus-within {
    border-bottom-color: ${({ theme }) => theme.palette.primary};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Input = styled.input`
  background: transparent;
  padding-left: 0;
  border: none;
  padding: 4px;
  padding-left: 0;
  flex: 1;
`;

export const Chip = styled.div`
  cursor: default;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 4px 8px;
  background: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.text_white};
  border-radius: 32px;

  svg {
    color: ${({ theme }) => theme.palette.text_white};
    margin-left: 2px;
  }

  button {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
  }
`;
