import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Content = styled.div`
  max-width: 94vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 16px;

    span {
      margin-right: 0px;
    }
  }
`;

interface IImage {
  uri?: string;
}

export const Image = styled.span<IImage>`
  @keyframes imageEnterAnimationMultipleImageInput {
    0% {
      opacity: 0;
      transform: translateX(200px) rotateZ(30deg);
    }
    45% {
      transform: rotateZ(-8deg);
      animation-timing-function: cubic-bezier(0.52, 0.4, 0.34, 0.88);
    }
    60% {
      transform: rotateZ(5deg);
    }
    75% {
      transform: rotateZ(-2deg);
    }
    80% {
      transform: rotateZ(1deg);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }
  transform-origin: center;
  animation: imageEnterAnimationMultipleImageInput 750ms
    cubic-bezier(0.75, 0.36, 0.01, 1.15);
  background-image: url(${({ uri }) => uri});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  min-width: 200px;
  border-radius: 4px;
  height: 132px;
  margin-right: 12px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }

  &.warning {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.warning},
      0 0 12px ${({ theme }) => theme.palette.warning};
  }

  span {
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(2px);
    padding: 0.6rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    transition: ease 280ms;
  }

  &:hover span {
    visibility: visible;
    opacity: 1;
  }

  .loading {
    visibility: visible !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    background: transparent !important;
    backdrop-filter: none;
  }

  .loading div {
    background: transparent !important;
  }
`;

export const Add = styled.input.attrs({ type: 'file' })`
  display: none;

  & + label {
    cursor: pointer;
    min-width: 2.4rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    border: none;

    background: ${({ theme }) => theme.palette.primary};
    opacity: 0.8;

    svg {
      color: ${({ theme }) => theme.palette.text_white};
    }

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: ${({ theme }) => theme.shadow.box_shadow};

    &:hover {
      opacity: 1;
    }
  }
`;

export const ImageButton = styled.button`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  border-radius: 4px;
  background: ${({ theme }) => theme.palette.error};

  svg {
    color: ${({ theme }) => theme.palette.text_white};
  }
  margin-left: 8px;
`;

export const Title = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.control};
  width: 100%;
  display: flex;

  .warning {
    color: ${({ theme }) => theme.palette.warning};
    margin-left: 8px;
    font-size: 0.9rem;
    margin-top: -4px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
`;
