import React, { useCallback, useMemo, useState } from 'react';

import {
  Droppable,
  Draggable,
  DropResult,
  DragDropContext,
} from 'react-beautiful-dnd';

import { CgMenuGridO } from 'react-icons/cg';
import { HiOutlinePencil } from 'react-icons/hi';
import { FiPlus, FiTrash2, FiAlertTriangle } from 'react-icons/fi';

import IProduct from '../../models/IProduct';

import { useProducts } from '../../hooks/products';

import ProductsSelectorModal from '../ProductsSelectorModal';

import {
  Header,
  Warning,
  AddButton,
  Container,
  Suggestion,
  EditSuggestion,
  SuggestionTitle,
  SuggestionImage,
  AddButtonWrapper,
  NoSuggestionsMessage,
  EditSuggestionContainer,
  InfoWrapper,
  LeftContainer,
} from './styles';
import { ECategoryType } from '../../enums/categoryType';

export type ISuggestionProduct = Pick<IProduct, 'id' | 'title' | 'imageUrl'>;

interface ISuggestionsProps {
  editingProductId: number;
  suggestions: ISuggestionProduct[] | null;
  onChange: (suggestions?: ISuggestionProduct[] | null) => void;
}

const SuggestionsComponent: React.FC<ISuggestionsProps> = ({
  suggestions,
  editingProductId,
  onChange,
}) => {
  const [editingSuggestion, setEditingSuggestion] = useState<number | null>(
    null,
  );

  const [modalOpen, setModalOpen] = useState(false);

  const { products } = useProducts();

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
    setEditingSuggestion(null);
  }, []);

  const reorderSuggestions = useCallback(
    (list, startIndex: number, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    },
    [],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination) {
        const newSuggestions = reorderSuggestions(
          suggestions,
          result.source.index,
          result?.destination?.index,
        );

        onChange(newSuggestions as IProduct[]);
      }
    },
    [reorderSuggestions, suggestions, onChange],
  );

  const handleAddClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleDeleteClick = useCallback(
    (id: number) => {
      onChange(suggestions?.filter(item => item.id !== id));
    },
    [onChange, suggestions],
  );

  const handleEditClick = useCallback((id: number) => {
    setEditingSuggestion(id);
    setModalOpen(true);
  }, []);

  const handleModalSelect = useCallback(
    (id?: number) => {
      if (editingSuggestion) {
        onChange(
          suggestions &&
            suggestions.map(item => {
              if (item.id === editingSuggestion) {
                const newProduct = products.find(product => product.id === id);
                return newProduct || item;
              }
              return item;
            }),
        );
        setEditingSuggestion(null);
      } else {
        const newProduct = products.find(product => product.id === id);

        if (newProduct && suggestions) {
          onChange([...suggestions, newProduct]);
        }
      }
      setModalOpen(false);
    },
    [editingSuggestion, products, suggestions, onChange],
  );

  const modalProducts = useMemo(() => {
    if (products) {
      return products?.filter(
        product =>
          product?.categories?.every(c => c?.type !== ECategoryType.PIZZA) &&
          suggestions &&
          !suggestions?.some(item => item.id === product.id) &&
          editingProductId !== product.id,
      );
    }
    return [];
  }, [products, suggestions, editingProductId]);

  // test

  return (
    <Container>
      <ProductsSelectorModal
        open={modalOpen}
        products={modalProducts}
        onClose={handleModalClose}
        onSelect={handleModalSelect}
      />
      <Header>
        Sugestões
        {suggestions && suggestions?.length > 5 && (
          <Warning>
            <FiAlertTriangle size={24} />
            <span>Recomendamos 5 itens, no máximo!</span>
          </Warning>
        )}
      </Header>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="suggestions" direction="vertical">
          {providedDroppable => (
            <div
              {...providedDroppable.droppableProps}
              ref={providedDroppable.innerRef}
            >
              {suggestions?.map((product, index) => (
                <Draggable
                  key={product.id}
                  draggableId={product.id.toString()}
                  index={index}
                >
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Suggestion>
                        <InfoWrapper className="wrapper">
                          <LeftContainer>
                            <CgMenuGridO size={26} />
                            <SuggestionImage>
                              {product.imageUrl && (
                                <img
                                  src={product.imageUrl}
                                  alt={product.title}
                                />
                              )}
                            </SuggestionImage>
                            <SuggestionTitle>{product.title}</SuggestionTitle>
                          </LeftContainer>

                          <EditSuggestionContainer>
                            <EditSuggestion
                              onClick={() => handleDeleteClick(product.id)}
                            >
                              <FiTrash2 size={26} />
                            </EditSuggestion>
                            <EditSuggestion
                              onClick={() => handleEditClick(product.id)}
                            >
                              <HiOutlinePencil size={26} />
                            </EditSuggestion>
                            <span className="edit">{`#${index + 1}`}</span>
                          </EditSuggestionContainer>
                        </InfoWrapper>
                      </Suggestion>
                    </div>
                  )}
                </Draggable>
              ))}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {suggestions && suggestions?.length === 0 && (
        <NoSuggestionsMessage>
          Não há sugestões cadastradas. Clique no + para começar.
        </NoSuggestionsMessage>
      )}

      <AddButtonWrapper>
        <AddButton onClick={handleAddClick}>
          <FiPlus size={26} />
        </AddButton>
      </AddButtonWrapper>
    </Container>
  );
};

export default SuggestionsComponent;
