import styled, { css } from 'styled-components';

interface ILoadingProps {
  small?: boolean;
}
export const Container = styled.div<ILoadingProps>`
  height: 100%;
  width: 100%;

  ${({ small }) =>
    small &&
    css`
      position: relative;
      overflow: visible;
      height: 20%;
      padding-bottom: 32px;
    `}

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
`;
