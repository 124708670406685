import React, { useCallback } from 'react';

import ICategory from '../../models/ICategory';

import drag from '../../assets/icons/drag.svg';
import { chooseCategoryIcon } from '../../utils/icons';

import {
  Left,
  ListName,
  Position,
  ListImage,
  CategoryBadge,
  DraggableIcon,
  ListContainer,
  SubcategoryBadge,
  ListTypesContainer,
} from './styles';

interface IOrderableCategoryProps {
  position: number;
  category: ICategory;
  onClick?: (category: ICategory) => void;
}

const OrderableCategory: React.FC<IOrderableCategoryProps> = ({
  category,
  position,
  onClick,
}) => {
  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick(category);
    }
  }, [category, onClick]);

  return (
    <ListContainer onClick={handleOnClick}>
      <Left>
        <DraggableIcon>
          <img src={drag} alt="Arraste" />
        </DraggableIcon>
        <ListImage>
          <img src={chooseCategoryIcon(category.name)} alt={category.name} />
        </ListImage>
        <ListName>{category.name}</ListName>
      </Left>
      <ListTypesContainer>
        {category.isCategory && <CategoryBadge>categoria</CategoryBadge>}
        {category.isSubcategory && (
          <SubcategoryBadge>subcategoria</SubcategoryBadge>
        )}
      </ListTypesContainer>
      <Position>{`#${position}`}</Position>
    </ListContainer>
  );
};

export default OrderableCategory;
