import styled, { css } from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

interface IContainerProps {
  visible: boolean;
}

export const Container = styled.div<IContainerProps>`
  bottom: 0;
  width: 100%;
  position: absolute;
  background: #f4f4f6;

  transition: height 0.4s;

  overflow: hidden;

  ${({ visible }) =>
    visible
      ? css`
          height: 100vh;
        `
      : css`
          height: 0;
        `}
`;

export const Header = styled.header`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 32px;

  @media (max-width: 460px) {
    max-height: 150px;
  }
`;

export const PageInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const BackButton = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 24px;
  margin-left: 32px;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;

  font-size: 32px;

  background: none;
  border: 0;

  padding-bottom: 8px;

  border-bottom: 2px solid #db1622;

  transition: border-bottom 0.2s;

  &:hover {
    border-bottom: 8px solid #db1622;
  }

  svg {
    margin-right: 8px;
  }

  @media (max-width: 650px) {
    display: none;
  }
`;

export const ConfirmFloatingButton = styled.button`
  display: none;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;
  border-radius: 50%;

  border: 0;
  background: ${({ theme }) => theme.palette.primary};

  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.2);

  position: absolute;
  right: 24px;
  bottom: 24px;

  @media (max-width: 650px) {
    display: flex;
  }
`;

export const Main = styled.div`
  padding: 32px;
  padding-right: 0;
`;

export const Message = styled.span``;

export const Categories = styled.div`
  height: calc(100vh - 180px);

  margin-top: 8px;
  padding-top: 16px;
  padding-right: 32px;
  padding-bottom: 32px;

  overflow-y: auto;
`;

export const StyledDragDropContext = styled(DragDropContext)`
  padding-top: 16px;
`;

export const StyledDraggable = styled(Draggable)``;

export const StyledDroppable = styled(Droppable)``;
