import styled, { css } from 'styled-components';

interface ITimesContainer {
  disabled: boolean;
}

export const Container = styled.div`
  padding: 0.5rem 1.4rem;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.background};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow: auto;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 1.8rem;
  }
  padding: 0.8rem 0;
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;

  border: none;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};
  border-radius: 4px;
  margin-left: auto;
  padding: 0.5rem 1rem;

  svg {
    margin-left: 8px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
    `}

  @media (max-width: 800px) {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 2rem;
    box-shadow: ${({ theme }) => theme.shadow.box_shadow};
    z-index: 10;

    span {
      display: none;
    }

    svg {
      margin: 0;
      min-width: 1.8rem;
      min-height: 1.8rem;
    }
  }
`;

export const CheckboxContainer = styled.div`
  > div {
    display: flex;
    align-items: center;

    @media (max-width: 800px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
    }
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.span`
  margin-left: 16px;
`;

export const Description = styled.span`
  font-size: 14px;
  margin-top: 16px;
`;

export const TimesContainer = styled.div<ITimesContainer>`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: 16px;
  padding-bottom: 16px;

  flex: 1 1 auto;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
      pointer-events: none;

      * {
        pointer-events: none;
      }
    `}

  @media (max-width: 480px) {
    padding-bottom: 32px;
  }
`;

export const Placeholder = styled.div`
  width: 1px;
  min-height: 1rem;
  opacity: 0;

  @media (max-width: 800px) {
    min-height: 4rem;
  }
`;

export const EmptyMessage = styled.span`
  height: 2rem;
`;

export const ErrorMessage = styled(EmptyMessage)`
  color: ${({ theme }) => theme.palette.error};
  display: flex;
  align-items: center;

  margin-top: -12px;
  margin-bottom: 6px;

  svg {
    margin-right: 0.4rem;
  }
`;

export const Time = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }
`;

export const DeleteTimeButton = styled.button`
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: ${({ theme }) => theme.palette.primary};
`;
