import ReactModal from 'react-modal';
import styled from 'styled-components';

import { appearFromBottom } from '../../styles/animations';

export const StyledModal = styled(ReactModal)`
  animation: ${appearFromBottom} 0.5s;
`;

export const Container = styled.div`
  max-height: 90vh;
  max-width: 90vw;
  width: min(100vw, 600px);

  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  padding: 32px;

  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};
  background-color: ${({ theme }) => theme.palette.background_white};

  .error-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .error {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.error};
    align-self: flex-start;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 16px;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 4px;

  border: 0;
  background: none;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  span {
    font-size: 12px;
    color: #aaa;
  }
`;

export const IconOptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-left: 8px;
`;

export const QuantitiesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;

  div {
    width: 100%;
  }
`;

export const HalfInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  #disabled-input {
    cursor: not-allowed;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  margin-top: 24px;
`;

export const CancelButton = styled.button`
  flex: 1;

  padding: 5px;

  background: none;
  color: ${({ theme }) => theme.palette.primary};
  border: 1px solid ${({ theme }) => theme.palette.primary};
`;

export const SaveButton = styled.button`
  flex: 1;
  height: 37px;
  margin-left: 16px;
  padding: 6px 18px;

  border: 0;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};
`;

export const SelectProductContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
`;

export const SelectedProduct = styled.button`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.control};
  padding: 8px 16px;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.background_white};

  transition: border 100ms;

  img {
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }

  .placeholder {
    color: #808080;
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary};
  }
`;

export const Label = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.control};
`;
