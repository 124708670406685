import styled, { css } from 'styled-components';

interface IContainerProps {
  color?: string;
  disabled: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  margin-top: 16px;
  align-items: center;

  font-size: 14px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
    `}

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label {
    display: block;

    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;

    cursor: pointer;

    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  input[type='checkbox'] + label:last-child {
    margin-bottom: 0;
  }

  input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid ${({ color }) => color || '#db1622'};
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.6;
    -webkit-transition: all 0.12s, border-color 0.08s;
    transition: all 0.12s, border-color 0.08s;
  }

  input[type='checkbox']:checked + label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
