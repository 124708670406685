import React, { createContext, useCallback, useState, useContext } from 'react';

import { AxiosResponse } from 'axios';
import IComplementsGroup from '../models/IComplementsGroup';
import ISaveComplementsGroupDTO from '../dtos/ISaveComplementsGroupDTO';

import api from '../services/api';
import { useCompany } from './company';
import { normalizePositions } from '../utils/arrays';

interface ComplementsContextData {
  isComplementsLoading: boolean;
  complementsGroups: IComplementsGroup[];
  loadComplementsGroups(): Promise<void>;
  deleteComplementsGroup(id?: number): Promise<void>;
  saveComplementsGroup(
    saveComplementsDTO: ISaveComplementsGroupDTO,
    id: number,
  ): Promise<void>;
  saveComplementsPosition(
    newComplementsGroup: IComplementsGroup,
  ): Promise<void>;
  inactivateComplementGroup: (id: number) => Promise<void>;
}

const ComplementsContext = createContext<ComplementsContextData>(
  {} as ComplementsContextData,
);

export const ComplementsProvider: React.FC = ({ children }) => {
  const { company } = useCompany();

  const [data, setData] = useState<IComplementsGroup[]>([]);
  const [isComplementsLoading, setIsComplementsLoading] = useState(true);

  const loadComplementsGroups = useCallback(async () => {
    if (company) {
      setIsComplementsLoading(true);
      const response = await api.get<IComplementsGroup[]>(
        `restricted/complements-groups/${company?.id}`,
      );
      setData(
        response.data.map(group => ({
          ...group,
          complements: normalizePositions(group.complements),
        })),
      );
      setIsComplementsLoading(false);
    }
  }, [company]);

  const saveComplementsGroup = useCallback(
    async (saveComplementsDTO: ISaveComplementsGroupDTO, id: number) => {
      setIsComplementsLoading(true);

      if (id > 0) {
        const response = await api.put<IComplementsGroup>(
          `restricted/complements-groups/${id}/${company?.id}`,
          saveComplementsDTO,
        );

        setData([response.data, ...data.filter(group => group.id !== id)]);

        setIsComplementsLoading(false);
      } else {
        const response = await api.post<IComplementsGroup>(
          `restricted/complements-groups/${company?.id}`,
          saveComplementsDTO,
        );

        setData([response.data, ...data]);

        setIsComplementsLoading(false);
      }
    },
    [data, company],
  );

  const saveComplementsPosition = useCallback(
    async (newComplementsGroup: IComplementsGroup) => {
      if (company) {
        const response = await api.put<
          IComplementsGroup,
          AxiosResponse<IComplementsGroup>
        >(
          `/restricted/complements-groups/${newComplementsGroup?.id}/${company.id}`,
          newComplementsGroup,
        );

        setData(old =>
          old.map(current =>
            current.id === response.data.id ? response.data : current,
          ),
        );
      }
    },
    [company],
  );

  const inactivateComplementGroup = useCallback(
    async (id: number) => {
      const complementGroupToBeAltered = data.find(
        complementGroup => complementGroup.id === id,
      );

      await api.patch(
        `/restricted/complements-groups/${complementGroupToBeAltered?.id}/toggle/`,
      );

      const newComplementGroup = [...data];

      const index = newComplementGroup.findIndex(
        complementGroup =>
          complementGroup.id === complementGroupToBeAltered?.id,
      );

      newComplementGroup[index].isActive = !newComplementGroup[index].isActive;

      setData(newComplementGroup);
    },
    [data],
  );

  const deleteComplementsGroup = useCallback(async (id: number) => {
    setIsComplementsLoading(true);
    try {
      await api.delete(`restricted/complements-groups/${id}`);
      setData(old => old.filter(group => group.id !== id));
    } finally {
      setIsComplementsLoading(false);
    }
  }, []);

  return (
    <ComplementsContext.Provider
      value={{
        complementsGroups: data,
        isComplementsLoading,
        saveComplementsGroup,
        saveComplementsPosition,
        deleteComplementsGroup,
        loadComplementsGroups,
        inactivateComplementGroup,
      }}
    >
      {children}
    </ComplementsContext.Provider>
  );
};

export function useComplements(): ComplementsContextData {
  const context = useContext(ComplementsContext);

  if (!context) {
    throw new Error('useComplements must be used within ComplementsProvider');
  }

  return context;
}
