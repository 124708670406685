import styled, { css } from 'styled-components';

interface IContainerProps {
  beingDragged: boolean;
  items: number;
  activeItems: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  background: #fff;

  padding: 16px;
  margin-top: 16px;

  /* @media (min-width: 1270px) {
    ${({ beingDragged }) =>
    beingDragged
      ? css`
          transform: translateX(calc(calc(100vh / -2.3) - 14px));
        `
      : css``}
  } */

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 240ms ease;
    cursor: pointer;
    will-change: transform;
  }

  input[type='checkbox']:checked + label {
    transform: rotateZ(180deg);
  }

  .item-container {
    will-change: height;
    transition: height ${({ items }) => items * 100}ms ease;
  }

  ${({ activeItems, items }) =>
    activeItems &&
    css`
      .item-container {
        height: ${items * 36}px;
      }
    `}
`;

export const Main = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  div + div {
    margin-left: 16px;
  }
`;

export const DraggableIcon = styled.div`
  img {
    height: 40px;
    width: 40px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GroupName = styled.span``;

export const Mandatory = styled.span`
  margin-top: 16px;
  color: #ccc;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Top = styled.div`
  display: flex;
  justify-content: flex-end;

  button + button {
    margin-left: 16px;
  }
`;

export const IconContainer = styled.button`
  display: flex;
  padding: 6px;

  color: #fff;
  background: #db1622;
  border: 0;

  border-radius: 4px;
`;

export const ItemAmount = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;

export const ItemsContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  gap: 8px;
  padding-left: 56px;
  height: 0;
`;

export const Item = styled.div`
  font-size: 16px;
  span {
    margin-right: 8px;
  }
`;
