import styled, { css, keyframes } from 'styled-components';

interface IContainerProps {
  visible: boolean;
}

interface ITitleProps {
  selected: boolean;
}

interface ISectionProps {
  visible: boolean;
  thisPage: 1 | 2 | 3 | 4 | 5;
  previousPage: 1 | 2 | 3 | 4 | 5;
}

interface IPrintingOptionProps {
  selected: boolean;
}

interface IPrintingOptionsContainer {
  inverse?: boolean;
}

const appearFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to{
    transform: translateX(0);
  }
`;

const appearFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to{
    transform: translateX(0);
  }
`;

export const Container = styled.div<IContainerProps>`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  bottom: 0;
  position: absolute;

  transition: height 0.4s;
  background: ${({ theme }) => theme.palette.background_secondary};

  overflow: hidden;

  ${({ visible }) =>
    visible
      ? css`
          height: 100%;
        `
      : css`
          height: 0;
        `}
`;

export const Header = styled.header`
  width: 100%;
  max-height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 32px;

  div {
    display: flex;
    align-items: center;
  }

  svg {
    cursor: pointer;
  }
`;

export const StatusChangeButton = styled.button`
  border: 0;
  background: none;

  font-size: 24px;
  margin-right: 192px;
  padding-bottom: 16px;
  border-bottom: 4px solid ${({ theme }) => theme.palette.primary};

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const OrderNumber = styled.strong`
  font-size: 24px;

  margin-left: 32px;
  padding-bottom: 16px;
  border-bottom: 4px solid ${({ theme }) => theme.palette.primary};

  @media (max-width: 600px) {
    font-size: 20px;
  }

  @media (max-width: 400px) {
    font-size: 18px;
  }
`;

export const Main = styled.div`
  width: 100%;

  display: flex;
  flex: 1 1 auto;

  padding: 32px;
  font-size: 16px;

  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 1075px) {
    height: calc(100% - 94px);

    margin-top: 32px;

    flex-direction: column;
  }

  @media (max-width: 480px) {
    padding-bottom: 80px;
  }
`;

export const SectionTitles = styled.div`
  margin-top: 32px;
  max-width: 100vw;
  overflow: auto;
  height: 32px;

  span {
    cursor: pointer;
    transition: all 0.2s;
  }

  span + span {
    margin-left: 32px;
  }
`;

export const SectionTitle = styled.span<ITitleProps>`
  ${({ selected }) =>
    selected
      ? css`
          color: #1b1c1e;
          border-bottom: 4px solid ${({ theme }) => theme.palette.primary};
        `
      : css`
          color: #1b1c1e70;
        `}
`;

export const Information = styled.div`
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CancelOrderButton = styled.button`
  font-size: 14px;
  background: ${({ theme }) => theme.palette.error};
  color: ${({ theme }) => theme.palette.text_white};

  border: 0;
  padding: 6px;
  margin-left: 16px;

  span {
    &::after {
      content: 'Cancelar pedido';

      @media (max-width: 500px) {
        content: 'Cancelar';
      }
    }
  }
`;

export const InformationTitle = styled.h2``;

interface IHorizontalWrapper {
  proportional?: boolean;
}

export const HorizontalWrapper = styled.div<IHorizontalWrapper>`
  display: flex;
  width: 100%;
  margin-top: 24px;

  div + div {
    margin-left: 24px;
    width: ${({ proportional }) => (proportional ? '50%' : '30%')};
  }

  div {
    width: ${({ proportional }) => (proportional ? '50%' : '70%')};
  }

  @media (max-width: 640px) {
    flex-direction: column;

    div {
      width: 100%;
    }

    div + div {
      margin-left: 0;
      width: 100%;
      margin-top: 32px;
    }
  }
`;

export const Printing = styled.div`
  width: 50%;
  margin-left: 64px;

  @media (max-width: 1075px) {
    margin-left: 0;
    margin-top: 32px;
  }

  @media (min-width: 546px) {
    z-index: 2;
  }

  @media (max-width: 545px) {
    width: 100%;
  }
`;

export const PrintPreview = styled.div`
  display: flex;
  height: calc(100% - 150px);

  padding: 8px;

  margin-top: 32px;

  overflow-y: auto;

  background-color: #f3edbf;
`;

export const PrintPreviewRagged = styled.div`
  display: flex;
  height: 30px;
  background: linear-gradient(-135deg, #f3edbf 10px, transparent 0) 0 10px,
    linear-gradient(135deg, #f3edbf 10px, #f4f4f6 0) 0 10px;
  background-color: #f3edbf;
  background-position: left bottom !important;
  background-repeat: repeat-x !important;
  background-size: 20px 20px;
`;

export const Divisor = styled.span`
  margin: 0 8px;
`;

export const PrintingTitle = styled.h2`
  margin-bottom: 8px;
`;

export const PrintingOptionsContainer = styled.div<IPrintingOptionsContainer>`
  display: flex;
  align-items: center;
  flex-direction: ${({ inverse }) => (inverse ? 'row-reverse' : 'row')};
  justify-content: ${({ inverse }) => (inverse ? 'flex-end' : 'flex-start')};
  margin-right: 0;
`;

export const PrintingOption = styled.span<IPrintingOptionProps>`
  cursor: pointer;
  transition: all 0.4;

  ${({ selected }) =>
    selected
      ? css`
          font-size: 18px;
          font-weight: 500;
        `
      : css`
          font-size: 14px;
        `}
`;

export const Section = styled.div<ISectionProps>`
  padding-bottom: 32px;

  margin-top: 32px;

  > div {
    margin-top: 32px;
  }

  ${({ previousPage, thisPage }) =>
    previousPage < thisPage
      ? css`
          animation: ${appearFromRight} 0.4s;
        `
      : css`
          animation: ${appearFromLeft} 0.4s;
        `}

  ${({ visible }) =>
    visible
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;

export const ActionsButton = styled.button`
  width: 60px;
  height: 60px;

  display: none;
  align-items: center;
  justify-content: center;

  border: 0;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary};

  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.2);

  right: 24px;
  bottom: 24px;
  position: absolute;

  @media (max-width: 1000px) {
    display: flex;
  }
`;

export const OrderTimeContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.control};
  }

  .input {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.text};

    input {
      border: none;
      padding: 8px 16px;
      background: #ccc4;

      @media (max-width: 480px) {
        width: 144px;
      }
    }

    button {
      height: 100%;

      border: 0;
      margin-left: 8px;
      padding: 8px 12px;
      border-radius: 2px;
      position: relative;

      color: ${({ theme }) => theme.palette.text_white};
      background: ${({ theme }) => theme.palette.primary};

      overflow: hidden;
      text-align: center;
    }

    button:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
`;

export const OrderTimeLoading = styled.div`
  width: 100%;
  height: 100%;

  display: none;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  position: absolute;
  background: ${({ theme }) => theme.palette.primary};

  &.visible {
    display: flex;
  }
  div {
    border-color: ${({ theme }) => theme.palette.text_white} !important;
  }
`;
