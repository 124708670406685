import styled, { css } from 'styled-components';

export interface IPageProps {
  selected?: boolean;
  hideIcon?: boolean;
  userCanAccess?: boolean;
  isSubPageTab?: boolean;
}

export const Container = styled.button<IPageProps>`
  ${({ isSubPageTab, userCanAccess, hideIcon, selected, theme }) =>
    isSubPageTab
      ? css`
          height: 30px;

          display: flex;
          align-items: center;
          justify-content: space-between;

          font-size: 90%;

          margin: 16px 32px;

          cursor: pointer;

          span {
            transition: border 0.4s;
            border-bottom: 2px solid transparent;
          }

          ${userCanAccess &&
          css`
            &:hover {
              span {
                border-bottom: 2px solid ${theme.palette.primary};
              }
            }
          `}

          @media (max-width: 1250px) {
            font-size: 80%;
          }
        `
      : css`
          width: 100%;
          height: 46px;

          display: flex;
          align-items: center;
          justify-content: space-between;

          padding: 8px 32px;
          background: linear-gradient(
            to left,
            ${theme.palette.background_secondary},
            transparent
          );

          position: relative;

          > div {
            display: flex;
            align-items: center;
          }

          ${hideIcon &&
          css`
            @media (max-width: 1270px) {
              span {
                margin-left: 60px;
              }
            }
          `}

          img {
            width: 30px;
            height: 30px;

            margin-right: 32px;

            ${hideIcon &&
            css`
              @media (max-width: 1270px) {
                display: none;
              }
            `}
          }

          &:after {
            content: '';

            width: 6px;
            height: 100%;

            right: 0;
            display: block;

            position: absolute;
            transform: scaleX(0);
            transform-origin: right center;
            transition: transform 240ms cubic-bezier(0.67, 0.3, 0.19, 1.54);
          }

          ${selected &&
          userCanAccess &&
          css`
            &:after {
              transform: none;
            }

            &:after {
              border-radius: 4px 0 0 4px;
              background: ${theme.palette.primary};
            }
          `}

          ${userCanAccess &&
          css`
            &:hover {
              background: rgba(0, 0, 0, 0.04);
            }
          `}

          @media (max-width: 1270px) {
            font-size: 14px;
            padding: 8px 16px;
          }
        `}

  border: 0;
  background: none;

  ${({ userCanAccess }) =>
    !userCanAccess &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;
