import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
`;

export const Content = styled.main`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  background: #f4f4f6;
  position: relative;
`;

export const Header = styled.header`
  width: 100%;
  max-height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 32px;
  padding-top: 24px;
  padding-bottom: 16px;
`;

export const PageInfo = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;

    @media (min-width: 1271px) {
      display: none;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PageName = styled.strong`
  font-size: 32px;
  margin-left: 8px;

  @media (max-width: 1027px) {
    display: none;
  }
`;

export const PageDescription = styled.span`
  margin-top: 9px;
  margin-left: 64px;

  color: #0008;

  @media (max-width: 550px) {
    display: none;
  }
`;

export const Main = styled.div`
  width: 100%;
  padding: 0 32px;

  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 16px;

  overflow-y: auto;
  overflow-x: hidden;

  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 480px) {
    padding-bottom: 64px;
  }
`;

export const CreateTableTutorial = styled.div`
  padding: 8px;
  font-size: 18px;

  ol {
    font-size: 16px;
    margin-top: 16px;
    margin-left: 32px;
  }

  li {
    margin-top: 8px;
  }

  a {
    text-decoration: none;

    font-weight: 500;

    color: #000;
    padding-bottom: 4px;
    border-bottom: 1px solid #db1622;
  }
`;

export const FlexPlaceholder = styled.div`
  height: 0;
  width: 18.7%;
`;

export const Padding = styled.div`
  margin-bottom: 16px;
`;
