import React, { useCallback, useEffect, useState } from 'react';

import { useSpring } from 'react-spring';
import { FiAlertCircle, FiX } from 'react-icons/fi';

import { Container, Content, Message, DismissButton, Wrapper } from './styles';
import { ToastMessage } from '../../hooks/toast';

interface ISnackProps {
  message: ToastMessage | null;
  onDismiss: () => void;
}

const Snack: React.FC<ISnackProps> = ({ message, onDismiss }) => {
  const [open, setOpen] = useState(true);

  const props = useSpring(
    open
      ? {
          to: { opacity: 1, bottom: 16 },
          from: { opacity: 0, bottom: 0 },
        }
      : {
          to: { opacity: 0, bottom: 0 },
          from: { opacity: 1, bottom: 16 },
        },
  );

  const handleOnDismiss = useCallback(() => {
    setOpen(false);

    setTimeout(() => {
      onDismiss();
    }, 300);
  }, [onDismiss]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleOnDismiss();
    }, 4500);

    return () => clearTimeout(timeout);
  });

  return (
    <Container style={props}>
      <Content type={message?.type}>
        <Wrapper>
          <div>
            <FiAlertCircle size={24} />
          </div>
          {message && <Message>{message.description}</Message>}
        </Wrapper>
        <DismissButton onClick={handleOnDismiss}>
          <FiX size={26} />
        </DismissButton>
      </Content>
    </Container>
  );
};

export default Snack;
