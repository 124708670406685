import styled, { css } from 'styled-components';

interface IContainerProps {
  reverse: boolean;
  bottom?: boolean;
}

export const Container = styled.div<IContainerProps>`
  position: relative;

  span {
    width: 160px;
    background: #db1622;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;
    position: absolute;
    bottom: ${({ bottom }) =>
      bottom ? 'calc(-300% - 12px)' : 'calc(100% + 12px)'};
    left: 50%;
    transform: translateX(-50%);
    color: #000;
    z-index: 999;

    ${({ reverse }) =>
      reverse &&
      css`
        bottom: -490%;
      `}

    &::before {
      content: '';
      border-style: solid;
      border-color: #db1622 transparent;
      border-width: 6px 6px 0 6px;
      top: ${({ bottom }) => (bottom ? '-6px' : '100%')};
      position: absolute;
      left: 50%;
      transform: ${({ bottom }) =>
        bottom ? 'translateX(-50%) rotateZ(180deg)' : 'translateX(-50%)'};

      ${({ reverse }) =>
        reverse &&
        css`
          top: -10%;
          border-width: 0px 8px 8px 8px;
        `}
    }
  }
  &:hover span {
    visibility: visible;
    opacity: 1;
  }
`;
