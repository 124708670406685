import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface IContainerProps {
  type?: 'success' | 'error' | 'info';
}

const toastTypeVariations = {
  info: css`
    background: ${({ theme }) => theme.palette.background_info};
    color: ${({ theme }) => theme.palette.info};
    border-bottom-color: ${({ theme }) => theme.palette.info};
  `,
  error: css`
    background: ${({ theme }) => theme.palette.background_error};
    color: ${({ theme }) => theme.palette.error};
    border-bottom-color: ${({ theme }) => theme.palette.error};
  `,
  success: css`
    background: ${({ theme }) => theme.palette.background_success};
    color: ${({ theme }) => theme.palette.success};
    border-bottom-color: ${({ theme }) => theme.palette.success};
  `,
};

export const Container = styled(animated.div)`
  z-index: 999;
  position: absolute;

  @media (min-width: 801px) {
    left: 50%;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding: 0 16px;
  }
`;

export const Content = styled.div<IContainerProps>`
  padding: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};
  border-bottom: 3px solid;
  ${props => toastTypeVariations[props.type || 'info']}

  border-radius: 8px;

  box-shadow: ${({ theme }) => theme.shadow.box_shadow};

  bottom: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-left: 8px;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Message = styled.span`
  margin-left: 14px;
  margin-right: 14px;
  font-size: 100%;
`;

export const DismissButton = styled.button`
  display: flex;
  align-items: center;

  height: 100%;

  border: 0;
  background: none;

  margin-right: 8px;
`;
