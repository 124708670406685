import styled, { css } from 'styled-components';

interface IFilterProps {
  selected: boolean;
}

interface IInnerContentProps {
  visible: boolean;
}

export const Container = styled.div`
  height: 100%;
  display: flex;
`;

export const Content = styled.main`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  position: relative;
  background: ${({ theme }) => theme.palette.background_secondary};

  @media (max-width: 1270px) {
    width: 100vw;
  }
`;

export const InnerContent = styled.div<IInnerContentProps>`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  overflow: hidden;
  transition: height 0.4s;

  ${({ visible }) =>
    visible
      ? css`
          height: 100%;
        `
      : css`
          height: 0;
        `}
`;

export const Header = styled.header`
  width: 100%;
  max-height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 32px;
  padding-top: 24px;
`;

export const PageInfo = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;

    @media (min-width: 1271px) {
      display: none;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PageName = styled.strong`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 32px;
  margin-left: 8px;

  @media (max-width: 1027px) {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const AddCategoryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  padding: 8px;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};

  span {
    font-size: 14px;
    white-space: nowrap;

    &::after {
      content: 'Adicionar nova';
      margin-left: 8px;
    }
  }

  @media (max-width: 595px) {
    max-height: 34px;
    max-width: 34px;

    span {
      &::after {
        content: '';
        margin-left: 0;
      }
    }
  }
`;

export const Main = styled.main`
  width: 100%;

  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding: 0 32px;
  padding-right: 0;

  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const CategoriesContainer = styled.div`
  width: 100%;
  height: -webkit-fill-available;

  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;

  padding-top: 32px;
  padding-right: 32px;

  overflow-y: scroll;
  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 480px) {
    padding-bottom: 80px;
  }
`;

export const MainHeader = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
`;

export const PizzaGroupsTab = styled.span<IFilterProps>`
  padding: 6px;
  padding-bottom: 16px;

  cursor: pointer;

  ${({ selected }) =>
    selected
      ? css`
          border-bottom: 3px solid ${({ theme }) => theme.palette.primary};
        `
      : css`
          border-bottom: 1px solid transparent;
        `}

  &:hover {
    ${({ selected }) =>
      !selected &&
      css`
        border-bottom: 1px solid ${({ theme }) => theme.palette.text};
      `};
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  overflow: auto;

  margin-top: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.control_light};

  span + span {
    margin-left: 32px;
    white-space: nowrap;
  }

  @media (max-width: 600px) {
    span + span {
      margin-left: 16px;
    }
  }
`;

export const Filter = styled.span<IFilterProps>`
  padding: 6px;
  padding-bottom: 16px;

  cursor: pointer;

  ${({ selected }) =>
    selected
      ? css`
          border-bottom: 3px solid ${({ theme }) => theme.palette.primary};
        `
      : css`
          border-bottom: 1px solid transparent;
        `}

  &:hover {
    ${({ selected }) =>
      !selected &&
      css`
        border-bottom: 1px solid ${({ theme }) => theme.palette.text};
      `};
  }
`;

export const DisplayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 32px;
  margin-right: 32px;

  border-bottom: 1px solid ${({ theme }) => theme.palette.control_light};

  button + button {
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid ${({ theme }) => theme.palette.text};
  }

  @media (max-width: 500px) {
    border: 0;
    margin-top: 0;
    padding-bottom: 8px;

    margin-top: 32px;

    width: 100%;
    justify-content: flex-end;
  }
`;

export const DisplayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  background: none;
`;

export const AddTutorial = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  padding: 8px;
  margin-top: 128px;

  button {
    border: 0;
    background: none;

    margin-left: 8px;

    text-decoration: none;

    font-weight: 500;

    padding-bottom: 4px;
    border-bottom: 2px solid ${({ theme }) => theme.palette.primary};
  }
`;

export const NoCategoriesFound = styled.div`
  width: 100%;
  margin-top: 128px;
  text-align: center;
`;

export const FlexPlaceholder = styled.div`
  height: 0;
  width: 16%;
`;
