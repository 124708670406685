import React from 'react';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './routes';
import AppProvider from './hooks';

import GlobalStyle from './styles/global';
import OfflineModal from './components/OfflineModal';

const App: React.FC = () => {
  return (
    <Router>
      <AppProvider>
        <OfflineModal />
        <Routes />
        <GlobalStyle />
      </AppProvider>
    </Router>
  );
};

export default App;
