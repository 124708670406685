import styled from 'styled-components';
import TimeField from 'react-simple-timefield';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;

  @media (max-width: 700px) {
    gap: 32px;
  }
`;

export const WeekDaysContainer = styled.div`
  width: 100%;
  min-height: 32px;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 2fr;

  align-items: flex-start;
  justify-content: center;

  position: relative;

  gap: 28px;

  h1 {
    align-self: flex-start;
    font-size: 16px;
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
  }
`;

export const WeekDaysLeftContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WeekDaysRightContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 12px;

  span {
    font-size: 14px;
  }
`;

export const ScheduleContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  padding: 4px 4px 4px 4px;
  display: flex;
  align-items: center;
  border-radius: 4px;

  span {
    margin: 0 12px;
  }

  &:first-child::after {
    content: '';
    display: block;
    position: relative;
    width: 20px;
    height: 100%;
  }

  @media (max-width: 360px) {
    span {
      margin: 0 4px;
    }
  }
`;

export const Input = styled(TimeField)`
  width: 100px !important;
  height: 100%;
  border: none;
  background: transparent;
  box-shadow: 0 -1px 0 black inset;
  font-size: 16px !important;
`;

export const AddScheduleButton = styled.button`
  width: 28px;
  height: 28px;
  border: none;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -40px;
  top: 2px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_hover};

  svg {
    color: ${({ theme }) => theme.palette.text_white};
  }
`;
