/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import {
  Draggable,
  Droppable,
  DropResult,
  DragDropContext,
} from 'react-beautiful-dnd';

import { CgMenuGridO } from 'react-icons/cg';
import Spinner from 'react-spinner-material';
import { HiOutlinePencil } from 'react-icons/hi';
import { FiPlus, FiTrash2, FiAlertTriangle } from 'react-icons/fi';

import isEqual from 'react-fast-compare';
import IProduct from '../../models/IProduct';

import { useToast } from '../../hooks/toast';
import { useCompany } from '../../hooks/company';
import { useSidebar } from '../../hooks/sidebar';
import { useProducts } from '../../hooks/products';

import Tour from '../../components/Tour';
import HelpButton from '../../components/HelpButton';
import ProductIcon from '../../components/ProductIcon';
import EmptyMessage from '../../components/EmptyMessage';
import LoadingAnimation from '../../components/LoadingAnimation';
import ProductsSelectorModal from '../../components/ProductsSelectorModal';

import api from '../../services/api';
import tourData from '../../tour/highlights';
import { PageNames } from '../../enums/pages';
import { ReactComponent as ProductPlaceholderSVG } from '../../assets/icons/product_placeholder.svg';

import {
  Header,
  Warning,
  AddButton,
  Container,
  Highlight,
  EditHighlight,
  HighlightImage,
  HighlightTitle,
  AddButtonWrapper,
  HighlightsContainer,
  EditHighlightContainer,
  HighlightInfoContainer,
  EditHighlightButtons,
} from './styles';
import { ECategoryType } from '../../enums/categoryType';

import SaveButton from '../../components/SaveButton';

const HighlightsPage: React.FC = () => {
  const { setSelectedPage } = useSidebar();
  const [tourOpen, setTourOpen] = useState(false);
  const [isHighlightsLoading, setIsHighlightsLoading] = useState(false);
  const [highlights, setHighlights] = useState<IProduct[]>([]);
  const [oldHighlights, setOldHighlights] = useState<IProduct[]>([]);
  const [editingHighlight, setEditingHighlight] = useState<number | null>(null);

  const highlightsContainer = useRef<HTMLDivElement | null>(null);

  const [modalOpen, setModalOpen] = useState(false);

  const { products, loadProducts, isProductsLoading } = useProducts();
  const { company } = useCompany();
  const { addToast } = useToast();

  const loadHighlights = useCallback(async () => {
    setIsHighlightsLoading(true);
    const { data } = await api.get<IProduct[]>(
      '/restricted/products/highlights',
    );
    setIsHighlightsLoading(false);
    setOldHighlights(data || []);
    setHighlights(data || []);
  }, []);

  const saveHighlights = useCallback(async () => {
    setIsHighlightsLoading(true);
    try {
      await api.post(
        '/restricted/products/highlights',
        highlights.map((highlight, index) => ({
          productId: highlight.id,
          position: index,
        })),
      );
      setOldHighlights(highlights);
      addToast({
        type: 'success',
        description: 'Destaques salvos com sucesso!',
      });
    } catch {
      addToast({
        type: 'error',
        description: 'Ocorreu um erro ao salvar destaques.',
      });
    }
    setIsHighlightsLoading(false);
  }, [highlights, addToast]);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
    setEditingHighlight(null);
  }, []);

  const reorderHighlights = useCallback(
    (list, startIndex: number, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    },
    [],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination) {
        setHighlights(old => {
          const newHighlights = reorderHighlights(
            old,
            result.source.index,
            result?.destination?.index,
          );

          return newHighlights as IProduct[];
        });
      }
    },
    [reorderHighlights],
  );

  const handleAddClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleDeleteClick = useCallback((id: number) => {
    setHighlights(old => {
      return old.filter(item => item.id !== id);
    });
  }, []);

  const handleEditClick = useCallback((id: number) => {
    setEditingHighlight(id);
    setModalOpen(true);
  }, []);

  const handleTourOpen = useCallback(() => {
    setTourOpen(true);
  }, []);

  const handleTourClose = useCallback(() => {
    setTourOpen(false);
  }, []);

  const handleModalSelect = useCallback(
    (id?: number) => {
      if (editingHighlight) {
        setHighlights(old =>
          old.map(item => {
            if (item.id === editingHighlight) {
              const newProduct = products.find(product => product.id === id);
              return newProduct || item;
            }
            return item;
          }),
        );
        setEditingHighlight(null);
      } else {
        const newProduct = products.find(product => product.id === id);

        if (newProduct) {
          setHighlights(old => [...old, newProduct]);
        }
      }
      setModalOpen(false);

      setTimeout(() => {
        if (highlightsContainer.current) {
          const { scrollHeight } = highlightsContainer.current;
          highlightsContainer.current.scrollTo({
            left: 0,
            top: scrollHeight + 100,
          });
        }
      }, 150);
    },
    [editingHighlight, products],
  );

  const modalProducts = useMemo(() => {
    return products.filter(
      product =>
        !highlights.some(item => item.id === product.id) &&
        product?.categories.every(
          category => category.type !== ECategoryType.PIZZA,
        ),
    );
  }, [products, highlights]);

  useEffect(() => {
    if (company) {
      loadProducts();
      loadHighlights();
    }
  }, [loadProducts, company, loadHighlights]);

  useEffect(() => {
    setSelectedPage(PageNames.HIGHLIGHTS);
  }, [setSelectedPage]);

  return (
    <Container>
      <ProductsSelectorModal
        products={modalProducts}
        open={modalOpen}
        onClose={handleModalClose}
        onSelect={handleModalSelect}
      />
      <Header>
        <h1>Destaques</h1>
        <HelpButton onClick={handleTourOpen} />
        {isProductsLoading ||
          (isHighlightsLoading && (
            <Spinner color="black" radius={24} stroke={2} visible />
          ))}
        {highlights.length > 5 && (
          <Warning>
            <FiAlertTriangle size={24} />
            <span>Recomendamos 5 itens no máximo!</span>
          </Warning>
        )}
      </Header>
      {isHighlightsLoading || isProductsLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          {highlights.length > 0 ? (
            <HighlightsContainer
              ref={highlightsContainer}
              className="has-custom-scroll-bar"
            >
              {tourOpen && (
                <Highlight id="highlights-container">
                  <div className="wrapper">
                    <CgMenuGridO size={26} id="highlights-drag" />
                    <HighlightImage>
                      <ProductPlaceholderSVG className="product-icon" />
                    </HighlightImage>
                    <HighlightTitle>Produto</HighlightTitle>

                    <span className="edit">
                      <EditHighlightContainer>
                        <EditHighlight
                          onClick={() => {}}
                          id="highlights-delete"
                        >
                          <FiTrash2 size={26} />
                        </EditHighlight>
                        <EditHighlight onClick={() => {}} id="highlights-edit">
                          <HiOutlinePencil size={26} />
                        </EditHighlight>
                      </EditHighlightContainer>
                      #
                    </span>
                  </div>
                </Highlight>
              )}
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="highlights" direction="vertical">
                  {providedDroppable => (
                    <div
                      {...providedDroppable.droppableProps}
                      ref={providedDroppable.innerRef}
                    >
                      {highlights.map((product, index) => (
                        <Draggable
                          key={product.id}
                          draggableId={product.id.toString()}
                          index={index}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Highlight>
                                <div className="wrapper">
                                  <HighlightInfoContainer>
                                    <CgMenuGridO size={26} />
                                    <HighlightImage>
                                      <ProductIcon
                                        url={product.imageUrl}
                                        alt={product.title}
                                      />
                                    </HighlightImage>
                                    <HighlightTitle>
                                      {product.title}
                                    </HighlightTitle>
                                  </HighlightInfoContainer>

                                  <EditHighlightContainer>
                                    <EditHighlightButtons>
                                      <EditHighlight
                                        onClick={() =>
                                          handleDeleteClick(product.id)
                                        }
                                      >
                                        <FiTrash2 size={26} />
                                      </EditHighlight>
                                      <EditHighlight
                                        onClick={() =>
                                          handleEditClick(product.id)
                                        }
                                      >
                                        <HiOutlinePencil size={26} />
                                      </EditHighlight>
                                    </EditHighlightButtons>

                                    <span>{`#${index + 1}`}</span>
                                  </EditHighlightContainer>
                                </div>
                              </Highlight>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {providedDroppable.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <AddButtonWrapper>
                <AddButton onClick={handleAddClick} id="highlights-add">
                  <FiPlus size={26} />
                </AddButton>
              </AddButtonWrapper>
              <SaveButton
                onClick={saveHighlights}
                disabled={
                  isProductsLoading ||
                  isHighlightsLoading ||
                  isEqual(oldHighlights, highlights)
                }
              />
            </HighlightsContainer>
          ) : (
            <HighlightsContainer
              ref={highlightsContainer}
              className="has-custom-scroll-bar"
            >
              <EmptyMessage plural="destaques" onClick={handleAddClick} />

              <SaveButton
                onClick={saveHighlights}
                disabled={
                  isProductsLoading ||
                  isHighlightsLoading ||
                  isEqual(oldHighlights, highlights)
                }
              />
            </HighlightsContainer>
          )}
        </>
      )}
      {tourOpen && <Tour steps={tourData} onFinish={handleTourClose} />}
    </Container>
  );
};

export default HighlightsPage;
