import React, { useCallback, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { IStockCheckDTO } from '../../models/IStock';
import FormlessInput from '../FormlessInput';
import Loading from '../Loading';
import {
  ButtonsContainer,
  CancelButton,
  CloseButton,
  Container,
  Header,
  SaveButton,
  StyledModal,
} from './styles';

interface IAddStockCheckModalProps {
  isOpen: boolean;
  onConfirm: (stockCheck: IStockCheckDTO) => Promise<void>;
  onClose: () => void;
}

const AddStockCheckModal: React.FC<IAddStockCheckModalProps> = ({
  isOpen,
  onConfirm,
  onClose,
}) => {
  const [stockCheckTitle, setStockCheckTitle] = useState('');
  const [stockCheckDescription, setStockCheckDescription] = useState('');

  const [stockCheckTitleError, setStockCheckTitleError] = useState('');
  const [stockCheckDescriptionError, setStockCheckDescriptionError] = useState(
    '',
  );

  const [isSaving, setIsSaving] = useState(false);

  const handleStockCheckTitleChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setStockCheckTitle(value);
      setStockCheckTitleError('');
    },
    [],
  );

  const handleStockCheckDescriptionChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setStockCheckDescription(value);
      setStockCheckDescriptionError('');
    },
    [],
  );

  const hasErrors = useCallback(() => {
    let hasErrors = false;

    if (stockCheckTitle.length < 4 || stockCheckTitle.length > 31) {
      setStockCheckTitleError(
        !stockCheckTitle
          ? 'O título da conferência é obrigatório.'
          : 'O título da conferência precisa ter entre 4 e 31 caracteres.',
      );

      hasErrors = true;
    }

    if (stockCheckDescription.length > 160) {
      setStockCheckDescriptionError(
        'A descrição deve ter no máximo 160 caracteres',
      );
    }

    return hasErrors;
  }, [stockCheckDescription.length, stockCheckTitle]);

  const handleOnModalClose = useCallback(() => {
    setStockCheckTitle('');
    setStockCheckDescription('');

    setStockCheckTitleError('');
    setStockCheckDescriptionError('');

    onClose();
  }, [onClose]);

  const handleOnSaveStockCheck = useCallback(async () => {
    if (hasErrors()) return;

    setIsSaving(true);

    const newStockCheck: IStockCheckDTO = {
      title: stockCheckTitle,
      ...(stockCheckDescription && {
        description: stockCheckDescription,
      }),
    };

    onConfirm(newStockCheck);

    setIsSaving(false);

    setStockCheckTitle('');

    handleOnModalClose();
  }, [
    hasErrors,
    stockCheckTitle,
    stockCheckDescription,
    onConfirm,
    handleOnModalClose,
  ]);

  return (
    <StyledModal
      isOpen={isOpen}
      shouldCloseOnEsc
      id="add-stock-check-modal"
      onRequestClose={handleOnModalClose}
      shouldCloseOnOverlayClick
      style={{
        overlay: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <Container className="has-custom-scroll-bar-2">
        <Header>
          <h3>Nova conferência</h3>
          <CloseButton onClick={handleOnModalClose}>
            <FiX size={20} />
          </CloseButton>
        </Header>
        <div className="error-container">
          <FormlessInput
            title="Título"
            placeholder="Título da conferência"
            name="stock_check_title"
            value={stockCheckTitle}
            onChange={handleStockCheckTitleChanged}
            minLength={4}
            maxLength={31}
          />
          {stockCheckTitleError && (
            <span className="error">{stockCheckTitleError}</span>
          )}
          <FormlessInput
            title="Descrição (opcional)"
            placeholder="Descrição da conferência"
            name="stock_check_description"
            value={stockCheckDescription}
            onChange={handleStockCheckDescriptionChanged}
            maxLength={160}
          />
          {stockCheckDescriptionError && (
            <span className="error">{stockCheckDescriptionError}</span>
          )}
        </div>
        <ButtonsContainer>
          <CancelButton onClick={handleOnModalClose}>Cancelar</CancelButton>
          <SaveButton onClick={handleOnSaveStockCheck}>
            {isSaving ? (
              <Loading color="text_white" stroke={2} radius={16} />
            ) : (
              'Salvar'
            )}
          </SaveButton>
        </ButtonsContainer>
      </Container>
    </StyledModal>
  );
};

export default AddStockCheckModal;
