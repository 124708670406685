import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { v4 as uuid } from 'uuid';
import Toggle from 'react-toggle';
import VMasker from 'vanilla-masker';
import { useTheme } from 'styled-components';
import { FiAlertCircle, FiPlus, FiTrash } from 'react-icons/fi';

import { useToast } from '../../hooks/toast';
import { useCompany } from '../../hooks/company';
import { useSidebar } from '../../hooks/sidebar';
import { useScheduledProducts } from '../../hooks/scheduled_products';

import { PageNames } from '../../enums/pages';
import IScheduledProduct from '../../models/IScheduledProduct';

import FormlessInput from '../../components/FormlessInput';

import {
  Time,
  Title,
  Header,
  AddButton,
  Container,
  Placeholder,
  Description,
  EmptyMessage,
  ErrorMessage,
  TimesContainer,
  DeleteTimeButton,
  CheckboxContainer,
  ToggleContainer,
} from './styles';

import SaveButton from '../../components/SaveButton';

interface IError {
  id: number;
  localId: string;
  message: string;
}

const ScheduledProductsPage: React.FC = () => {
  const theme = useTheme();

  const { company, toggleOption } = useCompany();
  const { addToast } = useToast();
  const { setSelectedPage, selectedPage } = useSidebar();

  const {
    times,
    loading,
    loadTimes,
    saveTimes,
    deleteTime,
    toggleActive,
  } = useScheduledProducts();

  const [errors, setErrors] = useState<IError[]>([]);
  const [localTimes, setLocalTimes] = useState<IScheduledProduct[]>([]);

  useEffect(() => {
    loadTimes();
  }, [loadTimes]);

  useEffect(() => {
    setSelectedPage(PageNames.SCHEDULED_PRODUCTS);
  }, [setSelectedPage]);

  useEffect(() => {
    if (times) {
      setLocalTimes(times);
    }
  }, [times]);

  const handleOnAddClicked = useCallback(() => {
    setLocalTimes(prevState => {
      return [
        {
          id: 0,
          time: '',
          localId: uuid(),
        },
        ...prevState,
      ];
    });
  }, []);

  const handleOnTimeChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, id: number | string) => {
      const { value } = e.target;

      setLocalTimes(prevState => {
        const newState = [...prevState];

        if (typeof id === 'number') {
          const index = newState.findIndex(t => t.id === id);
          newState[index].time = VMasker.toPattern(value, '99:99:99');
        } else {
          const index = newState.findIndex(t => t.localId === id);
          newState[index].time = VMasker.toPattern(value, '99:99:99');
        }

        return newState;
      });
    },
    [],
  );

  const handleDeleteTime = useCallback(
    async (id: number | string) => {
      if (typeof id === 'number') {
        try {
          await deleteTime(id);

          setLocalTimes(prevState => prevState.filter(t => t.id !== id));

          addToast({
            type: 'success',
            description: 'Horário escluído com sucesso.',
          });
        } catch {
          addToast({
            type: 'error',
            description: 'Erro ao deletar horário, tente novamente.',
          });
        }
      } else {
        setLocalTimes(prevState => prevState.filter(t => t.localId !== id));
      }
    },
    [addToast, deleteTime],
  );

  const handleOnToggleActive = useCallback(async () => {
    try {
      await toggleActive();
    } catch {
      addToast({
        type: 'error',
        description: 'Erro ao ativar o agendamento de produtos.',
      });
    }
  }, [addToast, toggleActive]);

  const handleOnSave = useCallback(async () => {
    if (loading) return;

    const errored = localTimes.filter(t => t.time.length < 8);

    if (errored.length > 0) {
      setErrors(
        errored.map(e => {
          return {
            id: e.id,
            localId: e.localId,
            message: 'Preencha o campo corretamente (HH:mm:ss).',
          };
        }),
      );
      return;
    }

    try {
      await saveTimes(localTimes);

      addToast({
        type: 'success',
        description: 'Horários salvos com sucesso!',
      });
    } catch (err) {
      const apiErrors = (err as any)?.response?.data?.errors;
      const keys = Object.keys(apiErrors);

      const indexes = keys.map(k =>
        Number(k.substr(k.indexOf('[') + 1, k.indexOf(']') - 1)),
      );

      const ids = localTimes
        .filter((_, index) => indexes.includes(index))
        .map(t => {
          return {
            id: t.id,
            localId: t.localId,
            message: 'Os valores do horário estão inválido.',
          };
        });

      setErrors(ids);

      addToast({
        type: 'error',
        description: 'Erro ao salvar os horários. Tente novamente!',
      });
    }
  }, [localTimes, loading, addToast, saveTimes]);

  const getError = useMemo(
    () => (item: IScheduledProduct) => {
      return errors.find(e => e.id === item.id && e.localId === item.localId);
    },
    [errors],
  );

  return (
    <Container className="has-custom-scroll-bar-2">
      <Header>
        <h1>{selectedPage}</h1>
        <AddButton
          onClick={handleOnAddClicked}
          disabled={!company?.scheduledProducts}
        >
          <span>Adicionar</span>
          <FiPlus />
        </AddButton>
      </Header>
      <CheckboxContainer>
        <div>
          <ToggleContainer>
            <Toggle
              icons={false}
              checked={company?.scheduledProducts}
              className="enable-schedule-toggle"
              onChange={handleOnToggleActive}
            />
            <Title style={{ marginRight: 16 }}>Ativar produtos agendados</Title>
          </ToggleContainer>
          <ToggleContainer>
            <Toggle
              icons={false}
              checked={company?.requiredScheduledProducts}
              className="enable-required-schedule-toggle"
              disabled={!company?.scheduledProducts}
              onChange={() => toggleOption('requiredScheduledProducts')}
            />
            <Title>
              Obrigatório/Não aceitar pedidos sem horário selecionado.
            </Title>
          </ToggleContainer>
        </div>
        <Description>
          Esses modo permite que o cliente selecione o horário que deseja
          receber seu pedido.
        </Description>
      </CheckboxContainer>
      <TimesContainer
        id="formContainer"
        className="has-custom-scroll-bar"
        disabled={!company?.scheduledProducts}
      >
        {localTimes.map(item => (
          <>
            <Time>
              <FormlessInput
                key={item.id}
                maxLength={8}
                title="Horário"
                value={item.time}
                name="product_time"
                onChange={e => handleOnTimeChanged(e, item.id || item.localId)}
              />
              <DeleteTimeButton
                onClick={() => handleDeleteTime(item.id || item.localId)}
              >
                <FiTrash size={20} color={theme.palette.text_white} />
              </DeleteTimeButton>
            </Time>
            {!!getError(item) && (
              <ErrorMessage>
                <FiAlertCircle size={22} />
                {getError(item)?.message}
              </ErrorMessage>
            )}
          </>
        ))}
        {localTimes.length === 0 && (
          <EmptyMessage>Nenhum horário cadastrado.</EmptyMessage>
        )}
        <Placeholder />
        <SaveButton
          onClick={handleOnSave}
          disabled={!company?.scheduledProducts}
          style={{ marginBottom: 16 }}
        />
      </TimesContainer>
    </Container>
  );
};

export default ScheduledProductsPage;
