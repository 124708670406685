import styled, { css } from 'styled-components';

interface IContainerProps {
  visible: boolean;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;

  bottom: 0;
  position: absolute;
  background: ${({ theme }) => theme.palette.background_secondary};

  transition: height 0.4s;

  overflow: hidden;

  ${({ visible }) =>
    visible
      ? css`
          height: 100%;
        `
      : css`
          height: 0;
        `}
`;

export const Header = styled.header`
  width: 100%;
  max-height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 32px;

  @media (max-width: 460px) {
    max-height: 150px;
  }
`;

export const CategoryInfo = styled.div`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
    margin-right: 16px;

    @media (max-width: 460px) {
      margin-right: 0;
    }
  }

  > div {
    width: 100%;

    display: flex;
    align-items: center;

    @media (max-width: 460px) {
      margin-top: 32px;
    }
  }
`;

export const EditCategory = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px;

  border: 0;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};

  span {
    font-size: 16px;
  }

  svg {
    margin-right: 16px;
  }
`;

export const CategoryName = styled.span`
  font-size: 32px;
  margin-left: 8px;

  border: 0;
  background: none;

  margin-right: 32px;
  padding-bottom: 8px;

  @media (max-width: 800px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
    margin-right: 4px;
  }
`;

export const CategoryTypes = styled.div`
  display: flex;

  padding: 8px 32px;

  button {
    border: 0;
    background: 0;
  }

  span {
    margin-left: 48px;
  }

  @media (max-width: 460px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const IsCategory = styled.button`
  margin-right: 128px;

  @media (max-width: 500px) {
    margin-right: 64px;
  }

  @media (max-width: 460px) {
    margin-right: 0;
  }
`;

export const IsSubcategory = styled.button`
  @media (max-width: 460px) {
    margin-top: 16px;
  }
`;

export const Icon = styled.div`
  display: flex;
  position: relative;

  svg {
    position: absolute;
  }

  svg + svg {
    top: -6px;
    left: 2px;
  }
`;

export const Main = styled.div``;

export const Products = styled.div``;

export const ProductsHeader = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 8px 32px;

  span {
    font-size: 24px;
  }

  button {
    display: flex;
    align-items: center;

    border: 0;
    padding: 8px;
    color: ${({ theme }) => theme.palette.text_white};
    background: ${({ theme }) => theme.palette.primary};

    span {
      font-size: 14px;

      &::after {
        content: 'Adicionar novo';
        margin-left: 8px;
      }
    }

    @media (max-width: 460px) {
      span {
        &::after {
          content: '';
          margin-left: 0;
        }
      }
    }
  }
`;

export const DisplayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 32px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.control};

  button + button {
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid ${({ theme }) => theme.palette.text};
  }

  span {
    font-size: 24px;
    padding-bottom: 8px;
  }
`;

export const DisplayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: right;

  border: 0;
  background: none;

  @media (max-width: 500px) {
    margin-top: 32px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProductsContainer = styled.div`
  height: calc(100vh - 285px);

  display: flex;
  flex-flow: row wrap;
  align-content: start;
  justify-content: space-between;

  overflow: auto;

  padding: 32px;

  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 500px) {
    height: calc(100vh - 295px);

    padding: 16px 16px 32px 0;
  }
`;

export const ProductPlaceholder = styled.div`
  height: 0;
  width: 16%;
`;
