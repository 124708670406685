import styled, { css } from 'styled-components';
import DatePicker from 'react-date-picker';

import 'react-date-picker/dist/DatePicker.css';

interface IInputProps {
  fullWidth?: boolean;
  hasMarginTop?: boolean;
}

export const Container = styled.div<IInputProps>`
  margin-top: ${({ hasMarginTop }) => (hasMarginTop ? '16px' : '8px')};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      .datepicker-custom,
      .react-date-picker {
        * {
          width: 100% !important !important;
        }
      }

      .react-date-picker__inputGroup__day {
        width: 28px !important;
      }

      .react-date-picker__inputGroup__month {
        width: 36px !important;
      }

      .react-date-picker__inputGroup__year {
        width: 48px !important;
      }

      .react-date-picker__calendar-button {
        width: 45px;
      }

      svg {
        width: 40px !important;
        margin: 0 !important;
      }
    `}

  div {
    border: 0;
    cursor: text;
  }

  div.react-date-picker__wrapper {
    width: 100%;
    border-bottom: 1px solid #aaa;
  }
`;

export const Label = styled.span`
  display: flex;
  justify-content: space-between;

  color: #aaa;
  font-size: 12px;
`;

export const StyledInput = styled(DatePicker)`
  width: 100%;

  @media (max-width: 352px) {
    font-size: 12px;
  }
`;
