/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { v4 as uuid } from 'uuid';
import { useTheme } from 'styled-components';
import { FiTrash2, FiX, FiEye, FiEyeOff } from 'react-icons/fi';
import Select, { components, ValueType } from 'react-select';
import { NoticeProps } from 'react-select/src/components/Menu';

import { RiImageAddLine } from 'react-icons/ri';
import { useToast } from '../../hooks/toast';
import { useCategories } from '../../hooks/categories';

import ICategory from '../../models/ICategory';

import FormlessInput from '../FormlessInput';
import CustomCheckbox from '../CustomCheckbox';

import { selectStyles } from '../../styles/select';
import { ECategoryType } from '../../enums/categoryType';
import { getFormattedCategoryType } from '../../utils/categories';
import { IPizzaCrust, IPizzaEdge, IPizzaSize } from '../../models/IPizza';

import NumberInput from '../NumberInput';
import Loading from '../Loading';

import {
  Size,
  Type,
  Edge,
  Edge as Crust,
  Header,
  Scroll,
  Container,
  ModalTitle,
  SaveButton,
  Additionals,
  CloseButton,
  InnerHeader,
  HeaderButtonsContainer,
  StyledModal,
  AddNewButton,
  ActiveButton,
  CancelButton,
  RemoveButton,
  ButtonsContainer,
  CheckboxContainer,
  AdditionalsContainer,
  PricesInputsContainer,
  NumberAndActiveInputContainer,
  AddImageButton,
} from './styles';
import { hasDuplicateValues } from '../../utils/arrays';
import OrderButton from '../OrderButton';
import OrderGroupModal, { IGroupProps } from '../OrderGroupModal';
import api from '../../services/api';
import PizzaCrustActionsMenu from '../PizzaCrustActionsMenu';

interface IAddCategoryModalProps {
  isOpen: boolean;
  category?: ICategory | null;
  onClose: () => void;
}

StyledModal.setAppElement('#root');

const fakeSelectOptions = [
  {
    label: 'Padrão',
    value: ECategoryType.STANDARD,
  },
  {
    label: 'Pizzas',
    value: ECategoryType.PIZZA,
  },
  // {
  //   label: 'Lasanhas',
  //   value: ECategoryType.LASAGNA,
  // },
  // {
  //   label: 'Tortas',
  //   value: ECategoryType.PIE,
  // },
];

interface IValueType {
  label: string;
  value: ECategoryType;
}

interface LocalRef {
  id?: number;
  localId?: string;
  pizzaSizeId?: number | string;
  price: number;
  active: boolean;
}

interface LocalCommonSizeReference {
  sizeReferences: LocalRef[];
}

interface IPizzaSizeImage {
  id: number;
  file: File;
}

type GroupToOrder = 'size' | 'crust' | 'edge' | null;

type LocalPizzaSize = Omit<IPizzaSize, 'pizzaCrusts' | 'pizzaEdges'>;

type LocalPizzaCrust = Omit<IPizzaCrust, 'price' | 'active' | 'id'> &
  LocalCommonSizeReference;

type LocalPizzaEdge = Omit<IPizzaEdge, 'price' | 'active' | 'id'> &
  LocalCommonSizeReference;

function checkSizeRefId(size: LocalPizzaSize, ref: LocalRef) {
  return [size?.id, size?.localId].includes(ref.pizzaSizeId);
}

function transformPizzaCategoryComplementToLocal(
  sizes: IPizzaSize[],
  key: 'pizzaCrusts' | 'pizzaEdges',
): (LocalPizzaCrust | LocalPizzaEdge)[] {
  const local: (LocalPizzaCrust | LocalPizzaEdge)[] = [];

  sizes.forEach(size => {
    size[key].forEach((item, index) => {
      const foundIndex = local.findIndex(
        localItem => localItem?.name === item?.name,
      );

      const newLocalReference = {
        id: item?.id,
        price: item.price,
        active: item?.active,
        pizzaSizeId: size.id,
      };

      const crustItem = (item as unknown) as IPizzaCrust;

      if (foundIndex === -1) {
        return local.push({
          name: item.name,
          deleted: item?.deleted,
          sizeReferences: [newLocalReference],
          localId: uuid(),
          position: item?.position === 999 ? index : item?.position || index,
          ...(key === 'pizzaCrusts' && {
            disableEdges: crustItem?.disableEdges || false,
          }),
        });
      }

      return local[foundIndex].sizeReferences.push(newLocalReference);
    });
  });

  return local;
}

function transformLocalToCategory(
  size: LocalPizzaSize,
  local: (LocalPizzaCrust | LocalPizzaEdge)[],
): (IPizzaCrust | IPizzaEdge)[] {
  const result: (IPizzaCrust | IPizzaEdge)[] = [];

  local.forEach((item, index) => {
    const foundRef = item.sizeReferences.find(ref => checkSizeRefId(size, ref));

    const crustItem = (item as unknown) as IPizzaCrust;

    result.push({
      name: item.name,
      deleted: item.deleted,
      id: foundRef?.id,
      localId: foundRef?.localId,
      price: foundRef?.price || 0,
      active: foundRef?.active || false,
      pizzaSizeId: size?.id || size?.localId,
      position: item?.position || index,
      ...(crustItem?.disableEdges !== undefined && {
        disableEdges: crustItem?.disableEdges,
      }),
    });
  });

  return result;
}

const AddCategoryModal: React.FC<IAddCategoryModalProps> = ({
  isOpen,
  category,
  onClose,
}) => {
  const theme = useTheme();

  const { addToast } = useToast();
  const { saveCategory } = useCategories();

  const [categoryName, setCategoryName] = useState('');
  const [categoryNameError, setCategoryNameError] = useState('');

  const [pizzaSizes, setPizzaSizes] = useState<LocalPizzaSize[]>([]);
  const [pizzaCrusts, setPizzaCrusts] = useState<LocalPizzaCrust[]>([]);
  const [pizzaEdges, setPizzaEdges] = useState<LocalPizzaEdge[]>([]);

  const [isSaving, setIsSaving] = useState(false);
  const [isCategory, setIsCategory] = useState(true);
  const [isSubcategory, setIsSubcategory] = useState(false);

  const [categoryTypeError, setCategoryTypeError] = useState('');
  const [selectedType, setSelectedType] = useState<ECategoryType | null>(null);

  const [groupToOrder, setGroupToOrder] = useState<GroupToOrder>(null);

  const [pizzaSizeImages, setPizzaSizeImages] = useState<IPizzaSizeImage[]>([]);

  useEffect(() => {
    if (category && isOpen) {
      setCategoryName(category.name);

      const sizePizzaCrusts = transformPizzaCategoryComplementToLocal(
        category.pizzaSizes,
        'pizzaCrusts',
      );

      const sizePizzaEdges = transformPizzaCategoryComplementToLocal(
        category.pizzaSizes,
        'pizzaEdges',
      );

      setPizzaSizes(category.pizzaSizes || []);
      setPizzaCrusts(sizePizzaCrusts || []);
      setPizzaEdges(sizePizzaEdges || []);

      setIsCategory(category.isCategory);
      setIsSubcategory(category.isSubcategory);

      setSelectedType(category.type);

      setPizzaSizeImages([]);
    } else {
      setCategoryName('');

      setPizzaEdges([]);
      setPizzaSizes([]);
      setPizzaCrusts([]);

      setIsCategory(true);
      setIsSubcategory(false);

      setSelectedType(null);

      setPizzaSizeImages([]);
    }
  }, [category, isOpen]);

  const handleOnTypeChanged = useCallback((param: ValueType<IValueType>) => {
    setCategoryTypeError('');
    setSelectedType((param as IValueType).value);

    const isSized = [
      ECategoryType.PIE,
      ECategoryType.PIZZA,
      ECategoryType.LASAGNA,
    ].includes((param as IValueType)?.value);

    if (isSized) {
      setIsSubcategory(false);
      setIsCategory(true);
    }
  }, []);

  const selectedGroupToOrder = useMemo(() => {
    if (!groupToOrder) return [];

    interface IGroupForModalProps {
      [key: string]: () => IGroupProps[];
    }

    const newGroupForModal: IGroupForModalProps = {
      size: () =>
        pizzaSizes.map((size, index) => ({
          id: size?.id || size?.localId || '',
          label: size.name,
          position: size?.position || index,
        })),

      crust: () =>
        pizzaCrusts.map((crust, index) => ({
          id: crust?.localId || '',
          label: crust.name,
          position: crust?.position || index,
        })),

      edge: () =>
        pizzaEdges.map((edge, index) => ({
          id: edge?.localId || '',
          label: edge.name,
          position: edge?.position || index,
        })),
    };

    return newGroupForModal[groupToOrder]();
  }, [groupToOrder, pizzaCrusts, pizzaEdges, pizzaSizes]);

  const NoOptionsMessage = (props: NoticeProps<IValueType>) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">Sem opções</span>
      </components.NoOptionsMessage>
    );
  };

  const handleOnIsCategoryChanged = useCallback(() => {
    setIsCategory(prevState => {
      if (!isSubcategory && prevState) {
        return prevState;
      }

      return !prevState;
    });
  }, [isSubcategory]);

  const handleOnIsSubcategoryChanged = useCallback(() => {
    setIsSubcategory(prevState => {
      if (!isCategory && prevState) {
        return prevState;
      }

      return !prevState;
    });
  }, [isCategory]);

  const handleOnOrderButtonClicked = useCallback(
    (groupToOrder: GroupToOrder) => {
      setGroupToOrder(groupToOrder);
    },
    [],
  );

  const handleOnOrderGroupModalClose = useCallback(() => {
    setGroupToOrder(null);
  }, []);

  const handleOnOrderGroupModalConfirm = useCallback(
    (newGroups: IGroupProps[]) => {
      if (!groupToOrder) return null;

      const returnedModalGroup = {
        size: () =>
          setPizzaSizes(old =>
            old
              ? newGroups.map(group => ({
                  ...(old.find(item =>
                    [item.id, item.localId].includes(group.id),
                  ) as LocalPizzaSize),
                  position: group.position,
                }))
              : [],
          ),

        crust: () =>
          setPizzaCrusts(old =>
            old
              ? newGroups.map(group => ({
                  ...(old.find(
                    item => item.localId === group.id,
                  ) as LocalPizzaCrust),
                  position: group.position,
                }))
              : [],
          ),

        edge: () =>
          setPizzaEdges(old =>
            old
              ? newGroups.map(group => ({
                  ...(old.find(
                    item => item.localId === group.id,
                  ) as LocalPizzaEdge),
                  position: group.position,
                }))
              : [],
          ),
      };

      returnedModalGroup[groupToOrder]();

      return setGroupToOrder(null);
    },
    [groupToOrder],
  );

  const handleOnAddSizesClicked = useCallback(() => {
    setPizzaSizes(prevState => {
      const newSize = {
        name: '',
        slices: 8,
        flavours: 1,
        deleted: false,
        position: 0,
        localId: uuid(),
        pizzaCrusts: [],
        pizzaEdges: [],
      };

      const newRef = {
        active: true,
        price: 0,
        localId: newSize.localId,
      };

      setPizzaCrusts(prevCrustsState =>
        prevCrustsState.map(crust => ({
          ...crust,
          sizeReferences: [...crust.sizeReferences, newRef],
        })),
      );

      setPizzaEdges(prevEdgesState =>
        prevEdgesState.map(edge => ({
          ...edge,
          sizeReferences: [...edge.sizeReferences, newRef],
        })),
      );

      return [newSize, ...prevState].map((item, index) => ({
        ...item,
        position: index,
      }));
    });
  }, []);

  const handleOnAddCrustClicked = useCallback(() => {
    setPizzaCrusts(prevState =>
      [
        {
          name: '',
          deleted: false,
          localId: uuid(),
          price: 0,
          position: 0,
          sizeReferences: pizzaSizes.map(size => ({
            localId: uuid(),
            price: 0,
            active: true,
            pizzaSizeId: size?.localId || size?.id,
          })),
          disableEdges: false,
        },
        ...prevState,
      ].map((item, index) => ({
        ...item,
        position: index,
      })),
    );
  }, [pizzaSizes]);

  const handleOnAddEdgeClicked = useCallback(() => {
    setPizzaEdges(prevState =>
      [
        {
          name: '',
          deleted: false,
          localId: uuid(),
          price: 0,
          position: 0,
          sizeReferences: pizzaSizes.map(size => ({
            localId: uuid(),
            price: 0,
            active: true,
            pizzaSizeId: size?.localId || size?.id,
          })),
        },
        ...prevState,
      ].map((item, index) => ({
        ...item,
        position: index,
      })),
    );
  }, [pizzaSizes]);

  const handleOnSizeChanged = useCallback(
    (value: string, type: 'SLICES' | 'NAME' | 'FLAVOURS', index: number) => {
      setPizzaSizes(prevState => {
        const next = [...prevState];

        if (type === 'SLICES') {
          next[index].slices = Number(value);
          return next;
        }

        if (type === 'FLAVOURS') {
          next[index].flavours = Number(value);
          return next;
        }

        next[index].name = value;
        return next;
      });
    },
    [],
  );

  const handleOnCrustNameChanged = useCallback(
    (crustIndex: number, value: string) => {
      setPizzaCrusts(prevState =>
        prevState.map((crust, index) =>
          index === crustIndex ? { ...crust, name: value } : crust,
        ),
      );
    },
    [],
  );

  const handleOnCrustEnableEdgesChanged = useCallback(
    (crustIndex: number, value: boolean) => {
      setPizzaCrusts(prevState =>
        prevState.map((crust, index) =>
          index === crustIndex ? { ...crust, disableEdges: !value } : crust,
        ),
      );
    },
    [],
  );

  const handleOnEdgeNameChanged = useCallback(
    (edgesIndex: number, value: string) => {
      setPizzaEdges(prevState =>
        prevState.map((edge, index) =>
          index === edgesIndex ? { ...edge, name: value } : edge,
        ),
      );
    },
    [],
  );

  const handleOnCrustPriceChanged = useCallback(
    (size: LocalPizzaSize, crustIndex: number, value: number) => {
      setPizzaCrusts(prevState =>
        prevState.map((crust, index) => {
          if (index !== crustIndex) return crust;

          return {
            ...crust,
            sizeReferences: crust.sizeReferences.map(ref => {
              if (!checkSizeRefId(size, ref)) return ref;
              return {
                ...ref,
                price: value,
              };
            }),
          };
        }),
      );
    },
    [],
  );

  const handleOnEdgePriceChanged = useCallback(
    (size: LocalPizzaSize, edgeIndex: number, value: number) => {
      setPizzaEdges(prevState =>
        prevState.map((edge, index) => {
          if (index !== edgeIndex) return edge;

          return {
            ...edge,
            sizeReferences: edge.sizeReferences.map(ref => {
              if (!checkSizeRefId(size, ref)) return ref;
              return {
                ...ref,
                price: value,
              };
            }),
          };
        }),
      );
    },
    [],
  );

  const handleOnCrustToggleActive = useCallback(
    (size: LocalPizzaSize, crustIndex: number) => {
      setPizzaCrusts(prevState =>
        prevState.map((crust, index) => {
          if (index !== crustIndex) return crust;

          return {
            ...crust,
            sizeReferences: crust.sizeReferences.map(ref => {
              if (!checkSizeRefId(size, ref)) return ref;
              return {
                ...ref,
                active: !(ref?.active || false),
              };
            }),
          };
        }),
      );
    },
    [],
  );

  const handleOnEdgeToggleActive = useCallback(
    (size: LocalPizzaSize, edgeIndex: number) => {
      setPizzaEdges(prevState =>
        prevState.map((edge, index) => {
          if (index !== edgeIndex) return edge;

          return {
            ...edge,
            sizeReferences: edge.sizeReferences.map(ref => {
              if (!checkSizeRefId(size, ref)) return ref;
              return {
                ...ref,
                active: !(ref?.active || false),
              };
            }),
          };
        }),
      );
    },
    [],
  );

  const handleOnPizzaSizeImageChange = useCallback(
    (id?: number, file?: File | null) => {
      if (!file || !id) {
        return null;
      }

      setPizzaSizeImages(old => {
        const foundIndex = old.findIndex(image => image.id === id);

        if (foundIndex <= -1) {
          return [...old, { id, file }];
        }

        const newValue = old;

        newValue[foundIndex].file = file;

        return newValue;
      });
    },
    [],
  );

  const handleOnDeleteSize = useCallback((id: string | number) => {
    setPizzaSizes(prevState => {
      return prevState
        .map(size =>
          (typeof id === 'number' ? size?.id === id : size?.localId === id)
            ? { ...size, deleted: true }
            : size,
        )
        .map((item, index) => ({
          ...item,
          position: index,
        }));
    });

    setPizzaCrusts(prevState =>
      prevState.map((crust, index) => {
        return {
          ...crust,
          position: index,
          sizeReferences: crust.sizeReferences.filter(
            ref => ref?.id === id || ref?.localId === id,
          ),
        };
      }),
    );

    setPizzaEdges(prevState =>
      prevState.map((edge, index) => {
        return {
          ...edge,
          position: index,
          sizeReferences: edge.sizeReferences.filter(
            ref => ref?.id === id || ref?.localId === id,
          ),
        };
      }),
    );
  }, []);

  const handleOnDeleteCrust = useCallback((crustIndex: number) => {
    setPizzaCrusts(prevState =>
      prevState
        .filter((_, index) => index !== crustIndex)
        .map((item, index) => ({
          ...item,
          position: index,
        })),
    );
  }, []);

  const handleOnDeleteEdge = useCallback((crustIndex: number) => {
    setPizzaEdges(prevState =>
      prevState
        .filter((_, index) => index !== crustIndex)
        .map((item, index) => ({
          ...item,
          position: index,
        })),
    );
  }, []);

  const handleOnCategoryNameChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setCategoryName(value);
      setCategoryNameError('');
    },
    [],
  );

  const isSizedCategory = useMemo(() => {
    if (selectedType)
      return [
        ECategoryType.PIE,
        ECategoryType.PIZZA,
        ECategoryType.LASAGNA,
      ].includes(selectedType);

    return false;
  }, [selectedType]);

  const selectValue = useMemo(() => {
    if (selectedType)
      return {
        label: getFormattedCategoryType(selectedType),
        value: selectedType,
      };

    return null;
  }, [selectedType]);

  const activeSizes = useMemo<LocalPizzaSize[]>(() => {
    // console.log('pizzaSizes', pizzaSizes);
    return pizzaSizes.filter(s => !s.deleted);
  }, [pizzaSizes]);

  const activeCrusts = useMemo<LocalPizzaCrust[]>(() => {
    // console.log('pizzaCrusts', pizzaCrusts);
    return pizzaCrusts.filter(c => !c.deleted);
  }, [pizzaCrusts]);

  const activeEdges = useMemo<LocalPizzaEdge[]>(() => {
    // console.log('pizzaEdges', pizzaEdges);
    return pizzaEdges.filter(e => !e.deleted);
  }, [pizzaEdges]);

  const handleOnSaveCategory = useCallback(async () => {
    let errors = false;

    if (categoryName.length < 3) {
      setCategoryNameError(
        !categoryName
          ? 'O nome da categoria é obrigatório.'
          : 'O nome da categoria precisa conter, no mínimo, 3 caracteres.',
      );

      errors = true;
    }

    if (!selectedType) {
      setCategoryTypeError('Selecione o tipo da categoria.');

      errors = true;
    }

    if (errors) return;

    try {
      // let pizzaSizeError = '';

      // activeSizes.every(size => {
      //   if (size.slices >= size.flavours) {
      //     if (size.slices % size.flavours !== 0) {
      //       pizzaSizeError = `A quantidade de sabores em ${size.name} deve ser divisível pela quantidade de pedaços`;
      //       return false;
      //     }
      //   } else {
      //     pizzaSizeError = `A quantidade de pedaços em ${size.name} deve ser maior ou igual a quantidade de sabores`;
      //     return false;
      //   }

      //   return true;
      // });

      // if (pizzaSizeError) {
      //   return addToast({
      //     type: 'error',
      //     description: pizzaSizeError,
      //   });
      // }

      if (hasDuplicateValues(activeSizes, value => value.name)) {
        return addToast({
          type: 'error',
          description: 'O nome de cada tamanho deve ser único',
        });
      }

      if (hasDuplicateValues(pizzaCrusts, value => value.name)) {
        return addToast({
          type: 'error',
          description: 'O nome de cada massa deve ser único',
        });
      }

      if (hasDuplicateValues(pizzaEdges, value => value.name)) {
        return addToast({
          type: 'error',
          description: 'O nome de cada borda deve ser único',
        });
      }

      const isEdit = !!category?.id;

      const sizes: IPizzaSize[] = [];

      pizzaSizes
        .filter(size => (!size?.id ? !size?.deleted : true))
        .forEach(size => {
          const crusts = transformLocalToCategory(size, pizzaCrusts);
          const edges = transformLocalToCategory(size, pizzaEdges);

          sizes.push({
            ...size,
            pizzaCrusts: crusts,
            pizzaEdges: edges,
          });
        });

      setIsSaving(true);

      if (isEdit && pizzaSizeImages.length > 0) {
        const promises = pizzaSizeImages.map(async pizzaImage => {
          try {
            const data = new FormData();

            data.append('image', pizzaImage.file);

            await api.patch(
              `/restricted/categories/${category?.id}/pizza-sizes/${pizzaImage.id}/image`,
              data,
            );
          } catch (err) {
            console.log(err);
          }
        });

        await Promise.all(promises);
      }

      await saveCategory(
        {
          pizzaSizes: sizes,
          isCategory,
          isSubcategory,
          isActive: true,
          name: categoryName,
          id: category?.id || 0,
          type: selectedType || ECategoryType.STANDARD,
        },
        category?.id || 0,
      );

      addToast({
        type: 'success',
        description: `Categoria "${categoryName}" ${
          isEdit ? 'alterada' : 'criada'
        } com sucesso.`,
      });

      onClose();
      return setIsSaving(false);
    } catch (err) {
      const errors = (err as any).response?.data?.errors?.messages;

      addToast({
        type: 'error',
        description:
          (Array.isArray(errors) && errors[0]) || 'Ocorreu um erro inesperado.',
      });
      return setIsSaving(false);
    }
  }, [
    categoryName,
    selectedType,
    activeSizes,
    pizzaCrusts,
    pizzaEdges,
    pizzaSizes,
    pizzaSizeImages,
    isCategory,
    isSubcategory,
    category,
    saveCategory,
    addToast,
    onClose,
  ]);

  return (
    <StyledModal
      isOpen={isOpen}
      shouldCloseOnEsc
      id="add-category-Modal"
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      style={{
        overlay: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <Container>
        <Scroll className="has-custom-scroll-bar-2">
          <Header>
            <ModalTitle>Nova categoria</ModalTitle>
            <CloseButton onClick={onClose}>
              <FiX size={20} />
            </CloseButton>
          </Header>
          <div className="error-container">
            <FormlessInput
              placeholder="Nome"
              name="category_name"
              value={categoryName}
              onChange={handleOnCategoryNameChanged}
            />
            {categoryNameError && (
              <span className="error">{categoryNameError}</span>
            )}
          </div>
          <Type>
            <Select
              isSearchable
              value={selectValue}
              styles={selectStyles}
              isDisabled={!!category?.id}
              options={fakeSelectOptions}
              onChange={handleOnTypeChanged}
              placeholder="Tipo da categoria"
              menuPortalTarget={document.body}
              components={{ NoOptionsMessage }}
            />
            <div className="error-container">
              {categoryTypeError && (
                <span className="error">{categoryTypeError}</span>
              )}
            </div>
          </Type>
          <CheckboxContainer>
            {!isSizedCategory && (
              <>
                <CustomCheckbox
                  id="is-category"
                  text="Categoria"
                  checked={isCategory}
                  onChange={handleOnIsCategoryChanged}
                />
                <CustomCheckbox
                  id="is-subcategory"
                  text="Subcategoria"
                  checked={isSubcategory}
                  onChange={handleOnIsSubcategoryChanged}
                />
              </>
            )}
          </CheckboxContainer>
          {isSizedCategory && (
            <>
              <AdditionalsContainer>
                <InnerHeader>
                  <div>
                    <h4>Tamanhos</h4>
                    <span>Ex.: Broto, Grande, Média</span>
                  </div>
                  <HeaderButtonsContainer>
                    <OrderButton
                      onClick={() => handleOnOrderButtonClicked('size')}
                    />
                    <AddNewButton onClick={handleOnAddSizesClicked}>
                      Adicionar
                    </AddNewButton>
                  </HeaderButtonsContainer>
                </InnerHeader>
                {activeSizes.length > 0 ? (
                  <Additionals>
                    {activeSizes.map((size, index) => (
                      <Size key={`size-${size?.id || size?.localId}`}>
                        <FormlessInput
                          value={size.name}
                          title="Tamanho"
                          placeholder="Tamanho"
                          className="size_name_input"
                          name={`size_name_${index}`}
                          onChange={e =>
                            handleOnSizeChanged(e.target.value, 'NAME', index)
                          }
                        />
                        <FormlessInput
                          min={0}
                          type="number"
                          title="Pedaços"
                          value={size.slices}
                          placeholder="Pedaços"
                          name={`size_slices_${index}`}
                          onChange={e =>
                            handleOnSizeChanged(e.target.value, 'SLICES', index)
                          }
                        />
                        <FormlessInput
                          min={0}
                          type="number"
                          title="Sabores"
                          value={size.flavours}
                          placeholder="Sabores"
                          name={`size_flavours_${index}`}
                          onChange={e =>
                            handleOnSizeChanged(
                              e.target.value,
                              'FLAVOURS',
                              index,
                            )
                          }
                        />
                        {size.id && (
                          <>
                            <AddImageButton
                              htmlFor={`pizzaSizeImage-${size.id}`}
                            >
                              <input
                                id={`pizzaSizeImage-${size.id}`}
                                type="file"
                                onChange={event =>
                                  handleOnPizzaSizeImageChange(
                                    size.id,
                                    event.target?.files
                                      ? event.target?.files[0]
                                      : null,
                                  )
                                }
                                accept="image/x-png,image/jpeg"
                              />
                              <RiImageAddLine
                                size={22}
                                color={theme.palette.text_white}
                              />
                            </AddImageButton>
                          </>
                        )}
                        <RemoveButton
                          onClick={() =>
                            handleOnDeleteSize(size.id || size.localId || 0)
                          }
                        >
                          <FiTrash2
                            size={22}
                            color={theme.palette.text_white}
                          />
                        </RemoveButton>
                      </Size>
                    ))}
                  </Additionals>
                ) : (
                  <span className="empty-message">
                    Nenhum tamanho cadastrado.
                  </span>
                )}
              </AdditionalsContainer>
            </>
          )}
          {selectedType === ECategoryType.PIZZA && (
            <>
              <AdditionalsContainer>
                <InnerHeader>
                  <div>
                    <h4>Massas</h4>
                    <span>Ex.: Fina, Grossa</span>
                  </div>
                  <HeaderButtonsContainer>
                    <OrderButton
                      onClick={() => handleOnOrderButtonClicked('crust')}
                    />
                    <AddNewButton onClick={handleOnAddCrustClicked}>
                      Adicionar
                    </AddNewButton>
                  </HeaderButtonsContainer>
                </InnerHeader>
                {activeCrusts.length > 0 ? (
                  <Additionals>
                    {activeCrusts.map((crust, crustIndex) => (
                      <Crust key={`crust-${crust?.localId}`}>
                        <FormlessInput
                          value={crust.name}
                          title="Nome da massa"
                          placeholder="Nome da massa"
                          className="crust_name_input"
                          name={`crust_name_${crustIndex}`}
                          onChange={e =>
                            handleOnCrustNameChanged(crustIndex, e.target.value)
                          }
                        />
                        <PizzaCrustActionsMenu
                          onDelete={() => handleOnDeleteCrust(crustIndex)}
                          enableEdges={!crust?.disableEdges}
                          setEnableEdges={value =>
                            handleOnCrustEnableEdgesChanged(crustIndex, value)
                          }
                        />
                        <PricesInputsContainer>
                          {activeSizes.map((size, sizeIndex) => {
                            const ref = crust.sizeReferences.find(currentRef =>
                              checkSizeRefId(size, currentRef),
                            );

                            return (
                              <NumberAndActiveInputContainer
                                key={`${
                                  size?.id || size?.localId
                                }-${sizeIndex.toString()}-crust`}
                                active={ref?.active}
                              >
                                <NumberInput
                                  title={`Preço da massa para ${size.name.toUpperCase()}`}
                                  name={`crust-name${crustIndex}-${
                                    size?.id || size?.localId
                                  }`}
                                  defaultValue={ref?.price}
                                  onChange={value =>
                                    handleOnCrustPriceChanged(
                                      size,
                                      crustIndex,
                                      value,
                                    )
                                  }
                                />
                                <ActiveButton
                                  onClick={() =>
                                    handleOnCrustToggleActive(size, crustIndex)
                                  }
                                >
                                  {ref?.active ? (
                                    <FiEye
                                      size={18}
                                      color={theme.palette.text_white}
                                    />
                                  ) : (
                                    <FiEyeOff
                                      size={18}
                                      color={theme.palette.text_white}
                                    />
                                  )}
                                </ActiveButton>
                              </NumberAndActiveInputContainer>
                            );
                          })}
                        </PricesInputsContainer>
                      </Crust>
                    ))}
                  </Additionals>
                ) : (
                  <span className="empty-message">
                    Nenhuma massa cadastrada.
                  </span>
                )}
              </AdditionalsContainer>
              <AdditionalsContainer>
                <InnerHeader>
                  <div>
                    <h4>Bordas</h4>
                    <span>
                      Ex.: Padrão, Recheada (Cheddar), Recheada (Nutella)
                    </span>
                  </div>
                  <HeaderButtonsContainer>
                    <OrderButton
                      onClick={() => handleOnOrderButtonClicked('edge')}
                    />
                    <AddNewButton onClick={handleOnAddEdgeClicked}>
                      Adicionar
                    </AddNewButton>
                  </HeaderButtonsContainer>
                </InnerHeader>
                {activeEdges.length > 0 ? (
                  <Additionals>
                    {activeEdges.map((edge, edgeIndex) => (
                      <Edge key={`edge-${edge?.localId}`}>
                        <FormlessInput
                          value={edge.name}
                          title="Nome da borda"
                          placeholder="Nome da borda"
                          className="edge_type_input"
                          name={`edge_type_${edgeIndex}`}
                          onChange={e =>
                            handleOnEdgeNameChanged(edgeIndex, e.target.value)
                          }
                        />
                        <RemoveButton
                          onClick={() => handleOnDeleteEdge(edgeIndex)}
                        >
                          <FiTrash2
                            size={22}
                            color={theme.palette.text_white}
                          />
                        </RemoveButton>
                        <PricesInputsContainer>
                          {activeSizes.map((size, sizeIndex) => {
                            const ref = edge.sizeReferences.find(currentRef =>
                              checkSizeRefId(size, currentRef),
                            );

                            return (
                              <NumberAndActiveInputContainer
                                key={`${
                                  size?.id || size?.localId
                                }-${sizeIndex.toString()}-edge`}
                                active={ref?.active}
                              >
                                <NumberInput
                                  title={`Preço da borda para ${size.name.toUpperCase()}`}
                                  name={`edge-name${edgeIndex}-${
                                    size?.id || size?.localId
                                  }`}
                                  defaultValue={ref?.price}
                                  onChange={value =>
                                    handleOnEdgePriceChanged(
                                      size,
                                      edgeIndex,
                                      value,
                                    )
                                  }
                                />
                                <ActiveButton
                                  onClick={() =>
                                    handleOnEdgeToggleActive(size, edgeIndex)
                                  }
                                >
                                  {ref?.active ? (
                                    <FiEye
                                      size={18}
                                      color={theme.palette.text_white}
                                    />
                                  ) : (
                                    <FiEyeOff
                                      size={18}
                                      color={theme.palette.text_white}
                                    />
                                  )}
                                </ActiveButton>
                              </NumberAndActiveInputContainer>
                            );
                          })}
                        </PricesInputsContainer>
                      </Edge>
                    ))}
                  </Additionals>
                ) : (
                  <span className="empty-message">
                    Nenhuma borda cadastrada.
                  </span>
                )}
              </AdditionalsContainer>
            </>
          )}
          <ButtonsContainer>
            <CancelButton onClick={onClose}>Cancelar</CancelButton>
            <SaveButton onClick={handleOnSaveCategory}>
              {isSaving ? (
                <Loading color="text_white" stroke={2} radius={16} />
              ) : (
                'Salvar'
              )}
            </SaveButton>
          </ButtonsContainer>
        </Scroll>
        <OrderGroupModal
          id="pizzaOrderModal"
          isOpen={groupToOrder !== null}
          groups={selectedGroupToOrder}
          onClose={handleOnOrderGroupModalClose}
          onConfirm={handleOnOrderGroupModalConfirm}
        />
      </Container>
    </StyledModal>
  );
};

export default AddCategoryModal;
