import styled from 'styled-components';

interface IContainerProps {
  notAuthed: boolean;
}

interface INextStatusButtonProps {
  color: string;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;

  height: auto;

  display: flex;
  flex-direction: column;

  position: relative;

  padding: 16px;
  margin-bottom: 16px;

  cursor: pointer;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};

  @media (max-width: 401) {
    height: 229px;
  }
`;

export const Content = styled.div``;

export const OrderPlacedStatus = styled.div`
  width: 110px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.palette.status_placed};
  background: ${({ theme }) => theme.palette.status_placed_transparency};

  span {
    font-size: 12px;
    text-align: center;
    display: inline-block;
  }
`;

export const OrderApprovedStatus = styled.div`
  width: 110px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.palette.status_approved};
  background: ${({ theme }) => theme.palette.status_approved_transparency};

  span {
    font-size: 12px;
    text-align: center;
    display: inline-block;
  }
`;

export const OrderCanceledStatus = styled.div`
  width: 110px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.palette.status_canceled};
  background: ${({ theme }) => theme.palette.status_canceled_transparency};

  span {
    font-size: 12px;
    text-align: center;
    display: inline-block;
  }
`;

export const OrderFinishedStatus = styled.div`
  width: 110px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.palette.status_approved};
  background: ${({ theme }) => theme.palette.status_approved_transparency};

  span {
    font-size: 12px;
    text-align: center;
    display: inline-block;
  }
`;

export const OrderInPrepareStatus = styled.div`
  width: 110px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.palette.status_in_prepare};
  background: ${({ theme }) => theme.palette.status_in_prepare_transparency};

  span {
    font-size: 12px;
    text-align: center;
    display: inline-block;
  }
`;

export const OrderInTransitStatus = styled.div`
  width: 110px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.palette.status_in_transit};
  background: ${({ theme }) => theme.palette.status_in_transit_transparency};

  span {
    font-size: 12px;
    text-align: center;
    display: inline-block;
  }
`;

export const OrderCompletedStatus = styled.div`
  width: 110px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.palette.status_approved};
  background: ${({ theme }) => theme.palette.status_approved_transparency};

  span {
    font-size: 12px;
    text-align: center;
    display: inline-block;
  }
`;

export const Left = styled.div`
  flex: 1;
`;

export const Right = styled.div`
  top: 16px;
  right: 16px;
  position: absolute;
`;

export const OrderInfo = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 16px;
  }

  span.not-authed {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.error};
  }

  strong {
    font-size: 13px;
    font-weight: bold;
  }
`;

export const TopOrderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const Time = styled.div`
  display: flex;
  flex-direction: column;

  margin-right: 64px;
  margin-bottom: -24px;

  span {
    font-size: 14px;
  }

  strong {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    margin-bottom: unset;
    margin-right: unset;

    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 8px;
  }
`;

export const OrderSummary = styled.div`
  display: flex;
  align-items: stretch;

  margin-top: 8px;

  > div + div {
    margin-left: 16px;
  }

  .first-media {
    @media (max-width: 860px) {
      display: none;
    }
  }

  .second-media {
    @media (max-width: 740px) {
      display: none;
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    > div + div {
      margin-left: 0px;
    }
  }
`;

export const Summary = styled.div`
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
  }
`;

export const OrderInfoItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  position: relative;

  span.label {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text_light};
  }

  span.value {
    font-size: 14px;
  }

  .second {
    display: none;
  }

  &:hover {
    .first {
      display: none;
    }
    .second {
      display: block;
    }
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NextStatusButton = styled.button<INextStatusButtonProps>`
  width: 190px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  padding: 0 8px;

  color: ${({ color }) => color};
  background: ${({ color }) => `${color}30`};

  @media (max-width: 450px) {
    width: 150px;
    padding: 0 4px;
    font-size: 12px;
  }

  @media (max-width: 415px) {
    width: 110px;
    font-size: 11px;
  }
`;

export const ApproveOrderButton = styled.button`
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;

  cursor: pointer;

  background: ${({ theme }) => theme.palette.status_approved_transparency};
`;

export const PrintOrderButton = styled.button`
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  cursor: pointer;

  background: ${({ theme }) => theme.palette.status_placed_transparency};

  @media (max-width: 700px) {
    display: none;
  }
`;

export const CancelOrderButton = styled.button`
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  cursor: pointer;

  background: ${({ theme }) => theme.palette.status_canceled_transparency};
  margin-left: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > div + div {
    margin-left: 16px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    > div + div {
      margin-left: 0px;
    }
  }
`;

export const HorizontalItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text_light};
  }

  strong {
  }
`;

export const SummaryHorizontal = styled.div`
  display: flex;

  > div + div {
    margin-left: 16px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;

    > div + div {
      margin-left: 0px;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 16px;

  > div {
    display: flex;
  }

  @media (max-width: 375px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }
`;
