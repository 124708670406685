import Ripple from 'react-ripples';
import styled from 'styled-components';

import topRight from '../../assets/top-right.svg';
import bottomLeft from '../../assets/bottom-left.svg';

export const Container = styled.div`
  height: 100vh;

  position: relative;

  display: flex;
  flex-direction: column;

  min-height: 860px;

  overflow: hidden;

  @media (max-height: 860px) {
    height: 860px;
  }
`;

export const Header = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100px;
  height: 60px;

  cursor: pointer;

  margin: 32px;

  span {
    margin-left: 16px;
  }

  @media (min-width: 541px) {
    padding-bottom: 8px;
    border-bottom: 2px solid #db1622;
  }
`;

export const Content = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: -132px;
  padding: 0 32px;

  img {
    width: 500px;
    height: 500px;

    @media (max-width: 1200px) {
      width: 400px;
      height: 400px;
    }

    @media (max-width: 1080px) {
      display: none;
    }
  }

  @media (max-width: 470px) {
    align-items: flex-start;
    margin-top: 72px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 6;
  margin-left: 128px;

  @media (max-width: 1080px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const BorderContainer = styled.div`
  width: 100%;
  margin-top: 32px;

  display: flex;
  justify-content: flex-end;
`;

export const Border = styled.div`
  width: 40px;
  border-top: 4px solid #db1622;
  margin-bottom: 16px;
`;

export const Title = styled.strong`
  font-size: 40px;
  font-weight: 900;

  @media (max-width: 470px) {
    max-width: 250px;
    font-size: 30px;
  }
`;

export const Message = styled.span`
  font-size: 16px;
  color: #aaa;

  @media (min-width: 1081px) {
    max-width: 400px;
  }

  @media (max-width: 470px) {
    font-size: 14px;
  }
`;

export const InputContainer = styled.div`
  width: 600px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  margin-top: 32px;

  > div {
    width: 100%;
  }

  > div + div {
    margin-top: 32px;
  }

  @media (max-width: 1300px) {
    width: 500px;
  }

  @media (max-width: 1080px) {
    width: 100%;
  }
`;

export const BottomLeft = styled.div`
  height: 100%;
  width: 102%;
  background: url(${bottomLeft}) no-repeat bottom left;
  position: absolute;
  z-index: -1;

  margin-left: -1px;
  margin-bottom: -1px;
`;

export const TopRight = styled.div`
  height: 100vh;
  width: 100%;
  background: url(${topRight}) no-repeat top right;
  mask: url(${topRight}) no-repeat top right;
  position: absolute;
  z-index: 4;

  margin-top: -1px;
  overflow: hidden;
  header {
    position: absolute;
    top: 0;
    left: 0;
    color: ${({ theme }) => theme.palette.text_white};

    @media (max-width: 470px) {
      top: -5px;
    }
  }
`;

export const ButtonRipple = styled(Ripple)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60px;
  min-height: 60px;

  margin-top: 32px;

  border: 0;
  border-radius: 32px 0 32px 32px;

  background: ${({ theme }) => theme.palette.primary};

  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.4s;
  cursor: pointer;

  &:hover {
    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);
  }

  span {
    color: #fff;
  }

  @media (min-width: 471px) {
    display: none;
  }
`;

export const SuccessMessage = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 930px) {
    flex-direction: column;

    > * {
      text-align: center;
    }
  }
`;

export const Wrapper = styled.div`
  margin-left: 64px;
  z-index: 6;
  display: flex;
  flex-direction: column;

  span {
    font-size: 20px;
    max-width: 600px;
  }

  strong {
    font-size: 40px;
    font-weight: 500;

    max-width: 600px;

    margin-bottom: 16px;
  }

  @media (max-width: 930px) {
    margin-left: 0;
    margin-top: 32px;

    strong {
      font-size: 24px;
    }

    span {
      font-size: 20px;
    }
  }
`;

export const AccessPortalButton = styled.button`
  border: none;
  background: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.text_white};
  font-size: 1.2rem;
  padding: 6px 0;
  margin-top: 12px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
`;
