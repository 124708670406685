import React from 'react';

import { Container, Title, Content } from './styles';

interface ISpanProps {
  title: string;
  content: string;
  className?: string;
}

const Span: React.FC<ISpanProps> = ({ title, content, className }) => {
  return (
    <Container className={className}>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Container>
  );
};

export default Span;
