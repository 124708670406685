import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 0 32px;
  z-index: 10;
  width: 100%;

  @media (max-width: 500px) {
    padding: 0 16px;
  }
`;

export const Header = styled.header`
  font-size: 24px;
  padding: 24px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const Suggestion = styled.div`
  user-select: none;
  max-width: 100%;
  padding-bottom: 8px;

  .wrapper {
    position: relative;
    border-radius: 6px;
    padding: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    box-shadow: ${({ theme }) => theme.shadow.box_shadow_hover};
    background: ${({ theme }) => theme.palette.background_white};

    svg {
      color: ${({ theme }) => theme.palette.text_light};
    }

    .edit {
      font-weight: bold;
      font-size: 18px;
    }
  }

  @media (max-width: 600px) {
    .wrapper {
      min-width: 80vw;
      align-items: flex-start;
    }
  }
`;

export const InfoWrapper = styled.div`
  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  & > span {
    max-width: 240px;
  }
`;

export const SuggestionTitle = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25%;
`;

export const SuggestionImage = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const EditSuggestionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-left: auto;
`;

export const EditSuggestion = styled.button`
  padding: 6px;
  border-radius: 32px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;

export const AddButtonWrapper = styled.div`
  position: relative;

  width: 100%;
  margin: 16px auto;
  margin-bottom: 32px;
`;

export const AddButton = styled.button`
  margin: 0 auto;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.palette.control};
  padding: 4px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    min-width: 40%;
    min-height: 1px;
    max-height: 1px;
    background: ${({ theme }) => theme.palette.control};
    top: 0;
    bottom: 0;
    margin: auto 0;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  svg {
    color: ${({ theme }) => theme.palette.control};
  }
`;

export const NoSuggestionsMessage = styled.span`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  svg {
    margin: 4px 2px;
  }
`;

export const Warning = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  width: 100%;
  display: hidden;
  span {
    color: ${({ theme }) => theme.palette.warning};
    font-size: 16px;
  }
  svg {
    stroke: ${({ theme }) => theme.palette.warning};
  }

  @media (max-width: 600px) {
    span {
      font-size: 14px;
    }
  }
`;
