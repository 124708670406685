import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f4f4f6;
  padding: 0 16px 16px 0;

  @media (max-width: 480px) {
    padding-bottom: 64px;
  }
`;

export const InnerContent = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: height 0.4s;

  > div {
    width: 100%;
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  max-height: 100px;
  padding: 24px 16px 0px 32px;
  gap: 16px;
`;

export const PageInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: 16px;

    @media (min-width: 1271px) {
      display: none;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Messages = styled.div`
  user-select: none;
  width: 100%;
  padding-bottom: 16px;
`;

export const PageName = styled.strong`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 32px;
  margin-left: 8px;

  @media (max-width: 1027px) {
    display: none;
  }
`;

export const AddMessageButton = styled.button`
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-self: flex-end;

  border: 0;
  color: #fff;
  padding: 8px;
  background: #db1622;

  span {
    font-size: 14px;

    &::after {
      content: 'Adicionar novo';
      margin-left: 8px;
    }
  }
`;

export const DraggableContainer = styled.div`
  padding: 32px 16px 32px 32px;
  overflow-y: scroll;

  > div {
    width: 100%;
  }
`;
