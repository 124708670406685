import styled from 'styled-components';
import { Form } from '@unform/web';
import Radio from '../../components/Radio';

export const Container = styled.div`
  padding: 8px 22px 16px 22px;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.background};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  @supports (-webkit-touch-callout: none) {
    padding-bottom: 64px;
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  h1 {
    font-size: 1.8rem;
    white-space: nowrap;

    @media (max-width: 600px) {
      display: none;
    }
  }
  padding: 0.8rem 0;

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media (max-width: 1024px) {
    > div {
      width: 100%;
    }
  }
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;

  border: none;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};
  border-radius: 4px;
  margin-left: auto;
  padding: 0.5rem 1rem;

  svg {
    margin-left: 8px;
  }

  @media (max-width: 800px) {
    position: fixed;
    bottom: 8rem;
    right: 1rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 2rem;
    box-shadow: ${({ theme }) => theme.shadow.box_shadow};
    z-index: 10;

    span {
      display: none;
    }

    svg {
      margin: 0;
      min-width: 1.8rem;
      min-height: 1.8rem;
    }
  }
`;

export const NamePriceContainer = styled(Form)`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: 16px;

  > div {
    margin-bottom: 0.8rem;
  }

  flex: 1 1 auto;
  overflow-y: auto;
`;

export const Placeholder = styled.div`
  width: 1px;
  min-height: 1rem;
  opacity: 0;

  @media (max-width: 800px) {
    min-height: 4rem;
  }
`;
export const RadioContainer = styled(Radio.Group)`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: -4px;

  input {
    display: none;
  }

  label {
    user-select: none;
    cursor: pointer;
    margin-right: 1.5rem;
    font-size: 1.2rem;
    padding-bottom: 8px;
    border-bottom: 2px solid ${({ theme }) => theme.palette.control};
    transition: border-color 200ms ease;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.text};

    svg {
      margin-left: 0.4rem;
    }

    .checked {
      display: none;
    }
  }

  input:checked + label {
    border-color: ${({ theme }) => theme.palette.primary};
    .unchecked {
      display: none;
    }
    .checked {
      display: block;
    }
  }

  @media (max-width: 800px) {
    label {
      font-size: 1rem;
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;

    label {
      margin-bottom: 0.4rem;
    }
  }
`;

export const TypeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  h2 {
    font-size: 1.4rem;
    margin-right: 0.4rem;
  }
`;

export const EmptyMessage = styled.span`
  height: 2rem;
`;

export const ErrorMessage = styled(EmptyMessage)`
  color: ${({ theme }) => theme.palette.error};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.4rem;
  }
`;
