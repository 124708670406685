import styled, { css } from 'styled-components';
import { ReactComponent as ProductPlaceholderSVG } from '../../assets/icons/product_placeholder.svg';

const styles = [
  css`
    .st0 {
      fill: #85c1e9 !important;
    }
  `,

  css`
    .st0 {
      fill: #94e7b7 !important;
    }
  `,

  css`
    .st0 {
      fill: #f2be90 !important;
    }
  `,

  css`
    .st0 {
      fill: #f2a59d !important;
    }
  `,

  css`
    .st0 {
      fill: #f9e79f !important;
    }
  `,
];

interface ISvg {
  variation: number;
}

export const Svg = styled(ProductPlaceholderSVG)<ISvg>`
  ${({ variation }) => variation && styles[variation]};
`;
