import { IStep } from '../components/Tour';

const tour: IStep[] = [
  {
    text: 'Esta é a página de Produtos!',
  },
  {
    id: 'products-container',
    position: 'top',
    text: 'Aqui são listados todos os produtos.',
  },
  {
    id: 'products-filters',
    position: 'left',
    text: 'Por aqui você pode filtrá-los...',
  },
  {
    id: 'products-search',
    position: 'left',
    text: 'ou fazer uma busca mais específica.',
  },
  {
    id: 'products-sort-by',
    position: 'left',
    text: 'Por aqui você pode ordená-los por nome ou data de criação.',
  },
  {
    id: 'products-display-preference',
    position: 'left',
    text: 'Aqui você pode mudar a forma que eles são listados.',
  },
  {
    id: 'products-add',
    position: 'left',
    text: 'E, para cadastrar um novo produto, basta clicar aqui.',
  },
];

export default tour;
