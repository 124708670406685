/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiArrowLeft, FiArrowRight, FiChevronRight, FiX } from 'react-icons/fi';
import { Textfit } from 'react-textfit';
import html2canvas from 'html2canvas';

import { useCompany } from '../../hooks/company';

import { formatToLocalDate, formatToMoney } from '../../utils/format';
import { isDatesSameDay } from '../../utils/date';

import IDiscountCoupon from '../../models/IDiscountCoupon';
import {
  ICouponArtVariant,
  ICouponArtText,
  ICouponArtTemplate,
} from '../../models/ICouponArt';

import {
  StyledModal,
  Container,
  CouponArtTemplateList,
  CouponArtTemplateListItem,
  ArtPreview,
  BackgroundImage,
  ArtPreviewTextWrapper,
  ArtPreviewLogo,
  PreviewContainer,
  CloseButton,
  Header,
  SaveButton,
  ArtActionsContainer,
  PrevArtButton,
  NextArtButton,
  VariantContainer,
  Variants,
  VariantButton,
} from './styles';

import { parseStringToTags } from '../../utils/html';
import { couponArtTemplates } from '../../assets/couponArts';

interface ICouponArtModalProps {
  isOpen: boolean;
  selectedDiscountCoupon: IDiscountCoupon | null;
  onClose: () => void;
}

const normalizeCouponArtText = (
  text: ICouponArtText,
  imageWidth: number,
  imageHeight: number,
) => {
  const normalizeFromWidth = (value: number) => (value * 100) / imageWidth;
  const normalizeFromHeight = (value: number) => (value * 100) / imageHeight;

  return {
    ...text,
    width: normalizeFromWidth(text.width),
    height: normalizeFromHeight(text.height),
    x: normalizeFromWidth(text.x),
    y: normalizeFromHeight(text.y),
    rotation: text.rotation,
    color: text.color,
  };
};

const CouponArtModal: React.FC<ICouponArtModalProps> = ({
  isOpen,
  selectedDiscountCoupon,
  onClose,
}) => {
  const artPreviewRef = useRef<HTMLDivElement>(null);
  const { company } = useCompany();

  const [
    couponArtTemplate,
    setCouponArtTemplate,
  ] = useState<ICouponArtTemplate | null>(null);

  const [selectedVariantId, setSelectedVariantId] = useState<number | null>(
    null,
  );

  const handleOnArtTemplateSelect = (value: ICouponArtTemplate) => {
    setCouponArtTemplate(value);
    setSelectedVariantId(value.variants[0]?.id || null);
  };

  const handleOnVariantSelect = (id: number) => {
    setSelectedVariantId(id);
  };

  const handleOnNextTemplateClick = useCallback(() => {
    setCouponArtTemplate(old => {
      if (old) {
        const oldVariant = old.variants.find(
          variant => variant.id === selectedVariantId,
        );
        const index = couponArtTemplates.indexOf(old);
        const nextIndex = index + 1;
        const newValue = couponArtTemplates[nextIndex] || old;

        const newVariantId =
          newValue.variants?.find(variant => variant?.name === oldVariant?.name)
            ?.id || newValue.variants[0]?.id;

        setSelectedVariantId(newVariantId || null);
        return newValue;
      }
      return null;
    });
  }, [selectedVariantId]);

  const handleOnPrevTemplateClick = useCallback(() => {
    setCouponArtTemplate(old => {
      if (old) {
        const oldVariant = old.variants.find(
          variant => variant.id === selectedVariantId,
        );
        const index = couponArtTemplates.indexOf(old);
        const nextIndex = index - 1;
        const newValue = couponArtTemplates[nextIndex] || old;

        const newVariantId =
          newValue.variants?.find(variant => variant?.name === oldVariant?.name)
            ?.id || newValue.variants[0]?.id;

        setSelectedVariantId(newVariantId || null);
        return newValue;
      }
      return null;
    });
  }, [selectedVariantId]);

  const handleOnClose = () => {
    if (couponArtTemplate) {
      setSelectedVariantId(null);
      setCouponArtTemplate(null);
    } else {
      onClose();
    }
  };

  const selectedVariant = useMemo(() => {
    if (couponArtTemplate && selectedVariantId) {
      return couponArtTemplate.variants.find(
        variant => variant.id === selectedVariantId,
      );
    }

    return null;
  }, [couponArtTemplate, selectedVariantId]);

  const handleOnSave = useCallback(async () => {
    if (artPreviewRef.current && selectedVariant) {
      const scaleFactor =
        selectedVariant.imageHeight / artPreviewRef.current.clientHeight;

      const canvas = await html2canvas(artPreviewRef.current, {
        scale: scaleFactor,
        useCORS: true,
      });

      const imageUri = canvas.toDataURL('image/png', 1);

      const link = window.document.createElement('a');
      link.style.display = 'none';
      link.href = imageUri;
      link.download = `${company?.companyName} - ${selectedDiscountCoupon?.ref} (${selectedVariant?.name}).png`;

      window.document.body.appendChild(link);
      link.click();
      window.document.body.removeChild(link);
    }
  }, [artPreviewRef, selectedVariant, company, selectedDiscountCoupon]);

  const normalizedCouponArt = useMemo(() => {
    if (!selectedVariant) {
      return null;
    }

    type KeyOfCouponArt = keyof ICouponArtVariant;

    const itemsToNormalize: KeyOfCouponArt[] = [
      'logo',
      'amount',
      'ref',
      'maxUses',
      'disclaimer',
      'minAmount',
      'maxDiscount',
    ];

    const normalizedItems = itemsToNormalize.reduce(
      (acc, item) => ({
        ...acc,
        [item]: normalizeCouponArtText(
          selectedVariant[item] as ICouponArtText,
          selectedVariant.imageWidth,
          selectedVariant.imageHeight,
        ),
      }),
      {},
    );

    return {
      ...selectedVariant,
      ...normalizedItems,
    };
  }, [selectedVariant]);

  const amountText = useMemo(
    () =>
      selectedDiscountCoupon?.amountType === 'FIXED'
        ? formatToMoney(selectedDiscountCoupon.amount)
        : `${selectedDiscountCoupon?.amount}%`,
    [selectedDiscountCoupon],
  );

  const maxUsesText = useMemo(() => {
    if (selectedVariant?.maxUses?.empty) return '';
    return selectedDiscountCoupon?.maxUses
      ? parseStringToTags(`Cupons: *${selectedDiscountCoupon?.maxUses}*`)
      : '';
  }, [selectedDiscountCoupon, selectedVariant]);

  const minAmountText = useMemo(() => {
    return formatToMoney(selectedDiscountCoupon?.minAmount || 0);
  }, [selectedDiscountCoupon]);

  const maxDiscountText = useMemo(() => {
    return formatToMoney(selectedDiscountCoupon?.maxAmount || 0);
  }, [selectedDiscountCoupon]);

  const disclaimerText = useMemo(() => {
    const formatedStartDate = formatToLocalDate(
      selectedDiscountCoupon?.initialDate || '',
    );

    if (
      isDatesSameDay(
        selectedDiscountCoupon?.initialDate || '',
        selectedDiscountCoupon?.finalDate || '',
      )
    ) {
      return parseStringToTags(
        `Cupom válido para o dia *${formatedStartDate}*`,
      );
    }

    const formatedFinalDate = formatToLocalDate(
      selectedDiscountCoupon?.finalDate || '',
    );

    return parseStringToTags(
      `Cupom válido de *${formatedStartDate}* até *${formatedFinalDate}*`,
    );
  }, [selectedDiscountCoupon]);

  const couponArtTemplateIndex = useMemo(
    () =>
      couponArtTemplate
        ? couponArtTemplates.findIndex(
            template => template.id === couponArtTemplate.id,
          )
        : 0,
    [couponArtTemplate],
  );

  const filteredTemplates = useMemo(() => couponArtTemplates, []);

  useEffect(() => {
    if (selectedVariant && artPreviewRef?.current) {
      const aspectRatio =
        selectedVariant.imageWidth / selectedVariant.imageHeight;

      const padding = 72;
      const width = (window.innerHeight - padding * 4) * aspectRatio;

      const handleResize = () => {
        artPreviewRef?.current?.style?.setProperty('width', `${width}px`);
      };

      window.addEventListener('resize', handleResize);
      handleResize();

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }

    return () => {};
  }, [selectedVariant]);

  useEffect(() => {
    if (isOpen) {
      const listener = (event: KeyboardEvent) => {
        if (event.key === 'ArrowLeft') handleOnPrevTemplateClick();
        if (event.key === 'ArrowRight') handleOnNextTemplateClick();
      };
      window.addEventListener('keydown', listener);

      return () => {
        window.removeEventListener('keydown', listener);
      };
    }

    return () => null;
  }, [handleOnNextTemplateClick, handleOnPrevTemplateClick, isOpen]);

  return (
    <StyledModal isOpen={isOpen}>
      <Container className="has-custom-scroll-bar">
        <Header>
          <h1>{couponArtTemplate?.name || 'Criar arte de cupom'}</h1>
          <CloseButton onClick={handleOnClose}>
            <FiX size={24} />
          </CloseButton>
        </Header>
        {!normalizedCouponArt ? (
          <CouponArtTemplateList>
            {filteredTemplates.map(template => (
              <CouponArtTemplateListItem
                key={template.name}
                onClick={() => handleOnArtTemplateSelect(template)}
              >
                <img src={template.thumb} alt={template.name} />
                <span>{template.name}</span>
                <FiChevronRight size={24} />
              </CouponArtTemplateListItem>
            ))}
          </CouponArtTemplateList>
        ) : (
          <PreviewContainer>
            <ArtPreview ref={artPreviewRef}>
              <ArtPreviewLogo {...normalizedCouponArt.logo}>
                <img src={company?.logoUrl || ''} alt="" />
              </ArtPreviewLogo>

              <ArtPreviewTextWrapper {...normalizedCouponArt.amount}>
                <Textfit mode="single">{amountText}</Textfit>
              </ArtPreviewTextWrapper>

              <ArtPreviewTextWrapper {...normalizedCouponArt.ref}>
                <Textfit mode="single">{selectedDiscountCoupon?.ref}</Textfit>
              </ArtPreviewTextWrapper>

              <ArtPreviewTextWrapper {...normalizedCouponArt.ref}>
                <Textfit mode="single">{selectedDiscountCoupon?.ref}</Textfit>
              </ArtPreviewTextWrapper>

              <ArtPreviewTextWrapper {...normalizedCouponArt.minAmount}>
                <Textfit mode="single">{minAmountText}</Textfit>
              </ArtPreviewTextWrapper>

              <ArtPreviewTextWrapper {...normalizedCouponArt.maxDiscount}>
                <Textfit mode="single">{maxDiscountText}</Textfit>
              </ArtPreviewTextWrapper>

              {maxUsesText && (
                <ArtPreviewTextWrapper {...normalizedCouponArt.maxUses}>
                  <Textfit mode="single">{maxUsesText}</Textfit>
                </ArtPreviewTextWrapper>
              )}

              <ArtPreviewTextWrapper {...normalizedCouponArt.disclaimer}>
                <Textfit mode="multi" style={{ height: '100%' }}>
                  {disclaimerText}
                </Textfit>
              </ArtPreviewTextWrapper>

              <BackgroundImage src={normalizedCouponArt.imageUri} />
            </ArtPreview>
            <VariantContainer>
              <span>Variante:</span>
              <Variants>
                {couponArtTemplate?.variants.map(variant => (
                  <VariantButton
                    key={variant?.name}
                    onClick={() => handleOnVariantSelect(variant?.id || 0)}
                    active={variant?.id === selectedVariant?.id}
                  >
                    {variant?.name}
                  </VariantButton>
                ))}
              </Variants>
            </VariantContainer>
            <ArtActionsContainer>
              <PrevArtButton
                onClick={handleOnPrevTemplateClick}
                disabled={couponArtTemplateIndex === 0}
              >
                <FiArrowLeft size={20} />
                Arte anterior
              </PrevArtButton>
              <SaveButton onClick={handleOnSave}>Salvar imagem</SaveButton>
              <NextArtButton
                onClick={handleOnNextTemplateClick}
                disabled={
                  couponArtTemplateIndex >= couponArtTemplates.length - 1
                }
              >
                Próxima arte
                <FiArrowRight size={20} />
              </NextArtButton>
            </ArtActionsContainer>
          </PreviewContainer>
        )}
      </Container>
    </StyledModal>
  );
};

export default CouponArtModal;
