import styled, { css } from 'styled-components';

interface SummaryModalProps {
  isVisible: boolean;
}

export const Container = styled.div<SummaryModalProps>`
  width: 100%;
  bottom: 0;
  position: absolute;
  background: ${({ theme }) => theme.palette.background_secondary};
  transition: height 0.4s;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;

  ${({ isVisible }) =>
    isVisible
      ? css`
          height: 100%;
        `
      : css`
          height: 0;
        `}
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  @media (max-width: 375px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  @media (max-width: 375px) {
    gap: 4px;

    strong {
      font-size: 20px;
    }
  }
`;

export const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 24px;
  overflow-y: auto;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
`;

export const Title = styled.strong`
  font-size: 24px;
`;
