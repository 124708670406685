import styled from 'styled-components';

export const GridContainer = styled.div`
  width: calc(1 / 3 * 100% - (1 - 1 / 3) * 24px);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;

  background: #fff;
  margin-bottom: 24px;
  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.1);

  @media (max-width: 1780px) {
    width: calc(1 / 2 * 100% - (1 - 1 / 3) * 16px);
  }

  @media (max-width: 1280px) {
    width: 100%;
    flex: 0 100%;
    margin-left: 0;
    margin-bottom: 16px;
  }

  @media (max-width: 800px) {
    > span {
      font-size: 18px;
    }
  }

  @media (max-width: 500px) {
    box-shadow: none;
  }
`;

export const GridContent = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const ListContainer = styled.div`
  width: 100%;

  background: #fff;

  margin-bottom: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.1);

  strong,
  span {
    flex: 1;
  }

  @media (max-width: 500px) {
    box-shadow: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const Button = styled.button`
  border: none;
  background: none;

  display: flex;
  align-self: flex-end;
  padding: 2px;
  cursor: pointer;

  svg {
    border-radius: 50%;
    padding: 4px;

    &:hover {
      background: #1c1d1f50;
    }
  }
`;
