import styled from 'styled-components';

interface StatusProps {
  status: 'CLOSED' | 'OPEN';
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  background: ${({ theme }) => theme.palette.background_white};
  cursor: pointer;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Cashier = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const CashierInfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 32px;

  @media (max-width: 412px) {
    flex-direction: column;
  }
`;

export const OpenCloseDatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const Title = styled.span`
  font-size: 1.25rem;
`;

export const CashierNumber = styled.span`
  font-size: 1rem;
  font-weight: 700;
`;

export const Label = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.control};
`;

export const Value = styled.span`
  font-size: 1rem;
`;

export const PrintButton = styled.button`
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  cursor: pointer;

  background: ${({ theme }) => theme.palette.status_placed_transparency};

  svg {
    color: ${({ theme }) => theme.palette.status_placed};
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

export const Status = styled.span<StatusProps>`
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  background-color: ${({ theme, status }) =>
    status === 'CLOSED'
      ? theme.palette.status_approved_transparency
      : theme.palette.status_in_prepare_transparency};

  color: ${({ theme, status }) =>
    status === 'CLOSED'
      ? theme.palette.status_approved
      : theme.palette.status_in_prepare};
`;
