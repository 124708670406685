/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useState } from 'react';
import {
  FiTrash,
  FiEdit,
  FiChevronDown,
  FiPauseCircle,
  FiPlayCircle,
} from 'react-icons/fi';
import { IoMdAdd } from 'react-icons/io';
import { useComplements } from '../../hooks/complements';
import { useToast } from '../../hooks/toast';
import IComplementsGroup from '../../models/IComplementsGroup';
import { formatToMoney } from '../../utils/format';

import {
  Title,
  Items,
  Container,
  SmallItem,
  SmallItems,
  SmallTitle,
  DisplayName,
  IsMandatory,
  SmallContainer,
  Actions,
  DeleteButton,
  EditButton,
  ItemsCount,
  SmallExpandButton,
  SmallAddButton,
  PlayPauseComplementGroupButton,
} from './styles';

interface IComplementsGroupItemProps {
  small?: boolean;
  complementsGroup: IComplementsGroup;
  onClick: (group: IComplementsGroup) => void;
  onDelete?: (group: IComplementsGroup) => void;
  onItemsClick?: (group: IComplementsGroup) => void;
}

const ComplementsGroupItem: React.FC<IComplementsGroupItemProps> = ({
  small,
  complementsGroup,
  onClick,
  onDelete,
  onItemsClick,
}) => {
  const { inactivateComplementGroup } = useComplements();

  const { addToast } = useToast();

  const [expanded, setExpanded] = useState(false);

  const itemsCount = useMemo(() => {
    if (complementsGroup?.productQty) {
      return `${
        complementsGroup.productQty !== 0 ? complementsGroup.productQty : ''
      } ${
        complementsGroup.productQty === 0
          ? 'Nenhum item'
          : complementsGroup.productQty > 1
          ? 'itens'
          : 'item'
      }`;
    }
    return 'Nenhum item';
  }, [complementsGroup]);

  const itemsDescription = useMemo(() => {
    if (complementsGroup.complements.length > 0) {
      return complementsGroup.complements
        .sort((a, b) => ((a.position || 0) > (b.position || 0) ? 1 : -1))
        .map(complement => complement.title)
        .join(', ')
        .concat('.');
    }

    return '';
  }, [complementsGroup]);

  const toggleExpanded = () => {
    setExpanded(old => !old);
  };

  const handleOnComplementGroupInactivated = useCallback(
    async (complementGroupToBeAlteredId: number) => {
      try {
        await inactivateComplementGroup(complementGroupToBeAlteredId);

        addToast({
          type: 'success',
          description: 'Grupo alterado!',
        });
      } catch (err) {
        const errors = (err as any).response?.data?.errors?.messages;

        addToast({
          type: 'error',
          description:
            (Array.isArray(errors) && errors[0]) ||
            'Ocorreu um erro inesperado.',
        });
      }
    },
    [addToast, inactivateComplementGroup],
  );

  if (small) {
    return (
      <SmallContainer
        items={complementsGroup.complements.length}
        expanded={expanded}
      >
        <SmallTitle>{complementsGroup.title}</SmallTitle>
        <SmallAddButton onClick={() => onClick(complementsGroup)}>
          <IoMdAdd size={22} />
        </SmallAddButton>
        {complementsGroup.complements.length > 1 && (
          <SmallExpandButton onClick={toggleExpanded} className="expand-button">
            <FiChevronDown size={24} />
          </SmallExpandButton>
        )}
        <SmallItems className="small-items">
          {complementsGroup.complements.map(complement => (
            <SmallItem key={complement.id}>
              <span>{complement.title}</span>
              <strong>{formatToMoney(complement.unitPrice)}</strong>
            </SmallItem>
          ))}
        </SmallItems>
      </SmallContainer>
    );
  }

  return (
    <Container active={complementsGroup.isActive}>
      <Title>
        <strong>{complementsGroup.title}</strong>
        <Actions>
          <EditButton onClick={() => onClick(complementsGroup)}>
            <FiEdit size={22} />
          </EditButton>
          {!!complementsGroup?.id && (
            <PlayPauseComplementGroupButton
              type="button"
              onClick={() =>
                handleOnComplementGroupInactivated(complementsGroup?.id || 0)
              }
            >
              {complementsGroup.isActive ? <FiPauseCircle /> : <FiPlayCircle />}
            </PlayPauseComplementGroupButton>
          )}
          <DeleteButton
            disabled={
              complementsGroup?.productQty
                ? complementsGroup?.productQty > 0
                : false
            }
            onClick={() => onDelete && onDelete(complementsGroup)}
          >
            <FiTrash size={22} />
          </DeleteButton>
        </Actions>
      </Title>
      {complementsGroup.displayName && (
        <DisplayName>{complementsGroup.displayName}</DisplayName>
      )}
      <Items>{itemsDescription}</Items>
      <IsMandatory>
        <span>
          {complementsGroup.minAmount > 0 ? 'OBRIGATÓRIO' : 'NÃO OBRIGATÓRIO'}
        </span>
        <ItemsCount
          disabled={!complementsGroup?.productQty}
          onClick={() => onItemsClick && onItemsClick(complementsGroup)}
        >
          {itemsCount}
        </ItemsCount>
      </IsMandatory>
    </Container>
  );
};

export default ComplementsGroupItem;
