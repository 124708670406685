import { DateTime } from 'luxon';
import { CustomerRolesTypes } from '../models/IUser';

export const formatToMoney = (value: number, noPrefix?: boolean): string => {
  if (noPrefix) {
    return value
      .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
      .replace('R$', '');
  }

  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
};

export const formatToDocument = (value: string): string =>
  value.length === 14
    ? value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    : value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

type IAuthRolesLabels = {
  [key in CustomerRolesTypes]: string;
};

const authRolesLabels: IAuthRolesLabels = {
  Customer: 'Cliente',
  Company: 'Dono',
  Manager: 'Gerente',
  Marketing: 'Marketing',
  Employee: 'Funcionário',
};

export const formatAuthRole = (role: CustomerRolesTypes): string =>
  authRolesLabels[role as CustomerRolesTypes];

export const formatAuthRoles = (roles: CustomerRolesTypes[]): string =>
  roles.map(formatAuthRole).join(', ');

export const formatToLocalDate = (dateString: string | Date): string => {
  const date =
    typeof dateString === 'string'
      ? DateTime.fromISO(dateString)
      : DateTime.fromJSDate(dateString);

  return date.toLocaleString({
    locale: 'pt-BR',
  });
};

export const formatToFullLocalDate = (dateString: string | Date): string => {
  const date =
    typeof dateString === 'string'
      ? DateTime.fromISO(dateString)
      : DateTime.fromJSDate(dateString);

  return date.toFormat("dd/MM/yyyy 'às' HH:mm");
};
