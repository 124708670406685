import React, { useMemo } from 'react';
import { FiArrowDown, FiArrowUp, FiPrinter, FiX } from 'react-icons/fi';
import {
  CloseButton,
  Container,
  Content,
  Header,
  Label,
  Value,
  ScrollContainer,
  Col,
  Row,
  VerticalDivider,
  CashierNumber,
  Date,
  Operator,
  Close,
  Received,
  Supply,
  Products,
  HorizontalDivider,
  ReceivedItem,
  ReceivedItemsContainer,
  TotalLabel,
  Movement,
  CloseSection,
  CloseSectionsContainer,
  ProductsList,
  ProductsCategoryLabel,
  ProductSection,
  ProductItem,
  PrintButton,
  LeftHeader,
  NoContentMessage,
  CanceledOrders,
  CanceledOrder,
  CanceledOrderHeader,
  CanceledOrderSection,
} from './styles';

// import { ReactComponent as RevenueSVG } from '../../assets/icons/dashboard/revenues-color.svg';
// import { ReactComponent as ProductsSVG } from '../../assets/icons/products.svg';
// import { ReactComponent as AverageSVG } from '../../assets/icons/dashboard/average-color.svg';
import { PaymentTypes } from '../../enums/paymentType';
import { chooseColorPaymentTypeIcon } from '../../utils/icons';
import { getPaymentTypeName } from '../../utils/string';
import { formatToFullLocalDate, formatToMoney } from '../../utils/format';
import ICashierReport from '../../models/ICashierReport';
import { CashMovement } from '../../enums/cashMovement';

interface CashierReportProps {
  cashierReport: ICashierReport | null;
  onPrintClicked: () => void;
  onClose: () => void;
}

const CashierReport: React.FC<CashierReportProps> = ({
  cashierReport,
  onPrintClicked,
  onClose,
}) => {
  const totalAmount = useMemo(() => {
    return cashierReport?.salesPaymentMethods?.reduce(
      (acc, current) => acc + current.paymentAmount,
      0,
    );
  }, [cashierReport]);

  const movementsTotalAmount = useMemo(() => {
    return cashierReport?.cashMovements?.reduce((acc, current) => {
      if (current.type === CashMovement.BLEED) {
        return acc - current.amount;
      }

      return acc + current.amount;
    }, 0);
  }, [cashierReport]);

  const productsSalesTotal = useMemo(() => {
    return cashierReport?.items?.reduce(
      (acc, current) =>
        acc +
        current.items.reduce(
          (itemAcc, currentItem) => itemAcc + currentItem.total,
          0,
        ),
      0,
    );
  }, [cashierReport]);

  return (
    <ScrollContainer>
      <Container>
        <Header>
          <LeftHeader>
            <CloseButton onClick={onClose}>
              <FiX size={32} />
            </CloseButton>
            <h2>Relatório</h2>
          </LeftHeader>

          <PrintButton type="button" onClick={onPrintClicked}>
            <FiPrinter size={22} />
          </PrintButton>
        </Header>

        <Content>
          <CashierNumber>
            <Label>Caixa</Label>
            <Value>{cashierReport?.shift.id}</Value>
          </CashierNumber>

          <Date>
            <Row style={{ justifyContent: 'space-between' }}>
              <Col>
                <Label>Data de abertura</Label>
                <Value>
                  {formatToFullLocalDate(cashierReport?.shift.start || '')}
                </Value>
              </Col>
              <VerticalDivider />
              <Col>
                <Label>Data de fechamento</Label>
                <Value>
                  {cashierReport?.shift.end
                    ? formatToFullLocalDate(cashierReport?.shift.end || '')
                    : '-'}
                </Value>
              </Col>
            </Row>
          </Date>

          {/* <Device>
            <Label>Dispositivo</Label>
            <Value>Andorinha-pequena-de-casa</Value>
          </Device> */}

          <Operator>
            <Label>Operador (Abertura)</Label>
            <Value>{cashierReport?.shift.openOperatorName}</Value>
          </Operator>

          {/* <Platform>
            <Label>Plataforma</Label>
            <Value>BS Self Checkout</Value>
          </Platform> */}

          {/* <Total>
            <Label>Totalizadores</Label>
            <TotalSectionsContainer>
              <TotalSection>
                <Label>
                  <RevenueSVG />
                  Total em vendas
                </Label>
                <Value>R$ 999.999,99</Value>
              </TotalSection>
              <TotalSection>
                <Label>
                  <ProductsSVG />
                  Quantidade de produtos
                </Label>
                <Value>999.999.999</Value>
              </TotalSection>
              <TotalSection>
                <Label>
                  <AverageSVG />
                  Ticket médio
                </Label>
                <Value>R$ 999.999,99</Value>
              </TotalSection>
            </TotalSectionsContainer>
          </Total> */}

          <Received>
            <Label>Recebimentos</Label>
            <ReceivedItemsContainer
              itemsLength={cashierReport?.salesPaymentMethods?.length || 1}
            >
              {cashierReport?.salesPaymentMethods?.map(item => {
                const icon = chooseColorPaymentTypeIcon(
                  item.paymentType as PaymentTypes,
                );
                const label = getPaymentTypeName(item.paymentType);
                const value = formatToMoney(item.paymentAmount);

                return (
                  <ReceivedItem key={item.id}>
                    <Label>
                      <img src={icon} alt={label} />
                      {label}
                    </Label>

                    <Value>{value}</Value>
                  </ReceivedItem>
                );
              })}
            </ReceivedItemsContainer>

            <HorizontalDivider />
            <Row>
              <TotalLabel>
                Total de vendas:&nbsp;
                <strong>{formatToMoney(totalAmount || 0)}</strong>
              </TotalLabel>
            </Row>
          </Received>

          <Supply>
            <Label>Suprimento/Sangria</Label>
            {(cashierReport?.cashMovements?.length || 0) > 0 ? (
              <>
                {cashierReport?.cashMovements.map(movement => (
                  <Movement key={movement.id}>
                    <Label>
                      {movement.type === CashMovement.SUPPLY ? (
                        <FiArrowDown size={24} />
                      ) : (
                        <FiArrowUp size={24} className="bleed" />
                      )}
                      {movement.description}
                    </Label>
                    <Value>
                      {`
                      ${movement.type === CashMovement.BLEED ? '-' : ''}
                      ${formatToMoney(movement.amount)}
                    `}
                    </Value>
                  </Movement>
                ))}

                <HorizontalDivider />
                {cashierReport?.cashMovements && (
                  <Row>
                    <TotalLabel>
                      Total:&nbsp;
                      <strong>
                        {formatToMoney(movementsTotalAmount || 0)}
                      </strong>
                    </TotalLabel>
                  </Row>
                )}
              </>
            ) : (
              <NoContentMessage>Nenhuma movimentação.</NoContentMessage>
            )}
          </Supply>

          <Close>
            <Label>Fechamento</Label>
            <CloseSectionsContainer>
              <CloseSection>
                <Label>Total realizado</Label>
                <Value>
                  {formatToMoney(
                    cashierReport?.cashSummary.actualCashAmount || 0,
                  )}
                </Value>
              </CloseSection>
              <CloseSection>
                <Label>Total informado</Label>
                <Value>
                  {formatToMoney(
                    cashierReport?.cashSummary.closeCashAmount || 0,
                  )}
                </Value>
              </CloseSection>
            </CloseSectionsContainer>
            <HorizontalDivider />

            <Row>
              <TotalLabel>
                Diferença:&nbsp;
                <strong>
                  {formatToMoney(
                    cashierReport?.cashSummary.cashBalanceAmount || 0,
                  )}
                </strong>
              </TotalLabel>
            </Row>
          </Close>

          <Products>
            <Label>Produtos vendidos</Label>
            <ProductsList>
              {(cashierReport?.items?.length || 0) > 0 ? (
                <>
                  {cashierReport?.items.map((item, index) => (
                    <React.Fragment key={item.category}>
                      <ProductsCategoryLabel>
                        {item.category}
                      </ProductsCategoryLabel>
                      {item.items.map(i => (
                        <ProductItem key={i.id}>
                          <Label>{i.title}</Label>
                          <ProductSection>
                            <Label>Qtde.</Label>
                            <Value>{i.qty}</Value>
                          </ProductSection>
                          <ProductSection>
                            <Label>Preço.</Label>
                            <Value>{formatToMoney(i.unitPrice)}</Value>
                          </ProductSection>
                          <ProductSection>
                            <Label>Total.</Label>
                            <Value>{formatToMoney(i.total)}</Value>
                          </ProductSection>
                        </ProductItem>
                      ))}
                      {cashierReport.items.length - 1 > index && (
                        <HorizontalDivider />
                      )}
                    </React.Fragment>
                  ))}
                  <HorizontalDivider />
                  <Row>
                    <TotalLabel>
                      Total de produtos vendidos:&nbsp;
                      <strong>{formatToMoney(productsSalesTotal || 0)}</strong>
                    </TotalLabel>
                  </Row>
                </>
              ) : (
                <NoContentMessage>Nenhum item.</NoContentMessage>
              )}
            </ProductsList>
          </Products>

          <CanceledOrders>
            <Label>Pedidos cancelados</Label>
            {(cashierReport?.canceledOrders.orders.length || 0) > 0 ? (
              <>
                {cashierReport?.canceledOrders.orders.map(order => (
                  <CanceledOrder key={order.id}>
                    <CanceledOrderHeader>
                      <CanceledOrderSection>
                        <Label>Pedido</Label>
                        <Value>{`#${order.number}`}</Value>
                      </CanceledOrderSection>
                      <CanceledOrderSection>
                        <Label>Total</Label>
                        <Value>{formatToMoney(order.total)}</Value>
                      </CanceledOrderSection>
                    </CanceledOrderHeader>
                    {order.reason && <Label>{order.reason}</Label>}
                    <HorizontalDivider />
                  </CanceledOrder>
                ))}

                <Row>
                  <TotalLabel>
                    Total cancelado:&nbsp;
                    <strong>
                      {formatToMoney(
                        cashierReport?.canceledOrders.canceledTotal || 0,
                      )}
                    </strong>
                  </TotalLabel>
                </Row>
              </>
            ) : (
              <NoContentMessage>Nenhum pedido cancelado.</NoContentMessage>
            )}
          </CanceledOrders>
        </Content>
      </Container>
    </ScrollContainer>
  );
};

export default CashierReport;
