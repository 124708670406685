import ReactModal from 'react-modal';
import styled, { keyframes } from 'styled-components';

const appearFromBottom = keyframes`
  from {
    transform: translateY(100%);
  }
  to{
    transform: translateY(0);
  }
`;

export const StyledModal = styled(ReactModal)`
  animation: ${appearFromBottom} 0.5s;
`;

export const Scroll = styled.div`
  max-height: 90vh;
  width: min(100vw, 600px);

  overflow-y: scroll;
  overflow-x: hidden;

  border-radius: 8px;

  div.empty-message {
    padding: 16px;
  }
`;

export const Container = styled.div`
  max-height: 90vh;
  width: min(100vw, 600px);
  overflow: hidden;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};
  background-color: ${({ theme }) => theme.palette.background_white};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

export const ModalTitle = styled.h3``;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: 0;
  background: none;
`;

export const OrdersContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 16px;

  overflow-y: hidden;

  margin-top: 8px;

  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
