import styled, { css } from 'styled-components';
import { TabOptions } from '.';

interface ITabProps {
  selected: boolean;
}

interface IAddButtonProps {
  selectedTab: TabOptions;
}

export const Container = styled.div`
  display: flex;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Content = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  background: #f4f4f6;
  position: relative;

  transition: all 0.2s;

  @media (max-width: 1270px) {
    width: 100vw;
  }
`;

export const InnerContent = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: height 0.4s;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  max-height: 100px;
  padding: 0 32px;
  padding-top: 24px;
  gap: 16px;
`;

export const AddButton = styled.button<IAddButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: 0;
  background-color: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.text_white};

  span {
    font-size: 14px;

    &::after {
      ${({ selectedTab }) =>
        selectedTab === 'MOVEMENTS'
          ? css`
              content: 'Nova movimentação';
            `
          : css`
              content: 'Nova conferência';
            `}
      margin-left: 8px;
    }
  }

  @media (max-width: 1024px) {
    position: fixed;
    bottom: 12px;
    right: 12px;
    min-width: 16px;
    min-height: 16px;
    border-radius: 32px;
    box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
    justify-content: center;
    padding: 8px;
    z-index: 10;
    svg {
      width: 32px;
      height: 32px;
    }

    span::after {
      display: none;
      content: '';
      margin-left: 0;
    }
  }
`;

export const PageInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: auto;

  svg {
    margin-right: 16px;

    @media (min-width: 1271px) {
      display: none;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const PageName = styled.strong`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 32px;
  margin-left: 8px;

  @media (max-width: 1027px) {
    display: none;
  }
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: start;
  justify-content: space-between;

  flex: 1 1 auto;
  overflow-y: auto;
  width: 100%;

  padding: 32px;

  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 480px) {
    padding-bottom: 112px;
  }
`;

export const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-top: 32px;

  width: 100%;

  span + span {
    margin-left: 32px;
  }

  @media (max-width: 448px) {
    span + span {
      margin-left: 0px;
    }
  }
`;

export const Tab = styled.span<ITabProps>`
  padding: 6px;
  padding-bottom: 16px;
  cursor: pointer;

  ${({ selected }) =>
    selected
      ? css`
          border-bottom: 3px solid #db1622;
        `
      : css`
          border-bottom: 1px solid transparent;
        `}

  &:hover {
    ${({ selected }) =>
      !selected &&
      css`
        border-bottom: 1px solid #1b1c1e;
      `};
  }
`;

export const MainHeader = styled.div`
  display: flex;
  padding: 0 32px;
  width: 100%;

  justify-content: space-between;

  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    padding: 16px;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const DisplayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 32px;

  border-bottom: 1px solid #ccc;

  button + button {
    border-left: 1px solid #000;
    padding-left: 8px;
    margin-left: 8px;
  }

  @media (max-width: 700px) {
    border: 0;
    margin-top: 0;
    padding-bottom: 8px;
  }
`;

export const DisplayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  background: none;
`;

export const EmptyMessageContainer = styled.div`
  width: 100%;
  text-align: center;

  margin-top: 128px;
`;

export const FlexPlaceholder = styled.div`
  height: 0;
  width: 32%;
`;

export const SelectedProduct = styled.button`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.control};
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.palette.background_white};

  transition: border 100ms;

  img {
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }

  .placeholder {
    color: #808080;
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary};
  }
`;

export const ProductInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;
  padding-right: 8px;
  gap: 8px;
`;

export const Label = styled.strong``;

export const TextInput = styled.input`
  width: 256px;
  min-height: 38px;
  border: 1px solid ${({ theme }) => theme.palette.control};
  border-radius: 4px;
  padding: 4px 8px;
`;

export const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 48px;

  button {
    margin-right: auto;
  }
`;

export const FiltersGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  #status-filter {
    width: 256px;
  }

  @media (max-width: 730px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;

  @media (max-width: 480px) {
    #dashboard-filters {
      flex-direction: column;
    }
  }
`;

export const Filter = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  padding: 12px 0px;

  button {
    box-shadow: none;
    margin-bottom: unset;
  }

  div {
    padding: 0;
    margin-bottom: unset;
  }

  @media (max-width: 370px) {
    flex-direction: column;
  }
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  color: ${({ theme }) => theme.palette.primary};
  background: transparent;
  border: 1px solid ${({ theme }) => theme.palette.primary};

  padding: 16px 48px;

  position: relative;
`;

export const SearchResultContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
`;

export const AppliedFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
`;

export const Title = styled.span``;

export const EmptyMovementsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
