/* eslint-disable no-nested-ternary */
export const isNumberValid = (value: string): boolean => {
  const regex = /^[0-9\b]+$/;
  return regex.test(value);
};

export const randomInt = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const clamp = (value: number, min: number, max: number): number =>
  value < min ? min : value > max ? max : value;

export const normalizeNumber = (num: number): number => num || 0;

export const proportion = (a: number, aResult: number, b: number): number =>
  (b * aResult) / a;

export const decimalPlaces = (value: number, places: number): number =>
  Number(value.toFixed(places));
