/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useCallback, useContext, useState } from 'react';
import ConfirmDialog from '../components/ConfirmDialog';

interface IConfirmDialogOptions {
  title: string;
  message?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

interface IConfirmDialog {
  showConfirmDialog: (options: IConfirmDialogOptions) => void;
}

const Context = createContext<IConfirmDialog>({} as IConfirmDialog);

export const useConfirmDialog = (): IConfirmDialog => useContext(Context);

export const ConfirmDialogProvider: React.FC = ({ children }) => {
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState<IConfirmDialogOptions>({
    title: 'Deseja mesmo sair?',
    onConfirm: () => {},
    onCancel: () => {},
  });

  const handleShowConfirmDialog = useCallback(
    (receivedOptions: IConfirmDialogOptions) => {
      setShow(true);
      setOptions({
        title: receivedOptions.title,
        message: receivedOptions.message,
        onConfirm: () => {
          setShow(false);
          receivedOptions.onConfirm();
        },
        onCancel: () => {
          setShow(false);
          receivedOptions.onCancel();
        },
      });
    },
    [],
  );

  return (
    <Context.Provider value={{ showConfirmDialog: handleShowConfirmDialog }}>
      {children}
      <ConfirmDialog
        title={options.title}
        message={options.message}
        open={show}
        onCancel={options.onCancel}
        onConfirm={options.onConfirm}
      />
    </Context.Provider>
  );
};
