/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import { FiCheckSquare, FiSquare } from 'react-icons/fi';
import { Input } from './styles';

interface ICheckboxProps {
  name: string;
  checkedElement?: React.ReactElement;
  uncheckedElement?: React.ReactElement;
  defaultValue?: boolean;
  value?: boolean;
  onChange?: (value?: boolean) => void;
}

const Checkbox: React.FC<ICheckboxProps> = ({
  name,
  checkedElement,
  uncheckedElement,
  defaultValue,
  value,
  onChange,
}) => {
  const [checked, setChecked] = useState(defaultValue || false);

  const handleCheck = useCallback(() => {
    setChecked(true);
    onChange && onChange(true);
  }, [onChange]);

  const handleUncheck = useCallback(() => {
    setChecked(false);
    onChange && onChange(false);
  }, [onChange]);

  useEffect(() => {
    setChecked(value || false);
  }, [value]);

  return (
    <div>
      <Input
        checked={checked}
        type="checkbox"
        id={`formless-checkbox-${name}`}
        name={name}
      />
      <label htmlFor={`formless-checkbox-${name}`}>
        <button type="button" onClick={handleCheck} className="checked">
          {checkedElement || <FiSquare size={18} />}
        </button>

        <button type="button" onClick={handleUncheck} className="unchecked">
          {uncheckedElement || <FiCheckSquare size={18} />}
        </button>
      </label>
    </div>
  );
};

export default Checkbox;
