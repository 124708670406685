import styled, { css } from 'styled-components';

interface IContentProps {
  overlay: boolean;
}

interface IFilterProps {
  selected: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  overflow-x: hidden;

  @media (max-width: 1270px) {
    width: 100vw;
  }
`;

export const Content = styled.main<IContentProps>`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  position: relative;

  background: ${({ theme }) => theme.palette.background_secondary};

  transition: all 0.2s;

  ${({ overlay }) =>
    overlay &&
    css`
      pointer-events: none;

      filter: blur(2px);
    `}

  @media (max-width: 1270px) {
    width: 100vw;
  }
`;

export const Header = styled.header`
  width: 100%;
  max-height: 100px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: 16px;

  padding: 16px 32px;
`;

export const PageInfo = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: 16px;

    @media (min-width: 1271px) {
      display: none;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const PageName = styled.strong`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 32px;
  margin-left: 8px;

  @media (max-width: 1027px) {
    display: none;
  }
`;

export const Filters = styled.div`
  width: 100%;

  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.palette.control};

  margin-left: 32px;

  span + span {
    margin-left: 32px;
  }

  @media (max-width: 950px) {
    font-size: 14px;
  }
`;

export const Filter = styled.span<IFilterProps>`
  padding: 6px;
  padding-bottom: 16px;

  cursor: pointer;
  user-select: none;

  ${({ selected }) =>
    selected &&
    css`
      border-bottom: 3px solid ${({ theme }) => theme.palette.primary};
    `}

  &:hover {
    ${({ selected }) =>
      !selected &&
      css`
        border-bottom: 1px solid #1b1c1e;
      `};
  }
`;

export const AddCouponButton = styled.button`
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-self: flex-end;

  border: 0;
  color: #fff;
  padding: 8px;
  background: #db1622;

  span {
    font-size: 14px;

    &::after {
      content: 'Adicionar novo';
      margin-left: 8px;
    }
  }

  @media (max-width: 1024px) {
    position: fixed;
    bottom: 12px;
    right: 12px;
    min-width: 16px;
    min-height: 16px;
    border-radius: 32px;
    box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
    justify-content: center;
    padding: 8px;
    z-index: 10;
    svg {
      width: 32px;
      height: 32px;
    }

    span::after {
      display: none;
      content: '';
      margin-left: 0;
    }
  }
`;

export const MainContent = styled.div`
  height: -webkit-fill-available;

  padding: 0 32px;
  overflow-y: scroll;
`;

export const CouponsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;

  > div {
    margin-top: 16px;
  }

  margin-bottom: 32px;

  @media (max-width: 480px) {
    padding-bottom: 80px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 64px;
`;
