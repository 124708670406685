import React, {
  useRef,
  useState,
  useCallback,
  InputHTMLAttributes,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { FiAlertCircle, FiCopy } from 'react-icons/fi';

import { Error, Label, Container, StyledInput, Copy } from './styles';

export interface IFormlessInputRef {
  setValue: (value: string) => void;
  getDefaultValue: () => string;
  getDataset: () => any;
}

interface IFormlessInputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  variant?: 'COPIABLE' | 'STANDARD';
  maxLength?: number;
  onCopy?: () => void;
}

const FormlessInput: React.ForwardRefRenderFunction<
  IFormlessInputRef,
  IFormlessInputProps
> = (
  { error, name, value, title, variant, maxLength, onCopy, ...rest },
  ref,
) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [hasFocus, setHasFocus] = useState(false);
  const [hasContent, setHasContent] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      setValue: value => {
        if (inputRef.current) {
          inputRef.current.value = value;
        }
      },
      getDefaultValue: () => inputRef.current?.defaultValue || '',
      getDataset: () => inputRef.current?.dataset || {},
    }),
    [],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (maxLength && inputRef?.current && containerRef?.current) {
      const handleChange = () => {
        containerRef.current?.style.setProperty(
          '--current',
          `"${inputRef.current?.value.length}"`,
        );
      };

      inputRef.current.addEventListener('input', handleChange);

      handleChange();

      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        inputRef?.current?.removeEventListener('input', handleChange);
      };
    }
  }, [maxLength]);

  const handleOnFocus = useCallback(() => {
    setHasFocus(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setHasFocus(false);
    setHasContent(!!inputRef.current?.value);
  }, []);

  return (
    <Container maxLength={maxLength} ref={containerRef}>
      <Label htmlFor={name}>{title}</Label>
      <StyledInput
        ref={inputRef}
        hasError={!!error}
        hasFocus={hasFocus}
        hasContent={hasContent}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        value={value}
        maxLength={maxLength}
        {...rest}
      />
      {variant === 'COPIABLE' && !error && (
        <Copy onClick={onCopy}>
          <FiCopy size={20} />
        </Copy>
      )}
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default forwardRef(FormlessInput);
