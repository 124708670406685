import styled, { css } from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

interface IContainerProps {
  visible: boolean;
}

export const Container = styled.div<IContainerProps>`
  bottom: 0;
  width: 100%;
  position: absolute;
  background: #f4f4f6;

  transition: height 0.4s;

  overflow: hidden;

  ${({ visible }) =>
    visible
      ? css`
          height: 100%;
        `
      : css`
          height: 0;
        `}
`;

export const Header = styled.header`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 32px;

  @media (max-width: 460px) {
    max-height: 150px;
  }

  @media (max-width: 528px) {
    padding: 32px 16px;

    > div:has(button) {
      width: fit-content;
    }
  }

  > div:has(button) {
    margin-bottom: 0px;
  }
`;

export const PageInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const BackButton = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 24px;
  margin-left: 32px;

  @media (max-width: 528px) {
    font-size: 20px;
    margin-left: 16px;
  }
`;

export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 32px;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 128px;
`;

export const Message = styled.span`
  padding: 16px 0;
`;

export const Categories = styled.div`
  width: 100%;
  height: 100px;
  flex: 1 1 auto;
  overflow-y: auto;
  padding-right: 32px;
  overflow-y: auto;
`;

export const StyledDragDropContext = styled(DragDropContext)``;

export const StyledDraggable = styled(Draggable)``;

export const DraggableContainer = styled.div`
  padding-bottom: 16px;
`;

export const StyledDroppable = styled(Droppable)``;
