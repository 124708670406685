import styled, { css } from 'styled-components';

interface ICustomLinkProps {
  error: boolean;
}

interface IToggleButtonProps {
  selected: boolean;
}

export const Container = styled.div`
  height: 100%;
  display: flex;
`;

export const Content = styled.main`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  position: relative;
  background: ${({ theme }) => theme.palette.background_secondary};

  @media (max-width: 1270px) {
    width: 100vw;
  }
`;

export const InnerContent = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  overflow: hidden;

  transition: height 0.4s;
`;

export const Header = styled.header`
  width: 100%;
  max-height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 32px;
  padding-top: 24px;
`;

export const PageInfo = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;

    @media (min-width: 1271px) {
      display: none;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PageName = styled.strong`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 32px;
  margin-left: 8px;

  @media (max-width: 1027px) {
    display: none;
  }
`;

export const Main = styled.main`
  width: 100%;
  height: -webkit-fill-available;

  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding: 0 32px;

  margin-bottom: 16px;

  overflow-y: auto;
  overflow-x: hidden;
`;

export const LinkPageLink = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 32px 8px;

  @media (max-width: 448px) {
    padding-right: 32px;
  }

  span {
    font-size: 18px;

    @media (max-width: 800px) {
      font-size: 16px;
    }
  }

  > div {
    width: 100%;
    display: flex;
    align-items: flex-end;

    button {
      width: 40px;
      height: 40px;

      border: 0;
      background: none;

      display: flex;
      margin-left: 16px;
      align-items: center;
      justify-content: center;
    }
  }

  a {
    width: 100%;
    max-width: fit-content;
    display: flex;
    margin-top: 16px;

    text-decoration: none;
    color: ${({ theme }) => theme.palette.text};

    span {
      font-size: 18px;
      font-weight: 500;
      border-bottom: 3px solid ${({ theme }) => theme.palette.primary};

      @media (max-width: 800px) {
        font-size: 16px;
      }

      @media (max-width: 600px) {
        font-size: 80%;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
`;

export const Top = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-top: 16px;
  padding-top: 16px;
`;

export const GroupContainer = styled.div`
  width: calc(1 / 3 * 100% - (1 - 1 / 3) * 24px);

  position: relative;

  padding: 16px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 16px;
  }
`;

export const GroupContent = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 16px;
  margin-bottom: 48px;

  input {
    margin-top: 8px;

    border: 0;
    padding-bottom: 8px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.black_transparency};
  }
`;

export const GroupHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const GroupIcon = styled.img`
  width: 30px;
  height: 30px;
`;

export const GroupTitle = styled.span`
  margin-left: 16px;
`;

export const ToggleButton = styled.button<IToggleButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px 32px;

  border: 0;
  border-radius: 8px 0px 8px 0;

  right: 0;
  bottom: 0;
  position: absolute;

  background: ${({ theme, selected }) =>
    selected ? theme.palette.success_light : theme.palette.error_light};
`;

export const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px 32px;

  border: 0;
  border-radius: 0 8px 0 8px;

  left: 0;
  bottom: 0;
  position: absolute;

  background: ${({ theme }) => theme.palette.error_light};
`;

export const Bottom = styled.div`
  width: 100%;

  margin-top: 64px;

  @media (max-width: 480px) {
    padding-bottom: 80px;
  }
`;

export const BottomHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 32px;

  span {
    font-size: 18px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    padding: 8px;
    color: ${({ theme }) => theme.palette.text_white};
    background: ${({ theme }) => theme.palette.primary};

    span {
      font-size: 14px;

      &::after {
        content: 'Adicionar novo';
        margin-left: 8px;
      }
    }

    @media (max-width: 660px) {
      span {
        &::after {
          content: '';
          margin-left: 0;
        }
      }

      margin-left: 16px;
    }
  }
`;

export const LinkName = styled.input`
  margin-top: 8px;

  width: 100%;

  border: 0;
  margin-left: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.black_transparency};
`;

export const CustomLink = styled.div<ICustomLinkProps>`
  width: calc(1 / 3 * 100% - (1 - 1 / 3) * 24px);

  padding: 16px;
  margin-bottom: 24px;

  position: relative;

  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};

  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${({ theme }) => theme.palette.error};
    `}

  @media(max-width: 800px) {
    width: 100%;
  }
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.palette.error};
`;

export const CustomGroups = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding-top: 16px;
`;

export const NoCustomLinks = styled.div`
  width: 100%;

  margin-top: 128px;
  margin-bottom: 64px;

  text-align: center;
`;

export const Placeholder = styled.div`
  height: 0px;
  width: calc(1 / 3 * 100% - (1 - 1 / 3) * 24px);
`;
