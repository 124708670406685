export const getImageAsync = (src: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => resolve(image);
    image.onerror = reject;
    image.src = src;
  });

export async function urltoFile(url: string, filename: string): Promise<File> {
  const mimeType = (url.match(/^data:([^;]+);/) || '')[1];
  const res = await fetch(url);

  const buffer = await res.arrayBuffer();
  return new File([buffer], filename, { type: mimeType });
}
