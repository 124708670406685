import React, { useCallback } from 'react';

import { FiPlayCircle, FiPauseCircle, FiTrash } from 'react-icons/fi';

import ICategory from '../../models/ICategory';

import { useProducts } from '../../hooks/products';

import { chooseCategoryIcon } from '../../utils/icons';

import {
  Info,
  Left,
  Image,
  Content,
  ListName,
  Container,
  ListImage,
  ItemAmount,
  Visibility,
  CategoryBadge,
  ListContainer,
  ListVisibility,
  TypesContainer,
  DeleteContainer,
  SubcategoryBadge,
  ListTypesContainer,
} from './styles';
import { AuthRole } from '../../hooks/auth';

interface ICategoryProps {
  category: ICategory;
  display: 'GRID' | 'LIST';
  onClick: (id: number) => void;
  onDelete: (id: number) => void;
  onStatusChange: (id: number, resource: string) => void;
}

const Category: React.FC<ICategoryProps> = ({
  display,
  category,
  onClick,
  onDelete,
  onStatusChange,
}) => {
  const { products } = useProducts();

  const handleOnStatusClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (category.active) {
        onStatusChange(category.id, 'deactivate');
      } else {
        onStatusChange(category.id, 'activate');
      }
    },
    [onStatusChange, category],
  );

  const handleOnDeleteCategory = useCallback(
    (e: React.MouseEvent<SVGElement>) => {
      e.stopPropagation();
      onDelete(category.id);
    },
    [category, onDelete],
  );

  const getCategoryItemCount = useCallback(() => {
    let itemCount = 0;
    if (category.isSubcategory && category.isCategory) {
      itemCount = products.filter(
        product =>
          (product.subcategory && product.subcategory.id === category.id) ||
          product.categories.some(c => c.id === category.id),
      ).length;
    } else if (category.isSubcategory) {
      itemCount = products.filter(
        product =>
          product.subcategory && product.subcategory.id === category.id,
      ).length;
    } else {
      itemCount = products.filter(product =>
        product.categories.some(c => c.id === category.id),
      ).length;
    }

    if (itemCount === 0) {
      return 'Nenhum item';
    }

    if (itemCount === 1) {
      return '1 item';
    }

    return `${itemCount} itens`;
  }, [category, products]);

  if (display === 'GRID') {
    return (
      <Container>
        <Content onClick={() => onClick(category.id)}>
          <Image>
            <img src={chooseCategoryIcon(category.name)} alt={category.name} />
          </Image>
          <Info>
            <strong>{category.name}</strong>
            <span>{getCategoryItemCount()}</span>
            <TypesContainer>
              {category.isCategory && <CategoryBadge>categoria</CategoryBadge>}
              {category.isSubcategory && (
                <SubcategoryBadge>subcategoria</SubcategoryBadge>
              )}
            </TypesContainer>
          </Info>
        </Content>
        <AuthRole blackList={['Employee']}>
          <DeleteContainer>
            <FiTrash onClick={handleOnDeleteCategory} />
          </DeleteContainer>
        </AuthRole>
        {category.active ? (
          <Visibility active onClick={handleOnStatusClick}>
            <FiPauseCircle size={24} />
            <span>Pausar Vendas</span>
          </Visibility>
        ) : (
          <Visibility active={false} onClick={handleOnStatusClick}>
            <FiPlayCircle size={24} />
            <span>Retomar Vendas</span>
          </Visibility>
        )}
      </Container>
    );
  }

  return (
    <ListContainer onClick={() => onClick(category.id)}>
      <Left>
        <ListImage>
          <img src={chooseCategoryIcon(category.name)} alt={category.name} />
        </ListImage>
        <ListName>{category.name}</ListName>
      </Left>
      <ItemAmount>{getCategoryItemCount()}</ItemAmount>
      <ListTypesContainer>
        {category.isCategory && <CategoryBadge>categoria</CategoryBadge>}
        {category.isSubcategory && (
          <SubcategoryBadge>subcategoria</SubcategoryBadge>
        )}
      </ListTypesContainer>
      <AuthRole blackList={['Employee']}>
        <DeleteContainer>
          <FiTrash onClick={handleOnDeleteCategory} />
        </DeleteContainer>
      </AuthRole>
      {category.active ? (
        <ListVisibility active onClick={handleOnStatusClick}>
          <FiPauseCircle size={24} />
          <span>Pausar Vendas</span>
        </ListVisibility>
      ) : (
        <ListVisibility active={false} onClick={handleOnStatusClick}>
          <FiPlayCircle size={24} />
          <span>Retomar Vendas</span>
        </ListVisibility>
      )}
    </ListContainer>
  );
};

export default Category;
