import styled from 'styled-components';

import Modal from 'react-modal';

import { appearFromBottom } from '../../styles/animations';

export const StyledModal = styled(Modal)`
  animation: ${appearFromBottom} 0.5s;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 650px;
  height: 560px;

  background: ${({ theme }) => theme.palette.primary};

  border-radius: 16px;

  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);

  min-width: 250px;

  overflow: hidden;

  @media (min-width: 701px) {
    max-height: 560px;
  }

  @media (max-width: 700px) {
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    border-radius: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  padding: 12px;
  gap: 1rem;

  margin-bottom: 1rem;

  span {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 0.9rem;

    color: ${({ theme }) => theme.palette.text_white};
  }
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BackButton = styled.button`
  border: 0;
  background: none;

  margin-right: auto;
  margin-bottom: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  font-weight: 400;

  cursor: pointer;

  &,
  svg {
    color: ${({ theme }) => theme.palette.text_white};
  }
`;

export const SaveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: none;
  border: 1px solid ${({ theme }) => theme.palette.text_white};
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.text_white};

  padding: 4px 12px;
`;

export const Content = styled.div`
  border-top-right-radius: 16px;
  overflow: hidden;
`;

export const GroupContainer = styled.div`
  height: 512px;

  display: flex;
  flex-direction: column;

  background: #fff;

  gap: 8px;

  overflow-y: scroll;

  button + button {
    margin-top: 32px;
  }

  padding: 32px;
  padding-bottom: 128px;

  @media (max-width: 700px) {
    height: calc(100vh - 133px);
  }
`;

export const Group = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;

  gap: 8px;

  border-radius: 8px;

  background: ${({ theme }) => theme.palette.background_white};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_secondary};

  #groupInfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
    }

    #groupHeader {
      display: flex;
      align-items: center;
      gap: 8px;

      img {
        width: 32px;
        height: 32px;
        border-radius: 4px;
      }
    }
  }
`;

export const PositionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SortByButton = styled.button`
  width: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  background: transparent;
  border: 1px solid ${({ theme }) => theme.palette.background_white};
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.text_white};

  padding: 4px 12px;

  margin-right: 16px;
`;
