import { PixKeyType } from '../enums/pixKeyType';

export const unmaskPostalCode = (postalCode: string): string => {
  return postalCode.replace('.', '').replace('-', '');
};

export const unmaskPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber
    .replace('(', '')
    .replace(')', '')
    .replace(' ', '')
    .replace(' ', '')
    .replace('-', '')
    .replace(' ', '');
};

export const unmaskMoney = (value: string): number => {
  return Number(value.replace(/\D/g, '')) / 100;
};

export const unmaskPixKey = (value: string, type: PixKeyType): string => {
  switch (type) {
    case PixKeyType.CNPJ: {
      return value
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '');
    }

    case PixKeyType.CPF: {
      return value
        .replace('.', '')
        .replace('.', '')
        .replace('.', '')
        .replace('-', '');
    }

    case PixKeyType.PHONE: {
      return value
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '')
        .replace(' ', '')
        .replace(' ', '');
    }

    default: {
      return value;
    }
  }
};
