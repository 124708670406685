import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface IContainerProps {
  disabled: boolean | undefined;
}

interface IInputProps {
  hasError: boolean;
}

interface ILabelProps {
  hasFocus: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  border-bottom: 2px solid ${({ theme }) => theme.palette.outline};

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.palette.disabled};
    `}
`;

export const Label = styled.label<ILabelProps>`
  display: flex;
  justify-content: space-between;

  font-size: 80%;

  ${({ hasFocus }) =>
    hasFocus
      ? css`
          color: ${({ theme }) => theme.palette.primary};
        `
      : css`
          color: ${({ theme }) => theme.palette.control};
        `}

  > a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.primary};
    font-size: 14px;
    transition: opacity 0.3s;
  }

  > a:hover {
    opacity: 0.6;
  }

  margin-bottom: 8px;
`;

export const StyledInput = styled.input<IInputProps>`
  border: 0;
  font-size: 18px;
  background: none;
`;

export const Error = styled(Tooltip)`
  height: 20px;
  position: absolute;
  top: 18px;
  right: 12px;

  svg {
    margin: 0;
  }

  span {
    background: ${({ theme }) => theme.palette.error};
    color: ${({ theme }) => theme.palette.text_white};
    &::before {
      border-color: ${({ theme }) => theme.palette.error} transparent;
    }
  }
`;
