import React, { createContext, useCallback, useContext, useState } from 'react';
import { IGroupProps } from '../components/OrderGroupModal';
import api from '../services/api';
import { useCompany } from './company';
import { BsTicketPrintCodeType } from '../enums/bsticket';

interface BsTicketContextData {
  productsToOrder: IGroupProps[];
  loadProductsToOrder: () => Promise<void>;
  saveProductsOrder: (newOrderedProducts: IGroupProps[]) => Promise<void>;
  changeBsTicketCodeType: (newCodeType: BsTicketPrintCodeType) => Promise<void>;
}

const BsTicketContext = createContext<BsTicketContextData>(
  {} as BsTicketContextData,
);

export const BsTicketProvider: React.FC = ({ children }) => {
  const { company } = useCompany();

  const [productsToOrder, setProductsToOrder] = useState<IGroupProps[]>([]);

  const loadProductsToOrder = useCallback(async () => {
    if (company) {
      const response = await api.get<IGroupProps[]>(
        `restricted/products/bsticket/order`,
      );

      setProductsToOrder(response.data);
    }
  }, [company]);

  const saveProductsOrder = useCallback(
    async newOrderedProducts => {
      if (company) {
        const response = await api.patch<IGroupProps[]>(
          `restricted/products/bsticket/order`,
          newOrderedProducts,
        );

        setProductsToOrder(response.data);
      }
    },
    [company],
  );

  const changeBsTicketCodeType = useCallback(
    async newCodeType => {
      if (company) {
        await api.patch(
          `restricted/companies/bsticket/${newCodeType}/print-type`,
        );
      }
    },
    [company],
  );

  return (
    <BsTicketContext.Provider
      value={{
        productsToOrder,
        loadProductsToOrder,
        saveProductsOrder,
        changeBsTicketCodeType,
      }}
    >
      {children}
    </BsTicketContext.Provider>
  );
};

export function useBsTicket(): BsTicketContextData {
  const context = useContext(BsTicketContext);

  if (!context) {
    throw new Error('useBsTicket must be used within BsTicketProvider');
  }

  return context;
}
