import React, { useCallback } from 'react';

import { BiCheckCircle } from 'react-icons/bi';
import { useCompany } from '../../hooks/company';
import { useToast } from '../../hooks/toast';

import { Container } from './styles';

import {
  choosePaymentTypeIcon,
  chooseDeliveryTypeIcon,
} from '../../utils/icons';

interface TypeSelectProps {
  source: string;
  name: string;
  type: 'payment' | 'delivery';
}

const TypeSelect: React.FC<TypeSelectProps> = ({
  source,
  name,
  type,
  ...rest
}) => {
  const {
    togglePaymentType,
    toggleDeliveryType,
    acceptedPaymentTypes,
    acceptedOrderTypes,
  } = useCompany();
  const { addToast } = useToast();

  const handleOnToggleType = useCallback(
    async (
      e: React.MouseEvent<HTMLButtonElement>,
      name: string,
      active: boolean,
    ) => {
      e.preventDefault();

      try {
        type === 'payment'
          ? await togglePaymentType(name, active)
          : await toggleDeliveryType(name, active);

        addToast({
          type: 'success',
          description: `Você alterou o tipo de ${
            type === 'payment' ? 'pagamento' : 'entrega'
          }.`,
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Verifique a conexão e tente novamente.',
        });
      }
    },
    [type, toggleDeliveryType, togglePaymentType, addToast],
  );

  return (
    <Container
      selected={
        type === 'payment'
          ? acceptedPaymentTypes.includes(`${source}`)
          : acceptedOrderTypes.includes(`${source}`)
      }
      onClick={
        type === 'payment'
          ? e =>
              handleOnToggleType(
                e,
                `${source}`,
                acceptedPaymentTypes.includes(`${source}`),
              )
          : e =>
              handleOnToggleType(
                e,
                `${source}`,
                acceptedOrderTypes.includes(`${source}`),
              )
      }
      {...rest}
    >
      <img
        src={
          type === 'payment'
            ? choosePaymentTypeIcon(
                `${source}`,
                acceptedPaymentTypes.includes(`${source}`),
              )
            : chooseDeliveryTypeIcon(
                `${source}`,
                acceptedOrderTypes.includes(`${source}`),
              )
        }
        alt={name}
      />
      <span>{name}</span>
      <BiCheckCircle size={24} />
    </Container>
  );
};

export default TypeSelect;
