import styled from 'styled-components';

// export const Container = styled.div`
//   width: 100%;
//   cursor: default;

//   display: grid;
//   grid-template-rows: 1fr;
//   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//   grid-gap: 16px;
//   align-items: center;
//   justify-content: space-between;

//   margin-top: 32px;

//   @media (max-width: 1027px) {
//     flex-wrap: wrap;
//   }
// `;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: 16px;

  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};
`;

export const CardTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  img {
    width: 40px;
    height: 40px;
  }
`;

export const CardDescription = styled.div`
  display: flex;
  flex-direction: column;

  text-align: right;

  span + span {
    font-size: 24px;
  }
`;

export const CardBottom = styled.div`
  display: flex;
  align-items: center;

  margin-top: 16px;
  padding-top: 16px;

  border-top: 1px solid ${({ theme }) => theme.palette.control};

  span {
    font-size: 14px;
    margin-left: 16px;
    color: ${({ theme }) => theme.palette.text_light};
  }
`;
