import { ECategoryType } from '../enums/categoryType';

export const getFormattedCategoryType = (type: ECategoryType): string => {
  const types: { [key in ECategoryType]: string } = {
    LASAGNA: 'Lasanha',
    PIE: 'Torta',
    PIZZA: 'Pizza',
    STANDARD: 'Padrão',
  };

  return types[type] || types.STANDARD;
};

export const isSizedCategory = (categoryType?: ECategoryType): boolean => {
  if (!categoryType) return false;

  return [
    ECategoryType.PIE,
    ECategoryType.PIZZA,
    ECategoryType.LASAGNA,
  ].includes(categoryType);
};
