import { Base64 } from 'js-base64';

import { ICredentials } from '../models/ICredentials';

export const validatePassword = (password: string): boolean => {
  if (!password) {
    return false;
  }

  const regex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
  );

  return regex.test(password);
};

export const decryptCredentials = (credentials: string): ICredentials => {
  return JSON.parse(Base64.decode(credentials)) as ICredentials;
};
