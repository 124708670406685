import styled, { css } from 'styled-components';

interface IPrintButtonProps {
  type?: string;
}

interface IComplementsContainerProps {
  inverse?: boolean;
}

export const Container = styled.div`
  width: 90%;
  min-height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  width: 100%;

  margin-top: 6pt;

  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 52.5pt;
    height: 52.5pt;
  }
`;

export const Main = styled.main``;

export const OrderType = styled.div`
  text-align: center;
  color: #fff;
  padding: 0 6pt;
  background: #000;
  font-weight: bold;
`;

export const OrderNumber = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 10.5pt;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 10.5pt;
  margin-top: 12pt;
`;

export const ItemsTitle = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  width: 100%;

  padding-bottom: 6pt;
  border-bottom: 0.75pt solid #000;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;

  margin-left: 30%;
`;

export const Items = styled.div``;

export const Item = styled.div`
  padding: 6pt 0;
  border-bottom: 0.75pt solid #000;
`;

export const HorizontalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemName = styled.div`
  span {
    font-size: 15pt;
    margin-left: 4.5pt;
  }
  margin-bottom: 6pt;
`;

export const ItemComments = styled.span``;

export const ItemAdditionals = styled.div`
  margin-left: 12pt;
`;

export const ItemAdditionalsTitle = styled.span`
  font-weight: bold;
`;

export const ItemOptionals = styled.div`
  margin-left: 12pt;
`;

export const ItemOptionalsTitle = styled.span`
  font-weight: bold;
`;

export const Complement = styled.div`
  display: flex;
  justify-content: space-between;

  margin-left: 12pt;

  div {
    display: flex;

    span + span {
      margin-left: 3pt;
    }
  }
`;

export const ComplementAmount = styled.span``;

export const ComplementName = styled.span``;

export const ComplementsGroup = styled.div``;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  font-size: 9pt;
  margin-top: 6pt;

  @media print {
    margin-top: 2.625em;
  }
`;

export const ApprovedBy = styled.span``;

export const OrderDate = styled.div``;

export const PrintButton = styled.div<IPrintButtonProps>`
  position: absolute;

  top: 18pt;
  right: 24pt;

  button {
    display: flex;
    align-items: center;

    svg {
      margin-right: 12pt;
    }

    ${({ type }) =>
      type === 'MODAL'
        ? css`
            font-size: 18pt;

            border: 0;
            background: none;
          `
        : css`
            font-size: 18pt;

            border: 0;
            padding-bottom: 12px;
            border-bottom: 4px solid #db1622;
            background: none;
          `}
  }

  @media (max-width: 680px) {
    display: none;
  }
`;

export const CustomerContainer = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 10.5pt;
  margin-top: 12pt;
  padding-bottom: 6pt;
  border-bottom: 0.75pt solid ${({ theme }) => theme.palette.black};
`;

export const CustomerName = styled.div`
  font-size: 13.5pt;
`;

export const ComplementsContainer = styled.div<IComplementsContainerProps>`
  display: flex;
  flex-direction: ${({ inverse }) => (inverse ? 'column-reverse' : 'column')};
`;
