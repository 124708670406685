import styled, { css } from 'styled-components';

interface IContainerProps {
  selected: boolean;
}

export const Container = styled.button<IContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  margin-top: 16px;

  font-family: 'Poppins';

  border: 0;
  height: 130px;
  width: 130px;

  border-radius: 8px;

  margin-right: 16px;

  color: ${({ selected }) => (selected ? '#fff' : '#000')};
  background: ${({ selected }) => (selected ? '#db1622' : '#fdfdfd')};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: box-shadow 400ms;
  transition: border-right 100ms;
  transition: border-bottom 100ms;

  img {
    height: 40px;
    width: 40px;
  }

  span {
    margin-top: 20px;
  }

  &:hover {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }

  svg {
    position: absolute;
    bottom: 4px;
    right: 4px;
    opacity: 1;
    transition: opacity 150ms ease, transform 240ms ease;
    transform: none;

    ${({ selected }) =>
      !selected &&
      css`
        visibility: hidden;
        opacity: 0;
        transform: scale(0.8) rotateY(-30deg);
      `};
  }

  @media (max-width: 800px) {
    height: 100px;
    width: 100px;

    img {
      height: 8vw;
      width: 8vw;
    }

    span {
      margin-top: 12px;
    }

    svg {
      width: 18px;
      height: 18px;
      bottom: 0;
      right: 0;
    }
  }
`;
