import React, { useCallback, useState } from 'react';

import { FiEye, FiEyeOff, FiTrash2 } from 'react-icons/fi';

import NumberInput from '../NumberInput';
import IComplement from '../../models/IComplement';

import Input from '../Input';

import { Top, Bottom, Container, IconContainer } from './styles';

interface IComplementProps {
  index: number;
  complement: IComplement;
  onToggleActive?: (id: number) => void;
  onDelete: (id: string | number) => void;
  onChange: (value: string | number, name: string, id: string | number) => void;
}

const Complement: React.FC<IComplementProps> = ({
  index,
  complement,
  onChange,
  onDelete,
  onToggleActive,
}) => {
  const [defaultUnitPrice] = useState(complement.unitPrice);

  const handleOnPriceChange = useCallback(
    (value: number) => {
      onChange(value, 'unitPrice', complement.id || complement.localId || 0);
    },
    [complement, onChange],
  );

  const handleOnDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      onDelete(complement.id || complement.localId || '');
    },
    [complement, onDelete],
  );

  const handleOnToggleActive = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (onToggleActive) onToggleActive(complement.id || 0);
    },
    [complement, onToggleActive],
  );

  return (
    <Container active={complement.isActive}>
      <Top>
        <Input
          onChange={e =>
            onChange(
              e.target.value,
              'title',
              complement.id || complement.localId || 0,
            )
          }
          value={complement.title}
          name={`complement_name_${index}`}
          title="Nome (obrigatório)"
          maxLength={120}
        />
        <Input
          name="max_amount"
          title="Quantidade máxima"
          value={complement.maxAmount}
          type="number"
          min={0}
          onChange={e =>
            onChange(
              e.target.value,
              'maxAmount',
              complement.id || complement.localId || 0,
            )
          }
        />
      </Top>
      <Bottom>
        <Input
          name="complement_description"
          title="Descrição"
          value={complement.description}
          onChange={e =>
            onChange(
              e.target.value,
              'description',
              complement.id || complement.localId || 0,
            )
          }
          maxLength={255}
        />
        <NumberInput
          title="Preço"
          name="complement_price"
          onChange={handleOnPriceChange}
          defaultValue={defaultUnitPrice}
        />
      </Bottom>
      <IconContainer right={32} onClick={handleOnDelete}>
        <FiTrash2 />
      </IconContainer>
      {!!complement.id && (
        <IconContainer right={72} onClick={handleOnToggleActive}>
          {complement.isActive ? <FiEye /> : <FiEyeOff />}
        </IconContainer>
      )}
    </Container>
  );
};

export default Complement;
