import React, { useCallback } from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { useConfirmDialog } from '../../hooks/confim_dialog';
import { ITeammate } from '../../models/IUser';
import { formatAuthRoles } from '../../utils/format';

import {
  Container,
  InfosContainer,
  TeammateName,
  TeammateEmail,
  TeammateStatus,
  ButtonsContainer,
  Button,
} from './styles';

interface ITeammateProps {
  teammate: ITeammate;
  onEditTeammateClicked: () => void;
  onRevokeTeammateClicked: (email: string) => void;
}

const Teammate: React.FC<ITeammateProps> = ({
  teammate,
  onEditTeammateClicked,
  onRevokeTeammateClicked,
}) => {
  const { showConfirmDialog } = useConfirmDialog();

  const handleOnRevokeTeammateClicked = useCallback(
    () =>
      showConfirmDialog({
        title: 'Remover Membro',
        message: 'Deseja realmente remover este membro?',
        onCancel: () => null,
        onConfirm: () => onRevokeTeammateClicked(teammate.email),
      }),
    [teammate, onRevokeTeammateClicked, showConfirmDialog],
  );

  return (
    <Container>
      <InfosContainer>
        <TeammateName>
          {`${teammate.name} - `}
          <strong>{formatAuthRoles(teammate.roles)}</strong>
        </TeammateName>
        <TeammateEmail>{teammate.email}</TeammateEmail>
        <TeammateStatus active={teammate.active} />
      </InfosContainer>
      <ButtonsContainer>
        <Button type="button" onClick={onEditTeammateClicked} title="Editar">
          <FiEdit size={22} />
        </Button>
        <Button
          type="button"
          onClick={handleOnRevokeTeammateClicked}
          title="Excluir"
        >
          <FiTrash size={22} />
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export default Teammate;
