import React, { useCallback } from 'react';

import denied from '../../assets/denied.svg';
import { Dialog, Image, Container, CancelButton, StyledModal } from './styles';

interface IProps {
  open: boolean;
  onCancel: () => void;
}

StyledModal.setAppElement('#root');

const AccessDeniedDialog: React.FC<IProps> = ({ open, onCancel }) => {
  const handleOnCancel = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onCancel();
    },
    [onCancel],
  );

  const handleOnDivCancel = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onCancel();
    },
    [onCancel],
  );

  return (
    <StyledModal
      id="access-denied-dialog"
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={handleOnCancel}
      isOpen={open}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        },
      }}
    >
      <Container onClick={handleOnDivCancel}>
        <Dialog>
          <span>
            <Image src={denied} />
            <h3>Esta operação deve ser feita pelo PDV</h3>
          </span>
          <CancelButton onClick={handleOnCancel}>Ok</CancelButton>
        </Dialog>
      </Container>
    </StyledModal>
  );
};

export default AccessDeniedDialog;
