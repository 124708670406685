import ReactModal from 'react-modal';
import styled from 'styled-components';

import { appearFromBottom } from '../../styles/animations';

export const StyledModal = styled(ReactModal)`
  animation: ${appearFromBottom} 0.5s;
  background: ${({ theme }) => theme.palette.background_white};
  position: relative;
  border-radius: 8px;

  > h3 {
    margin-bottom: 32px;
  }
`;
export const CloseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const Content = styled.div`
  padding: 16px;
  display: grid;
  max-height: 80vh;
  overflow-y: auto;
  grid-template-columns: repeat(5, auto);

  @media (max-width: 680px) {
    grid-template-columns: repeat(3, auto);
  }

  @media (max-width: 412px) {
    grid-template-columns: repeat(2, auto);
  }
`;

export const Animation = styled.button`
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;

  &:hover {
    background: rgba(0, 0, 0, 0.09);
  }
`;
