import React from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { Container } from './styles';

interface IHelpButtonProps {
  onClick?: () => void;
}

const HelpButton: React.FC<IHelpButtonProps> = ({ onClick }) => (
  <Container onClick={onClick} id="help-button">
    <FiHelpCircle size={24} className="help" />
  </Container>
);

export default HelpButton;
