import styled, { css } from 'styled-components';

interface ButtonProps {
  buttonStyle: 'filled' | 'outline';
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  font-size: 14px;

  ${({ buttonStyle, theme }) => css`
    --color: ${({ theme }) => theme.palette.primary};
    border: 1px solid var(--color);
    background-color: ${buttonStyle === 'filled'
      ? 'var(--color)'
      : 'transparent'};
    color: ${buttonStyle === 'filled'
      ? theme.palette.text_white
      : 'var(--color)'};
  `}
`;
