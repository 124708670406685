import styled, { css } from 'styled-components';

interface IMostSoldProductProps {
  last: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 1270px) {
    width: 100vw;
  }
`;

export const Content = styled.main`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  padding-bottom: 0;
  position: relative;
  background: ${({ theme }) => theme.palette.background_secondary};
`;

export const Header = styled.header`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 24px;
`;

export const PageInfo = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-right: 16px;

    @media (min-width: 1271px) {
      display: none;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ChooseModuleButton = styled.button`
  display: flex;
  align-items: center;

  border: 0;
  background: none;

  svg {
    display: block;
  }

  span {
    font-size: 18px;
    padding-bottom: 8px;
    border-bottom: 2px solid ${({ theme }) => theme.palette.primary};

    @media (max-width: 580px) {
      display: none;
    }
  }
`;

export const PageName = styled.strong`
  font-size: 32px;

  @media (max-width: 1027px) {
    display: none;
  }
`;

export const Main = styled.div`
  width: 100%;

  flex: 1 1 auto;
  overflow-y: auto;

  padding: 24px;
  padding-bottom: 32px;

  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 480px) {
    padding-bottom: 64px;
  }
`;

export const CardsContainer = styled.div`
  width: 100%;

  display: grid;
  grid-gap: 16px;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  cursor: default;
  margin-top: 32px;
`;

export const Card = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: 16px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};
`;

export const FiltersContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 16px;
`;

export const ChartContainer = styled.div`
  scroll-margin-top: 10vh;
  margin-top: 32px;
`;

export const MostSoldContainer = styled.div`
  flex: 1;
  cursor: default;

  margin-top: 32px;
  border-radius: 8px;
  scroll-margin-top: 10vh;

  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};
`;

export const MostSoldHeader = styled.div`
  padding: 16px;
`;

export const MostSoldTitle = styled.h2``;

export const MostSoldProducts = styled.div`
  height: 100%;
  padding: 16px;
`;

export const MostSoldProduct = styled.div<IMostSoldProductProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ last }) =>
    !last &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.palette.control_light};
    `}

  margin-bottom: 16px;
  padding-bottom: 16px;
`;

export const Wrapper = styled.div`
  width: 20%;
  display: flex;
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary};

  width: 40px;
  min-width: 40px;

  height: 40px;
  min-height: 40px;

  color: ${({ theme }) => theme.palette.text_white};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 16px;
`;

export const ProductName = styled.div``;

export const ProductPrice = styled.div``;

export const Total = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 12px;
`;

export const Value = styled.strong``;

export const ButtonModuleContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  button + button {
    margin-left: 32px;
  }

  @media (max-width: 865px) {
    flex-direction: column;

    button + button {
      margin-left: 0;
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
`;

export const ButtonModule = styled.button`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: 0;
  padding: 64px;
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background-color: ${({ theme }) => theme.palette.background_white};

  transition: border-width 0.2s, transform 0.4s;

  &:hover {
    transform: translateY(-2%);
    border-bottom: 4px solid ${({ theme }) => theme.palette.primary};
  }

  @media (max-width: 865px) {
    width: 100%;
  }
`;

export const Logo = styled.img`
  width: 200px;
  height: 200px;
  margin-bottom: 64px;

  @media (max-width: 390px) {
    width: 150px;
    height: 150px;
  }
`;

export const ModuleTitle = styled.div`
  font-size: 32px;
`;

export const LoadMoreItems = styled.button`
  width: 100%;

  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;

  border: none;
  font-size: 1rem;
  padding: 0.4rem;
  margin-top: auto;
  border-radius: 4px;

  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};
`;
