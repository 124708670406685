import { DateTime } from 'luxon';

export const weekDays = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];

export const getWeekDayString = (day: number): string => {
  return weekDays[day];
};

export const normalizeHHmmToHHmmss = (hour: string): string => {
  const match = hour.split(':').length === 3;
  return match ? hour : `${hour}:00`;
};

export const convertHHmmssToNumber = (hour: string): number => {
  const normalizedHour = normalizeHHmmToHHmmss(hour)
    .substring(0, 5)
    .replace(/\D/g, '');

  return Number(normalizedHour);
};

export const isDatesSameDay = (date1: string, date2: string): boolean => {
  const date1Date = DateTime.fromISO(date1);
  const date2Date = DateTime.fromISO(date2);

  return date1Date.hasSame(date2Date, 'day');
};
