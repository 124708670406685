const hexToRgb = (hex: string) => {
  const normalized = hex.replace(
    /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
    (_, r, g, b) => `#${r}${r}${g}${g}${b}${b}`,
  );

  return normalized
    ?.substring(1)
    ?.match(/.{2}/g)
    ?.map(x => parseInt(x, 16));
};

const rgbToHex = (r: number, g: number, b: number) => {
  const colorsArray = [r, g, b].map(x => {
    const hex = x.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  });

  return `#${colorsArray.join('')}`;
};

const lerp = (a: number, b: number, u: number) => (1 - u) * a + u * b;

export const mergeColors = (
  color: string,
  secondColor: string,
  percentage: number,
): string => {
  const converted = hexToRgb(color) || [];
  const convertedSecond = hexToRgb(secondColor) || [];

  const r = Math.round(lerp(converted[0], convertedSecond[0], percentage));
  const g = Math.round(lerp(converted[1], convertedSecond[1], percentage));
  const b = Math.round(lerp(converted[2], convertedSecond[2], percentage));

  return rgbToHex(r, g, b);
};

export const getBlackOrWhiteFromColor = (color: string): string => {
  const [r, g, b] = hexToRgb(color) || [];

  if (r * 0.299 + g * 0.587 + b * 0.114 > 186) {
    return '#000000';
  }

  return '#ffffff';
};
