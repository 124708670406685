import styled, { css } from 'styled-components';

interface IPrintButtonProps {
  type?: string;
}

interface IComplementsContainerProps {
  inverse?: boolean;
}

export const Container = styled.div`
  width: 90%;
  height: 100%;

  display: flex;
  flex-direction: column;

  margin: 0 auto;

  @media print {
    overflow: hidden;
    page-break-inside: avoid;

    table {
      display: flex;
      flex-direction: column;

      font-size: 10.5pt;
      margin-top: 12pt;

      table-layout: fixed;

      thead {
        display: flex;

        tr {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          width: 100%;
        }

        th {
          text-align: right;

          &:nth-child(1) {
            width: 15%;
          }

          &:nth-child(2) {
            width: 40%;
          }

          &:nth-child(3) {
            width: 25%;
          }

          &:nth-child(4) {
            width: 25%;
          }

          &:nth-child(1),
          &:nth-child(2) {
            text-align: left;
          }
        }
      }

      tbody {
        tr {
          display: flex;
          align-items: flex-start;

          justify-content: space-between;

          td {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            text-align: right;

            &:nth-child(1) {
              width: 15%;
            }

            &:nth-child(2) {
              width: 40%;
            }

            &:nth-child(3) {
              width: 25%;
            }

            &:nth-child(4) {
              width: 25%;
            }

            &:nth-child(1),
            &:nth-child(2) {
              text-align: left;
            }
          }

          span.teste {
            font-weight: bold;
          }
        }
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 6pt;
`;

export const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 9pt;
  text-align: center;

  margin-left: 12pt;
`;

export const CompanyName = styled.strong`
  font-size: 12pt;
`;

export const CompanyAddress = styled.span``;

export const CompanyNeighborhood = styled.span``;

export const CompanyCity = styled.span``;

export const Main = styled.main`
  margin-top: 6pt;
`;

export const OrderNumberContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OrderType = styled.div`
  text-align: center;
  padding: 0 6pt;
  font-weight: bold;

  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.black};
`;

export const PaymentContainer = styled.div`
  margin-left: 30%;

  margin-top: 6pt;
`;

export const OrderNumber = styled.strong``;

export const CustomerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6pt;

  border-bottom: 1px solid ${({ theme }) => theme.palette.black};

  span {
    font-size: 9pt;
  }

  span:last-child {
    margin-bottom: 6pt;
  }
`;

export const CustomerName = styled.div`
  font-size: 12pt;
  font-weight: bold;
`;

export const ItemsContainer = styled.table`
  display: flex;
  flex-direction: column;

  font-size: 10.5pt;
  margin-top: 12pt;

  table-layout: fixed;

  border-bottom: 1px solid ${({ theme }) => theme.palette.black};
`;

export const ItemsTitle = styled.thead`
  display: flex;

  tr {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
  }

  th {
    text-align: right;

    &:nth-child(1) {
      width: 8%;
    }

    &:nth-child(2) {
      margin-left: 8px;
      width: 47%;
    }

    &:nth-child(3) {
      width: 25%;
    }

    &:nth-child(4) {
      width: 25%;
    }

    &:nth-child(1),
    &:nth-child(2) {
      text-align: left;
    }
  }

  padding-bottom: 6pt;
  border-bottom: 1px solid ${({ theme }) => theme.palette.black};
`;

export const OrderInfoItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  position: relative;

  span.value {
    font-size: 10.5pt;
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;

  margin-left: 56%;
`;

export const Items = styled.div``;

export const Item = styled.tbody`
  display: flex;
  flex-direction: column;

  tr.main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    td {
      text-align: right;

      &:nth-child(1) {
        width: 8%;
      }

      &:nth-child(2) {
        width: 47%;
      }

      &:nth-child(3) {
        width: 25%;
      }

      &:nth-child(4) {
        width: 25%;
      }

      &:nth-child(1),
      &:nth-child(2) {
        text-align: left;
      }
    }
  }

  tr.additionals {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    width: 92% !important;
    margin-left: 8% !important;

    td {
      width: 100% !important;
    }
  }
`;

export const ItemName = styled.span``;

export const ItemComments = styled.span`
  font-size: 9pt;
`;

export const ItemTotal = styled.span``;

export const ItemAdditionals = styled.div``;

export const ItemAdditionalsTitle = styled.span`
  font-weight: bold;
`;

export const ItemOptionals = styled.div``;

export const ItemOptionalsTitle = styled.span`
  font-weight: bold;
`;

export const Complement = styled.div`
  display: flex;
  justify-content: space-between;

  margin-left: 12pt;

  div {
    display: flex;

    span + span {
      margin-left: 3pt;
    }
  }
`;

export const ComplementAmount = styled.span``;

export const ComplementName = styled.span``;

export const Totals = styled.div`
  margin-left: 30%;
  margin-top: 12pt;
`;

export const Subtotal = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 9.75pt;
`;

export const Discount = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 9.75pt;
`;

export const DeliveryFee = styled.div`
  display: flex;
  justify-content: space-between;

  padding-bottom: 6pt;
  border-bottom: 1px solid ${({ theme }) => theme.palette.black};

  font-size: 9.75pt;
`;

export const Total = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 6pt;
`;

export const PaymentTitle = styled.span`
  display: flex;
  justify-content: center;

  border-bottom: 1px solid ${({ theme }) => theme.palette.black};

  font-size: 10.5pt;
`;

export const PaymentType = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 6pt;

  font-size: 9.75pt;
`;

export const TotalPrice = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 9.75pt;
`;

export const TotalChange = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 9.75pt;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  font-size: 9pt;
  margin-top: 6pt;
`;

export const ApprovedBy = styled.span``;

export const OrderDate = styled.div`
  margin-top: 6pt;
`;

export const PrintDate = styled.div``;

export const MadeBy = styled.div`
  margin-top: 6pt;
`;

export const PrintButton = styled.div<IPrintButtonProps>`
  button {
    display: flex;
    align-items: center;

    svg {
      margin-right: 12pt;
    }

    ${({ type }) =>
      type === 'MODAL'
        ? css`
            position: absolute;
            top: 24px;
            right: 16px;

            font-size: 18pt;

            border: 0;
            background: none;
          `
        : css`
            position: absolute;
            top: 24px;
            right: 32px;
            font-size: 18pt;

            border: 0;
            padding-bottom: 16px;
            border-bottom: 4px solid #db1622;
            background: none;
          `}
  }

  @media (max-width: 680px) {
    display: none;
  }
`;

export const Comments = styled.strong``;

export const CustomerPhone = styled.strong`
  margin-bottom: 3pt;
  font-size: 10.5pt !important;
`;

export const ComplementsContainer = styled.div<IComplementsContainerProps>`
  display: flex;
  flex-direction: ${({ inverse }) => (inverse ? 'column-reverse' : 'column')};
`;
