import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';

import { useHistory } from 'react-router-dom';
import { FiGrid, FiList, FiPlus } from 'react-icons/fi';

import IProduct from '../../models/IProduct';

import { AuthRole, useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { useCompany } from '../../hooks/company';
import { useSidebar } from '../../hooks/sidebar';
import { useProducts } from '../../hooks/products';
import { useCategories } from '../../hooks/categories';
import { useComplements } from '../../hooks/complements';

import Tour from '../../components/Tour';
import Search from '../../components/Search';
import Product from '../../components/Product';
import HelpButton from '../../components/HelpButton';
import EmptyMessage from '../../components/EmptyMessage';
import OptionsDialog from '../../components/OptionsDialog';
import LoadingAnimation from '../../components/LoadingAnimation';
import ConfirmActionDialog from '../../components/ConfirmActionDialog';
import SortByButtons, {
  SortByButtonsEnum,
} from '../../components/SortByButtons';

import tourData from '../../tour/products';
import { PageNames } from '../../enums/pages';

import {
  Filter,
  Header,
  Content,
  Filters,
  PageInfo,
  PageName,
  Container,
  MainHeader,
  MainContent,
  InnerContent,
  DisplayButton,
  FlexPlaceholder,
  AddProductButton,
  DisplayContainer,
  ProductsContainer,
  EmptyMessageContainer,
} from './styles';

import { search } from '../../utils/search';
import { LocalStorage } from '../../enums/localstorage';

const ProductsPage: React.FC = () => {
  const history = useHistory();
  const productListRef = useRef<HTMLDivElement>(null);

  const { addToast } = useToast();
  const { company } = useCompany();
  const { loadCategories } = useCategories();
  const { loadComplementsGroups } = useComplements();

  const {
    productPreferableDisplay,
    changeProductPreferableDisplay,
  } = useAuth();

  const {
    products,
    isProductsLoading,
    lastEditedProductId,
    loadProducts,
    selectProduct,
    deleteProduct,
    inactivateProduct,
  } = useProducts();

  const { setSelectedPage, selectedPage } = useSidebar();

  const [isTourVisible, setIsTourVisible] = useState(false);

  const [searchCriteria, setSearchCriteria] = useState('');
  const [itemToBeAlteredId, setItemToBeAlteredId] = useState(0);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isOptionsDialogOpen, setIsOptionsDialogOpen] = useState(false);
  const [procedure, setProcedure] = useState<'' | 'DELETE' | 'INACTIVATE'>('');
  const [selectedFilter, setSelectedFilter] = useState<'ALL' | 'NO_SKU'>('ALL');

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(
    false,
  );

  const [sortBy, setSortBy] = useState<SortByButtonsEnum>(
    (localStorage.getItem(
      LocalStorage.PRODUCTS_PREFERABLE_SORT,
    ) as SortByButtonsEnum) || SortByButtonsEnum.DATE_DESC,
  );

  useEffect(() => {
    async function loadData() {
      await loadProducts();
      await loadCategories();
      await loadComplementsGroups();
    }

    if (company) {
      loadData();
    }
  }, [loadCategories, loadProducts, loadComplementsGroups, company]);

  const handleOnSelectProduct = useCallback(
    (product: IProduct) => {
      selectProduct(product);
      history.push('/edit-product');
    },
    [history, selectProduct],
  );

  const handleOnSearchCriteriaChanged = useCallback((text: string) => {
    setSearchCriteria(text);
  }, []);

  const handleOnProductDeleted = useCallback(async () => {
    try {
      await deleteProduct(itemToBeAlteredId);

      addToast({
        type: 'success',
        description: 'Produto removido!',
      });
    } catch (err) {
      const errors = (err as any)?.response?.data?.errors?.messages;

      addToast({
        type: 'error',
        description:
          (Array.isArray(errors) && errors[0]) || 'Ocorreu um erro inesperado.',
      });
    }
  }, [deleteProduct, addToast, itemToBeAlteredId]);

  const handleOnProductInactivated = useCallback(async () => {
    try {
      await inactivateProduct(itemToBeAlteredId);

      addToast({
        type: 'success',
        description: 'Produto alterado!',
      });
    } catch (err) {
      const errors = (err as any)?.response?.data?.errors?.messages;

      addToast({
        type: 'error',
        description:
          (Array.isArray(errors) && errors[0]) || 'Ocorreu um erro inesperado.',
      });
    }
  }, [inactivateProduct, addToast, itemToBeAlteredId]);

  const handleOnActionDialogClosed = useCallback(() => {
    setProcedure('');
    setConfirmationMessage('');
    setIsConfirmationDialogOpen(false);
  }, []);

  const handleOnActionDialogConfirmed = useCallback(() => {
    handleOnActionDialogClosed();

    if (procedure === 'DELETE') {
      handleOnProductDeleted();
    } else {
      handleOnProductInactivated();
    }
  }, [
    procedure,
    handleOnProductDeleted,
    handleOnActionDialogClosed,
    handleOnProductInactivated,
  ]);

  const handleOnShowConfirmationDialog = useCallback(
    (id: number, proc: 'DELETE' | 'INACTIVATE') => {
      setProcedure(proc);
      setItemToBeAlteredId(id);

      if (proc === 'DELETE') {
        setConfirmationMessage('Deseja excluir o produto?');
      } else {
        const product = products.find(p => p.id === id);
        setConfirmationMessage(
          `Deseja ${
            product?.active ? 'pausar' : 'retomar'
          } as vendas do produto?`,
        );
      }

      setProcedure(proc);
      setIsConfirmationDialogOpen(true);
    },
    [products],
  );

  const handleAddNewProduct = useCallback(() => {
    history.push('/edit-product');
  }, [history]);

  const handleOnFilterChanged = useCallback((filter: 'ALL' | 'NO_SKU') => {
    setSelectedFilter(filter);
  }, []);

  const handleOnOptionsDialogClosed = useCallback(() => {
    setIsOptionsDialogOpen(false);
  }, []);

  const handleOnFilterDialogConfirmed = useCallback(
    (option: string) => {
      handleOnOptionsDialogClosed();

      setSelectedFilter(option as 'ALL' | 'NO_SKU');
    },
    [handleOnOptionsDialogClosed],
  );

  const handleOnDisplayChanged = useCallback(
    (display: 'LIST' | 'GRID') => {
      changeProductPreferableDisplay(display);
    },
    [changeProductPreferableDisplay],
  );

  const handleHelpButtonClick = useCallback(() => {
    setIsTourVisible(true);
  }, []);

  const handleTourFinish = useCallback(() => {
    setIsTourVisible(false);
  }, []);

  const handleOnSortByChanged = useCallback((sortBy: SortByButtonsEnum) => {
    setSortBy(sortBy);
    localStorage.setItem(LocalStorage.PRODUCTS_PREFERABLE_SORT, sortBy);
  }, []);

  const searchedProducts = useMemo(() => {
    if (!products) {
      return [];
    }

    const filteredProducts = search(
      products,
      (product: IProduct) =>
        product.title +
        product.subcategory?.name +
        product?.categories?.map(c => c?.name).join('') +
        product?.tags?.join(''),
      searchCriteria || '',
    );

    const sortedProducts = filteredProducts.sort((a, b) => {
      if (sortBy === SortByButtonsEnum.DATE_DESC) {
        return a.id > b.id ? -1 : 1;
      }

      if (sortBy === SortByButtonsEnum.DATE_ASC) {
        return a.id < b.id ? -1 : 1;
      }

      if (sortBy === SortByButtonsEnum.ALPHABETICAL_ASC) {
        return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
      }
      if (sortBy === SortByButtonsEnum.ALPHABETICAL_DESC) {
        return a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1;
      }

      return 0;
    });

    return sortedProducts;
  }, [products, searchCriteria, sortBy]);

  const filteredProducts = useMemo(() => {
    if (selectedFilter === 'ALL') {
      return searchedProducts;
    }

    return searchedProducts.filter(product => !product.sellerSku);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedProducts, selectedFilter, sortBy]);

  const dialogOptions = useMemo(() => {
    return [
      {
        ref: 'ALL',
        title: 'Todos',
        selected: selectedFilter === 'ALL',
      },
      {
        ref: 'NO_SKU',
        title: 'Sem código de integração',
        selected: selectedFilter === 'NO_SKU',
      },
    ];
  }, [selectedFilter]);

  useEffect(() => {
    setSelectedPage(PageNames.PRODUCTS);
  }, [setSelectedPage]);

  const autoScroll = () => {
    if (lastEditedProductId && productListRef.current) {
      const productElement = document.getElementById(
        `product-${lastEditedProductId}`,
      );
      if (productElement) {
        const { offsetTop } = productElement;
        const elementHeight = productElement.clientHeight;

        const scrollToPosition =
          offsetTop -
          elementHeight -
          (window.innerWidth <= 381
            ? 158
            : window.innerWidth <= 600
            ? 138
            : window.innerWidth <= 700
            ? 80
            : 32);
        productListRef.current.scrollTo({
          top: scrollToPosition,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <Container>
      <Content>
        <InnerContent>
          <Header>
            <PageInfo>
              <PageName>
                {selectedPage}
                <HelpButton onClick={handleHelpButtonClick} />
              </PageName>
            </PageInfo>
            <Search
              id="products-search"
              value={searchCriteria}
              onChange={handleOnSearchCriteriaChanged}
            />
            <AuthRole blackList={['Employee']}>
              <AddProductButton
                onClick={handleAddNewProduct}
                id="products-add"
                type="button"
              >
                <FiPlus />
                <span />
              </AddProductButton>
            </AuthRole>
          </Header>
          <MainContent>
            <MainHeader>
              <Filters id="products-filters">
                <Filter
                  onClick={() => handleOnFilterChanged('ALL')}
                  selected={selectedFilter === 'ALL'}
                >
                  Todos
                </Filter>
                <Filter
                  onClick={() => handleOnFilterChanged('NO_SKU')}
                  selected={selectedFilter === 'NO_SKU'}
                >
                  Sem código de integração
                </Filter>
              </Filters>
              <DisplayContainer id="products-display-preference">
                <DisplayButton>
                  <FiList
                    size={24}
                    onClick={() => handleOnDisplayChanged('LIST')}
                    color={
                      productPreferableDisplay === 'LIST' ? '#000' : '#0005'
                    }
                  />
                </DisplayButton>
                <DisplayButton>
                  <FiGrid
                    size={24}
                    onClick={() => handleOnDisplayChanged('GRID')}
                    color={
                      productPreferableDisplay === 'GRID' ? '#000' : '#0005'
                    }
                  />
                </DisplayButton>
                <SortByButtons
                  id="products-sort-by"
                  sortBy={sortBy}
                  onChange={handleOnSortByChanged}
                />
              </DisplayContainer>
            </MainHeader>
            {isProductsLoading ? (
              <LoadingAnimation />
            ) : (
              <>
                {filteredProducts.length > 0 ? (
                  <ProductsContainer
                    ref={productListRef}
                    className="has-custom-scroll-bar-2"
                    id="products-container"
                  >
                    {filteredProducts.map(product => (
                      <Product
                        autoScroll={autoScroll}
                        lastEdited={product.id === lastEditedProductId}
                        key={product.id}
                        product={product}
                        onClick={() => {
                          handleOnSelectProduct(product);
                        }}
                        onDelete={id =>
                          handleOnShowConfirmationDialog(id, 'DELETE')
                        }
                        onInactivate={id =>
                          handleOnShowConfirmationDialog(id, 'INACTIVATE')
                        }
                        display={productPreferableDisplay}
                        actions
                      />
                    ))}
                    <FlexPlaceholder />
                    <FlexPlaceholder />
                    <FlexPlaceholder />
                  </ProductsContainer>
                ) : (
                  <EmptyMessageContainer>
                    <EmptyMessage
                      searchCriteria={searchCriteria}
                      plural="produtos"
                      text="produto"
                      onClick={handleAddNewProduct}
                    />
                  </EmptyMessageContainer>
                )}
              </>
            )}
          </MainContent>
        </InnerContent>
      </Content>
      <ConfirmActionDialog
        title="Atenção"
        message={confirmationMessage}
        isOpen={isConfirmationDialogOpen}
        onClose={handleOnActionDialogClosed}
        onConfirm={handleOnActionDialogConfirmed}
      />
      <OptionsDialog
        title="Filtros"
        options={dialogOptions}
        isOpen={isOptionsDialogOpen}
        onClose={handleOnOptionsDialogClosed}
        onConfirm={handleOnFilterDialogConfirmed}
      />
      {isTourVisible && <Tour steps={tourData} onFinish={handleTourFinish} />}
    </Container>
  );
};

export default ProductsPage;
