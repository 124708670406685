import React, { useCallback, useMemo } from 'react';

import ITable from '../../models/ITable';

import tableIcon from '../../assets/icons/table-black.svg';

import { Container } from './styles';
import { useTables } from '../../hooks/tables';

interface ITableProps {
  table: ITable;
}

const Table: React.FC<ITableProps> = ({ table }) => {
  const { selectTable } = useTables();

  const handleClick = useCallback(() => {
    selectTable(table);
  }, [table, selectTable]);

  const orders = useMemo(() => {
    if (table.orders === 1) {
      return '1 comanda';
    }

    return `${table.orders} comandas`;
  }, [table]);

  return (
    <Container busy={table.busy} onClick={handleClick}>
      <img src={tableIcon} alt={`Mesa ${table.tableNumber}`} />
      <span>{`Mesa ${table.tableNumber}`}</span>
      {table.orders > 0 && <span className="card">{orders}</span>}
    </Container>
  );
};

export default Table;
