import React, { useCallback } from 'react';

import { FiEye, FiEyeOff, FiTrash2 } from 'react-icons/fi';
import { HiOutlinePencil } from 'react-icons/hi';
import { Container, EditMessage, EditMessageContainer } from './styles';

import IMessage from '../../models/IMessage';

interface IWarningBadgeProps {
  message: IMessage | null;
  color: string;
  blockEditable: boolean;
  onDelete: (id: number) => void;
  onToggle: (id: number) => void;
  onEdit: (message: IMessage | null) => void;
}

const WarningBadge: React.FC<IWarningBadgeProps> = ({
  color,
  message,
  blockEditable,
  onEdit,
  onToggle,
  onDelete,
}) => {
  const handleOnEdit = useCallback(() => {
    onEdit(message);
  }, [message, onEdit]);

  const handleOnDelete = useCallback(() => {
    if (message) {
      onDelete(message.id);
    }
  }, [message, onDelete]);

  const handleOnToggle = useCallback(() => {
    if (message) {
      onToggle(message.id);
    }
  }, [message, onToggle]);

  return (
    <Container color={color} active={message?.active}>
      {message && <h2>{message?.title}</h2>}
      <strong>{message?.description}</strong>
      {blockEditable && (
        <EditMessageContainer>
          <EditMessage onClick={handleOnEdit}>
            <HiOutlinePencil size={26} />
          </EditMessage>
          <EditMessage onClick={handleOnDelete}>
            <FiTrash2 size={26} />
          </EditMessage>
          <EditMessage onClick={handleOnToggle}>
            {message?.active ? <FiEye size={26} /> : <FiEyeOff size={26} />}
          </EditMessage>
        </EditMessageContainer>
      )}
    </Container>
  );
};

export default WarningBadge;
