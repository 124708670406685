import styled, { css } from 'styled-components';

interface IContainerProps {
  active: boolean;
}

interface IIconContainerProps {
  right: number;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 32px;
  background: ${({ theme }) => theme.palette.background_white};

  position: relative;

  ${({ active }) =>
    !active &&
    css`
      opacity: 0.7;
    `}
`;

export const Top = styled.div`
  width: 100%;

  display: flex;
  margin-top: 48px;

  justify-content: space-between;

  div {
    width: 45%;
  }
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  margin-top: 48px;

  justify-content: space-between;

  div {
    width: 45%;
  }
`;

export const IconContainer = styled.button<IIconContainerProps>`
  display: flex;
  padding: 6px;

  border: 0;
  padding: 6px;
  border-radius: 4px;

  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};

  top: 16px;
  position: absolute;
  right: ${({ right }) => right}px;
`;

export const ItemAmount = styled.span`
  width: 100%;
  text-align: right;
  margin-top: 16px;
`;
