import Modal from 'react-modal';

import styled from 'styled-components';

import { appearFromBottom } from '../../styles/animations';

export const StyledModal = styled(Modal)`
  animation: ${appearFromBottom} 0.5s;
`;

export const Container = styled.div`
  width: 40vw;
  height: 80vh;
  display: flex;
  background: ${({ theme }) => theme.palette.background_white};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};
  padding: 16px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 1100px) {
    width: 60vw;
  }

  @media (max-width: 500px) {
    width: 90vw;

    h2 {
      font-size: 22px;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  @media (max-width: 340px) {
    h2 {
      font-size: 20px;
    }
  }
`;

export const ProductsContainer = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

export const ProductImage = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4px;

  .product-icon {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
`;

interface IProductProps {
  inactive?: boolean;
}

export const Product = styled.button<IProductProps>`
  position: relative;
  border: none;
  width: 100%;
  padding: 8px;
  background: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.palette.text};
  opacity: ${({ inactive }) => (inactive ? 0.5 : 1)};

  span {
    overflow: hidden;
    text-align: left;
    max-width: calc(100% - 4rem);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }

  .arrow {
    position: absolute;
    right: 8px;
  }
`;

export const Search = styled.div`
  margin: 16px 0;
  width: calc(100% - 12px);
  padding: 0 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme }) => theme.palette.background_white};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  border-radius: 4px;

  input {
    width: 100%;
    padding: 4px 8px;
    border: none;
    background: transparent;
    color: ${({ theme }) => theme.palette.text};
  }

  svg {
    fill: ${({ theme }) => theme.palette.text};
  }
`;

export const ClearSearchButton = styled.button`
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
