import { IStep } from '../components/Tour';

const tour: IStep[] = [
  {
    text: 'Esta é a página de Pedidos!',
  },
  {
    id: 'orders-container',
    position: 'top',
    text: 'Aqui são listados todos os pedidos.',
  },
  {
    id: 'orders-filters',
    position: 'left',
    text: 'Por aqui você pode filtrá-los...',
  },
  {
    id: 'orders-search',
    position: 'left',
    text: 'ou fazer uma busca mais específica.',
  },
];

export default tour;
