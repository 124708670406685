import React from 'react';

import { AddTutorial, Container, NoSearchFound } from './styles';

interface EmptyMessageProps {
  searchCriteria?: string;
  text?: string;
  plural: string;
  nonCreatable?: boolean;
  onClick: () => void;
}

const EmptyMessage: React.FC<EmptyMessageProps> = ({
  text,
  plural,
  searchCriteria,
  nonCreatable,
  onClick,
}) => {
  return (
    <Container>
      {!searchCriteria ? (
        <AddTutorial>
          {`Ainda não há ${plural} cadastrados.`}
          {!nonCreatable && (
            <button type="button" onClick={() => onClick()}>
              clique aqui.
            </button>
          )}
        </AddTutorial>
      ) : (
        <NoSearchFound>
          <span>{`Nenhum ${text} encontrado`}</span>
        </NoSearchFound>
      )}
    </Container>
  );
};

export default EmptyMessage;
