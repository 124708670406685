import Modal from 'react-modal';
import styled from 'styled-components';

import { appearFromBottom } from '../../styles/animations';

interface IContainer {
  open?: boolean;
}

export const StyledModal = styled(Modal)`
  animation: ${appearFromBottom} 0.5s;
`;

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;

  padding: 8px;
  background: ${({ theme }) => theme.palette.background_white};

  border-radius: 16px;

  overflow-y: auto;
  overflow-x: hidden;

  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);

  min-width: 250px;
`;

export const Dialog = styled.div`
  width: 350px;
  height: 150px;
  padding: 18px;
  text-align: center;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  @media (max-width: 600px) {
    width: (100vw - 64px);
    height: calc(100vh / 3);
  }
`;

export const Image = styled.img`
  width: 60px;
  height: 60px;

  @media (max-width: 320px) {
    width: 45px;
    height: 45px;
  }
`;

export const CancelButton = styled.button`
  background: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.text_white};

  border: none;
  padding: 6px 28px;
  border-radius: 8px;
  font-size: 18px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
`;
