import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';

interface IHiddenInputProps {
  name: string;
  value?: string;
}

const HiddenInput: React.FC<IHiddenInputProps> = ({ name, value }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <input
      type="text"
      name={fieldName}
      ref={inputRef}
      defaultValue={defaultValue || value}
      style={{ display: 'none' }}
    />
  );
};

export default HiddenInput;
