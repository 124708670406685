import styled from 'styled-components';

export const Input = styled.input`
  display: none;

  + label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      background: transparent;
      border: none;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  + label .unchecked {
    display: flex;
  }

  + label .checked {
    display: none;
  }

  &:checked {
    + label .unchecked {
      display: none;
    }

    + label .checked {
      display: flex;
    }
  }
`;
