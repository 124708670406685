import styled from 'styled-components';

export const ProductsContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  gap: 24px;

  overflow-y: auto;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  flex: 1;

  border: 1px solid ${({ theme }) => theme.palette.control_light};
`;

export const ProductsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  padding: 24px;

  @media (max-width: 360px) {
    padding: 12px;
  }
`;

export const Product = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background_white};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_secondary};
  border-radius: 8px;
  padding: 12px 10px;

  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const ProductInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;

export const InfoWrapper = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const AddProductButton = styled.button`
  width: 100%;
  position: sticky;
  top: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.background};
  border: none;
  gap: 32px;
  padding: 12px 16px;
  color: ${({ theme }) => theme.palette.control};
  z-index: 1;

  svg {
    width: 32px;
    height: 32px;
  }

  &::after {
    content: '';
    width: 100%;
    height: 32px;
    display: block;
    position: absolute;
    bottom: -50%;
    background: linear-gradient(
      ${({ theme }) => theme.palette.background},
      transparent
    );
  }
`;

export const RemoveButton = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.text_white};
  border-radius: 4px;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Title = styled.strong``;

export const Info = styled.span``;

export const Label = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.control};
`;
