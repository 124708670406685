import styled, { css } from 'styled-components';

interface IContentProps {
  overlay?: boolean;
}

export const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Content = styled.main<IContentProps>`
  flex: 1 1 auto;
  overflow-y: auto;
  width: 100%;

  display: flex;
  flex-direction: column;

  background: #f4f4f6;
  position: relative;

  transition: all 0.2s;

  ${({ overlay }) =>
    overlay &&
    css`
      pointer-events: none;

      filter: blur(2px);
    `}

  @media (max-width: 480px) {
    padding-bottom: 64px;
  }
`;

export const Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  max-height: 100px;
  padding: 0 32px;
  padding-top: 24px;
`;

export const PageInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;

    @media (min-width: 1271px) {
      display: none;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const PageName = styled.strong`
  font-size: 32px;
  margin-left: 8px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const PageDescription = styled.span`
  color: #0008;

  margin-top: 9px;
  margin-left: 64px;

  @media (max-width: 720px) {
    display: none;
  }
`;

export const Main = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 0;
`;

export const NoComplementsGroupsMessage = styled.div`
  width: 100%;
  text-align: center;

  margin-top: 128px;
`;

export const ComplementsGroupsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: start;
  justify-content: space-between;

  flex: 1 1 auto;
  overflow-y: auto;
  width: 100%;
  height: 100px;

  padding: 32px;
  margin-top: 8px;
  padding-top: 8px;

  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 1280px) {
    height: calc(100vh - 180px);
  }

  @media (max-width: 500px) {
    padding: 32px 0px;
  }
`;

export const AddGroupButton = styled.button`
  display: flex;
  align-items: center;
  justify-self: flex-end;
  margin-left: 8px;
  border: 0;
  color: #fff;
  padding: 8px;
  background: #db1622;

  span {
    font-size: 14px;

    &::after {
      content: 'Novo';
      margin-left: 8px;
    }
  }

  @media (max-width: 460px) {
    span {
      &::after {
        content: '';
        margin-left: 0;
      }
    }
  }

  @media (max-width: 460px) {
    span {
      &::after {
        content: '';
        margin-left: 0;
      }
    }
  }
`;

export const FlexPlaceholder = styled.div`
  height: 0;
  width: 32%;
`;

export const AddProductTutorial = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  margin-top: 128px;

  padding: 8px;

  button {
    border: 0;
    background: none;

    margin-left: 8px;

    text-decoration: none;

    font-weight: 500;

    padding-bottom: 4px;
    border-bottom: 2px solid #db1622;
  }
`;
