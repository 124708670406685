export const getDeliveryTypeName = (name: string): string => {
  switch (name) {
    case 'DRIVE_THRU':
      return 'Drive Thru';
    case 'ON_TABLE':
      return 'Na Mesa';
    case 'CHECKOUT':
      return 'Retira';
    case 'TICKET':
      return 'BS Ticket';
    default:
      return 'Delivery';
  }
};

export const getPaymentTypeName = (name: string): string => {
  switch (name) {
    case 'CASH':
      return 'Dinheiro';
    case 'DEBIT_CARD':
    case 'DEBIT':
      return 'Cartão de Débito';
    case 'PIX':
      return 'Pix';
    case 'PIC_PAY':
      return 'PicPay';
    case 'AME':
      return 'Ame';
    case 'FB_PAYMENT':
      return 'Whatsapp (Facebook Pay)';
    case 'CHECK':
      return 'Cheque';
    case 'VOUCHER':
      return 'Vale Refeição';
    case 'MONTHLY_PAYER':
      return 'Mensalista';
    case 'NONE':
      return '-';
    default:
      return 'Cartão de Crédito';
  }
};

export const getFormattedStatus = (
  status: 'PLACED' | 'PREPARING' | 'IN_TRANSIT' | 'COMPLETED' | 'CANCELED',
): string => {
  switch (status) {
    case 'COMPLETED':
      return 'Finalizado';
    case 'IN_TRANSIT':
      return 'Em entrega';
    case 'PLACED':
      return 'Em aberto';
    case 'PREPARING':
      return 'Em preparo';
    default:
      return 'Cancelado';
  }
};

export const normalizeString = (string: string): string =>
  string
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim();

export const upperCaseFirstChar = (string: string): string =>
  `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;

export const digitsOnly = (string: string): string => string.replace(/\D/g, '');
