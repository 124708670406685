import axios from 'axios';

const repository = axios.create({
  baseURL: process.env.REACT_APP_CHATBOT_URL,
});

export const getReleaseUrl = async (arch: string): Promise<string> => {
  try {
    const { data: releases } = await repository.get(`/${arch}/RELEASES`);
    const currentReleaseName = releases.split(' ')[1] as string;
    const exeName = currentReleaseName.replace('-full.nupkg', ' Setup.exe');

    return encodeURI(`${process.env.REACT_APP_CHATBOT_URL}${arch}/${exeName}`);
  } catch {
    return '';
  }
};
// RELEASES BSFoodWhatsappBot-0.0.2-full.nupkg
