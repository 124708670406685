import { IStep } from '../components/Tour';

const tour: IStep[] = [
  {
    text: 'Esta é a página de Cupons de Descontos!',
  },
  {
    id: 'discount-info',
    position: 'bottom',
    text: 'Aqui ficam as definições do cupom.',
  },
  {
    id: 'discount-ref',
    position: 'left',
    text:
      'Esta é a referência, ou seja, o código que seus clientes terão que digitar.',
  },
  {
    id: 'discount-deactivate',
    position: 'left',
    text: 'Você pode desativar um cupom, mas cuidado, esta ação é permanente.',
  },
  {
    id: 'discount-details',
    position: 'right',
    text: 'Você pode ver mais detalhes do cupom clicando aqui.',
  },
  {
    id: 'discount-add',
    position: 'left',
    text: 'E para cadastrar um novo cupom de desconto basta clicar aqui.',
  },
];

export default tour;
