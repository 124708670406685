import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react';

import IOrder from '../models/IOrder';
import ITable from '../models/ITable';

import { useCompany } from './company';

import api from '../services/api';

interface TablesContextData {
  tables: ITable[];
  tableOrders: IOrder[];
  isTablesLoading: boolean;
  tableOrdersLoading: boolean;
  selectedTable: ITable | null;
  unselectTable: () => void;
  loadTables: () => Promise<void>;
  selectTable: (table: ITable) => void;
  setBusy: (tableNumber: number) => void;
}

const TablesContext = createContext<TablesContextData>({} as TablesContextData);

export const TablesProvider: React.FC = ({ children }) => {
  const { company } = useCompany();

  const [tables, setTables] = useState<ITable[]>([]);
  const [tableOrders, setTableOrders] = useState<IOrder[]>([]);
  const [isTablesLoading, setIsTablesLoading] = useState(true);
  const [tableOrdersLoading, setTableOrdersLoading] = useState(false);
  const [selectedTable, setSelectedTable] = useState<ITable | null>(null);

  const loadTables = useCallback(async () => {
    try {
      const response = await api.get<ITable[]>(
        `restricted/tables/${company?.id}`,
      );

      setTables(response.data);

      setIsTablesLoading(false);
    } catch {
      setIsTablesLoading(false);
    }
  }, [company]);

  const setBusy = useCallback((tableNumber: number) => {
    setTables(prevState =>
      prevState.map(t => {
        return {
          cards: t.cards,
          orders: t.orders,
          tableNumber: t.tableNumber,
          busy: tableNumber === t.tableNumber,
        };
      }),
    );
  }, []);

  const selectTable = useCallback((table: ITable) => {
    setSelectedTable(table);
  }, []);

  const unselectTable = useCallback(() => {
    setSelectedTable(null);
  }, []);

  const loadTableOrders = useCallback(async () => {
    try {
      setTableOrdersLoading(true);

      const response = await api.get(
        `restricted/orders/${company?.id}?status=PLACED,PREPARING,IN_TRANSIT&tableNumber=${selectedTable?.tableNumber}`,
      );

      setTableOrders(response.data.data);
    } catch (err) {
      console.log(err?.message || '');
    } finally {
      setTableOrdersLoading(false);
    }
  }, [selectedTable, company]);

  useEffect(() => {
    if (selectedTable) {
      loadTableOrders();
    }
  }, [selectedTable, loadTableOrders]);

  return (
    <TablesContext.Provider
      value={{
        tables,
        tableOrders,
        selectedTable,
        isTablesLoading,
        tableOrdersLoading,
        setBusy,
        loadTables,
        selectTable,
        unselectTable,
      }}
    >
      {children}
    </TablesContext.Provider>
  );
};

export function useTables(): TablesContextData {
  const context = useContext(TablesContext);

  if (!context) {
    throw new Error('useTables must be used within TablesProvider');
  }

  return context;
}
