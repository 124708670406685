import { ValidationError } from 'yup';

interface Errors {
  [key: string]: string;
}

export const getValidationErrors = (err: ValidationError): Errors => {
  const validationErrors: Errors = {};

  err.inner.forEach(error => {
    validationErrors[error.path] = error.message;
  });

  return validationErrors;
};

export const scrollToInputByName = (name: string): void => {
  const element = document.querySelector(`input[name=${name}]`);

  element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
};
