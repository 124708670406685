import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { CgColorPicker } from 'react-icons/cg';
import { getBlackOrWhiteFromColor } from '../../utils/colors';
import { Container, CustomContainer, CustomColor, Color } from './styles';

interface IColorPickerProps {
  colors: string[];
  cssVarPrefix: string;
  value: string;
  onChange: (color: string) => void;
}

const root = document.documentElement;

const ColorPicker: React.FC<IColorPickerProps> = ({
  value,
  colors,
  cssVarPrefix,
  onChange,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const changeColor = useCallback(
    (color: string) => {
      root.style.setProperty(`--${cssVarPrefix}-primary`, color);
      root.style.setProperty(
        `--${cssVarPrefix}-contrast`,
        getBlackOrWhiteFromColor(color),
      );
    },
    [cssVarPrefix],
  );

  const handleColorClick = useCallback(
    (color: string) => {
      onChange(color);
      changeColor(color);

      if (inputRef?.current) {
        inputRef.current.value = color;
      }
    },
    [changeColor, onChange],
  );

  const handleCustomClick = useCallback(() => {
    inputRef?.current?.focus();
    inputRef?.current?.click();
  }, []);

  const handleColorPickerBlur = useCallback(() => {
    if (inputRef?.current) {
      const { value } = inputRef?.current;
      onChange(value);
    }
  }, [onChange]);

  const handleColorPickerChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      changeColor(value);
    },
    [changeColor],
  );

  const DefaultColors = useMemo(
    () =>
      colors.map(color => (
        <Color
          key={color}
          color={color}
          onClick={() => handleColorClick(color)}
        />
      )),
    [colors, handleColorClick],
  );

  useEffect(() => {
    changeColor(value);
  }, [value, changeColor]);

  return (
    <Container {...rest}>
      <h2>Selecione uma cor:</h2>
      {DefaultColors}
      <CustomContainer>
        <span>Cor Customizada:</span>
        <CustomColor cssVarPrefix={cssVarPrefix} onClick={handleCustomClick}>
          <input
            ref={inputRef}
            type="color"
            onChange={handleColorPickerChange}
            onBlur={handleColorPickerBlur}
          />
          <CgColorPicker />
        </CustomColor>
      </CustomContainer>
    </Container>
  );
};

export default ColorPicker;
