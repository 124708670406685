import styled, { css } from 'styled-components';

interface IComplementGroupProps {
  active?: boolean;
}

export const Container = styled.div<IComplementGroupProps>`
  height: 166px;
  width: calc(1 / 3 * 100% - (1 - 1 / 3) * 16px);

  display: flex;
  flex-direction: column;

  position: relative;
  margin-bottom: 16px;

  border: 0;
  padding: 16px;
  background: ${({ theme }) => theme.palette.background_white};
  border-radius: 6px;

  box-shadow: ${({ theme }) => theme.shadow.box_shadow_hover};

  ${({ active }) =>
    !active &&
    css`
      opacity: 0.4;
    `}

  @media (max-width: 1780px) {
    width: calc(1 / 2 * 100% - (1 - 1 / 3) * 16px);
  }

  @media (max-width: 1280px) {
    width: 100%;
    flex: 0 100%;
    margin-left: 0;
    margin-bottom: 16px;
  }

  @media (max-width: 500px) {
    width: 100%;
    height: 126px;
    margin-right: 0;
    box-shadow: none;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const Items = styled.span`
  font-size: 14px;
  text-align: start;
  max-height: 60px;
  margin-top: 4px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media (max-width: 500px) {
    -webkit-line-clamp: 2;
    margin-top: 4px;
    font-size: 12px;
  }
`;

export const IsMandatory = styled.span`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemsCount = styled.button`
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.palette.text};

  &:disabled {
    cursor: default;
    opacity: 1;
    color: ${({ theme }) => theme.palette.text};
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const DeleteButton = styled.button`
  border: none;
  border-radius: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:not(:disabled) {
    svg {
      stroke: ${({ theme }) => theme.palette.control};
      transition: stroke 250ms ease-out;

      &:hover {
        stroke: ${({ theme }) => theme.palette.error_light};
      }
    }
  }
`;

export const EditButton = styled.button`
  border: none;
  border-radius: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    stroke: ${({ theme }) => theme.palette.control};
    transition: stroke 250ms ease-out;

    &:hover {
      stroke: ${({ theme }) => theme.palette.info}60;
    }
  }
`;

export const PlayPauseComplementGroupButton = styled.button`
  border: none;
  border-radius: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;

    stroke: ${({ theme }) => theme.palette.control};
    transition: stroke 250ms ease-out;

    &:hover {
      stroke: ${({ theme }) => theme.palette.info}60;
    }
  }
`;

interface ISmallContainer {
  items: number;
  expanded?: boolean;
}

export const SmallContainer = styled.div<ISmallContainer>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 16px;
  position: relative;

  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.4s;

  background: #fff;

  ${({ expanded, items }) =>
    expanded &&
    css`
      box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);

      .small-items {
        height: ${(items > 1 ? items * 22 : 8) + 8}px;
      }

      &:after {
        opacity: 0;
      }

      .expand-button svg {
        transform: rotateZ(180deg);
      }
    `}

  @media (max-width: 1780px) {
    flex: 0 48%;
  }

  @media (max-width: 1280px) {
    flex: 0 100%;
    width: 100%;
    margin-left: 0;
  }

  @media (max-width: 500px) {
    margin-right: 0;

    box-shadow: none;
  }
`;

export const SmallExpandButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background: transparent;

  svg {
    stroke: ${({ theme }) => theme.palette.text};
    transition: transform 200ms ease-in-out;
  }
`;

export const SmallAddButton = styled.button`
  position: absolute;
  top: 8px;
  right: 13px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background: ${({ theme }) => theme.palette.primary};
  height: 32px;

  border-radius: 12px;
  padding: 0 8px;

  svg {
    fill: ${({ theme }) => theme.palette.text_white};
    display: none;
  }

  &::before {
    content: 'Adicionar';
    font-size: 14px;
    display: block;
    color: ${({ theme }) => theme.palette.text_white};
  }

  @media (max-width: 800px) {
    border-radius: 50%;
    width: 32px;
    padding: 0;

    svg {
      display: block;
    }

    &::before {
      display: none;
    }
  }
`;

export const SmallTitle = styled.strong`
  text-align: start;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const SmallItems = styled.div`
  font-size: 12px;
  width: 100%;
  height: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  transition: height 200ms ease;
  will-change: height;

  @media (max-width: 500px) {
    -webkit-line-clamp: 2;
    margin-top: 4px;
    font-size: 12px;
  }
`;

export const SmallItem = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  padding: 2px 0;
`;

export const DisplayName = styled.span``;
