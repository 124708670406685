import { IStep } from '../components/Tour';

const tour: IStep[] = [
  {
    text: 'Esta é a página Dashboard!',
  },
  {
    id: 'dashboard-filters',
    position: 'bottom',
    text: 'Aqui você pode escolher entre períodos predefinidos de busca...',
  },
  {
    id: 'dashboard-filter-specific',
    position: 'bottom',
    text: 'ou escolher um período específico entre duas datas.',
  },
  {
    id: 'dashboard-cards',
    position: 'bottom',
    text: 'Aqui são exibidas algumas estatísticas importantes pra você.',
  },
  {
    id: 'dashboard-line-chart',
    position: 'top',
    text: 'Este gráfico mostra a evolução das suas vendas por hora.',
  },
  {
    id: 'dashboard-most-sold',
    position: 'top',
    text: 'Aqui são listados os produtos mais vendidos.',
  },
  {
    id: 'dashboard-most-sold-complements',
    position: 'top',
    text: 'Aqui são listados os complementos mais vendidos.',
  },
  {
    id: 'share-icon',
    text: 'Aqui você pode compartilhar seu menu ou sair da aplicação.',
    position: 'top',
  },
  {
    id: 'help-button',
    text: 'Você pode clicar aqui sempre que quiser rever o tutorial.',
    position: 'right',
  },
];

export default tour;
