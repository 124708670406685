import styled from 'styled-components';

export const Container = styled.button`
  border: 0;
  max-width: 24px;
  max-height: 24px;
  margin: 0 4px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  .help {
    display: block !important;
    stroke: ${({ theme }) => theme.palette.text};
  }

  @media (max-width: 1270px) {
    display: none;
  }
`;
