import styled, { css } from 'styled-components';

interface ReceivedItemsContainerProps {
  itemsLength: number;
}

export const ScrollContainer = styled.div.attrs({
  className: 'has-custom-scroll-bar-2',
})`
  width: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: min(1000px, 90%);
  padding: 1rem;
  background: ${({ theme }) => theme.palette.background};
  margin: 1rem 0;
  border-radius: 1rem 1.5rem;

  @media (max-width: 480px) {
    margin-bottom: 80px;
  }

  @media print {
    background: white;
    width: 100%;
    margin: auto;

    @page {
      margin: 3mm 2mm 2mm 3mm;
    }
  }
`;

interface IHeaderProps {
  disableMb?: boolean;
}

export const Header = styled.div<IHeaderProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ disableMb }) => (disableMb ? 0 : '1rem')};

  @media print {
    & svg {
      display: none;
    }
  }
`;

export const LeftHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: min-content;
  grid-gap: 1rem;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;

  @media print {
    display: none;
  }
`;

export const Card = styled.div`
  background: ${({ theme }) => theme.palette.background_white};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: fit-content;

  > .label {
    margin-bottom: 1rem;
  }

  @media print {
    box-shadow: none;
    border-radius: 0;
  }
`;

export const Label = styled.span.attrs({ className: 'label' })`
  font-weight: 400;
  font-size: 0.8rem;
`;

export const Value = styled.span.attrs({ className: 'value' })`
  font-weight: 500;
  font-size: 1rem;
`;

export const VerticalDivider = styled.span`
  width: 1px;
  height: 100%;
  background: ${({ theme }) => theme.palette.control_light};
  margin: 0 1rem;
`;

export const HorizontalDivider = styled(VerticalDivider)`
  height: 1px;
  width: 100%;
  margin: 1rem 0;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 412px) {
    flex-direction: column;
  }
`;

export const Col = styled(Row)`
  flex-direction: column;
`;

export const CashierNumber = styled(Card)`
  grid-row: 1 / 2;
  grid-column: span 1;

  > .label {
    margin-bottom: 0;
  }
`;

export const Date = styled(Card)`
  grid-row: 1 / 2;
  grid-column: span 5;
`;

export const Device = styled(Card)`
  grid-row: 2 / 3;
  grid-column: span 4;

  > .label {
    margin-bottom: 0;
  }
`;

export const Operator = styled(Card)`
  grid-row: 1 / 2;
  grid-column: span 3;

  > .label {
    margin-bottom: 0;
  }
`;

export const Platform = styled(Card)`
  grid-row: 2 / 3;
  grid-column: span 3;

  > .label {
    margin-bottom: 0;
  }
`;

export const Total = styled(Card)`
  grid-row: 3 / 4;
  grid-column: span 10;

  .label {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
  }
`;

export const TotalLabel = styled(Value)`
  font-weight: 400;

  strong {
    font-weight: 500;
  }
`;

export const Received = styled(Card)`
  grid-row: 4 / 5;
  grid-column: span 10;

  > .label {
    grid-column: span 2;
  }
`;

export const Supply = styled(Card)`
  grid-row: 5 / 6;
  grid-column: span 5;

  gap: 8px;
`;

export const Close = styled(Card)`
  grid-row: 5 / 6;
  grid-column: span 5;
`;

export const Products = styled(Card)`
  grid-row: 6 / 7;
  grid-column: span 5;
`;

export const CanceledOrders = styled(Card)`
  grid-row: 6 / 7;
  grid-column: span 5;
`;

export const Labels = styled(Row)`
  justify-content: space-between;
`;

export const Values = styled(Row)`
  justify-content: space-between;
`;

export const TotalSectionsContainer = styled(Row)`
  justify-content: space-between;
`;

export const TotalSection = styled(Col)`
  width: min-content;
  justify-content: center;
  align-items: center;

  .label {
    font-size: 1rem;
    font-weight: 500;
    white-space: nowrap;
  }

  .value {
    font-size: 1rem;
    font-weight: 400;
  }
`;

export const ReceivedItemsContainer = styled.div<ReceivedItemsContainerProps>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row dense;

  grid-column-gap: 2.5rem;

  ${({ itemsLength }) =>
    itemsLength > 1 &&
    css`
      .item {
        grid-column-start: 1;
      }

      .item:nth-child(n + ${Math.floor(itemsLength / 2) + 1}) {
        grid-column-start: 2;
      }
    `}

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const ReceivedItem = styled(Row).attrs({ className: 'item' })`
  justify-content: space-between;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }

  .label {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1rem;
    font-weight: 500;
    white-space: nowrap;
  }

  .value {
    font-size: 1rem;
    font-weight: 400;
  }

  @media (max-width: 412px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const Movement = styled(Row)`
  justify-content: space-between;

  .label {
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    svg {
      color: ${({ theme }) => theme.palette.success};
    }

    svg.bleed {
      color: ${({ theme }) => theme.palette.error};
    }
  }

  .value {
    font-size: 1rem;
    font-weight: 400;
  }
`;

export const CloseSectionsContainer = styled(TotalSectionsContainer)`
  @media (max-width: 412px) {
    gap: 1rem;
  }
`;

export const CloseSection = styled(TotalSection)`
  @media (max-width: 412px) {
    align-items: flex-start;
  }
`;

export const ProductsList = styled(Col)``;

export const ProductsCategoryLabel = styled(Label)`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const ProductItem = styled.div`
  width: 100%;
  display: grid;

  grid-template-columns: repeat(3, 1fr);

  > .label {
    grid-column: span 3;
    grid-row: span 1;
    font-size: 1rem;
    font-weight: 400;
  }

  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 412px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const ProductSection = styled(Col)`
  align-items: flex-start;
  justify-content: center;
  grid-column: span 1;

  .label {
    color: ${({ theme }) => theme.palette.text_light};
    font-size: 0.8rem;
    font-weight: 400;
  }

  .value {
    font-weight: 400;
  }

  &:nth-child(3) {
    justify-self: center;
  }

  &:nth-child(4) {
    justify-self: flex-end;
  }
`;

export const CanceledOrder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CanceledOrderHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CanceledOrderSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .label {
    color: ${({ theme }) => theme.palette.text_light};
    font-size: 0.8rem;
    font-weight: 400;
  }

  .value {
    font-weight: 400;
  }

  &:last-child {
    align-items: flex-end;
  }
`;

export const PrintButton = styled.button`
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  cursor: pointer;

  z-index: 2;

  background: ${({ theme }) => theme.palette.status_placed_transparency};

  svg {
    color: ${({ theme }) => theme.palette.status_placed};
  }

  @media print {
    display: none;
  }
`;

export const NoContentMessage = styled.span``;
