import React, { PropsWithChildren, ReactNode } from 'react';
import { FiX } from 'react-icons/fi';
import {
  Body,
  Button,
  Container,
  Header,
  LeftContainer,
  RightContainer,
  Title,
} from './styles';

type SummaryModalProps = PropsWithChildren<{
  title: string;
  isVisible: boolean;
  actionComponents?: ReactNode;
  onQuit: () => void;
}>;

const SummaryModal: React.FC<SummaryModalProps> = ({
  title,
  isVisible,
  actionComponents,
  children,
  onQuit,
}) => {
  return (
    <Container isVisible={isVisible}>
      <Header>
        <LeftContainer>
          <Button type="button" onClick={onQuit}>
            <FiX size={32} />
          </Button>
          <Title>{title}</Title>
        </LeftContainer>
        {actionComponents && (
          <RightContainer>{actionComponents}</RightContainer>
        )}
      </Header>
      <Body>{children}</Body>
    </Container>
  );
};

export default SummaryModal;
