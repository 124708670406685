import React, { useState, useContext, useCallback, createContext } from 'react';

import IDevice from '../models/IDevice';

import { useCompany } from './company';

import api from '../services/api';

interface DevicesContextData {
  devices: IDevice[];
  isDevicesLoading: boolean;
  getDevices: (type?: IDevice['deviceType']) => Promise<void>;
  activateDevice: (deviceId: string, code: string) => Promise<void>;
}

const DevicesContext = createContext<DevicesContextData>(
  {} as DevicesContextData,
);

export const DevicesProvider: React.FC = ({ children }) => {
  const { company } = useCompany();

  const [data, setData] = useState<IDevice[]>([]);

  const [isDevicesLoading, setIsDevicesLoading] = useState(false);

  const getDevices = useCallback(
    async (deviceType?: IDevice['deviceType']) => {
      setIsDevicesLoading(true);

      const response = await api.get<IDevice[]>(
        `/restricted/devices/${company?.id}`,
        { params: { deviceType: deviceType || 'ALL' } },
      );

      setIsDevicesLoading(false);

      setData(response.data);
    },
    [company],
  );

  const activateDevice = useCallback(
    async (deviceId: string, code: string) => {
      const response = await api.patch<IDevice[]>(
        `/restricted/devices/${company?.id}/activate`,
        {
          deviceId,
          activationCode: code,
          companyId: company?.id,
        },
      );

      if (response.status === 304) {
        throw new Error('Código inválido!');
      }

      const newData = [...data];
      const index = data.findIndex(d => d.deviceId === deviceId);

      if (index > -1) {
        newData[index].status = 'ACTIVE';
      }

      setData(newData);
    },
    [company, data],
  );

  return (
    <DevicesContext.Provider
      value={{
        devices: data,
        isDevicesLoading,
        getDevices,
        activateDevice,
      }}
    >
      {children}
    </DevicesContext.Provider>
  );
};

export function useDevices(): DevicesContextData {
  const context = useContext(DevicesContext);

  if (!context) {
    throw new Error('useDevices must be used within DevicesProvider');
  }

  return context;
}
