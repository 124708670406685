import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface IContainerProps {
  active: boolean;
}

interface IDayOfTheWeekProps {
  selected: boolean;
}

export const Container = styled.div<IContainerProps>`
  padding: 16px;
  background: #fff;

  ${({ active }) =>
    !active &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

export const DaysOfTheWeekContainer = styled.div`
  display: flex;

  margin-top: 16px;

  gap: 16px;
`;

export const DayOfTheWeek = styled.button<IDayOfTheWeekProps>`
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  color: #fff;
  background: #db1622;

  border: none;

  ${({ selected }) =>
    !selected &&
    css`
      color: #000;
      background: #f4f4f4;
    `}
`;

export const Hours = styled.div``;

export const Intervals = styled.div`
  margin-top: 48px;
`;

export const Interval = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 16px;
  }
`;

export const TimeInputContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 16px;
  margin-top: -20px;

  position: relative;

  span {
    font-size: 13px;
  }

  input {
    width: 100%;
    padding: 4px;
  }
`;

export const HoursHeader = styled.div`
  display: flex;
  align-items: center;

  margin-top: 32px;

  button {
    border: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 8px;
    background: #db1622;

    margin-left: 32px;
  }
`;

export const NoIntervals = styled.div`
  color: #ccc;
  font-size: 14px;
  margin-top: -32px;
`;

export const Error = styled(Tooltip)`
  height: 20px;
  position: absolute;
  top: 28px;
  right: 6px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
