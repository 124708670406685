import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useToast } from '../../hooks/toast';
import { useCompany } from '../../hooks/company';
import { useDevices } from '../../hooks/devices';

import Device from '../../components/Device';
import LoadingAnimation from '../../components/LoadingAnimation';
import ActivateDeviceDialog from '../../components/ActivateDeviceDialog';

import {
  Main,
  Header,
  Content,
  PageName,
  Container,
  NoDeviceFound,
  DevicesPlaceholder,
  Tabs,
} from './styles';
import { useSidebar } from '../../hooks/sidebar';
import { PageNames } from '../../enums/pages';
import Search from '../../components/Search';
import EditDeviceModal from '../../components/EditDeviceModal';
import IDevice from '../../models/IDevice';
import { normalizeString } from '../../utils/string';
import { useProducts } from '../../hooks/products';

const DevicesPage: React.FC = () => {
  const { addToast } = useToast();
  const { loadProducts } = useProducts();

  const { company } = useCompany();
  const { setSelectedPage, selectedPage } = useSidebar();

  const {
    devices,
    isDevicesLoading,
    getDevices,
    activateDevice,
  } = useDevices();

  const [deviceType, setDeviceType] = useState<IDevice['deviceType']>('POS');

  const [searchCriteria, setSearchCriteria] = useState('');
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const [selectedDevice, setSelectedDevice] = useState<IDevice | null>(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const handleDeviceTypeChange = async (value: typeof deviceType) => {
    setDeviceType(value);
  };

  const handleOnSearchCriteriaChanged = useCallback((text: string) => {
    setSearchCriteria(text);
  }, []);

  const handleOnDeviceActivateClicked = useCallback((device: IDevice) => {
    if (device?.status === 'ACTIVE') {
      return;
    }

    setSelectedDevice(device);
    setIsDialogVisible(true);
  }, []);

  const handleDeviceClick = (device: IDevice) => {
    if (device?.status !== 'ACTIVE')
      return handleOnDeviceActivateClicked(device);

    setSelectedDevice(device);
    return setIsEditModalVisible(true);
  };

  const handleOnEditModalClose = () => {
    setIsEditModalVisible(false);
    setSelectedDevice(null);
  };

  const handleOnDialogClosed = useCallback(() => {
    setIsDialogVisible(false);
  }, []);

  const handleOnDialogConfirmed = useCallback(
    async (code: string) => {
      if (!selectedDevice) return;

      setIsDialogVisible(false);

      try {
        await activateDevice(selectedDevice.deviceId?.toString(), code);

        addToast({
          type: 'success',
          description: 'Dispositivo ativado com sucesso!',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Código inválido!',
        });
      }
    },
    [selectedDevice, activateDevice, addToast],
  );

  const searchedDevices = useMemo(() => {
    if (searchCriteria) {
      return devices.filter(device => {
        const string_norm = normalizeString(searchCriteria);
        return normalizeString(device.name).includes(string_norm);
      });
    }

    return devices;
  }, [devices, searchCriteria]);

  useEffect(() => {
    if (company?.id) {
      getDevices(deviceType);
    }
  }, [deviceType, getDevices, company]);

  useEffect(() => {
    setSelectedPage(PageNames.DEVICES);
    loadProducts();
  }, [loadProducts, setSelectedPage]);

  return (
    <Container>
      <Content>
        <EditDeviceModal
          device={selectedDevice}
          visible={isEditModalVisible}
          onClose={handleOnEditModalClose}
        />
        <Header>
          <PageName>{selectedPage}</PageName>
          <Search
            value={searchCriteria}
            onChange={handleOnSearchCriteriaChanged}
          />
        </Header>
        <Tabs>
          <button
            type="button"
            className={deviceType === 'POS' ? 'active' : ''}
            onClick={() => handleDeviceTypeChange('POS')}
          >
            POS
          </button>
          <button
            type="button"
            className={deviceType === 'KIOSK' ? 'active' : ''}
            onClick={() => handleDeviceTypeChange('KIOSK')}
          >
            Kiosk
          </button>
        </Tabs>
        {isDevicesLoading ? (
          <LoadingAnimation />
        ) : (
          <Main className="has-custom-scroll-bar-2">
            {searchedDevices.length > 0 ? (
              <>
                {searchedDevices.map(device => (
                  <Device
                    key={device.id}
                    device={device}
                    onClick={() => handleDeviceClick(device)}
                    onActivate={() => handleOnDeviceActivateClicked(device)}
                  />
                ))}
                <DevicesPlaceholder />
                <DevicesPlaceholder />
                <DevicesPlaceholder />
              </>
            ) : (
              <NoDeviceFound>
                <span>Nenhum dispositivo encontrado.</span>
              </NoDeviceFound>
            )}
          </Main>
        )}
      </Content>
      <ActivateDeviceDialog
        isOpen={isDialogVisible}
        title="Ativar Dispositivo"
        onClose={handleOnDialogClosed}
        onConfirm={handleOnDialogConfirmed}
        message="Digite o código exibido no dispositivo."
      />
    </Container>
  );
};

export default DevicesPage;
