import { AxiosResponse } from 'axios';
import React, { createContext, useCallback, useContext, useState } from 'react';

import { IProductGroup } from '../models/IProductGroups';
import api from '../services/api';
import { useToast } from './toast';

type CreateProductGroupType = Omit<IProductGroup, 'id'>;

interface IProductGroupsContext {
  productGroups?: IProductGroup[];
  loadProductGroups: () => Promise<void>;
  createProductGroup: (group: CreateProductGroupType) => Promise<void>;
  editProductGroup: (group: IProductGroup) => Promise<void>;
  deleteProductGroup: (groupId: number) => Promise<void>;
  saveProductGroups: (groups: IProductGroup[]) => Promise<void>;
}

const ProductGroupsContext = createContext<IProductGroupsContext>(
  {} as IProductGroupsContext,
);

export const ProductGroupsProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();

  const [productGroups, setProductGroups] = useState<IProductGroup[]>([]);

  const loadProductGroups = useCallback(async () => {
    const { data } = await api.get<IProductGroup[]>('/product-groups');
    return setProductGroups(data);
  }, []);

  const createProductGroup = useCallback(
    async (group: CreateProductGroupType) => {
      try {
        const { data } = await api.post<
          IProductGroup,
          AxiosResponse<IProductGroup>
        >('/restricted/product-groups', group);

        setProductGroups(old => [...old, data]);

        addToast({
          type: 'success',
          description: 'Criação realizada com sucesso.',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Erro ao criar um novo grupo.',
        });
      }
    },
    [addToast],
  );

  const editProductGroup = useCallback(
    async (group: IProductGroup) => {
      try {
        const newGroups = productGroups.map(g =>
          g.id === group.id ? group : g,
        );

        await api.post('/restricted/product-groups', group);

        setProductGroups(newGroups);

        addToast({
          type: 'success',
          description: 'Edição realizada com sucesso.',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Erro ao editar o grupo.',
        });
      }
    },
    [addToast, productGroups],
  );

  const deleteProductGroup = useCallback(
    async (groupId: number) => {
      try {
        const newGroups = productGroups.filter(group => group.id !== groupId);

        await api.delete(`/restricted/product-groups/${groupId}`);

        setProductGroups(newGroups);

        addToast({
          type: 'success',
          description: 'Exclusão realizada com sucesso.',
        });
      } catch (err) {
        const errors = (err as any)?.response?.data?.errors?.messages;

        addToast({
          type: 'error',
          description:
            (err as any)?.response?.status === 400
              ? 'O grupo contém produtos relacionados.'
              : (Array.isArray(errors) && errors[0]) ||
                'Erro ao realizar a exclusão.',
        });
      }
    },
    [addToast, productGroups],
  );

  const saveProductGroups = useCallback(
    async (groups: IProductGroup[]) => {
      try {
        await api.patch('/restricted/product-groups', groups);

        setProductGroups(groups);

        addToast({
          type: 'success',
          description: 'Grupos salvos com sucesso.',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Erro ao salvar os grupos.',
        });
      }
    },
    [addToast],
  );

  return (
    <ProductGroupsContext.Provider
      value={{
        productGroups,
        loadProductGroups,
        createProductGroup,
        editProductGroup,
        deleteProductGroup,
        saveProductGroups,
      }}
    >
      {children}
    </ProductGroupsContext.Provider>
  );
};

export function useProductGroups(): IProductGroupsContext {
  const context = useContext(ProductGroupsContext);

  if (!context) {
    throw new Error(
      'useProductGroups must be used within ProductGroupsProvider',
    );
  }

  return context;
}
