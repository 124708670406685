import React, { useCallback, useMemo } from 'react';

import SkuInput, { SkuPriceInput } from '../SkuInput';
import { ReactComponent as ProductPlaceholderSVG } from '../../assets/icons/product_placeholder.svg';

import IProductSku from '../../models/IProductSku';

import {
  Top,
  Left,
  Right,
  ListName,
  ListImage,
  ListContainer,
  VerticalWrapper,
} from './styles';

import { randomInt } from '../../utils/numbers';
import { AuthRole } from '../../hooks/auth';

interface IProductProps {
  product: IProductSku;
  onChangeSku: (id: number, sku: string) => void;
  onChangeUnitPrice: (id: number, price: number) => void;
}

const ProductSku: React.FC<IProductProps> = ({
  product,
  onChangeSku,
  onChangeUnitPrice,
}) => {
  const handleOnSkuChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeSku(product.id, e.target.value);
    },
    [product, onChangeSku],
  );

  const handleOnPriceChange = useCallback(
    (value: number) => {
      onChangeUnitPrice(product.id, value);
    },
    [product, onChangeUnitPrice],
  );

  const productIcon = useMemo(() => {
    if (product.image) {
      return <img src={product.image} alt={product.name} />;
    }

    const classes = ['blue', 'red', 'orange', 'green', 'yellow'];

    const randomClass = classes[randomInt(0, classes.length - 1)];
    return <ProductPlaceholderSVG className={randomClass} />;
  }, [product]);

  return (
    <ListContainer>
      <Top>
        <Left>
          <ListImage>{productIcon}</ListImage>
          <VerticalWrapper>
            <ListName>{product.name}</ListName>
          </VerticalWrapper>
        </Left>
        <AuthRole disableOnly>
          <Right>
            {!product.isPizza && (
              <SkuPriceInput
                name="price"
                title="Preço do Produto"
                defaultValue={product?.unitPrice || 0}
                onChangeValue={handleOnPriceChange}
              />
            )}
            <SkuInput
              name="sellerSku"
              title="Código de Integração"
              value={product.sellerSku}
              onChange={handleOnSkuChanged}
            />
          </Right>
        </AuthRole>
      </Top>
    </ListContainer>
  );
};

export default ProductSku;
