import styled, { css } from 'styled-components';
import Ripple from 'react-ripples';

interface IFilterProps {
  selected?: boolean;
}

export const DateRangePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FiltersTitle = styled.strong``;

export const Filters = styled.div`
  display: flex;

  @media (max-width: 730px) {
    flex-direction: column;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const Filter = styled.div<IFilterProps>`
  width: 120px;
  height: 150px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.background_white};

  cursor: pointer;
  position: relative;

  transition: box-shadow 0.4s;

  ${({ selected }) =>
    selected
      ? css`
          box-shadow: ${({ theme }) => theme.shadow.box_shadow_small_primary};
          span.arrow {
            &::before {
              content: '';
              border-style: solid;
              border-color: ${({ theme }) => theme.palette.primary} transparent;
              border-width: 24px 24px 0 24px;

              left: 50%;
              bottom: -18px;
              position: absolute;

              transform: translateX(-50%);
            }
          }
        `
      : css`
          box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
        `}

  span {
    font-size: 14px;
  }

  strong {
    font-size: 24px;
    ${({ selected }) =>
      selected &&
      css`
        color: ${({ theme }) => theme.palette.primary};
      `}
  }

  &:hover {
    ${({ selected }) =>
      selected
        ? css`
            box-shadow: ${({ theme }) => theme.shadow.box_shadow_hover_primary};
          `
        : css`
            box-shadow: ${({ theme }) => theme.shadow.box_shadow_hover};
          `}
  }

  svg {
    margin-bottom: 16px;
  }
`;

export const CustomFilter = styled.div<IFilterProps>`
  height: 150px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 0 16px;

  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.background_white};

  position: relative;

  transition: box-shadow 0.4s;

  > div {
    width: 100%;
    margin-top: -8px;
  }

  ${({ selected }) =>
    selected
      ? css`
          box-shadow: ${({ theme }) => theme.shadow.box_shadow_small_primary};
          span.arrow {
            &::before {
              z-index: 1;
              content: '';
              border-style: solid;
              border-color: ${({ theme }) => theme.palette.primary} transparent;
              border-width: 24px 24px 0 24px;

              left: 50%;
              bottom: -18px;
              position: absolute;

              transform: translateX(-50%);
            }
          }
        `
      : css`
          box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
        `}

  span {
    font-size: 14px;
  }

  strong {
    font-size: 24px;
    ${({ selected }) =>
      selected &&
      css`
        color: ${({ theme }) => theme.palette.primary};
      `}
  }

  &:hover {
    ${({ selected }) =>
      selected
        ? css`
            box-shadow: ${({ theme }) => theme.shadow.box_shadow_hover_primary};
          `
        : css`
            box-shadow: ${({ theme }) => theme.shadow.box_shadow_hover};
          `}
  }

  @media (max-width: 730px) {
    margin-top: 32px;
  }

  @media (min-width: 731px) {
    margin-left: 16px;
  }
`;

export const Wrapper = styled.div`
  width: 20%;
  display: flex;
`;

export const DateInputContainer = styled.div`
  flex: 1;
  width: 100%;

  > div {
    width: 100%;
  }
`;

export const ButtonRipple = styled(Ripple)`
  width: 70px;
  height: 100%;
  margin-top: 4px;
  margin-left: 16px;

  border: 0;
  border-radius: 0 8px 8px 0;
`;

export const ConfirmDateButton = styled.button`
  width: 70px;
  height: 100%;

  border: 0;
  border-radius: 0 8px 8px 0;
  background: ${({ theme }) => theme.palette.primary};
`;
