import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiInfo, FiX } from 'react-icons/fi';
import Select from 'react-select';
import { CustomerRolesTypes, ITeammate } from '../../models/IUser';
import { selectStyles } from '../../styles/select';
import { allRoles } from '../../utils/auth';
import { formatAuthRole } from '../../utils/format';
import { validateEmail } from '../../utils/validations';
import FormlessInput from '../FormlessInput';
import Loading from '../Loading';

import {
  StyledModal,
  Container,
  Header,
  CloseButton,
  RolesContainer,
  LabelContainer,
  ButtonsContainer,
  CancelButton,
  SaveButton,
  Content,
  RoleContainer,
  RoleTitle,
} from './styles';

interface IAddTeammateModalProps {
  teammate: ITeammate | null;
  isOpen: boolean;
  onConfirm: (
    teammate: Pick<ITeammate, 'name' | 'email' | 'roles'>,
  ) => Promise<void>;
  onClose: () => void;
}

interface ISelect {
  label: string;
  value: CustomerRolesTypes;
}

const AddTeammateModal: React.FC<IAddTeammateModalProps> = ({
  teammate,
  isOpen,
  onConfirm,
  onClose,
}) => {
  const [teammateName, setTeammateName] = useState('');
  const [teammateEmail, setTeammateEmail] = useState('');

  const [selectedRole, setSelectedRole] = useState<ISelect | null>(null);

  const [teammateNameError, setTeammateNameError] = useState('');
  const [teammateEmailError, setTeammateEmailError] = useState('');
  const [teammateRoleError, setTeammateRoleError] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const [isRolesInfoModalOpen, setRolesInfoModalOpen] = useState(false);

  const formattedRoles = useMemo(
    () =>
      allRoles
        .filter(role => role !== 'Company')
        .map(role => ({
          label: formatAuthRole(role),
          value: role,
        })),
    [],
  );

  useEffect(() => {
    setTeammateName(teammate?.name || '');
    setTeammateEmail(teammate?.email || '');

    if (teammate) {
      setSelectedRole(
        formattedRoles.find(role => teammate.roles.includes(role.value)) ||
          null,
      );
    } else {
      setSelectedRole(null);
    }
  }, [formattedRoles, teammate]);

  const handleTeammateNameChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setTeammateName(value);
      setTeammateNameError('');
    },
    [],
  );

  const handleTeammateEmailChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setTeammateEmail(value);
      setTeammateEmailError('');
    },
    [],
  );

  const handleTeammateRolesChanged = useCallback((param: any) => {
    setSelectedRole(param as ISelect);
    setTeammateRoleError('');
  }, []);

  const handleOnSaveTeammate = useCallback(async () => {
    let hasErrors = false;

    if (teammateName.length < 2 || teammateName.length > 31) {
      setTeammateNameError(
        !teammateName
          ? 'O nome do membro é obrigatório.'
          : 'O nome do membro precisa conter entre 2 e 31 caracteres.',
      );

      hasErrors = true;
    }

    if (!validateEmail(teammateEmail)) {
      setTeammateEmailError(
        !teammateEmail
          ? 'O e-mail do membro é obrigatório.'
          : 'Insira um e-mail valido.',
      );

      hasErrors = true;
    }

    if (!selectedRole) {
      setTeammateRoleError('O cargo do membro é obrigatório.');

      hasErrors = true;
    }

    if (hasErrors) return;

    setIsSaving(true);

    const newTeammate: Pick<ITeammate, 'name' | 'email' | 'roles'> = {
      name: teammateName,
      email: teammateEmail,
      roles: selectedRole ? [selectedRole.value] : [],
    };

    await onConfirm(newTeammate);

    setIsSaving(false);

    setTeammateName('');
    setTeammateEmail('');
    setSelectedRole(null);

    onClose();
  }, [onClose, onConfirm, teammateName, teammateEmail, selectedRole]);

  const handleOnModalClose = useCallback(() => {
    setTeammateName('');
    setTeammateEmail('');
    setSelectedRole(null);

    setTeammateNameError('');
    setTeammateEmailError('');
    setTeammateRoleError('');

    onClose();
  }, [onClose]);

  return (
    <StyledModal
      isOpen={isOpen}
      shouldCloseOnEsc
      id="add-teammate-modal"
      onRequestClose={handleOnModalClose}
      shouldCloseOnOverlayClick
      style={{
        overlay: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <Container>
        <Header>
          {!teammate ? <h3>Novo membro</h3> : <h3>Editar membro</h3>}
          <CloseButton onClick={handleOnModalClose}>
            <FiX size={20} />
          </CloseButton>
        </Header>
        <div className="error-container">
          <FormlessInput
            title="Nome"
            placeholder="Nome"
            name="teammate_name"
            value={teammateName}
            onChange={handleTeammateNameChanged}
            disabled={!!teammate}
          />
          {teammateNameError && (
            <span className="error">{teammateNameError}</span>
          )}

          <FormlessInput
            title="E-mail"
            placeholder="E-mail"
            name="teammate_email"
            value={teammateEmail}
            onChange={handleTeammateEmailChanged}
            disabled={!!teammate}
          />
          {teammateEmailError && (
            <span className="error">{teammateEmailError}</span>
          )}

          <RolesContainer>
            <LabelContainer>
              <span>Cargo</span>
              <FiInfo
                size={16}
                className="info"
                onClick={() => setRolesInfoModalOpen(true)}
              />
            </LabelContainer>
            <Select
              isClearable={false}
              styles={selectStyles}
              value={selectedRole}
              options={formattedRoles}
              menuPortalTarget={document.body}
              onChange={handleTeammateRolesChanged}
              placeholder="Selecione o cargo"
            />
            {teammateRoleError && (
              <span className="error">{teammateRoleError}</span>
            )}
          </RolesContainer>
        </div>
        <ButtonsContainer>
          <CancelButton onClick={handleOnModalClose}>Cancelar</CancelButton>
          <SaveButton onClick={handleOnSaveTeammate}>
            {isSaving ? (
              <Loading color="text_white" stroke={2} radius={16} />
            ) : (
              'Salvar'
            )}
          </SaveButton>
        </ButtonsContainer>
      </Container>

      <StyledModal
        isOpen={isRolesInfoModalOpen}
        shouldCloseOnEsc
        id="roles-info-modal"
        onRequestClose={() => setRolesInfoModalOpen(false)}
        shouldCloseOnOverlayClick
        style={{
          overlay: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
      >
        <Container>
          <Header>
            <h3>Cargos</h3>
            <CloseButton onClick={() => setRolesInfoModalOpen(false)}>
              <FiX size={20} />
            </CloseButton>
          </Header>
          <Content>
            <RoleContainer>
              <RoleTitle>Gerente</RoleTitle>
              <ul>
                <li>Não pode acessar a Dashboard;</li>
                <li>Não pode acessar a Equipe;</li>
                <li>Não pode editar dados da Empresa.</li>
              </ul>
            </RoleContainer>

            <RoleContainer>
              <RoleTitle>Marketing</RoleTitle>
              <ul>
                <li>Acesso total ao cardápio;</li>
                <li>Acesso as categorias;</li>
                <li>Visualizar cupons;</li>
                <li>
                  Acesso a Configurações:
                  <p>&gt; Mensagens</p>
                  <p>&gt; Links</p>
                  <p>&gt; História</p>
                  <p>&gt; Tema</p>
                  <p>&gt; Pixel do Facebook</p>
                </li>
              </ul>
            </RoleContainer>

            <RoleContainer>
              <RoleTitle>Funcionário</RoleTitle>
              <ul>
                <li>Acesso total aos pedidos (exceto cancelar);</li>
                <li>Acesso a ativar/desativar produtos;</li>
                <li>Acesso a ativar/desativar categorias;</li>
                <li>Acesso a ativar/desativar complementos;</li>
                <li>Acesso a alterar tempo de entrega/retira dos pedidos.</li>
              </ul>
            </RoleContainer>
          </Content>
        </Container>
      </StyledModal>
    </StyledModal>
  );
};

export default AddTeammateModal;
