import React, { useCallback, useState, useMemo, useEffect } from 'react';

import { FiGrid, FiList, FiPlus } from 'react-icons/fi';

import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useCompany } from '../../hooks/company';
import { useSidebar } from '../../hooks/sidebar';
import { useProducts } from '../../hooks/products';
import { useCategories } from '../../hooks/categories';
import { useComplements } from '../../hooks/complements';

import Product from '../../components/Product';
import EmptyMessage from '../../components/EmptyMessage';
import OptionsDialog from '../../components/OptionsDialog';
import LoadingAnimation from '../../components/LoadingAnimation';
import SortByButtons, {
  SortByButtonsEnum,
} from '../../components/SortByButtons';

import { PageNames } from '../../enums/pages';

import {
  Tab,
  Header,
  Content,
  Tabs,
  PageInfo,
  PageName,
  Container,
  MainHeader,
  MainContent,
  InnerContent,
  DisplayButton,
  DisplayContainer,
  ProductsContainer,
  EmptyMessageContainer,
  FlexPlaceholder,
  AddButton,
  Label,
  Filter,
  SelectedProduct,
  Filters,
  FiltersContainer,
  ProductInfo,
  ButtonsContainer,
  CancelButton,
  AppliedFiltersContainer,
  Title,
  SearchResultContainer,
  EmptyMovementsContainer,
  TextInput,
  FiltersGroup,
} from './styles';

import { LocalStorage } from '../../enums/localstorage';
import AddStockMovementModal from '../../components/AddStockMovementModal';
import DateRangePicker, {
  EFilterTag,
  IDateRangePickerResponse,
  IDateRangePickerValue,
} from '../../components/DateRangePicker';
import ProductsSelectorModal from '../../components/ProductsSelectorModal';
import SaveButton from '../../components/SaveButton';
import StockMovementsTable from '../../components/StockMovementsTable';
import { useStock } from '../../hooks/stock';
import AppliedFilters from '../../components/AppliedFilters';
import {
  FilterProductType,
  IStockCheck,
  IStockCheckDTO,
  IStockMovementDTO,
} from '../../models/IStock';
import { useToast } from '../../hooks/toast';
import {
  BsTicketStockCheckStatusType,
  EBsTicketStockCheckStatusType,
} from '../../enums/bsticket';
import AddStockCheckModal from '../../components/AddStockCheck';
import { selectStyles } from '../../styles/select';
import StockCheck from '../../components/StockCheck';
import { useConfirmDialog } from '../../hooks/confim_dialog';
import StockCheckSummaryModal from '../../components/StockCheckSummaryModal';
import ButtonSm from '../../components/ButtonSm';

export type TabOptions = 'PRODUCTS' | 'MOVEMENTS' | 'STOCK_CHECK';

interface ISelect {
  label: EBsTicketStockCheckStatusType;
  value: BsTicketStockCheckStatusType;
}

const StockPage: React.FC = () => {
  const { company } = useCompany();
  const { loadCategories } = useCategories();
  const { loadComplementsGroups } = useComplements();

  const {
    productPreferableDisplay,
    changeProductPreferableDisplay,
  } = useAuth();

  const {
    products,
    isProductsLoading,
    lastEditedProductId,
    loadProducts,
  } = useProducts();

  const { setSelectedPage, selectedPage } = useSidebar();

  const {
    movements,
    stockChecks,
    loadMovements,
    createMovement,
    loadStockChecks,
    saveStockCheck,
    changeStockCheckStatus,
    createStockCheck,
  } = useStock();

  const { addToast } = useToast();

  const { showConfirmDialog } = useConfirmDialog();

  const [isOptionsDialogOpen, setIsOptionsDialogOpen] = useState(false);
  const [isSelectProductModalOpen, setIsSelectProductModalOpen] = useState(
    false,
  );

  const [selectedTab, setSelectedTab] = useState<TabOptions>('STOCK_CHECK');

  const [
    isAddStockMovementModalOpen,
    setIsAddStockMovementModalOpen,
  ] = useState(false);

  const [isAddStockCheckModalOpen, setIsAddStockCheckModalOpen] = useState(
    false,
  );

  const [sortBy, setSortBy] = useState<SortByButtonsEnum>(
    (localStorage.getItem(
      LocalStorage.STOCK_PREFERABLE_SORT,
    ) as SortByButtonsEnum) || SortByButtonsEnum.DATE_DESC,
  );

  const [movementsSelectedFilterTag, setMovementsSelectedFilterTag] = useState<
    EFilterTag
  >();

  const [movementsDateRangeFilter, setMovementsDateRangeFilter] = useState<
    IDateRangePickerValue | undefined
  >();

  const [
    filterSelectedProduct,
    setFilterSelectedProduct,
  ] = useState<FilterProductType | null>(null);

  const [isMovementsFiltersVisible, setIsMovementsFiltersVisible] = useState(
    true,
  );

  const [
    stockCheckSelectedFilterTag,
    setStockCheckSelectedFilterTag,
  ] = useState<EFilterTag>();

  const [stockCheckDateRangeFilter, setStockCheckDateRangeFilter] = useState<
    IDateRangePickerValue | undefined
  >();

  const [stockCheckTitleFilter, setStockCheckTitleFilter] = useState('');

  const [
    stockCheckStatusFilter,
    setStockCheckStatusFilter,
  ] = useState<ISelect | null>(null);

  const [isStockCheckFiltersVisible, setIsStockCheckFiltersVisible] = useState(
    true,
  );

  const [
    isStockCheckSummaryModalVisible,
    setIsStockCheckSummaryModalVisible,
  ] = useState(false);

  const [selectedStockCheck, setSelectedStockCheck] = useState<
    IStockCheck | undefined
  >(undefined);

  const formattedStockCheckStatusFilter: ISelect[] = useMemo(
    () => [
      {
        label: EBsTicketStockCheckStatusType.OPEN,
        value: 'OPEN',
      },
      {
        label: EBsTicketStockCheckStatusType.DONE,
        value: 'DONE',
      },
      {
        label: EBsTicketStockCheckStatusType.CANCELED,
        value: 'CANCELED',
      },
    ],
    [],
  );

  useEffect(() => {
    async function loadData() {
      await loadProducts();
      await loadCategories();
      await loadComplementsGroups();
    }

    if (company) {
      loadData();
    }
  }, [loadCategories, loadProducts, loadComplementsGroups, company]);

  const handleAddNewStockMovement = useCallback(() => {
    setIsAddStockMovementModalOpen(true);
  }, []);

  const handleAddNewStockCheck = useCallback(() => {
    setIsAddStockCheckModalOpen(true);
  }, []);

  const handleOnOptionsDialogClosed = useCallback(() => {
    setIsOptionsDialogOpen(false);
  }, []);

  const handleOnTabDialogConfirmed = useCallback(
    (option: string) => {
      handleOnOptionsDialogClosed();

      setSelectedTab(option as TabOptions);
    },
    [handleOnOptionsDialogClosed],
  );

  const handleOnDisplayChanged = useCallback(
    (display: 'LIST' | 'GRID') => {
      changeProductPreferableDisplay(display);
    },
    [changeProductPreferableDisplay],
  );

  const handleOnSortByChanged = useCallback((sortBy: SortByButtonsEnum) => {
    setSortBy(sortBy);
    localStorage.setItem(LocalStorage.STOCK_PREFERABLE_SORT, sortBy);
  }, []);

  const handleOnSelectProductModalClose = useCallback(() => {
    setIsSelectProductModalOpen(false);
  }, []);

  const handleOnSelectProduct = useCallback(
    (id?: number) => {
      const newProduct = products.find(product => product.id === id);

      setFilterSelectedProduct(newProduct || null);

      setIsSelectProductModalOpen(false);
    },
    [products],
  );

  const handleOnConfirmMovementsDateRangePicker = useCallback(
    ({ selectedTag, dates }: IDateRangePickerResponse) => {
      setMovementsSelectedFilterTag(selectedTag);
      setMovementsDateRangeFilter(dates);
    },
    [],
  );

  const handleOnConfirmStockCheckDateRangePicker = useCallback(
    ({ selectedTag, dates }: IDateRangePickerResponse) => {
      setStockCheckSelectedFilterTag(selectedTag);
      setStockCheckDateRangeFilter(dates);
    },
    [],
  );

  const handleOnClearMovementsFilters = useCallback(() => {
    setFilterSelectedProduct(null);
    setMovementsDateRangeFilter(undefined);
    setMovementsSelectedFilterTag(undefined);
  }, []);

  const handleCancelMovementsSearch = useCallback(() => {
    setIsMovementsFiltersVisible(true);
    handleOnClearMovementsFilters();
  }, [handleOnClearMovementsFilters]);

  const handleOnSearchMovements = useCallback(async () => {
    if (movementsDateRangeFilter) {
      await loadMovements({
        dates: movementsDateRangeFilter,
        ...(filterSelectedProduct && { product: filterSelectedProduct }),
      });
    }

    setIsMovementsFiltersVisible(false);
  }, [movementsDateRangeFilter, filterSelectedProduct, loadMovements]);

  const handleStockCheckStatusFilterChanged = useCallback((param: any) => {
    setStockCheckStatusFilter(param as ISelect);
  }, []);

  const handleOnStockCheckTitleFilterChanged = useCallback((text: string) => {
    setStockCheckTitleFilter(text);
  }, []);

  const handleOnClearStockCheckFilters = useCallback(() => {
    setStockCheckTitleFilter('');
    setStockCheckDateRangeFilter(undefined);
    setStockCheckSelectedFilterTag(undefined);
    setStockCheckStatusFilter(null);
  }, []);

  const handleCancelStockCheckSearch = useCallback(() => {
    setIsStockCheckFiltersVisible(true);
    handleOnClearStockCheckFilters();
  }, [handleOnClearStockCheckFilters]);

  const handleOnSearchStockCheck = useCallback(async () => {
    if (stockCheckDateRangeFilter) {
      await loadStockChecks({
        dates: stockCheckDateRangeFilter,
        ...(stockCheckTitleFilter && { title: stockCheckTitleFilter }),
        ...(stockCheckStatusFilter && { status: stockCheckStatusFilter.value }),
      });
    }

    setIsStockCheckFiltersVisible(false);
  }, [
    loadStockChecks,
    stockCheckDateRangeFilter,
    stockCheckStatusFilter,
    stockCheckTitleFilter,
  ]);

  const handleOnConfirmAddStockMovement = useCallback(
    async (stockMovement: IStockMovementDTO) => {
      try {
        createMovement(stockMovement);

        addToast({
          type: 'success',
          description: 'Movimentação criada com sucesso!',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro inesperado.',
        });
      }
    },
    [addToast, createMovement],
  );

  const handleOnConfirmStockCheckAction = useCallback(
    (action: 'processar' | 'cancelar') => {
      return new Promise<boolean>(resolve => {
        showConfirmDialog({
          title: 'Confirmar Ação',
          message: `Deseja realmente ${action} essa conferência? Essa ação não pode ser desfeita.`,
          onCancel: () => {
            resolve(false);
          },
          onConfirm: () => {
            resolve(true);
          },
        });
      });
    },
    [showConfirmDialog],
  );

  const handleOnConfirmAddStockCheck = useCallback(
    async (stockCheck: IStockCheckDTO) => {
      try {
        createStockCheck(stockCheck);

        addToast({
          type: 'success',
          description: 'Conferência criada com sucesso!',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro inesperado.',
        });
      }
    },
    [addToast, createStockCheck],
  );

  const handleOnEditStockCheck = useCallback((stockCheck: IStockCheck) => {
    setSelectedStockCheck(stockCheck);
    setIsStockCheckSummaryModalVisible(true);
  }, []);

  const handleOnCloseEditStockCheck = useCallback(() => {
    setSelectedStockCheck(undefined);
    setIsStockCheckSummaryModalVisible(false);
  }, []);

  const handleOnSaveStockCheck = useCallback(
    (stockCheck: IStockCheck) => {
      try {
        saveStockCheck(stockCheck);

        handleOnCloseEditStockCheck();

        addToast({
          type: 'success',
          description: 'Conferência alterada com sucesso!',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro inesperado.',
        });
      }
    },
    [addToast, handleOnCloseEditStockCheck, saveStockCheck],
  );

  const handleOnChangeStockCheckStatus = useCallback(
    async (stockCheckId: number, status: BsTicketStockCheckStatusType) => {
      const action = status === 'DONE' ? 'processar' : 'cancelar';

      const actionConfirmed = await handleOnConfirmStockCheckAction(action);

      if (!actionConfirmed) {
        return;
      }

      try {
        changeStockCheckStatus(stockCheckId, status);

        addToast({
          type: 'success',
          description: 'Conferência alterada com sucesso!',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro inesperado.',
        });
      }
    },
    [addToast, changeStockCheckStatus, handleOnConfirmStockCheckAction],
  );

  const searchedProducts = useMemo(() => {
    if (!products) {
      return [];
    }

    const sortedProducts = products.sort((a, b) => {
      if (sortBy === SortByButtonsEnum.DATE_DESC) {
        return a.id > b.id ? -1 : 1;
      }

      if (sortBy === SortByButtonsEnum.DATE_ASC) {
        return a.id < b.id ? -1 : 1;
      }

      if (sortBy === SortByButtonsEnum.ALPHABETICAL_ASC) {
        return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
      }
      if (sortBy === SortByButtonsEnum.ALPHABETICAL_DESC) {
        return a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1;
      }

      return 0;
    });

    return sortedProducts;
  }, [products, sortBy]);

  const filteredProducts = useMemo(() => {
    if (selectedTab === 'PRODUCTS') {
      return searchedProducts;
    }

    return searchedProducts.filter(product => !product.sellerSku);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedProducts, selectedTab, sortBy]);

  const dialogOptions = useMemo(() => {
    return [
      {
        ref: 'PRODUCTS',
        title: 'Produtos',
        selected: selectedTab === 'PRODUCTS',
      },
      {
        ref: 'MOVEMENTS',
        title: 'Movimentações',
        selected: selectedTab === 'MOVEMENTS',
      },
      {
        ref: 'STOCK_CHECK',
        title: 'Conferência',
        selected: selectedTab === 'STOCK_CHECK',
      },
    ];
  }, [selectedTab]);

  const movementsAppliedFilters = useMemo(() => {
    if (
      movementsDateRangeFilter?.startDate &&
      movementsDateRangeFilter?.endDate
    ) {
      return [
        [
          {
            label: 'Início',
            value: movementsDateRangeFilter.startDate.toLocaleDateString(),
          },
          {
            label: 'Fim',
            value: movementsDateRangeFilter.endDate.toLocaleDateString(),
          },
        ],
        ...(filterSelectedProduct
          ? [[{ label: 'Produto', value: filterSelectedProduct.title }]]
          : []),
      ];
    }

    return null;
  }, [movementsDateRangeFilter, filterSelectedProduct]);

  const stockCheckAppliedFilters = useMemo(() => {
    if (
      stockCheckDateRangeFilter?.startDate &&
      stockCheckDateRangeFilter?.endDate
    ) {
      return [
        [
          {
            label: 'Início',
            value: stockCheckDateRangeFilter?.startDate?.toLocaleDateString(),
          },
          {
            label: 'Fim',
            value: stockCheckDateRangeFilter?.endDate?.toLocaleDateString(),
          },
        ],
        ...(stockCheckTitleFilter
          ? [[{ label: 'Título', value: stockCheckTitleFilter }]]
          : []),
        ...(stockCheckStatusFilter
          ? [
              [
                {
                  label: 'Status',
                  value:
                    EBsTicketStockCheckStatusType[stockCheckStatusFilter.value],
                },
              ],
            ]
          : []),
      ];
    }

    return null;
  }, [
    stockCheckDateRangeFilter,
    stockCheckTitleFilter,
    stockCheckStatusFilter,
  ]);

  const handleOnTabChanged = useCallback(
    (tab: TabOptions) => {
      setSelectedTab(tab);

      handleOnClearMovementsFilters();
      setIsMovementsFiltersVisible(true);

      handleOnClearStockCheckFilters();
      setIsStockCheckFiltersVisible(true);
    },
    [handleOnClearMovementsFilters, handleOnClearStockCheckFilters],
  );

  useEffect(() => {
    setSelectedPage(PageNames.STOCK);
  }, [setSelectedPage]);

  return (
    <Container>
      <Content>
        <InnerContent>
          <Header>
            <PageInfo>
              <PageName>{selectedPage}</PageName>
            </PageInfo>
            {selectedTab !== 'PRODUCTS' && (
              <>
                {/* {!isFiltersVisible && (
                  <Search
                    value={searchCriteria}
                    onChange={handleOnSearchCriteriaChanged}
                  />
                )} */}
                {!isStockCheckSummaryModalVisible && (
                  <AddButton
                    type="button"
                    onClick={
                      selectedTab === 'MOVEMENTS'
                        ? handleAddNewStockMovement
                        : handleAddNewStockCheck
                    }
                    selectedTab={selectedTab}
                  >
                    <FiPlus size={20} />
                    <span />
                  </AddButton>
                )}
                {(!isMovementsFiltersVisible ||
                  !isStockCheckFiltersVisible) && (
                  <ButtonSm
                    buttonStyle="outline"
                    onClick={
                      selectedTab === 'MOVEMENTS'
                        ? handleCancelMovementsSearch
                        : handleCancelStockCheckSearch
                    }
                  >
                    Cancelar
                  </ButtonSm>
                )}
              </>
            )}
          </Header>
          <MainContent>
            <MainHeader>
              <Tabs>
                <Tab
                  onClick={() => handleOnTabChanged('PRODUCTS')}
                  selected={selectedTab === 'PRODUCTS'}
                >
                  Produtos
                </Tab>
                <Tab
                  onClick={() => handleOnTabChanged('MOVEMENTS')}
                  selected={selectedTab === 'MOVEMENTS'}
                >
                  Movimentações
                </Tab>
                <Tab
                  onClick={() => handleOnTabChanged('STOCK_CHECK')}
                  selected={selectedTab === 'STOCK_CHECK'}
                >
                  Conferência
                </Tab>
              </Tabs>
              {selectedTab === 'PRODUCTS' && (
                <DisplayContainer>
                  <DisplayButton>
                    <FiList
                      size={24}
                      onClick={() => handleOnDisplayChanged('LIST')}
                      color={
                        productPreferableDisplay === 'LIST' ? '#000' : '#0005'
                      }
                    />
                  </DisplayButton>
                  <DisplayButton>
                    <FiGrid
                      size={24}
                      onClick={() => handleOnDisplayChanged('GRID')}
                      color={
                        productPreferableDisplay === 'GRID' ? '#000' : '#0005'
                      }
                    />
                  </DisplayButton>
                  <SortByButtons
                    sortBy={sortBy}
                    onChange={handleOnSortByChanged}
                  />
                </DisplayContainer>
              )}
            </MainHeader>
            {isProductsLoading ? (
              <LoadingAnimation />
            ) : (
              <>
                {selectedTab === 'PRODUCTS' ? (
                  <>
                    {filteredProducts.length > 0 ? (
                      <ProductsContainer className="has-custom-scroll-bar-2">
                        {filteredProducts.map(product => (
                          <Product
                            lastEdited={product.id === lastEditedProductId}
                            key={product.id}
                            product={product}
                            onClick={() => null}
                            onDelete={() => null}
                            onInactivate={() => null}
                            display={productPreferableDisplay}
                            actions={false}
                          />
                        ))}
                        <FlexPlaceholder />
                      </ProductsContainer>
                    ) : (
                      <EmptyMessageContainer>
                        <EmptyMessage
                          plural="produtos"
                          text="produto"
                          onClick={() => null}
                          nonCreatable
                        />
                      </EmptyMessageContainer>
                    )}
                  </>
                ) : selectedTab === 'MOVEMENTS' ? (
                  <ProductsContainer className="has-custom-scroll-bar-2">
                    {isMovementsFiltersVisible ? (
                      <FiltersContainer>
                        <Filters>
                          <Filter>
                            <Label>Produto:</Label>
                            <SelectedProduct
                              type="button"
                              onClick={() => setIsSelectProductModalOpen(true)}
                            >
                              {filterSelectedProduct ? (
                                <ProductInfo>
                                  {filterSelectedProduct.imageUrl && (
                                    <img
                                      src={filterSelectedProduct.imageUrl}
                                      alt={filterSelectedProduct.title}
                                    />
                                  )}
                                  <span>{filterSelectedProduct.title}</span>
                                </ProductInfo>
                              ) : (
                                <span className="placeholder">
                                  Selecione um produto
                                </span>
                              )}
                            </SelectedProduct>
                          </Filter>
                          <DateRangePicker
                            selectedTag={movementsSelectedFilterTag}
                            onConfirm={handleOnConfirmMovementsDateRangePicker}
                          />
                        </Filters>
                        {(movementsDateRangeFilter ||
                          filterSelectedProduct) && (
                          <ButtonsContainer>
                            <CancelButton
                              type="button"
                              onClick={handleOnClearMovementsFilters}
                            >
                              Limpar
                            </CancelButton>
                            {movementsDateRangeFilter && (
                              <SaveButton
                                type="button"
                                onClick={handleOnSearchMovements}
                              >
                                Buscar
                              </SaveButton>
                            )}
                          </ButtonsContainer>
                        )}
                      </FiltersContainer>
                    ) : (
                      <SearchResultContainer>
                        <AppliedFiltersContainer>
                          <Title>Filtros aplicados:</Title>
                          {movementsAppliedFilters && (
                            <AppliedFilters
                              appliedFilters={movementsAppliedFilters}
                            />
                          )}
                        </AppliedFiltersContainer>

                        {movements.length > 0 ? (
                          <StockMovementsTable movements={movements} />
                        ) : (
                          <EmptyMovementsContainer>
                            <EmptyMessage
                              plural="movimentações"
                              text="movimentação"
                              onClick={handleAddNewStockMovement}
                              searchCriteria={
                                movementsAppliedFilters ? '404' : ''
                              }
                            />
                          </EmptyMovementsContainer>
                        )}
                      </SearchResultContainer>
                    )}
                  </ProductsContainer>
                ) : (
                  <ProductsContainer className="has-custom-scroll-bar-2">
                    {isStockCheckFiltersVisible ? (
                      <FiltersContainer>
                        <Filters>
                          <FiltersGroup>
                            <Filter>
                              <Label>Título:</Label>
                              <TextInput
                                type="text"
                                placeholder="Título da conferência"
                                onChange={e =>
                                  handleOnStockCheckTitleFilterChanged(
                                    e.target.value,
                                  )
                                }
                                value={stockCheckTitleFilter}
                              />
                            </Filter>
                            <Filter id="status-filter">
                              <Label>Status:</Label>
                              <Select
                                isClearable
                                styles={selectStyles}
                                value={stockCheckStatusFilter}
                                options={formattedStockCheckStatusFilter}
                                onChange={handleStockCheckStatusFilterChanged}
                                placeholder="Selecione o status"
                              />
                            </Filter>
                          </FiltersGroup>
                          <DateRangePicker
                            selectedTag={stockCheckSelectedFilterTag}
                            onConfirm={handleOnConfirmStockCheckDateRangePicker}
                          />
                        </Filters>
                        {(stockCheckDateRangeFilter ||
                          stockCheckTitleFilter ||
                          stockCheckStatusFilter) && (
                          <ButtonsContainer>
                            <CancelButton
                              type="button"
                              onClick={handleOnClearStockCheckFilters}
                            >
                              Limpar
                            </CancelButton>
                            {stockCheckDateRangeFilter && (
                              <SaveButton
                                type="button"
                                onClick={handleOnSearchStockCheck}
                              >
                                Buscar
                              </SaveButton>
                            )}
                          </ButtonsContainer>
                        )}
                      </FiltersContainer>
                    ) : (
                      <SearchResultContainer>
                        <AppliedFiltersContainer>
                          <Title>Filtros aplicados:</Title>
                          {stockCheckAppliedFilters && (
                            <AppliedFilters
                              appliedFilters={stockCheckAppliedFilters}
                            />
                          )}
                        </AppliedFiltersContainer>

                        {stockChecks.length > 0 ? (
                          <>
                            {stockChecks.map(stockCheck => (
                              <StockCheck
                                key={stockCheck.id}
                                stockCheck={stockCheck}
                                onEdit={() =>
                                  handleOnEditStockCheck(stockCheck)
                                }
                                onChangeStatus={handleOnChangeStockCheckStatus}
                              />
                            ))}
                          </>
                        ) : (
                          <EmptyMovementsContainer>
                            <EmptyMessage
                              plural="conferências"
                              text="conferência"
                              onClick={handleAddNewStockMovement}
                              searchCriteria={
                                stockCheckAppliedFilters ? '404' : ''
                              }
                            />
                          </EmptyMovementsContainer>
                        )}
                      </SearchResultContainer>
                    )}
                  </ProductsContainer>
                )}
              </>
            )}
          </MainContent>
        </InnerContent>
        <StockCheckSummaryModal
          isVisible={isStockCheckSummaryModalVisible}
          stockCheck={selectedStockCheck}
          onSave={handleOnSaveStockCheck}
          onClose={handleOnCloseEditStockCheck}
        />
      </Content>
      <OptionsDialog
        title="Subpáginas"
        options={dialogOptions}
        isOpen={isOptionsDialogOpen}
        onClose={handleOnOptionsDialogClosed}
        onConfirm={handleOnTabDialogConfirmed}
      />
      <AddStockMovementModal
        isOpen={isAddStockMovementModalOpen}
        onClose={() => setIsAddStockMovementModalOpen(false)}
        onConfirm={handleOnConfirmAddStockMovement}
      />
      <AddStockCheckModal
        isOpen={isAddStockCheckModalOpen}
        onClose={() => setIsAddStockCheckModalOpen(false)}
        onConfirm={handleOnConfirmAddStockCheck}
      />
      <ProductsSelectorModal
        products={products}
        open={isSelectProductModalOpen}
        onClose={handleOnSelectProductModalClose}
        onSelect={handleOnSelectProduct}
      />
    </Container>
  );
};

export default StockPage;
