import styled, { css } from 'styled-components';

interface IContainerProps {
  active?: boolean;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;

  ${({ active }) =>
    active
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0.5;
        `}

  padding: 16px;
  position: relative;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};

  scroll-margin: 120px;

  > div {
    width: 100%;
  }

  > div + div {
    margin-left: 32px;
  }

  @media (max-width: 550px) {
    flex-direction: column;

    > div + div {
      margin-left: 0;
      margin-top: 0.8rem;
    }
  }
`;

export const IconContainer = styled.span`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.palette.text_white};
  }
`;

export const DeleteContainer = styled.button`
  display: flex;
  padding: 6px;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};
  border: 0;
  border-radius: 4px;
  margin-left: 8px;
  align-items: center;
  justify-content: center;
`;

export const ActiveContainer = styled(DeleteContainer)``;

export const ItemAmount = styled.span`
  width: 100%;
  margin-top: 16px;
  text-align: right;
`;
