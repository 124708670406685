/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Route, Redirect } from 'react-router-dom';

interface IPrivateRouteProps {
  path: string;
  authed: boolean;
  component: any;
  redirect: string;
  exact?: boolean;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  path,
  exact,
  authed,
  redirect,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={props =>
        authed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirect,
              state: {
                redirect: `${props.location.pathname}${props.location.search}`,
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
