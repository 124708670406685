import React from 'react';
import { Button } from './styles';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle: 'filled' | 'outline';
}

const ButtonSm: React.FC<ButtonProps> = ({
  buttonStyle,
  children,
  ...props
}) => {
  return (
    <Button type="button" buttonStyle={buttonStyle} {...props}>
      {children}
    </Button>
  );
};

export default ButtonSm;
