import React, { useCallback } from 'react';
import { FiX, FiPlus } from 'react-icons/fi';

import Toggle from 'react-toggle';
import IBusinessHours, {
  IBusinessHourShift,
} from '../../models/IBusinessHours';

import { getWeekDayString } from '../../utils/date';

import {
  Container,
  WeekDaysContainer,
  StatusContainer,
  ScheduleContainer,
  InputContainer,
  Input,
  AddScheduleButton,
  WeekDaysLeftContainer,
  WeekDaysRightContainer,
} from './styles';

interface IBusinessHoursProps {
  businessHours: IBusinessHours;
  onChange: (newBusinessHours: IBusinessHours) => void;
}

const defaultShifts: IBusinessHourShift = {
  open: '',
  close: '',
};

const BusinessHours: React.FC<IBusinessHoursProps> = ({
  businessHours,
  onChange,
  ...rest
}) => {
  const handleAddOpeningHour = useCallback(
    (weekDay: number) => {
      return onChange(
        businessHours.map(currentDay => {
          if (currentDay.day !== weekDay) return currentDay;
          const newSchedule = currentDay.shifts.concat([defaultShifts]);
          return { ...currentDay, shifts: newSchedule };
        }),
      );
    },
    [businessHours, onChange],
  );

  const handleRemoveOpeningHour = useCallback(
    (weekDay: number, hour: number) => {
      return onChange(
        businessHours.map(currentDay => {
          if (currentDay.day !== weekDay) return currentDay;
          const newSchedule = currentDay.shifts.filter(
            (_, hourIndex) => hour !== hourIndex,
          );
          return { ...currentDay, shifts: newSchedule };
        }),
      );
    },
    [businessHours, onChange],
  );

  const handleHourChange = useCallback(
    (
      day: number,
      hour: number,
      key: keyof IBusinessHourShift,
      value: string,
    ) => {
      return onChange(
        businessHours.map(currentDay => {
          if (currentDay.day !== day) return currentDay;
          const newShifts = currentDay.shifts.map((currentHour, hourIndex) => {
            if (hour !== hourIndex) return currentHour;
            return { ...currentHour, [key]: value };
          });
          return { ...currentDay, shifts: newShifts };
        }),
      );
    },
    [businessHours, onChange],
  );

  const handleToggleOpenDay = useCallback(
    (day: number) => {
      return onChange(
        businessHours.map(currentDay => {
          if (currentDay.day !== day) return currentDay;
          return { ...currentDay, isOpen: !currentDay.isOpen };
        }),
      );
    },
    [businessHours, onChange],
  );

  return (
    <Container {...rest}>
      {businessHours.map(businessHour => (
        <WeekDaysContainer key={businessHour.day}>
          <WeekDaysLeftContainer>
            <h1>{getWeekDayString(businessHour.day)}</h1>
            <StatusContainer>
              <Toggle
                icons={false}
                checked={businessHour.isOpen}
                onChange={() => handleToggleOpenDay(businessHour.day)}
              />
              {businessHour.isOpen ? <span>Aberto</span> : <span>Fechado</span>}
            </StatusContainer>
          </WeekDaysLeftContainer>
          {businessHour.isOpen && (
            <WeekDaysRightContainer>
              <ScheduleContainer>
                {businessHour.shifts.map((openingHour, index) => (
                  <InputContainer>
                    <Input
                      value={openingHour.open}
                      onChange={(_: any, value: string) =>
                        handleHourChange(businessHour.day, index, 'open', value)
                      }
                    />
                    <span> - </span>
                    <Input
                      value={openingHour.close}
                      onChange={(_: any, value: string) =>
                        handleHourChange(
                          businessHour.day,
                          index,
                          'close',
                          value,
                        )
                      }
                    />
                    {index !== 0 && (
                      <FiX
                        size={20}
                        onClick={() =>
                          handleRemoveOpeningHour(businessHour.day, index)
                        }
                      />
                    )}
                  </InputContainer>
                ))}
              </ScheduleContainer>
              <AddScheduleButton
                type="button"
                onClick={() => handleAddOpeningHour(businessHour.day)}
              >
                <FiPlus size={20} />
              </AddScheduleButton>
            </WeekDaysRightContainer>
          )}
        </WeekDaysContainer>
      ))}
    </Container>
  );
};

export default BusinessHours;
