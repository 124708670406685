import ReactModal from 'react-modal';
import styled from 'styled-components';

import { appearFromBottom } from '../../styles/animations';

export const StyledModal = styled(ReactModal)`
  animation: ${appearFromBottom} 0.5s;

  > h3 {
    margin-bottom: 32px;
  }

  @media (max-width: 500px) {
    height: -webkit-fill-available;
  }
`;

export const Container = styled.div`
  width: min-content;
  height: fit-content;
  overflow-y: scroll;

  padding: 16px;

  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};
  background: ${({ theme }) => theme.palette.background_white};

  @media (max-width: 500px) {
    width: 100vw;
    height: 100vh;

    padding: 32px 16px;
  }
`;

export const Title = styled.strong`
  font-size: 18px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 16px;
  padding: 16px 0;

  svg {
    cursor: pointer;
  }
`;

export const Content = styled.div``;

export const Description = styled.div``;

export const InfoContainer = styled.div``;

export const CardsContainer = styled.div`
  display: flex;

  margin-top: 16px;

  gap: 16px;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const Card = styled.div`
  width: 200px;

  display: flex;
  flex-direction: column;

  padding: 16px;

  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};

  span {
    font-size: 12px;
  }
`;

export const IsUniqueContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 32px;

  span {
    font-size: 14px;
    border-bottom: 2px solid ${({ theme }) => theme.palette.primary};
  }
`;
