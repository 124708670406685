import React, { useEffect, useCallback, useState } from 'react';

import { useTheme } from 'styled-components';
import { FiCheck, FiSquare } from 'react-icons/fi';

import { useOrders } from '../../hooks/orders';

import SummarizedPrintItem from '../SummarizedPrintItem';
import KitchenPrintItem from '../KitchenPrintItem';
import DeliveryPrintItem from '../DeliveryPrintItem';

import {
  Dialog,
  Divisor,
  Content,
  Printing,
  StyledModal,
  CancelButton,
  PrintPreview,
  PrintingOption,
  ScrollContainer,
  PrintingOptionsContainer,
} from './styles';
import { useCompany } from '../../hooks/company';

interface IProps {
  open: boolean;
  onCancel: () => void;
}

StyledModal.setAppElement('#root');

const PrintModal: React.FC<IProps> = ({ open, onCancel }) => {
  const theme = useTheme();
  const { company } = useCompany();

  const { selectedOrder } = useOrders();

  const [selectedPrintVersion, setSelectedPrintVersion] = useState<
    'DELIVERY' | 'KITCHEN'
  >('KITCHEN');

  const handleOnEscPressed = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCancel();
      }
    },
    [onCancel],
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (event.target && event.target.id === 'modal') {
        onCancel();
      }
    },
    [onCancel],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleOnEscPressed, false);

    return () =>
      document.removeEventListener('keydown', handleOnEscPressed, false);
  });

  useEffect(() => {
    setSelectedPrintVersion(
      !company?.printKitchenLayout ? 'DELIVERY' : 'KITCHEN',
    );
  }, [company]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  const handleOnCancel = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onCancel();
    },
    [onCancel],
  );

  const handleOnKitchenSelected = useCallback(() => {
    setSelectedPrintVersion('KITCHEN');
  }, []);

  const handleOnDeliverySelected = useCallback(() => {
    setSelectedPrintVersion('DELIVERY');
  }, []);

  return (
    <StyledModal
      isOpen={open}
      id="print-modal"
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onCancel}
      style={{
        overlay: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <Content>
        <ScrollContainer className="has-custom-scroll-bar-2">
          <Dialog>
            <Printing>
              <PrintingOptionsContainer inverse={!company?.printKitchenLayout}>
                <PrintingOption
                  onClick={handleOnKitchenSelected}
                  selected={selectedPrintVersion === 'KITCHEN'}
                >
                  {selectedPrintVersion === 'KITCHEN' ? (
                    <FiCheck size={22} color={theme.palette.primary} />
                  ) : (
                    <FiSquare size={22} />
                  )}
                  <span>Cozinha</span>
                </PrintingOption>
                <Divisor>/</Divisor>
                <PrintingOption
                  onClick={handleOnDeliverySelected}
                  selected={selectedPrintVersion === 'DELIVERY'}
                >
                  {selectedPrintVersion === 'DELIVERY' ? (
                    <FiCheck size={22} color={theme.palette.primary} />
                  ) : (
                    <FiSquare size={22} />
                  )}
                  <span>Entrega</span>
                </PrintingOption>
              </PrintingOptionsContainer>
              <PrintPreview className="has-custom-scroll-bar-3">
                {selectedPrintVersion === 'KITCHEN' ? (
                  <KitchenPrintItem type="MODAL" order={selectedOrder} />
                ) : (
                  <>
                    {company?.isPrintSummedUp ? (
                      <SummarizedPrintItem type="MODAL" order={selectedOrder} />
                    ) : (
                      <DeliveryPrintItem type="MODAL" order={selectedOrder} />
                    )}
                  </>
                )}
              </PrintPreview>
            </Printing>
            <CancelButton onClick={handleOnCancel}>Fechar</CancelButton>
          </Dialog>
        </ScrollContainer>
      </Content>
    </StyledModal>
  );
};

export default PrintModal;
