import styled from 'styled-components';

export const Container = styled.div``;

export const AddTutorial = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  margin-top: 128px;

  padding: 8px;

  button {
    border: 0;
    background: none;

    margin-left: 8px;

    text-decoration: none;

    font-weight: 500;

    padding-bottom: 4px;
    border-bottom: 2px solid #db1622;
  }

  @media (max-width: 570px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px;

    button {
      width: fit-content;
      margin-left: unset;
    }
  }
`;

export const NoSearchFound = styled.div`
  width: 100%;
  text-align: center;

  margin-top: 128px;
`;
