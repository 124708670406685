import React, { useState, useContext, useCallback, createContext } from 'react';

import IScheduledProduct from '../models/IScheduledProduct';
import ISaveScheduledTimesDTO from '../dtos/ISaveScheduledTimesDTO';
import api from '../services/api';

interface ScheduledProductsContextData {
  loading: boolean;
  times: IScheduledProduct[];
  loadTimes: () => Promise<void>;
  toggleActive: () => Promise<void>;
  deleteTime: (id: number) => Promise<void>;
  saveTimes: (times: ISaveScheduledTimesDTO[]) => Promise<void>;
}

const ScheduledProductsContext = createContext<ScheduledProductsContextData>(
  {} as ScheduledProductsContextData,
);

export const ScheduledProductsProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [times, setTimes] = useState<IScheduledProduct[]>([]);

  const loadTimes = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get<IScheduledProduct[]>(
        '/restricted/companies/scheduled-products',
      );
      setTimes(response.data);
    } catch {
      // todo
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const saveTimes = useCallback(async (times: ISaveScheduledTimesDTO[]) => {
    setLoading(true);

    try {
      const response = await api.post<IScheduledProduct[]>(
        '/restricted/companies/scheduled-products',
        times,
      );

      setTimes(response.data);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  const toggleActive = useCallback(async () => {
    setLoading(true);

    try {
      await api.patch('/restricted/companies/scheduled-products/toggle');
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } catch {
      // todo
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteTime = useCallback(async (id: number) => {
    try {
      await api.delete(`/restricted/companies/scheduled-products/${id}`);
    } catch {
      // todo
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <ScheduledProductsContext.Provider
      value={{ loading, times, deleteTime, saveTimes, toggleActive, loadTimes }}
    >
      {children}
    </ScheduledProductsContext.Provider>
  );
};

export function useScheduledProducts(): ScheduledProductsContextData {
  const context = useContext(ScheduledProductsContext);

  if (!context) {
    throw new Error(
      'useScheduledProducts must be used within ScheduledProductsProvider',
    );
  }

  return context;
}
