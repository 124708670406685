import React, { useState, useContext, useCallback, createContext } from 'react';

import IOperator from '../models/IOperator';
import ISaveOperatorDTO from '../dtos/ISaveOperatorDTO';

import { useCompany } from './company';

import api from '../services/api';

interface OperatorsContextData {
  operators: IOperator[];
  isOperatorsLoading: boolean;
  getOperators: () => Promise<void>;
  saveOperator: (request: ISaveOperatorDTO) => Promise<void>;
  changeStatus: (id: number) => Promise<void>;
  resetPassword: (operatorId: number) => Promise<void>;
}

const OperatorsContext = createContext<OperatorsContextData>(
  {} as OperatorsContextData,
);

export const OperatorsProvider: React.FC = ({ children }) => {
  const { company } = useCompany();

  const [data, setData] = useState<IOperator[]>([]);

  const [isOperatorsLoading, setIsOperatorsLoading] = useState(false);

  const getOperators = useCallback(async () => {
    setIsOperatorsLoading(true);

    const response = await api.get<IOperator[]>(
      `/restricted/subaccounts/${company?.id}`,
    );

    setIsOperatorsLoading(false);

    setData(response.data);
  }, [company]);

  const saveOperator = useCallback(
    async (request: ISaveOperatorDTO) => {
      setIsOperatorsLoading(true);

      if (request.id) {
        const response = await api.patch<IOperator>(
          `/restricted/subaccounts/${company?.id}`,
          { ...request, companyId: company?.id },
        );
        setIsOperatorsLoading(false);

        const updatedOperators = [...data];

        const operatorIndex = data.findIndex(
          operator => operator.id === request.id,
        );

        if (operatorIndex > -1) {
          updatedOperators[operatorIndex] = response.data;

          setData(updatedOperators);
        }
      } else {
        const response = await api.post<IOperator>(
          `/restricted/subaccounts/${company?.id}`,
          request,
        );

        setIsOperatorsLoading(false);

        setData(prevState => [...prevState, response.data]);
      }
    },
    [company, data],
  );

  const changeStatus = useCallback(
    async (id: number) => {
      await api.patch(`/restricted/subaccounts/${company?.id}/toggle`, {
        id,
        companyId: company?.id,
      });

      const newData = [...data];

      const updatedOperatorIndex = data.findIndex(
        operator => operator.id === id,
      );

      if (updatedOperatorIndex > -1) {
        newData[updatedOperatorIndex].isActive = !newData[updatedOperatorIndex]
          .isActive;

        setData(newData);
      }
    },
    [company, data],
  );

  const resetPassword = useCallback(async (operatorId: number) => {
    await api.patch(`/restricted/subaccounts/${operatorId}/reset-password`);
  }, []);

  return (
    <OperatorsContext.Provider
      value={{
        operators: data,
        isOperatorsLoading,
        getOperators,
        saveOperator,
        changeStatus,
        resetPassword,
      }}
    >
      {children}
    </OperatorsContext.Provider>
  );
};

export function useOperators(): OperatorsContextData {
  const context = useContext(OperatorsContext);

  if (!context) {
    throw new Error('useOperators must be used within OperatorsProvider');
  }

  return context;
}
