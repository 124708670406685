import React, { memo, useMemo } from 'react';

import { randomInt } from '../../utils/numbers';
import { Svg } from './styles';

interface IProductIconProps {
  url?: string;
  alt?: string;
}

const ProductIcon: React.FC<IProductIconProps> = ({ url, alt }) => {
  const productIcon = useMemo(() => {
    if (url) {
      return <img className="product-icon" src={url} alt={alt} />;
    }

    const randomColor = randomInt(0, 4) || 0;
    return <Svg className="product-icon" variation={randomColor} />;
  }, [url, alt]);

  return productIcon;
};

export default memo(ProductIcon);
