import styled from 'styled-components';

export const ListContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px 8px;
  background: ${({ theme }) => theme.palette.background_white};

  cursor: pointer;

  transition: box-shadow 0.4s;

  box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 500px) {
    box-shadow: none;
  }
`;

export const ListImage = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.palette.unselected};
  background: ${({ theme }) => theme.palette.background_white};

  margin-left: 16px;

  img {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 400px) {
    margin-left: 0;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;

  width: 40%;

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const ListName = styled.strong`
  margin-left: 16px;
`;

export const ListTypesContainer = styled.div`
  display: flex;

  font-size: 12px;

  width: 20%;

  div + div {
    margin-left: 8px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const CategoryBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #3498db40;
  padding: 4px;
`;

export const SubcategoryBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #1abc9c40;
  padding: 4px;
`;

export const DraggableIcon = styled.div`
  img {
    height: 40px;
    width: 40px;
  }
`;

export const Position = styled.span`
  font-size: 20px;
  margin-right: 16px;
`;
