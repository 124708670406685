import styled, { css } from 'styled-components';

interface IContainer {
  visible: boolean;
}

export const Container = styled.div<IContainer>`
  position: relative;
  width: 40px;
  padding: 8px 16px;
  border-radius: 24px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme }) => theme.palette.background_white};
  gap: 12px;
  transition: width 800ms cubic-bezier(0, 0.93, 0.15, 1);
  cursor: pointer;

  .icon {
    position: absolute;
    margin-left: -7px;
    transition: transform 600ms cubic-bezier(0, 0.93, 0.39, 1);
    transition-delay: 220ms;
  }

  .loading {
    transition: transform 600ms cubic-bezier(0, 0.93, 0.39, 1), opacity ease;
    transition-delay: 220ms;
    transform: scale(0);
    opacity: 0;
  }

  @media (min-width: 1024px) {
    ${({ visible }) =>
      visible &&
      css`
        width: calc(100% - 40vw);
        .icon {
          margin-left: 0px;
          transform: rotateZ(-360deg);
        }
        .loading {
          transform: none;
          opacity: 1;
        }
      `}
  }

  @media (max-width: 1024px) {
    width: 100%;
    .icon {
      margin-left: 0px;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  margin-left: 32px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
`;
