import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  InputHTMLAttributes,
} from 'react';

import { useField } from '@unform/core';
import { Link } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { Container, StyledInput, Error, Label, Eye } from './styles';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  title: string;
  hasClassName?: boolean;
  hasForgotLink?: boolean;
  hasPasswordEye?: boolean;
  bottom?: boolean;
  maxLength?: number;
}

const Input: React.FC<IInputProps> = ({
  name,
  type,
  title,
  hasForgotLink,
  hasPasswordEye,
  hasClassName,
  maxLength,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    fieldName,
    defaultValue,
    error,
    registerField,
    clearError,
  } = useField(name);

  const [localType, setLocalType] = useState('');

  const [hasFocus, setHasFocus] = useState(false);
  const [hasContent, setHasContent] = useState(false);

  useEffect(() => {
    setLocalType(type || 'text');
  }, [type]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });

    if (maxLength && inputRef?.current && containerRef?.current) {
      const handleChange = () => {
        containerRef.current?.style.setProperty(
          '--current',
          `"${inputRef.current?.value.length}"`,
        );
      };

      inputRef.current.addEventListener('input', handleChange);

      handleChange();

      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        inputRef?.current?.removeEventListener('input', handleChange);
      };
    }
  }, [fieldName, maxLength, registerField]);

  const handleOnFocus = useCallback(() => {
    setHasFocus(true);
    clearError();
  }, [clearError]);

  const handleOnBlur = useCallback(() => {
    setHasFocus(false);
    setHasContent(!!inputRef.current?.value);
  }, []);

  const handleTogglePassword = useCallback(() => {
    setLocalType(prevState => {
      if (prevState === 'password') {
        return 'text';
      }
      return 'password';
    });
  }, []);

  return (
    <Container
      className={hasClassName ? rest.className : ''}
      maxLength={maxLength}
      ref={containerRef}
    >
      <Label htmlFor={name}>
        {title}
        {hasForgotLink && <Link to="/forgot-password">Esqueceu a senha?</Link>}
      </Label>
      <StyledInput
        name={fieldName}
        ref={inputRef}
        hasError={!!error}
        hasFocus={hasFocus}
        hasContent={hasContent}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        defaultValue={defaultValue}
        type={localType}
        maxLength={maxLength}
        {...rest}
      />
      {hasPasswordEye && (
        <Eye onClick={handleTogglePassword}>
          {localType === 'password' ? (
            <FiEyeOff size={20} />
          ) : (
            <FiEye size={20} />
          )}
        </Eye>
      )}
      {error && (
        <Error>
          <span>{error}</span>
        </Error>
      )}
    </Container>
  );
};

export default Input;
