import React from 'react';
import { FiCheck } from 'react-icons/fi';

import {
  Title,
  Button,
  Buttons,
  Message,
  Container,
  StyledModal,
} from './styles';

interface IOption {
  ref: string;
  title: string;
  selected?: boolean;
}

interface IOptionsDialog {
  title: string;
  isOpen: boolean;
  message?: string;
  options: IOption[];
  onClose: () => void;
  onConfirm: (option: string) => void;
}

StyledModal.setAppElement('#root');

const OptionsDialog: React.FC<IOptionsDialog> = ({
  title,
  isOpen,
  message,
  options,
  onClose,
  onConfirm,
}) => {
  return (
    <StyledModal
      id="options-dialog"
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      isOpen={isOpen}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        },
      }}
    >
      <Container>
        {title && <Title>{title}</Title>}
        {message && <Message>{message}</Message>}
        <Buttons>
          {options.map(option => (
            <Button onClick={() => onConfirm(option.ref)} key={option.ref}>
              <span>{option.title}</span>
              {option.selected && <FiCheck size={22} color="#db1622" />}
            </Button>
          ))}
        </Buttons>
      </Container>
    </StyledModal>
  );
};

export default OptionsDialog;
