/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Lottie from 'lottie-react-web';
import { useTheme } from 'styled-components';
import Select, { ValueType } from 'react-select';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

import ISaveDiscountCouponDTO from '../../dtos/ISaveDiscountCouponDTO';

import { useToast } from '../../hooks/toast';
import { useConfirmDialog } from '../../hooks/confim_dialog';
import { useDiscountsCoupons } from '../../hooks/discounts_coupons';

import DateInput from '../DateInput';
import NumberInput from '../NumberInput';
import FormlessInput from '../FormlessInput';
import CustomCheckbox from '../CustomCheckbox';

import { clamp, isNumberValid } from '../../utils/numbers';
import animation from '../../assets/animations/loading.json';

import {
  Error,
  Button,
  Header,
  Wrapper,
  Container,
  Information,
  DateContainer,
  InputContainer,
  ButtonContainer,
  SelectContainer,
  LoadingContainer,
  SingleUseContainer,
} from './styles';

interface IAddCouponModal {
  visible: boolean;
  onBackPressed: () => void;
}

interface IOperationType {
  label: string;
  value: 'TOTAL' | 'DELIVERY_FEE';
}

interface IAmountType {
  label: string;
  value: 'FIXED' | 'PERCENTAGE';
}

const operationTypes: IOperationType[] = [
  {
    value: 'TOTAL',
    label: 'Total do pedido',
  },
  {
    value: 'DELIVERY_FEE',
    label: 'Taxa de entrega',
  },
];

const amountTypes: IAmountType[] = [
  {
    value: 'FIXED',
    label: 'Valor fixo',
  },
  {
    value: 'PERCENTAGE',
    label: 'Porcentagem',
  },
];

const INITIAL_STATE: ISaveDiscountCouponDTO = {
  ref: '',
  amount: 0,
  maxUses: 0,
  companyId: 0,
  maxAmount: 0,
  minAmount: 0,
  finalDate: '',
  description: '',
  initialDate: '',
  uniqueUse: true,
  amountType: 'FIXED',
  operationType: 'TOTAL',
};

const AddCouponModal: React.FC<IAddCouponModal> = ({
  visible,
  onBackPressed,
}) => {
  const theme = useTheme();

  const { addToast } = useToast();
  const { showConfirmDialog } = useConfirmDialog();
  const { loadingController, saveCoupon } = useDiscountsCoupons();

  const [finalDate, setFinalDate] = useState<Date | null>(null);
  const [initialDate, setInitialDate] = useState<Date | null>(null);

  const [coupon, setCoupon] = useState<ISaveDiscountCouponDTO>(INITIAL_STATE);

  const [changed, setChanged] = useState(false);

  const [uniqueUse, setUniqueUse] = useState(true);

  const [
    selectedOperationType,
    setSelectedOperationType,
  ] = useState<IOperationType | null>(null);

  const [
    selectedAmountType,
    setSelectedAmountType,
  ] = useState<IAmountType | null>(null);

  const [errors, setErrors] = useState<string[]>([]);

  const handleOnClose = useCallback(
    (bypass?: boolean) => {
      if (changed && visible && !bypass) {
        showConfirmDialog({
          title: 'Deseja mesmo sair?',
          onConfirm: () => {
            onBackPressed();
            setChanged(false);
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onCancel: () => {},
        });
      } else {
        onBackPressed();
      }
    },
    [visible, changed, onBackPressed, showConfirmDialog],
  );

  const handleOnEscPressed = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleOnClose();
      } else {
        setChanged(true);
      }
    },
    [handleOnClose],
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        event.target &&
        event.target.id !== 'drawer' &&
        event.path &&
        !event.path.find(
          p =>
            (p.id === 'drawer' || p.id === 'toast') && !loadingController.post,
        )
      ) {
        handleOnClose();
      }
    },
    [handleOnClose, loadingController],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  useEffect(() => {
    document.addEventListener('keydown', handleOnEscPressed, false);

    return () =>
      document.removeEventListener('keydown', handleOnEscPressed, false);
  });

  const resetData = useCallback(() => {
    setCoupon(INITIAL_STATE);

    setFinalDate(null);
    setInitialDate(null);

    setChanged(false);

    setSelectedAmountType(null);
    setSelectedOperationType(null);
    setErrors([]);
  }, []);

  useEffect(() => {
    if (!visible) {
      resetData();
    }
  }, [visible, resetData]);

  useEffect(() => {
    setCoupon(prevState => {
      if (selectedOperationType?.value === 'DELIVERY_FEE') {
        return {
          ...prevState,
          amount: 100,
          maxAmount: 0,
        };
      }

      return {
        ...prevState,
        amount: 0,
        maxAmount: 0,
      };
    });
  }, [selectedOperationType, selectedAmountType]);

  const handleOnSubmit = useCallback(async () => {
    const errors = {
      ref: !coupon.ref,
      amount: !coupon.amount || coupon.amount <= 0,
      description: !coupon.description,
      operationType: !selectedOperationType,
      amountType: !selectedAmountType,
      initialDate: !initialDate,
      finalDate: !finalDate,
      minAmount:
        (selectedAmountType as IAmountType)?.value === 'FIXED' &&
        coupon.minAmount < coupon.amount,
      maxUses: !coupon.maxUses,
    };

    const errorsArray = Object.keys(errors).map(key => [
      key,
      errors[key as keyof typeof errors],
    ]);

    const hasErrors = !errorsArray.every(item => item[1] === false);

    if (hasErrors) {
      return setErrors(
        errorsArray.filter(item => item[1]).map(item => item[0] as string),
      );
    }

    try {
      await saveCoupon({
        ...coupon,
        finalDate: finalDate?.toISOString() || '',
        initialDate: initialDate?.toISOString() || '',
        operationType: selectedOperationType
          ? (selectedOperationType as IOperationType).value
          : 'TOTAL',
        amountType: selectedAmountType
          ? (selectedAmountType as IAmountType).value
          : 'FIXED',
        uniqueUse,
      });

      addToast({
        type: 'success',
        description: 'Cupom criado com sucesso!',
      });

      resetData();
      handleOnClose(true);
    } catch (err) {
      const errors = (err as any)?.response?.data?.errors?.messages;
      addToast({
        type: 'error',
        description:
          (Array.isArray(errors) && errors[0]) || 'Erro ao criar cupom.',
      });
    }
  }, [
    coupon,
    finalDate,
    uniqueUse,
    initialDate,
    selectedAmountType,
    selectedOperationType,
    addToast,
    resetData,
    saveCoupon,
    handleOnClose,
  ]);

  const handleOnInitialDateChanged = useCallback((date: Date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    setInitialDate(date);
  }, []);

  const handleOnFinalDateChanged = useCallback((date: Date) => {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    setFinalDate(date);
  }, []);

  const formatReference = useCallback((value: string): string => {
    return value.toUpperCase().replace(/[^A-Za-z\ds]/g, '');
  }, []);

  const handleOnReferenceChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setCoupon(prevState => {
        return {
          ...prevState,
          ref: formatReference(value),
        };
      });
    },
    [formatReference],
  );

  const handleOnAmountChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | number) => {
      if (typeof e === 'number') {
        setCoupon(prevState => {
          return {
            ...prevState,
            amount: e,
          };
        });
      } else {
        const { value } = e.target;

        if (isNumberValid(value) || !value) {
          setCoupon(prevState => {
            return {
              ...prevState,
              amount: clamp(Number(value || 0), 0, 100),
            };
          });
        }
      }
    },
    [],
  );

  const handleOnDescriptionChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setCoupon(prevState => {
        return {
          ...prevState,
          description: value,
        };
      });
    },
    [],
  );

  const handleOnMinAmountChanged = useCallback((value: number) => {
    setCoupon(prevState => {
      return {
        ...prevState,
        minAmount: value || 0,
      };
    });
  }, []);

  const handleOnMaxAmountChanged = useCallback((value: number) => {
    setCoupon(prevState => {
      return {
        ...prevState,
        maxAmount: value,
      };
    });
  }, []);

  const handleOnMaxUsesChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (isNumberValid(value) || !value) {
        setCoupon(prevState => {
          return {
            ...prevState,
            maxUses: Number(value),
          };
        });
      }
    },
    [],
  );

  const handleOnOperationTypeChanged = useCallback(
    (param: ValueType<IOperationType>) => {
      setSelectedOperationType(param as IOperationType);

      if ((param as IOperationType).value === 'TOTAL') {
        setSelectedAmountType({
          value: 'FIXED',
          label: 'Valor fixo',
        });
      } else {
        setSelectedAmountType({
          value: 'PERCENTAGE',
          label: 'Porcentagem',
        });
      }
    },
    [],
  );

  const handleOnAmountTypeChanged = useCallback(
    (param: ValueType<IAmountType>) => {
      setSelectedAmountType(param as IAmountType);

      setCoupon(prevState => {
        return {
          ...prevState,
          amount: 0,
        };
      });
    },
    [],
  );

  const handleOnUniqueUseClicked = useCallback(() => {
    setUniqueUse(prevState => !prevState);
  }, []);

  const maxAmountShown = useMemo(() => {
    return (
      selectedOperationType &&
      (selectedOperationType as IOperationType).value === 'TOTAL' &&
      selectedAmountType &&
      (selectedAmountType as IAmountType).value === 'PERCENTAGE'
    );
  }, [selectedOperationType, selectedAmountType]);

  return (
    <Container visible={visible} id="drawer">
      <Header>
        <FiArrowLeft size={24} onClick={() => handleOnClose()} />
        <span>Cadastro de Cupom</span>
      </Header>
      <Information className="has-custom-scroll-bar-2">
        <FormlessInput
          id="ref"
          name="ref"
          title="Referência"
          value={coupon.ref}
          onChange={handleOnReferenceChanged}
        />
        {errors.includes('ref') && <Error>Campo obrigatório</Error>}
        <SelectContainer>
          <Select
            options={operationTypes}
            value={selectedOperationType}
            onChange={handleOnOperationTypeChanged}
            placeholder="Selecione o tipo do cupom"
          />
        </SelectContainer>
        <SingleUseContainer>
          <CustomCheckbox
            checked={uniqueUse}
            text="Cupom de uso único"
            id="unique-use-cupom-checkbox"
            onChange={handleOnUniqueUseClicked}
          />
          <span>
            cupons de uso único podem ser usados apenas uma vez por cliente.
          </span>
        </SingleUseContainer>
        {errors.includes('operationType') && <Error>Campo obrigatório</Error>}
        {selectedOperationType &&
          (selectedOperationType as IOperationType).value === 'TOTAL' && (
            <>
              <SelectContainer>
                <Select
                  options={amountTypes}
                  value={selectedAmountType}
                  onChange={handleOnAmountTypeChanged}
                  placeholder="Selecione o tipo do valor"
                />
              </SelectContainer>
              {errors.includes('amountType') && (
                <Error>Campo obrigatório</Error>
              )}
            </>
          )}
        {selectedOperationType &&
          (selectedOperationType as IOperationType).value === 'TOTAL' && (
            <InputContainer>
              {selectedAmountType &&
              (selectedAmountType as IAmountType).value === 'FIXED' ? (
                <NumberInput
                  title="Valor"
                  name="amount"
                  defaultValue={coupon.amount}
                  onChange={handleOnAmountChanged}
                />
              ) : (
                <FormlessInput
                  id="amount"
                  name="amount"
                  title="Valor"
                  value={coupon.amount}
                  type="number"
                  onChange={handleOnAmountChanged}
                />
              )}
              {errors.includes('amount') && <Error>Campo obrigatório</Error>}
            </InputContainer>
          )}
        <InputContainer>
          <FormlessInput
            id="description"
            title="Descrição"
            name="description"
            value={coupon.description}
            onChange={handleOnDescriptionChanged}
          />
          {errors.includes('description') && <Error>Campo obrigatório</Error>}
        </InputContainer>
        <DateContainer>
          <DateInput
            fullWidth
            label="início"
            value={initialDate}
            onChange={handleOnInitialDateChanged}
            minDate={new Date()}
          />
          <DateInput
            fullWidth
            label="fim"
            value={finalDate}
            onChange={handleOnFinalDateChanged}
            minDate={new Date()}
          />
        </DateContainer>
        {(errors.includes('finalDate') || errors.includes('initialDate')) && (
          <Error>Campo obrigatório</Error>
        )}
        {maxAmountShown ? (
          <Wrapper>
            <div>
              <NumberInput
                name="minAmount"
                title="Valor mínimo"
                defaultValue={coupon.minAmount}
                onChange={handleOnMinAmountChanged}
              />
              {errors.includes('minAmount') && (
                <Error>
                  {coupon.minAmount < coupon.amount
                    ? 'Precisa ser maior que o valor do desconto'
                    : 'Campo obrigatório'}
                </Error>
              )}
            </div>
            <NumberInput
              name="maxAmount"
              defaultValue={coupon.maxAmount}
              title="Valor máximo do desconto"
              onChange={handleOnMaxAmountChanged}
            />
          </Wrapper>
        ) : (
          <InputContainer>
            <NumberInput
              name="minAmount"
              title="Valor mínimo"
              defaultValue={coupon.minAmount}
              onChange={handleOnMinAmountChanged}
            />
            {errors.includes('minAmount') && (
              <Error>
                {coupon.minAmount < coupon.amount
                  ? 'Precisa ser maior que o valor do desconto'
                  : 'Campo obrigatório'}
              </Error>
            )}
          </InputContainer>
        )}
        <InputContainer>
          <FormlessInput
            id="maxUses"
            name="maxUses"
            title="Quantidade"
            value={coupon.maxUses}
            onChange={handleOnMaxUsesChanged}
          />
          {errors.includes('maxUses') && <Error>Campo obrigatório</Error>}
        </InputContainer>
        <ButtonContainer>
          <Button onClick={handleOnSubmit}>
            {loadingController.post ? (
              <LoadingContainer>
                <Lottie
                  options={{
                    animationData: animation,
                  }}
                  width={80}
                  height={80}
                  style={{ overflow: 'visible' }}
                />
              </LoadingContainer>
            ) : (
              <>
                <span>Continuar</span>
                <FiArrowRight size={24} color={theme.palette.text_white} />
              </>
            )}
          </Button>
        </ButtonContainer>
      </Information>
    </Container>
  );
};

export default AddCouponModal;
