import { Form } from '@unform/web';
import styled, { css } from 'styled-components';
import Radio from '../../components/Radio';

interface IHorizontalWrapperProps {
  asymmetrical?: boolean;
}

interface ISubPageProps {
  selected: boolean;
}

export const DivPadding = styled.div`
  width: 100%;
  height: 8px;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;

  input {
    scroll-margin: 60px;
  }
`;

export const Content = styled.main`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  background: #f4f4f6;
  position: relative;
`;

export const Header = styled.header`
  transform-origin: left top;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  padding-top: 24px;
  margin-bottom: 16px;
`;

export const PageName = styled.strong`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 32px;

  @media (max-width: 1027px) {
    display: none;
  }
`;

export const SubPages = styled.div`
  width: 100%;
  min-height: 64px;
  overflow: auto;

  padding: 0 32px 8px 32px;
  margin-bottom: 8px;

  display: flex;

  font-size: 20px;

  div + div {
    margin-left: 64px;
  }

  @media (max-width: 1000px) {
    margin-bottom: 8px;
    font-size: 100%;
    div + div {
      margin-left: 16px;
    }
  }

  @media (max-width: 640px) {
    width: 100vw;
  }
`;

export const SubPage = styled.div<ISubPageProps>`
  white-space: nowrap;
  cursor: pointer;

  ${({ selected }) =>
    selected
      ? css`
          padding-bottom: 8px;
          border-bottom: 4px solid #db1622;
          font-weight: 500;
        `
      : css``}
`;

export const Main = styled.main`
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  align-content: center;
  width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;

  flex-wrap: wrap;

  overflow-y: auto;

  input {
    font-size: 24px;
  }

  @media (max-width: 1024px) {
    max-width: 100vw;
    input {
      font-size: 20px;
    }
  }

  @media (max-width: 480px) {
    padding-bottom: 80px;
  }
`;

export const CompanySettings = styled(Form)`
  width: calc(100% - 32px);
  max-width: 1fr;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 64px;
    width: 200px;

    transition: box-shadow 0.4s;

    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.1);

    &:hover {
      box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);
    }

    border: 0;
    color: #fff;
    background: #db1622;

    padding: 16px 48px;

    span {
      font-size: 18px;
    }
  }
`;

export const HorizontalWrapper = styled.div<IHorizontalWrapperProps>`
  display: flex;

  > div + div {
    margin-left: 32px;
  }

  @media (max-width: 730px) {
    flex-direction: column;

    div {
      width: 100%;
    }

    div + div {
      width: 100%;
      margin-left: 0;
    }
  }

  #clientIdContainer {
    margin-right: 24px;
  }

  #apiCompanyKeyContainer {
    width: auto;
  }
`;

export const HorizontalWrapper2 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

export const DisplayName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};
  padding: 16px 32px;

  input {
    width: 87%;
  }

  @media (max-width: 1800px) {
    width: 100%;
  }
`;

export const Span = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input``;

export const Title = styled.span`
  font-size: 14px;
`;

export const CompanyName = styled.span`
  font-size: 32px;
`;

export const GroupTitle = styled.div`
  border-bottom: 4px solid #db1622;

  font-weight: 500;
  font-size: 24px;

  button {
    border: 0;
    color: #fff;
    background: #db1622;
    width: 35px;
    height: 35px;
    margin-bottom: 16px;
  }
`;

export const GroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};

  margin-top: 32px;

  > div {
    width: 100%;
  }

  > div + div {
    margin-top: 24px;
    width: 100%;
  }

  @media (max-width: 800px) {
    margin-top: 16px;
  }

  :first-child {
    margin-top: unset;
  }

  #contentContainer {
    margin-top: 0;
  }

  #settings-bsticket-products {
    margin-top: unset;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;

    #bs-ticket-order-products-button {
      margin-left: unset;
    }
  }
`;

export const GroupDescription = styled.p`
  margin-top: 16px;
`;

export const InnerGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddressContainer = styled.div`
  display: flex;
  width: 100%;

  div {
    width: 20%;
  }

  div + div {
    width: 10%;
    margin-left: 32px;
  }

  div + div + div {
    width: 70%;
  }

  @media (max-width: 730px) {
    flex-direction: column;

    div {
      width: 100%;
    }

    div + div {
      width: 100%;
      margin-left: 0;
      margin-top: 32px;
    }

    div + div + div {
      width: 100%;
      margin-top: 32px;
    }
  }
`;

export const PricesContaier = styled.div`
  display: flex;
  flex-direction: column;

  div {
    div {
      margin-bottom: 32px;
    }
  }
`;

export const GroupSubTitles = styled.span`
  width: fit-content;
  border-bottom: 4px solid #db1622;
  margin-top: 32px;
  font-size: 18px;
`;

export const DeliveryTypesContainer = styled.div`
  max-width: 100%;
  display: flex;
  font-size: 24px;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    font-size: 20px;
  }
`;

export const PaymentTypesContainer = styled(DeliveryTypesContainer)``;

export const PixContainer = styled.div`
  margin-top: 16px;

  > div {
    margin-top: 16px;
  }
`;

export const RadioContainer = styled(Radio.Group)`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: -4px;

  input {
    display: none;
  }

  label {
    margin-top: 8px;
    user-select: none;
    cursor: pointer;
    margin-right: 1.5rem;
    font-size: 1.2rem;
    padding-bottom: 8px;
    transition: border-color 200ms ease;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.text};

    svg {
      margin-left: 0.4rem;
    }

    .checked {
      display: none;
    }
  }

  input:checked + label {
    border-color: ${({ theme }) => theme.palette.primary};
    .unchecked {
      display: none;
    }
    .checked {
      display: block;
    }
  }

  @media (max-width: 800px) {
    label {
      font-size: 1rem;
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;

    label {
      margin-bottom: 0.4rem;
    }
  }
`;

export const TypeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;

  h2 {
    font-size: 1.4rem;
    margin-right: 0.4rem;
  }

  h4 {
    width: 100%;
  }
`;

export const BrandsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    margin-top: 16px;
  }
`;

export const UserSettings = styled(Form)`
  width: calc(100% - 32px);
  max-width: 1fr;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const PreferencesContainer = styled.div`
  width: 100%;
  padding: 0 16px;
  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const BusinessHoursGroupTitle = styled(GroupTitle)`
  display: flex;
  justify-content: space-between;
`;

export const AddButton = styled.button`
  border: 0;
  color: #fff;
  background: #db1622;
  width: 35px;
  height: 35px;
  margin-bottom: 16px;
  transition: filter 120ms ease-out;
  will-change: filter;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const BusinessHoursGroupContainer = styled.div`
  display: flex;

  max-width: 800px;
  flex-wrap: wrap;

  margin-top: 32px;
`;

export const ChangePassword = styled.div`
  padding-top: 64px;

  > div {
    margin-top: 32px;
  }
`;

export const ChangePasswordTitle = styled.span`
  border-bottom: 4px solid #db1622;

  max-width: 800px;

  background: #f4f4f6;

  font-weight: 500;
  font-size: 24px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 16px;

  span {
    margin-left: 16px;
  }
`;

export const DeliveryRadiusContainer = styled.div`
  width: 100%;
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 32px;

  span {
    margin-left: 16px;
  }
`;

export const DeliveryRadiusTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    width: 153px;
    font-size: 18px;
    margin-bottom: 32px;
    border-bottom: 4px solid #db1622;
  }
`;

export const AddDeliveryRadiusButton = styled.button`
  border: 0;
  color: #fff;
  background: #db1622;

  width: 35px;
  height: 35px;
  margin-bottom: 16px;
`;

export const DeliveryRadiuses = styled.div``;

export const Options = styled(Form)`
  width: calc(100% - 32px);
  max-width: 1fr;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: smooth-appearance 0.5s ease;
  padding-bottom: 16px;
`;

const integratoinStyles = css`
  width: calc(100% - 32px);
  max-width: 1fr;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: smooth-appearance 0.5s ease;
  padding-bottom: 16px;
`;

export const IntegrationContainer = styled(Form)`
  ${integratoinStyles}

  #fbPixelContainer {
    margin-top: 24px;
  }
`;

export const ChatbotContainer = styled.div`
  ${integratoinStyles}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const CompanyCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;

  > span {
    font-weight: 500;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
  }
`;

export const CompanyCode = styled.div`
  width: 100%;
  height: 2rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${({ theme }) => theme.palette.background_secondary};
    box-shadow: ${({ theme }) => theme.shadow.box_shadow_secondary};
    border-radius: 4px;

    padding: 2px 12px;
  }

  #clientId {
    display: flex;
    flex: 1;
  }

  button {
    background: none;
    border: none;
    padding: 0;
  }

  a {
    color: ${({ theme }) => theme.palette.primary};
  }

  .copyButton {
    background: none;
    border: none;
    padding: 0;
  }
`;

export const BsTicketContainer = styled.div`
  width: 100%;
  max-width: 1fr;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: smooth-appearance 0.5s ease;
  padding-bottom: 16px;
`;
