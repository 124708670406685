/* eslint-disable no-return-assign */
type IObjectWithPosition<T> = T & {
  position?: number;
};

export function filterByValue<T extends { [key: string]: string }>(
  array: T[],
  string: string,
): T[] {
  return array.filter(o =>
    Object.keys(o).some(k => {
      if (typeof o[k] === 'string') {
        return o[k].toLowerCase().includes(string.toLowerCase());
      }
      return false;
    }),
  );
}

export const equal = (arr0: any[], arr1: any[]): boolean =>
  JSON.stringify(arr0) === JSON.stringify(arr1);

export function reorderArray<T>(
  list: Array<T>,
  startIndex: number,
  endIndex: number,
): Array<T> {
  const result = Array.from<T>(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function hasDuplicateValues<T>(
  array: T[],
  getIdentifier: (value: T) => number | string,
): boolean {
  const identifiers = array.map(getIdentifier);

  return !array.every((item, index) => {
    const cuttedArray = identifiers.filter((_, idIndex) => idIndex !== index);
    return !cuttedArray.includes(getIdentifier(item));
  });
}

export function removeDuplicateValues<T>(
  array: T[],
  identifier: (item: T) => string | number,
): T[] {
  const resultArray: T[] = [];

  array.forEach(item => {
    const key = identifier(item);
    const foundIndex = resultArray.findIndex(
      current => identifier(current) === key,
    );

    if (foundIndex !== -1) {
      return (resultArray[foundIndex] = item);
    }

    return resultArray.push(item);
  });

  return resultArray;
}

export function normalizePositions<T>(array: IObjectWithPosition<T>[]): T[] {
  return array
    .sort((a, b) =>
      (a?.position || 0) > (b?.position || 0)
        ? 1
        : (b?.position || 0) > (a?.position || 0)
        ? -1
        : 0,
    )
    .map((item, index) => ({ ...item, position: index }));
}
