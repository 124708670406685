import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useSidebar } from '../../hooks/sidebar';
import { useCashierReport } from '../../hooks/cashier_report';

// import LoadingAnimation from '../../components/LoadingAnimation';

import { PageNames } from '../../enums/pages';

import {
  Main,
  Header,
  Content,
  PageInfo,
  PageName,
  Container,
  CancelButton,
  CashiersList,
  FiltersContainer,
  AppliedFiltersContainer,
} from './styles';

import CashierReportItem from '../../components/CashierReportItem';
import CashierReportFilters, {
  CashierPlatformFilter,
} from '../../components/CashierReportFilters';
import Pager, { PagerRef } from '../../components/Pager';
import CashierAppliedFilters from '../../components/CashierAppliedFilters';
import CashierReport from '../../components/CashierReport';
import { StyledModal } from '../../components/ChooseLoadingAnimationModal/styles';
import IDevice from '../../models/IDevice';
import ICashierReport, {
  IPeriodCashierReport,
  IShift,
} from '../../models/ICashierReport';
import { useDevices } from '../../hooks/devices';
import CashierReportPrint, {
  ICashierReportPrintRef,
} from '../../components/CashierReportPrint';
import { IDateRangePickerValue } from '../../components/DateRangePicker';
import PeriodCashierReport from '../../components/CashierReport/PeriodReport';
import api from '../../services/api';

async function loadPeriodReport(
  platform: CashierPlatformFilter,
  dateRange: IDateRangePickerValue,
): Promise<IPeriodCashierReport> {
  const { data } = await api.get<IPeriodCashierReport>('restricted/reports', {
    params: {
      platform,
      startAt: dateRange.startDate,
      endAt: dateRange.endDate,
    },
  });

  return data;
}

const Modal: React.FC<{ isOpen: boolean }> = ({ isOpen, children }) => (
  <StyledModal
    isOpen={isOpen}
    style={{
      overlay: { backgroundColor: 'rgba(0, 0, 0, .5)' },
      content: {
        height: '100%',
        background: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflowY: 'auto',
      },
    }}
  >
    {children}
  </StyledModal>
);

const CashierReportPage: React.FC = () => {
  const {
    cashiersReports,
    isCashierReportLoading,
    filtersStates,
    loadCashiersReports,
    loadCashierReport,
  } = useCashierReport();

  const { devices } = useDevices();

  const pagerRef = useRef<PagerRef>(null);

  const reportPrintRef = useRef<ICashierReportPrintRef>(null);

  const [isCashierReportModalOpen, setIsCashierReportModalOpen] = useState(
    false,
  );

  const [showCancelButton, setShowCancelButton] = useState(false);

  const [
    selectedCashierReport,
    setSelectedCashierReport,
  ] = useState<ICashierReport | null>(null);

  const [periodReport, setPeriodReport] = useState<IPeriodCashierReport | null>(
    null,
  );

  const foundDevice = devices.find(
    device => device.deviceId === filtersStates.device.get,
  );

  const { setSelectedPage, selectedPage } = useSidebar();

  const handleOnSearch = useCallback(
    async (
      deviceId?: IDevice['deviceId'],
      platform?: CashierPlatformFilter,
      dateRange?: IDateRangePickerValue,
    ) => {
      if (pagerRef?.current) {
        if (deviceId) {
          await loadCashiersReports(deviceId);
          pagerRef.current.setPage(1);
          setShowCancelButton(true);
        } else if (platform && dateRange) {
          const report = await loadPeriodReport(platform, dateRange);
          setPeriodReport(report);
        }
      }
    },
    [loadCashiersReports],
  );

  const handleOnCancelButton = useCallback(() => {
    if (pagerRef?.current) {
      pagerRef.current.setPage(0);
      setShowCancelButton(false);
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCashierReportClick = useCallback(
    async (shiftId: IShift['id'], deviceId: IDevice['deviceId']) => {
      const cashierReportResponse = await loadCashierReport(shiftId, deviceId);

      setSelectedCashierReport(cashierReportResponse);

      setIsCashierReportModalOpen(true);
    },
    [loadCashierReport],
  );

  const handleOnPrintCashierReportClicked = useCallback(
    async (shiftId?: IShift['id'], deviceId?: IDevice['deviceId']) => {
      if (shiftId && deviceId) {
        const cashierReportResponse = await loadCashierReport(
          shiftId,
          deviceId,
        );

        setSelectedCashierReport(cashierReportResponse);
      }

      reportPrintRef?.current?.print();
    },
    [loadCashierReport],
  );

  const handleCashierReportModalClose = useCallback(() => {
    setIsCashierReportModalOpen(false);
  }, []);

  useEffect(() => {
    setSelectedPage(PageNames.CASHIER_REPORT);
  }, [setSelectedPage]);

  return (
    <Container>
      <Content>
        <Header>
          <PageInfo>
            <PageName>{selectedPage}</PageName>
          </PageInfo>

          {showCancelButton && (
            <CancelButton onClick={handleOnCancelButton}>Cancelar</CancelButton>
          )}
        </Header>

        <Main>
          <Pager.Container ref={pagerRef}>
            <Pager.Page column>
              <FiltersContainer>
                {/* <SearchIllustrationContainer>
                  <SearchingIllustration />
                  <MessageContainer>
                    <Title>Pesquisar</Title>
                    <Message>
                      Utilize os filtros abaixo para encontrar o
                      <br />
                      relatório de caixa que deseja.
                    </Message>
                  </MessageContainer>
                </SearchIllustrationContainer> */}

                <CashierReportFilters
                  onSearch={handleOnSearch}
                  isLoading={isCashierReportLoading}
                />

                <Modal isOpen={!!periodReport}>
                  {periodReport && (
                    <PeriodCashierReport
                      report={periodReport}
                      onClose={() => setPeriodReport(null)}
                    />
                  )}
                </Modal>
              </FiltersContainer>
            </Pager.Page>

            <Pager.Page column>
              <CashiersList>
                <AppliedFiltersContainer>
                  <CashierAppliedFilters
                    filters={{
                      device: foundDevice?.name,
                    }}
                  />
                </AppliedFiltersContainer>
                {cashiersReports.length > 0 ? (
                  <>
                    {cashiersReports?.map(cashierReport => (
                      <CashierReportItem
                        key={cashierReport.id}
                        id={cashierReport.id}
                        start={cashierReport.start}
                        end={cashierReport.end}
                        openOperatorName={cashierReport.openOperatorName}
                        onClickCashierReport={() =>
                          handleCashierReportClick(
                            cashierReport.id,
                            cashierReport.deviceUuid,
                          )
                        }
                        onPrintClicked={() =>
                          handleOnPrintCashierReportClicked(
                            cashierReport.id,
                            cashierReport.deviceUuid,
                          )
                        }
                      />
                    ))}
                  </>
                ) : (
                  <span style={{ margin: 'auto' }}>
                    Nenhum relatório encontrado.
                  </span>
                )}
              </CashiersList>
            </Pager.Page>

            <Modal isOpen={isCashierReportModalOpen}>
              <CashierReport
                cashierReport={selectedCashierReport}
                onPrintClicked={handleOnPrintCashierReportClicked}
                onClose={handleCashierReportModalClose}
              />
            </Modal>
          </Pager.Container>
          {selectedCashierReport && (
            <CashierReportPrint
              ref={reportPrintRef}
              cashierReport={selectedCashierReport}
            />
          )}
        </Main>
      </Content>
    </Container>
  );
};

export default CashierReportPage;
