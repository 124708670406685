import React, { useState, useContext, createContext, useCallback } from 'react';
import api from '../services/api';
import IMessage from '../models/IMessage';
import { useCompany } from './company';
import { IMessageDTO } from '../dtos/IMessageDTO';
import IMessagePositionDTO from '../dtos/IMessagePositionDTO';

interface MessageContextData {
  messages: IMessage[];
  loadMessages: () => Promise<void>;
  deleteMessage: (id: number) => Promise<void>;
  newMessage: (message: IMessageDTO) => Promise<void>;
  toggleMessage: (id: number) => Promise<void>;
  editMessage: (message: IMessage, id: number) => Promise<void>;
  savePosition: (position: IMessagePositionDTO[]) => Promise<void>;
}

const MessageContext = createContext<MessageContextData>(
  {} as MessageContextData,
);

export const MessageProvider: React.FC = ({ children }) => {
  const { company } = useCompany();
  const [messages, setMessages] = useState<IMessage[]>([]);

  const newMessage = useCallback(
    async (message: IMessageDTO) => {
      const response = await api.post<IMessage>(
        `/restricted/companies/${company?.id}/messages`,
        message,
      );

      setMessages(prevState => [response.data, ...prevState]);
    },
    [company],
  );

  const editMessage = useCallback(
    async (message: IMessage, id: number) => {
      const response = await api.put<IMessage>(
        `restricted/companies/${company?.id}/messages/${id}`,
        message,
      );

      const newMessages = [...messages];

      const index = newMessages.findIndex(m => m.id === id);

      if (index > -1) {
        newMessages[index] = response.data;
        setMessages(newMessages);
      } else {
        setMessages([response.data, ...messages.filter(m => m.id === id)]);
      }
    },
    [company, messages],
  );

  const loadMessages = useCallback(async () => {
    const response = await api.get<IMessage[]>(
      `/restricted/companies/${company?.id}/messages`,
    );

    setMessages(response.data);
  }, [company]);

  const deleteMessage = useCallback(
    async (id: number) => {
      await api.delete(`restricted/companies/${company?.id}/messages/${id}`);
      setMessages(oldState => oldState.filter(message => message.id !== id));
    },
    [company],
  );

  const savePosition = useCallback(
    async (position: IMessagePositionDTO[]) => {
      const response = await api.put(
        `restricted/companies/${company?.id}/messages/position`,
        position,
      );
      setMessages(response.data);
    },
    [company],
  );

  const toggleMessage = useCallback(
    async (id: number) => {
      const messageToBeAltered = messages.find(message => message.id === id);

      await api.patch(
        `restricted/companies/${company?.id}/messages/${id}/${
          messageToBeAltered?.active ? 'deactivate' : 'activate'
        }`,
      );

      const newMessages = [...messages];

      const index = newMessages.findIndex(
        message => message.id === messageToBeAltered?.id,
      );

      newMessages[index].active = !newMessages[index].active;
      setMessages(newMessages);
    },
    [company, messages],
  );

  return (
    <MessageContext.Provider
      value={{
        messages,
        newMessage,
        editMessage,
        savePosition,
        loadMessages,
        deleteMessage,
        toggleMessage,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export function useMessage(): MessageContextData {
  const context = useContext(MessageContext);

  if (!context) {
    throw new Error('useMessage must be used within MessageProvider');
  }

  return context;
}
