import React from 'react';

import { Container } from './styles';

interface TooltipProps {
  title: string;
  reverse?: boolean;
  className?: string;
  bottom?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  children,
  className,
  reverse = false,
  bottom,
}) => {
  return (
    <Container className={className} reverse={reverse} bottom={bottom}>
      {children}
      <span>{title}</span>
    </Container>
  );
};

export default Tooltip;
