import React, { useCallback, useState } from 'react';

import { FiX } from 'react-icons/fi';

import { AuthRole } from '../../hooks/auth';

import {
  Main,
  Header,
  Container,
  OperatorInfo,
} from '../EditOperatorModal/styles';

import { GroupSubTitles } from '../../pages/SettingsPage/styles';

import IDevice from '../../models/IDevice';

import ButtonSm from '../ButtonSm';
import { ConfigContainer } from './styles';
import DeviceMenuModal from '../DeviceMenuModal';
import { useProducts } from '../../hooks/products';

interface IEditDeviceModal {
  visible: boolean;
  device: IDevice | null;
  onClose: () => void;
}

const EditDeviceModal: React.FC<IEditDeviceModal> = ({
  visible,
  device,
  onClose,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { products } = useProducts();

  const handleOnMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleOnMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleOnMenuConfirm = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  return (
    <Container visible={visible}>
      {device && (
        <DeviceMenuModal
          device={device}
          products={products}
          open={isMenuOpen}
          onClose={handleOnMenuClose}
          onConfirm={handleOnMenuConfirm}
        />
      )}
      <Header>
        <OperatorInfo>
          <FiX onClick={onClose} size={32} />
          <span>Configurações de dispositivo</span>
        </OperatorInfo>
        {/* <HeaderButtonsContainer>
          <SaveOperatorButton onClick={() => {}}>
            <FiCheck />
            <span>Salvar</span>
          </SaveOperatorButton>
        </HeaderButtonsContainer> */}
      </Header>
      <Main>
        <AuthRole whiteList={['Company', 'Manager']} disableOnly>
          <ConfigContainer>
            <GroupSubTitles style={{ marginTop: 0 }}>Cardápio</GroupSubTitles>
            <ButtonSm buttonStyle="filled" onClick={handleOnMenuOpen}>
              Configurar cardápio
            </ButtonSm>
          </ConfigContainer>
        </AuthRole>
      </Main>
      {/* <SaveOperatorFloatingButton onClick={() => {}}>
        <FiSave color={theme.palette.text_white} size={24} />
      </SaveOperatorFloatingButton> */}
    </Container>
  );
};

export default EditDeviceModal;
