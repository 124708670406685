import React, { useCallback, useState } from 'react';

import * as Yup from 'yup';
import Lottie from 'lottie-react-web';
import queryString from 'query-string';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import illustration from '../../assets/forgot-password.svg';
import animation from '../../assets/animations/loading.json';
import acquisition_completed from '../../assets/animations/acquisition-completed.json';

import FormlessInput from '../../components/FormlessInput';

import {
  Form,
  Title,
  Border,
  Header,
  Content,
  Message,
  Wrapper,
  TopRight,
  Container,
  BottomLeft,
  ButtonRipple,
  InputContainer,
  SuccessMessage,
  BorderContainer,
  AccessPortalButton,
} from './styles';

const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();

  const { addToast } = useToast();
  const { forgotPassword, resetPassword } = useAuth();

  const [email, setEmail] = useState(() => {
    if (history.location.search) {
      const parsed = queryString.parse(history.location.search);

      if (parsed.token) {
        return parsed.email as string;
      }
    }

    return '';
  });

  const [emailError, setEmailError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [resetToken] = useState(() => {
    if (history.location.search) {
      const parsed = queryString.parse(history.location.search);

      if (parsed.token) {
        return parsed.token as string;
      }
    }

    return '';
  });

  const handleOnBackPressed = useCallback(() => {
    history.push('/signin');
  }, [history]);

  const handleOnSubmit = useCallback(async () => {
    if (loading) return;

    setLoading(true);

    if (resetToken) {
      try {
        const schema = Yup.object().shape({
          password: Yup.string().min(
            4,
            'A senha deve conter, no mínimo, 4 caracteres.',
          ),
          confirmPassword: Yup.string()
            .when('password', {
              is: value => value.length >= 6,
              then: Yup.string()
                .min(4, 'A senha deve conter, no mínimo, 4 caracteres.')
                .required('Confirmação de senha é obrigatória.'),
            })
            .oneOf([Yup.ref('password'), undefined], 'As senhas não conferem.'),
        });

        await schema.validate(
          { password, confirmPassword },
          { abortEarly: false },
        );

        setPasswordError('');

        await resetPassword({ password, confirmPassword, email, resetToken });

        setSuccess(true);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          if (!password) {
            setPasswordError('A senha é obrigatória.');
          } else if (password.length < 6) {
            setPasswordError('A senha precisa ter, no mínimo, 6 caracteres.');
          }

          if (!confirmPassword) {
            setConfirmPasswordError('A senha é obrigatória.');
          } else if (confirmPassword.length < 6) {
            setConfirmPasswordError(
              'A senha precisa ter, no mínimo, 6 caracteres.',
            );
          }

          if (confirmPassword !== password) {
            setConfirmPasswordError('As senhas não conferem.');
          }
        } else {
          addToast({
            type: 'error',
            description: 'Verifique sua conexão e tente novamente.',
          });
        }
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um email válido.')
            .required('O email é obrigatório.'),
        });

        await schema.validate({ email }, { abortEarly: false });

        setEmailError('');

        await forgotPassword(email);

        setSuccess(true);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          if (!email) {
            setEmailError('O email é obrigatório.');
          } else {
            setEmailError('Email inválido.');
          }
        } else {
          addToast({
            type: 'error',
            description: 'Verifique sua conexão e tente novamente.',
          });
        }
      } finally {
        setLoading(false);
      }
    }
  }, [
    email,
    resetToken,
    loading,
    password,
    confirmPassword,
    addToast,
    resetPassword,
    forgotPassword,
  ]);

  const handleOnPasswordChanged = useCallback((value: string) => {
    setPassword(value);
    setPasswordError('');
  }, []);

  const handleOnConfirmPasswordChanged = useCallback((value: string) => {
    setConfirmPassword(value);
    setConfirmPasswordError('');
  }, []);

  const handleOnEmailChanged = useCallback((value: string) => {
    setEmail(value);
    setEmailError('');
  }, []);

  return (
    <Container>
      <Header onClick={handleOnBackPressed}>
        <FiArrowLeft size={24} />
        <span>voltar</span>
      </Header>
      {success ? (
        <Content>
          <SuccessMessage>
            <Lottie
              options={{
                animationData: acquisition_completed,
                loop: false,
              }}
              height={window.innerWidth < 600 ? 150 : 250}
              width={window.innerWidth < 600 ? 150 : 250}
            />
            <Wrapper>
              {resetToken ? (
                <>
                  <strong>Prontinho!</strong>
                  <span>Sua senha foi alterada com sucesso.</span>
                  <AccessPortalButton onClick={handleOnBackPressed}>
                    Acessar o portal
                  </AccessPortalButton>
                </>
              ) : (
                <>
                  <strong>Email enviado!</strong>
                  <span>
                    Verifique sua caixa de entrada para concluir o processo de
                    redefinição da senha.
                  </span>
                </>
              )}
            </Wrapper>
          </SuccessMessage>
        </Content>
      ) : (
        <Content>
          <img src={illustration} alt="" />
          <Form>
            <Border />
            {resetToken && email ? (
              <>
                <Title>Alterar senha.</Title>
                <Message>Insira a nova senha abaixo.</Message>
                <InputContainer>
                  <FormlessInput
                    title="Senha"
                    name="oi6auv7q"
                    type="password"
                    value={password}
                    placeholder="Sua nova senha"
                    error={passwordError}
                    onChange={e => handleOnPasswordChanged(e.target.value)}
                  />
                  <FormlessInput
                    title="Confirmar senha"
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    placeholder="Confirme a senha"
                    error={confirmPasswordError}
                    onChange={e =>
                      handleOnConfirmPasswordChanged(e.target.value)
                    }
                  />
                </InputContainer>
              </>
            ) : (
              <>
                <Title>Esqueceu a senha?</Title>
                <Message>
                  Inisira seu email cadastrado para receber as instruções de
                  recuperação de senha.
                </Message>
                <InputContainer>
                  <FormlessInput
                    type="email"
                    title="Email"
                    name="e7w2ycmv"
                    placeholder="você@exemplo.com.br"
                    error={emailError}
                    onChange={e => handleOnEmailChanged(e.target.value)}
                  />
                </InputContainer>
              </>
            )}
            <ButtonRipple color="#fff8" onClick={handleOnSubmit}>
              {loading ? (
                <Lottie
                  options={{
                    animationData: animation,
                  }}
                  height={60}
                  width={60}
                />
              ) : (
                <span>Enviar</span>
              )}
            </ButtonRipple>
            <BorderContainer>
              <Border />
            </BorderContainer>
          </Form>
        </Content>
      )}
      <TopRight>
        <Header onClick={handleOnBackPressed}>
          <FiArrowLeft size={24} />
          <span>voltar</span>
        </Header>
      </TopRight>
      <BottomLeft />
    </Container>
  );
};

export default ForgotPasswordPage;
