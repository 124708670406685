import Modal from 'react-modal';
import styled from 'styled-components';

import { appearFromBottom } from '../../styles/animations';

export const StyledModal = styled(Modal)`
  animation: ${appearFromBottom} 0.5s;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 650px;
  height: 100%;
  height: -webkit-fill-available;

  background: ${({ theme }) => theme.palette.primary};

  border-radius: 16px;

  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);

  min-width: 250px;

  overflow: hidden;

  @media (min-width: 701px) {
    max-height: 560px;
  }

  @media (max-width: 700px) {
    width: 100vw;
    border-radius: 0;
  }
`;

export const BackButton = styled.div`
  margin: 16px;
  margin-right: auto;
  margin-bottom: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  font-weight: 400;

  cursor: pointer;

  &,
  svg {
    color: ${({ theme }) => theme.palette.text_white};
  }
`;

export const ComplementsContainer = styled.div`
  display: flex;
  flex-direction: column;

  background: #fff;

  height: 512px;

  overflow-y: scroll;

  button + button {
    margin-top: 32px;
  }

  padding: 32px;
  padding-bottom: 128px;

  @media (max-width: 700px) {
    height: calc(100vh - 133px);
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;

  > svg {
    @media (min-width: 501px) {
      display: none;
    }

    @media (max-width: 500px) {
      margin-left: 32px;
    }
  }
`;

export const Search = styled.div`
  display: flex;
  flex: 1;
  background: #fff;
  padding: 8px 12px;
  border-radius: 24px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};

  svg {
    color: #000000;
    height: 25px;
    width: 25px;
    margin-right: 16px;
  }

  input {
    width: 100%;
    color: #a8a8a8;
    border: 0;
    background: none;
  }

  input + svg {
    margin-right: 0;
    margin-left: 8px;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    padding: 8px 16px;
  }
`;

export const Content = styled.div`
  border-top-right-radius: 16px;
  overflow: hidden;
`;

export const Separator = styled.div`
  border-top: 1px solid #ccc;
  margin-bottom: 16px;

  @media (min-width: 501px) {
    display: none;
  }
`;
