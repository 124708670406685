import styled from 'styled-components';

interface IOpenProps {
  open?: boolean;
}

export const Container = styled.div<IOpenProps>`
  overflow: auto;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: ${({ theme }) => theme.palette.background_white};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};
  padding: 16px;
  border-radius: 8px;
  width: 40vw;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 2;
  opacity: ${({ open }) => (open ? '1' : '0')};
  transform: ${({ open }) => (open ? 'none' : 'translateX(100vh)')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  transition: opacity 150ms ease, transform 240ms ease, visibility 100ms;
  will-change: opacity, transform;

  @media (max-width: 840px) {
    width: 60vw;
  }

  @media (max-width: 500px) {
    width: 90vw;
    left: 0;

    h2 {
      font-size: 22px;
    }
  }
`;

export const Backdrop = styled.div<IOpenProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  will-change: backdrop-filter;
  backdrop-filter: blur(3px) brightness(0.6);
  z-index: 1;
  opacity: ${({ open }) => (open ? '1' : '0')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  transition: opacity 240ms ease, visibility 240ms;
  will-change: opacity, visibility, backdrop-filter;
`;

export const ProductsContainer = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

export const ProductImage = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4px;

  .product-icon {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Product = styled.div`
  position: relative;
  border: none;
  width: 100%;
  padding: 8px;
  background: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);

  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }

  .arrow {
    position: absolute;
    right: 8px;
  }
`;

export const Search = styled.div`
  margin: 16px 0;
  width: calc(100% - 12px);
  padding: 0 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme }) => theme.palette.background_white};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  border-radius: 4px;

  input {
    width: 100%;
    padding: 4px 8px;
    border: none;
    background: transparent;
    color: ${({ theme }) => theme.palette.text};
  }

  svg {
    fill: ${({ theme }) => theme.palette.text};
  }
`;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    border-color: ${({ theme }) => theme.palette.text} !important;
  }
`;
