import styled from 'styled-components';

interface PageProps {
  column?: boolean;
  index?: number;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  position: relative;
`;

export const Pages = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  transition: transform 400ms ease;
  position: relative;
`;

export const Page = styled.div<PageProps>`
  width: 100%;
  flex: 0 0 100%;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  position: absolute;
  top: 0;
  left: ${({ index }) => (index || 0) * 100}%;

  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
`;
