import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import AddTeammateModal from '../../components/AddTeammateModal';

import EmptyMessage from '../../components/EmptyMessage';
import LoadingAnimation from '../../components/LoadingAnimation';
import Search from '../../components/Search';
import Teammate from '../../components/Teammate';

import { PageNames } from '../../enums/pages';

import { useSidebar } from '../../hooks/sidebar';
import { useTeam } from '../../hooks/team';
import { useToast } from '../../hooks/toast';
import { ITeammate } from '../../models/IUser';

import { search } from '../../utils/search';

import {
  ButtonSearchContainer,
  Container,
  Content,
  Header,
  PageName,
  AddUserButton,
  Main,
  EmptyMessageContainer,
} from './styles';

const TeamPage: React.FC = () => {
  const {
    team,
    isTeamLoading,
    createTeammate,
    updtateTeammateRoles,
    revokeTeammate,
    getTeam,
  } = useTeam();

  const { selectedPage, setSelectedPage } = useSidebar();

  const { addToast } = useToast();

  const [searchCriteria, setSearchCriteria] = useState('');
  const [isEditTeammateModalOpen, setIsEditTeammateModalOpen] = useState(false);
  const [selectedTeammate, setSelectedTeammate] = useState<ITeammate | null>(
    null,
  );

  useEffect(() => {
    getTeam();
  }, [getTeam]);

  const handleOnSearchCriteriaChanged = useCallback((text: string) => {
    setSearchCriteria(text);
  }, []);

  const handleAddNewTeammateClicked = useCallback(() => {
    setIsEditTeammateModalOpen(true);
  }, []);

  const handleOnEditTeammateModalClosed = useCallback(() => {
    setSelectedTeammate(null);

    setIsEditTeammateModalOpen(false);
  }, []);

  const handleOnEditTeammateClicked = useCallback((teammate: ITeammate) => {
    setSelectedTeammate(teammate);

    setIsEditTeammateModalOpen(true);
  }, []);

  const handleOnEditTeammateModalConfirm = useCallback(
    async (receivedTeammate: Pick<ITeammate, 'name' | 'email' | 'roles'>) => {
      try {
        const teammateAlreadyExists = team.findIndex(
          teammate => teammate.email === receivedTeammate.email,
        );

        if (teammateAlreadyExists !== -1) {
          await updtateTeammateRoles(
            receivedTeammate.email,
            receivedTeammate.roles,
          );

          addToast({
            type: 'success',
            description: 'Membro atualizado com sucesso.',
          });
        } else {
          await createTeammate(receivedTeammate);

          addToast({
            type: 'success',
            description: 'Membro criado com sucesso.',
          });
        }
      } catch {
        addToast({
          type: 'error',
          description: 'Não foi possível criar/atualizar este membro.',
        });
      }
    },
    [team, addToast, createTeammate, updtateTeammateRoles],
  );

  const handleOnRevokeTeammateClicked = useCallback(
    async (email: string) => {
      try {
        await revokeTeammate(email);

        addToast({
          type: 'success',
          description: 'Membro removido com sucesso.',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Não foi possível remover este membro.',
        });
      }
    },
    [addToast, revokeTeammate],
  );

  const searchedTeam = useMemo(() => {
    return search(
      team,
      teammate => teammate.name + teammate.email,
      searchCriteria,
    );
  }, [team, searchCriteria]);

  useEffect(() => {
    setSelectedPage(PageNames.TEAM);
  }, [setSelectedPage]);

  return (
    <Container>
      <Content>
        <Header>
          <PageName>{selectedPage}</PageName>
          <ButtonSearchContainer>
            <Search
              value={searchCriteria}
              onChange={handleOnSearchCriteriaChanged}
            />
            <AddUserButton onClick={handleAddNewTeammateClicked}>
              <FiPlus />
              <span />
            </AddUserButton>
          </ButtonSearchContainer>
        </Header>
        {isTeamLoading ? (
          <LoadingAnimation />
        ) : (
          <Main className="has-custom-scroll-bar-2">
            {searchedTeam.length > 0 ? (
              searchedTeam.map(teammate => (
                <Teammate
                  key={teammate.id}
                  teammate={teammate}
                  onEditTeammateClicked={() =>
                    handleOnEditTeammateClicked(teammate)
                  }
                  onRevokeTeammateClicked={() =>
                    handleOnRevokeTeammateClicked(teammate.email)
                  }
                />
              ))
            ) : (
              <EmptyMessageContainer>
                <EmptyMessage
                  text="membro"
                  plural="membros"
                  searchCriteria={searchCriteria}
                  onClick={handleAddNewTeammateClicked}
                />
              </EmptyMessageContainer>
            )}
          </Main>
        )}
      </Content>
      <AddTeammateModal
        isOpen={isEditTeammateModalOpen}
        teammate={selectedTeammate}
        onConfirm={handleOnEditTeammateModalConfirm}
        onClose={handleOnEditTeammateModalClosed}
      />
    </Container>
  );
};

export default TeamPage;
