import { ICouponArtVariant } from '../../models/ICouponArt';
import * as amigoVariants from './amigo';

const defaultVariant: ICouponArtVariant = {
  ...amigoVariants.defaultVariant,
  imageUri: '/couponArts/pascoa/1080x1080.webp',
  logo: {
    ...amigoVariants.defaultVariant.logo,
  },
  description: {
    ...amigoVariants.defaultVariant.description,
  },
  amount: {
    ...amigoVariants.defaultVariant.amount,
  },
  ref: {
    ...amigoVariants.defaultVariant.ref,
    color: '#f58194',
  },
  maxUses: {
    ...amigoVariants.defaultVariant.maxUses,
  },
  disclaimer: {
    ...amigoVariants.defaultVariant.disclaimer,
  },
  minAmount: {
    ...amigoVariants.defaultVariant.minAmount,
  },
  maxDiscount: {
    ...amigoVariants.defaultVariant.maxDiscount,
  },
};

const storiesVariant: ICouponArtVariant = {
  ...amigoVariants.storiesVariant,
  imageUri: '/couponArts/pascoa/1080x1920.webp',
  logo: {
    ...amigoVariants.storiesVariant.logo,
  },
  description: {
    ...amigoVariants.storiesVariant.description,
  },
  amount: {
    ...amigoVariants.storiesVariant.amount,
  },
  ref: {
    ...amigoVariants.storiesVariant.ref,
    color: '#f58194',
  },
  maxUses: {
    ...amigoVariants.storiesVariant.maxUses,
  },
  disclaimer: {
    ...amigoVariants.storiesVariant.disclaimer,
  },
  minAmount: {
    ...amigoVariants.storiesVariant.minAmount,
  },
  maxDiscount: {
    ...amigoVariants.storiesVariant.maxDiscount,
  },
};

export const pascoa_variants: ICouponArtVariant[] = [
  defaultVariant,
  storiesVariant,
];
