import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import IInterval from '../../models/IInterval';

import {
  Error,
  Hours,
  Interval,
  Container,
  Intervals,
  DayOfTheWeek,
  TimeInputContainer,
  DaysOfTheWeekContainer,
} from './styles';

interface IAvailabilityProps {
  active: boolean;
  disabled?: boolean;
  interval: IInterval;
  selectedDays: number[];
  finalTimeError: string;
  initialTimeError: string;
  onAddInterval?(): void;
  onDayClicked(day: number): void;
  OnRemoveInterval?(interval: string | number): void;
  onChangeInterval: (interval: 'INITIAL' | 'FINAL', value: string) => void;
}

const Availability: React.FC<IAvailabilityProps> = ({
  active,
  disabled,
  interval,
  selectedDays,
  finalTimeError,
  initialTimeError,
  onDayClicked,
  onChangeInterval,
  ...rest
}) => {
  return (
    <Container active={active}>
      <h3>Dias da semana</h3>
      <DaysOfTheWeekContainer>
        <DayOfTheWeek
          onClick={() => onDayClicked(6)}
          selected={selectedDays.includes(6)}
          disabled={disabled}
          {...rest}
        >
          D
        </DayOfTheWeek>
        <DayOfTheWeek
          onClick={() => onDayClicked(0)}
          selected={selectedDays.includes(0)}
          disabled={disabled}
          {...rest}
        >
          S
        </DayOfTheWeek>
        <DayOfTheWeek
          onClick={() => onDayClicked(1)}
          selected={selectedDays.includes(1)}
          disabled={disabled}
          {...rest}
        >
          T
        </DayOfTheWeek>
        <DayOfTheWeek
          onClick={() => onDayClicked(2)}
          selected={selectedDays.includes(2)}
          disabled={disabled}
          {...rest}
        >
          Q
        </DayOfTheWeek>
        <DayOfTheWeek
          onClick={() => onDayClicked(3)}
          selected={selectedDays.includes(3)}
          disabled={disabled}
          {...rest}
        >
          Q
        </DayOfTheWeek>
        <DayOfTheWeek
          onClick={() => onDayClicked(4)}
          selected={selectedDays.includes(4)}
          disabled={disabled}
          {...rest}
        >
          S
        </DayOfTheWeek>
        <DayOfTheWeek
          onClick={() => onDayClicked(5)}
          selected={selectedDays.includes(5)}
          disabled={disabled}
          {...rest}
        >
          S
        </DayOfTheWeek>
      </DaysOfTheWeekContainer>
      <Hours>
        {/* <HoursHeader>
          <h4>Horário</h4>
          <button type="button" onClick={() => onAddInterval()}>
            <span>+</span>
          </button>
        </HoursHeader> */}
        <Intervals>
          {/* {intervals.map((interval, index) => ( */}
          <Interval>
            <span>Horário</span>
            <TimeInputContainer>
              <span>início</span>
              <input
                maxLength={5}
                value={interval.initialTime}
                onChange={e => onChangeInterval('INITIAL', e.target.value)}
                disabled={disabled}
                {...rest}
              />
              {initialTimeError && (
                <Error title={initialTimeError}>
                  <FiAlertCircle color="#c53030" size={20} />
                </Error>
              )}
            </TimeInputContainer>
            <TimeInputContainer>
              <span>fim</span>
              <input
                maxLength={5}
                value={interval.finalTime}
                onChange={e => onChangeInterval('FINAL', e.target.value)}
                disabled={disabled}
                {...rest}
              />
              {finalTimeError && (
                <Error title={finalTimeError}>
                  <FiAlertCircle color="#c53030" size={20} />
                </Error>
              )}
            </TimeInputContainer>
            {/* {index > 0 && (
                <FiTrash
                  onClick={() =>
                    OnRemoveInterval(interval.id || interval.localId)
                  }
                  size={20}
                />
              )} */}
          </Interval>
          {/* ))} */}
        </Intervals>
        {/* {intervals.length === 0 && (
          <NoIntervals>
            <span>
              Nenhum horário selecionado, o produto será exibido durante o dia
              todo.
            </span>
          </NoIntervals>
        )} */}
      </Hours>
    </Container>
  );
};

export default Availability;
