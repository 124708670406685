import React from 'react';

export const injectProps = (
  children: React.ReactNode,
  propsTransformer: (props: Record<string, unknown>) => Record<string, unknown>,
): React.ReactNode => {
  return React.Children.map(children, child => {
    if (!React.isValidElement(child)) {
      return child;
    }

    return React.cloneElement(child, {
      ...propsTransformer(child.props),
      ...(child.props?.children && {
        children: injectProps(child.props?.children, propsTransformer),
      }),
    });
  });
};
