import styled, { css } from 'styled-components';

interface IActionProps {
  active?: boolean;
}

interface IIconProps {
  rotate?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  position: relative;
`;

export const Action = styled.button<IActionProps>`
  display: flex;
  border: none !important;
  background: ${({ theme }) => theme.palette.background_white};
  padding: 12px !important;
  margin: 0 !important;
  user-select: none;

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.palette.primary_light};

      * {
        color: ${({ theme }) => theme.palette.background_white} !important;
      }
    `};
`;

export const Icon = styled.div<IIconProps>`
  display: flex;

  svg {
    color: ${({ theme }) => theme.palette.text};
    transition: transform 600ms cubic-bezier(0.07, 0.57, 0.34, 1.01);
  }

  /* .rotate {
    transform: rotateZ(180deg);
  } */

  &,
  .az-icon span {
    transition: transform 600ms cubic-bezier(0.07, 0.57, 0.34, 1.01);
  }

  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotateZ(180deg);

      .az-icon span:first-child,
      .rotate-svg {
        transform: rotateZ(-180deg);
      }
    `}
`;

export const AZIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text};
  gap: 4px;
  /* writing-mode: vertical-rl; */
  /* text-orientation: upright; */
  /* letter-spacing: -6px; */
  font-weight: bold;
  /* margin-top: -4px; */
  span {
    line-height: 10px;
    letter-spacing: 8px;
    height: 8px;
    transform-origin: 4.5px center;
  }
`;
