import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  color: #30303070;
  font-size: 14px;
`;

export const Content = styled.span`
  background: #ccc4;
  min-height: 41px;

  padding: 8px 16px;
`;
