import React, { createContext, useCallback, useContext, useState } from 'react';
import api from '../services/api';
import {
  ISearchMovementsProps,
  ISearchStockCheckProps,
  IStockCheck,
  IStockCheckDTO,
  IStockMovement,
  IStockMovementDTO,
} from '../models/IStock';
import { BsTicketStockCheckStatusType } from '../enums/bsticket';

interface StockContextData {
  movements: IStockMovement[];
  stockChecks: IStockCheck[];
  loadMovements: ({ product, dates }: ISearchMovementsProps) => Promise<void>;
  loadStockChecks: ({
    title,
    status,
    dates,
  }: ISearchStockCheckProps) => Promise<void>;
  createMovement: (newMovement: IStockMovementDTO) => Promise<void>;
  createStockCheck: (newStockCheck: IStockCheckDTO) => Promise<void>;
  changeStockCheckStatus: (
    stockCheckId: number,
    status: BsTicketStockCheckStatusType,
  ) => Promise<void>;
  saveStockCheck: (stockCheck: IStockCheck) => Promise<void>;
}

const StockContext = createContext<StockContextData>({} as StockContextData);

export const StockProvider: React.FC = ({ children }) => {
  const [movements, setMovements] = useState<IStockMovement[]>([]);
  const [stockChecks, setStockChecks] = useState<IStockCheck[]>([]);

  const loadMovements = useCallback(
    async ({ product, dates }: ISearchMovementsProps) => {
      if (dates.startDate && dates.endDate) {
        const startAt = dates.startDate.toISOString();
        const endAt = dates.endDate.toISOString();

        // const response = await api.get(
        //   `restricted/products-stock?${
        //     product && `productId=${product.id}&`
        //   }startAt=${startAt}&endAt=${endAt}`,
        // );

        const response = await api.get(
          `restricted/products-stock?startAt=${startAt}&endAt=${endAt}`,
        );

        setMovements(response.data);
      }
    },
    [],
  );

  const createMovement = useCallback(async (newMovement: IStockMovementDTO) => {
    await api.post(`restricted/products-stock`, {
      newMovement,
    });
  }, []);

  const loadStockChecks = useCallback(
    async ({ title, status, dates }: ISearchStockCheckProps) => {
      if (dates.startDate && dates.endDate) {
        // const startAt = dates.startDate.toISOString();
        // const endAt = dates.endDate.toISOString();

        // const response = await api.get(
        //   `restricted/stock-check?startAt=${startAt}&endAt=${endAt}`,
        // );

        const stockChecks: IStockCheck[] = [
          {
            id: 0,
            title: 'Stock check 1',
            description: 'Stock check description 1',
            createdAt: new Date(),
            status: 'OPEN' as BsTicketStockCheckStatusType,
            user: {
              id: '1',
              name: 'User 1',
            },
            products: [
              {
                id: 1,
                title: 'Product 1',
                availableStock: 999,
                qty: 999,
              },
            ],
          },
          {
            id: 1,
            title: 'Stock check 2',
            description: 'Stock check description 2 fsadf as asfdfh lkasjdf',
            createdAt: new Date(),
            closedAt: new Date(),
            status: 'DONE' as BsTicketStockCheckStatusType,
            user: {
              id: '2',
              name: 'User 2',
            },
            products: [
              {
                id: 2,
                title: 'Product 2',
                availableStock: 999,
                qty: 999,
              },
            ],
          },
          {
            id: 2,
            title: 'Stock check 3',
            createdAt: new Date(),
            closedAt: new Date(),
            status: 'CANCELED' as BsTicketStockCheckStatusType,
            user: {
              id: '3',
              name: 'User 3',
            },
            products: [
              {
                id: 3,
                title: 'Product 3',
                availableStock: 999,
                qty: 999,
              },
            ],
          },
        ];

        setStockChecks(stockChecks);
      }
    },
    [],
  );

  const createStockCheck = useCallback(
    async (newStockCheck: IStockCheckDTO) => {
      await api.post(`restricted/stock-check`, { newStockCheck });
    },
    [],
  );

  const changeStockCheckStatus = useCallback(
    async (stockCheckId: number, status: BsTicketStockCheckStatusType) => {
      await api.patch(`restricted/stock-check/${stockCheckId}/${status}`);
    },
    [],
  );

  const saveStockCheck = useCallback(async (stockCheck: IStockCheck) => {
    await api.patch(`restricted/stock-check`, stockCheck);
  }, []);

  return (
    <StockContext.Provider
      value={{
        movements,
        stockChecks,
        loadMovements,
        loadStockChecks,
        createMovement,
        createStockCheck,
        changeStockCheckStatus,
        saveStockCheck,
      }}
    >
      {children}
    </StockContext.Provider>
  );
};

export function useStock(): StockContextData {
  const context = useContext(StockContext);

  if (!context) {
    throw new Error('useStock must be used within StockProvider');
  }

  return context;
}
