import Modal from 'react-modal';
import styled from 'styled-components';

import { appearFromBottom } from '../../styles/animations';

export const StyledModal = styled(Modal)`
  animation: ${appearFromBottom} 0.5s;
`;

export const Container = styled.div`
  padding: 16px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};
  background: ${({ theme }) => theme.palette.background_white};
`;

export const Title = styled.h3``;

export const Header = styled.div``;

export const Description = styled.div`
  margin-top: 8px;
  font-size: 14px;
`;

export const Tip = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
`;

export const Content = styled.div``;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: 32px;
`;

export const CancelButton = styled.button`
  padding: 4px 16px;
  margin-left: 16px;

  background: none;
  color: ${({ theme }) => theme.palette.primary};
  border: 1px solid ${({ theme }) => theme.palette.primary};
`;

export const ConfirmButton = styled.button`
  padding: 4px 8px;
  margin-left: 16px;

  border: 0;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};
`;
