import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: calc(1 / 2 * 100% - (1 - 1 / 2) * 16px);

  position: relative;

  cursor: pointer;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};

  @media (max-width: 840px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  padding: 32px;
  padding-bottom: 64px;

  display: flex;
  position: relative;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const Left = styled.div`
  flex: 0.8;
  display: flex;
  flex-direction: column;
`;

export const Right = styled.div`
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Reference = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text_light};
  }

  strong {
    font-size: 24px;
  }

  @media (max-width: 1200px) {
    span {
      font-size: 12px;
    }

    strong {
      font-size: 18px;
    }
  }
`;

export const Discount = styled.div`
  display: flex;

  @media (max-width: 1200px) {
    align-items: center;
  }
`;

export const Amount = styled.div`
  font-size: 32px;

  @media (max-width: 1200px) {
    font-size: 24px;
  }
`;

export const AmountTitle = styled.div`
  margin-right: 16px;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

export const Image = styled.img`
  width: 80px;
  height: 80px;

  margin-right: 16px;

  @media (max-width: 1200px) {
    width: 50px;
    height: 50px;
  }

  @media (max-width: 470px) {
    display: none;
  }
`;

export const Wrapper = styled.div``;

export const Details = styled.div`
  padding: 8px;
  color: ${({ theme }) => theme.palette.text_light};

  left: 0;
  bottom: 0;
  position: absolute;

  @media (max-width: 415px) {
    font-size: 12px;
  }
`;

export const DisableCoupomButtonContainer = styled.div`
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;

  @media (max-width: 1024px) {
    position: unset;
  }
`;

export const DisableCoupomButton = styled.button`
  border: none;
  padding: 8px 32px;
  border-radius: 0 0 8px 0;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};

  @media (max-width: 1024px) {
    flex: 1;
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const CreateCoupomArtButton = styled(DisableCoupomButton)`
  border: none;
  padding: 8px 32px;
  border-radius: 8px 0 0 0;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.secondary};

  @media (max-width: 1024px) {
    border-radius: 0 0 0 8px;
  }
`;

export const CopyLinkButton = styled.button`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  z-index: 9;

  svg {
    stroke: ${({ theme }) => theme.palette.text};
  }
`;
