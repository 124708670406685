import styled, { css } from 'styled-components';

interface IDispositionProps {
  selected: boolean;
}

export const Container = styled.div`
  padding: 16px;
  background: #fff;
`;

export const DispositionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;

  gap: 16px;
`;

export const DispositionText = styled.button.attrs({ type: 'button' })<
  IDispositionProps
>`
  padding: 10px 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};

  border: none;

  ${({ selected }) =>
    !selected &&
    css`
      color: ${({ theme }) => theme.palette.text};
      background: ${({ theme }) => theme.palette.unselected};
    `}
`;
