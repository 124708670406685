import styled from 'styled-components';

export const Container = styled.div`
  width: calc(1 / 2 * 100% - (1 - 1 / 3) * 16px);

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #fff;
  padding: 8px 16px;

  span {
    text-align: center;
    margin: 0 8px;
  }

  div {
    display: flex;
  }

  @media (max-width: 540px) {
    width: 100%;
  }

  @media (max-width: 400px) {
    flex-direction: column-reverse;
    gap: 4px;
  }
`;

export const CheckboxContainer = styled.div`
  span {
    font-size: 12px;
  }
`;

export const Checkbox = styled.label`
  width: 56px;
  height: 56px;

  cursor: pointer;
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;

  input[type='checkbox'] {
    margin-top: 4px;
    background-color: #ddd;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
    top: 5px;
  }

  input[type='checkbox']:focus {
    outline: 1px solid rgba(0, 0, 0, 0.2);
  }

  input[type='checkbox']:checked {
    background-color: #db1622;
  }

  svg {
    top: 24px;
    right: 8px;
    position: absolute;
  }
`;
