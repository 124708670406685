import React from 'react';
import Loading from '../Loading';
import { Container, Button, LoadingContainer } from './styles';

interface ISaveButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

const SaveButton: React.FC<ISaveButtonProps> = ({
  loading,
  children,
  ...props
}) => {
  // const containerRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   containerRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  // }, []);

  return (
    <Container>
      <Button {...props}>
        {loading && (
          <LoadingContainer>
            <Loading color="text_white" stroke={2} radius={24} />
          </LoadingContainer>
        )}
        {children || 'Salvar'}
      </Button>
    </Container>
  );
};

export default SaveButton;
