import React, { useCallback, useEffect, useState } from 'react';

import { FiCheck, FiSave, FiX } from 'react-icons/fi';

import ICategory from '../../models/ICategory';

import { useToast } from '../../hooks/toast';
import { useCategories } from '../../hooks/categories';

import { IOrderable } from '../OrderCategoriesModal';
import OrderableCategory from '../OrderableCategory';

import {
  Main,
  Title,
  Header,
  Message,
  PageInfo,
  Container,
  BackButton,
  Categories,
  ConfirmButton,
  StyledDraggable,
  StyledDroppable,
  ConfirmFloatingButton,
  StyledDragDropContext,
} from './styles';

interface IOrderSubcategoriesModalProps {
  visible: boolean;
  selectedCategory: ICategory | null;
  onClose: () => void;
  onConfirm: (order: IOrderable[]) => void;
}

const OrderSubcategoriesModal: React.FC<IOrderSubcategoriesModalProps> = ({
  visible,
  selectedCategory,
  onClose,
  onConfirm,
}) => {
  const { addToast } = useToast();
  const { orderableSubcategories, getOrderableCategories } = useCategories();

  const [orderedCategories, setOrderedCategories] = useState<ICategory[]>([]);

  useEffect(() => {
    async function loadData() {
      try {
        await getOrderableCategories(selectedCategory?.id || 0);
      } catch {
        addToast({
          type: 'error',
          description: 'Verifique sua conexão e tente novamente.',
        });
      }
    }

    if (selectedCategory) {
      loadData();
    }
  }, [selectedCategory, addToast, getOrderableCategories]);

  useEffect(() => {
    if (orderableSubcategories.length > 0) {
      setOrderedCategories(
        orderableSubcategories.sort((current, next) => {
          if (current.position < next.position) {
            return -1;
          }

          if (current.position === next.position) {
            return 0;
          }

          return 1;
        }),
      );
    }
  }, [orderableSubcategories]);

  const handleOnConfirm = useCallback(() => {
    const orderedArray: IOrderable[] = orderedCategories.map((c, index) => {
      return {
        position: index,
        categoryId: c.id,
      };
    }, []);

    onConfirm(orderedArray);
  }, [orderedCategories, onConfirm]);

  const reorderCategories = useCallback(
    (list, startIndex: number, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    },
    [],
  );

  const handleOnDragEnd = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (result: any) => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const newCategories = reorderCategories(
        orderedCategories,
        result.source.index,
        result.destination.index,
      );

      setOrderedCategories(newCategories as ICategory[]);
    },
    [reorderCategories, orderedCategories],
  );

  return (
    <Container visible={visible}>
      <Header>
        <PageInfo>
          <BackButton onClick={onClose}>
            <FiX size={32} />
          </BackButton>
          <Title>{`${selectedCategory?.name} / subcategorias`}</Title>
        </PageInfo>
        <ConfirmButton onClick={handleOnConfirm}>
          <FiCheck />
          <span>Salvar</span>
        </ConfirmButton>
      </Header>
      <Main>
        <Message>
          Clique, segure e arraste os itens para alterar a order de exibição.
        </Message>
        <Categories className="has-custom-scroll-bar-2">
          <StyledDragDropContext onDragEnd={handleOnDragEnd}>
            <StyledDroppable droppableId="order-categories">
              {(providedDroppable, snapshot) => (
                <div
                  style={snapshot.isDraggingOver ? { background: '#ccc3' } : {}}
                  {...providedDroppable.droppableProps}
                  ref={providedDroppable.innerRef}
                >
                  {orderedCategories.map((category, index) => (
                    <StyledDraggable
                      index={index}
                      key={category.id}
                      draggableId={category.id.toString()}
                    >
                      {providedDraggable => (
                        <div
                          ref={providedDraggable.innerRef}
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                          role="button"
                          tabIndex={-1}
                        >
                          <OrderableCategory
                            category={category}
                            position={index + 1}
                          />
                        </div>
                      )}
                    </StyledDraggable>
                  ))}
                  {providedDroppable.placeholder}
                </div>
              )}
            </StyledDroppable>
          </StyledDragDropContext>
        </Categories>
      </Main>
      <ConfirmFloatingButton onClick={handleOnConfirm}>
        <FiSave size={24} color="#fff" />
      </ConfirmFloatingButton>
    </Container>
  );
};

export default OrderSubcategoriesModal;
