import styled from 'styled-components';

export const Label = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.palette.background_white};
  z-index: 2;

  &::after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background: ${({ theme }) => theme.palette.control};
  }

  font-size: 1rem;

  margin-top: 2rem;

  &:first-child {
    margin-top: 0;
  }
`;

export const EmptyProductsLabel = styled.span`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.control};
`;

export const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 0.8rem;
  margin-top: 0.8rem;

  .active {
    --color: ${({ theme }) => theme.palette.success};
  }

  .disable {
    --color: ${({ theme }) => theme.palette.error};
  }

  .invert {
    --color: ${({ theme }) => theme.palette.info};
  }
`;
