import styled, {
  css,
  FlattenSimpleInterpolation,
  keyframes,
} from 'styled-components';

interface IContainer {
  visible?: boolean;
}

const enterAnimation = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;

export const Container = styled.div<IContainer>`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  transition: opacity 340ms ease;
  animation: ${enterAnimation} 340ms 1;

  opacity: ${({ visible }) => (visible ? '1' : '0')};
`;

export const Background = styled.div`
  inset: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999991;
`;
export const ElementPlaceholder = styled.div`
  z-index: 999992;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  transform: translate(50vw, 50vh);
  border-radius: 4px;
  box-shadow: 0 0 0 calc(100vw + 100vh) rgba(0, 0, 0, 0.8);
  transition: transform 500ms cubic-bezier(0, 1.13, 0.64, 1.02), width,
    height 300ms ease;
`;

interface ICard {
  position: 'left' | 'right' | 'top' | 'bottom' | 'center' | 'top-left';
}

interface ICardStyles {
  left: FlattenSimpleInterpolation;
  right: FlattenSimpleInterpolation;
  top: FlattenSimpleInterpolation;
  bottom: FlattenSimpleInterpolation;
  center: FlattenSimpleInterpolation;
  'top-left': FlattenSimpleInterpolation;
}

const cardStyles: ICardStyles = {
  left: css`
    top: 0;
    bottom: 0;
    transform: translate(-324px, 0);
    margin: auto 0;

    ::before {
      bottom: 0;
      top: 0;
      right: -14px;
      margin: auto 0;
      transform: rotateZ(180deg);
    }
  `,
  right: css`
    top: 0;
    bottom: 0;
    right: 0;
    transform: translate(324px, 0);
    margin: auto 0;

    ::before {
      bottom: 0;
      top: 0;
      left: -16px;
      margin: auto 0;
    }
  `,
  top: css`
    left: 0;
    right: 0;
    transform: translate(0, -200px);
    margin: 0 auto;

    ::before {
      left: 0;
      right: 0;
      bottom: -18px;
      transform: rotateZ(-90deg);
      margin: 0 auto;
    }
  `,
  bottom: css`
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    transform: translate(0, 212px);

    ::before {
      top: -20px;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: rotateZ(90deg);
    }
  `,
  center: css`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: translate(-50%, 0);

    ::before {
      display: none;
    }
  `,
  'top-left': css`
    bottom: 0;
    transform: translate(-324px, -50px);
    margin: auto 0;

    ::before {
      bottom: -12px;
      right: -6px;
      transform: rotateZ(109deg);
    }
  `,
};

export const Card = styled.div<ICard>`
  position: absolute;
  z-index: 999992;
  min-width: 300px;
  max-width: 300px;
  min-height: 184px;
  max-height: 184px;
  border-radius: 16px;
  background: ${({ theme }) => theme.palette.background_white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px 12px;
  padding-top: 16px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};
  transition: all 240ms ease;

  button {
    border: none;
    padding: 4px 16px;
    font-size: 100%;
    background: ${({ theme }) => theme.palette.control};
    color: ${({ theme }) => theme.palette.text};
    border-radius: 4px;
    opacity: 0.8;
    transition: opacity 120ms ease;

    &:hover {
      opacity: 1;
    }
  }

  button.next {
    background: ${({ theme }) => theme.palette.primary};
    color: ${({ theme }) => theme.palette.text_white};
  }

  button.ignore {
    background: transparent;
    color: ${({ theme }) => theme.palette.control};
    text-decoration: underline;
    padding: 4px 8px;
  }

  ::before {
    position: absolute;
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 18px 16px 0;
    z-index: 99992;
    border-color: transparent ${({ theme }) => theme.palette.background_white}
      transparent transparent;
  }

  ${({ position }) => cardStyles[position]};
`;

export const Text = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex: 1;

  span {
    font-size: 120%;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
`;

export const IndicatorsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-bottom: 16px;
`;

interface IIndicator {
  active?: boolean;
  index?: number;
}

const indicatorAnimation = keyframes`
  from: {
    transform: translateY(-8px);
  } to {
    transform: none;
  };
`;

export const Indicator = styled.div<IIndicator>`
  cursor: pointer;
  position: relative;
  min-width: 12px;
  min-height: 12px;
  border-radius: 6px;
  background: ${({ theme, active }) =>
    active ? theme.palette.primary : theme.palette.outline};
  animation: ${indicatorAnimation} 240ms 1 ease;
`;

export const StepButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
`;
