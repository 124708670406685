import { IStep } from '../components/Tour';

const tour: IStep[] = [
  {
    text: 'Esta é a página de Links!',
  },
  {
    id: 'main-groups-container',
    position: 'bottom',
    text: 'Aqui são listados as redes sociais padrão.',
  },
  {
    id: 'groups-whatsapp',
    position: 'bottom',
    text: 'Aqui você informa o número de whatsapp da sua empresa...',
  },
  {
    id: 'groups-whatsapp',
    position: 'bottom',
    text: 'o padrão é o número de celular informado no cadastro..',
  },
  {
    id: 'groups-value',
    position: 'bottom',
    text: 'Aqui você informa o endereço da sua rede...',
  },
  {
    id: 'groups-value',
    position: 'bottom',
    text: 'lembre-se de que os links, geralmente, começam com https://',
  },
  {
    id: 'groups-visiblity',
    position: 'left',
    text: 'Aqui você informa se a rede aparecerá nos links.',
  },
  {
    id: 'custom-groups-container',
    position: 'top',
    text: 'Aqui são listados outros links do seu estabelecimento.',
  },
  {
    id: 'custom-groups-container-add',
    position: 'left',
    text: 'Para adicionar um novo link, basta clicar aqui.',
  },
  {
    id: 'custom-groups-container-name',
    position: 'right',
    text: 'O link precisa ter um nome...',
  },
  {
    id: 'custom-groups-container-value',
    position: 'right',
    text: 'e um endereço.',
  },
  {
    id: 'custom-groups-container-delete',
    position: 'right',
    text: 'Aqui você pode excluir um link cadastrado.',
  },
  {
    id: 'social-page-save',
    position: 'top-left',
    text: 'Agora é só salvar.',
  },
];

export default tour;
