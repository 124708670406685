import styled, { css } from 'styled-components';

interface IContainerProps {
  canceled: boolean;
}

interface ISummaryProps {
  visible: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;

  font-size: 16px;

  width: 100%;

  background: #fff;

  cursor: pointer;

  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.4s;

  &:hover {
    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);
  }

  ${({ canceled }) =>
    canceled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 16px;

  img {
    height: 70px;
    width: 70px;
    border-radius: 16px;

    @media (max-width: 590px) {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  svg {
    height: 35px;
    width: 35px;
    padding: 8px;
    border-radius: 50%;

    z-index: 2;

    &:hover {
      background: #1b1c1e20;
    }
  }

  strong + span {
    margin-top: 16px;
  }

  svg + svg {
    margin-top: 16px;
  }
`;

export const ItemName = styled.strong`
  max-width: 250px;
  font-weight: bold;

  @media (max-width: 500px) {
    -webkit-line-clamp: 2;
    margin-top: 4px;
    font-size: 14px;
  }
`;

export const ItemPrice = styled.span`
  display: none;

  @media (min-width: 591px) {
    display: block;
  }
`;

export const ItemPrice2 = styled.span`
  display: none;

  @media (max-width: 590px) {
    display: block;
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const Amount = styled.strong`
  font-weight: bold;

  @media (max-width: 590px) {
    display: none;
  }
`;

export const AmountTitle = styled.span`
  color: #c3cdd6;
  font-size: 14px;

  @media (max-width: 660px) {
    display: none;
  }
`;

export const Total = styled.strong`
  font-weight: bold;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const TotalTitle = styled.span`
  color: #c3cdd6;
  font-size: 14px;

  @media (max-width: 660px) {
    display: none;
  }
`;

export const Summary = styled.div<ISummaryProps>`
  margin: 0 16px;
  padding-bottom: 16px;

  border-top: 1px solid #1b1c1e70;
`;

export const ItemAdditionals = styled.div`
  margin-top: 16px;
`;

export const ItemAdditionalsTitle = styled.span`
  font-weight: bold;
`;

export const Complement = styled.div`
  display: flex;
  justify-content: space-between;

  margin-left: 16px;

  div {
    display: flex;

    span + span {
      margin-left: 4px;
    }
  }
`;

export const ComplementAmount = styled.span`
  @media (max-width: 500px) {
    -webkit-line-clamp: 2;
    margin-top: 4px;
    font-size: 14px;
  }
`;

export const ComplementName = styled.span`
  @media (max-width: 500px) {
    -webkit-line-clamp: 2;
    margin-top: 4px;
    font-size: 14px;
  }
`;
