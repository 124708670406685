import React, { useCallback, useState } from 'react';
import { Container, Options, Option, InputLabel } from './styles';

import OperatorAndDevice from './OperatorAndDevice';
import IDevice from '../../models/IDevice';
import SaveButton from '../SaveButton';
import { useCashierReport } from '../../hooks/cashier_report';
import DateRangePicker, {
  IDateRangePickerResponse,
  IDateRangePickerValue,
} from '../DateRangePicker';
import { useToast } from '../../hooks/toast';

export enum CashierFilterTypes {
  CASHIER = 'CASHIER',
  PERIOD = 'PERIOD',
}

export enum CashierPlatformFilter {
  BSTICKET = 'BSTICKET',
  BSSELFCHECKOUT = 'BSSELFCHECKOUT',
}

interface CashierReportFilters {
  isLoading: boolean;
  onSearch: (
    deviceId?: IDevice['deviceId'],
    platform?: CashierPlatformFilter,
    dateRange?: IDateRangePickerValue,
  ) => void;
}

const CashierReportFilters: React.FC<CashierReportFilters> = ({
  isLoading,
  onSearch,
}) => {
  const { addToast } = useToast();

  const [filterType, setFilterType] = useState(CashierFilterTypes.CASHIER);
  const [platform, setPlatform] = useState(CashierPlatformFilter.BSTICKET);
  const [selectedDateRange, setSelectedDateRange] = useState<
    Partial<IDateRangePickerResponse>
  >({ dates: { startDate: null, endDate: null } });

  const { filtersStates } = useCashierReport();
  // const [selectedOperator, setSelectedOperator] = useState<
  //   IOperator['id'] | null
  // >(null);

  const handleOnDateRangeConfirm = useCallback(
    ({ dates, selectedTag }: IDateRangePickerResponse) => {
      console.log(dates);
      setSelectedDateRange({ dates, selectedTag });
    },
    [],
  );

  const handleOnFilterTypeChange = useCallback((value: CashierFilterTypes) => {
    setFilterType(value);
  }, []);

  const handleOnPlatformChange = useCallback((value: CashierPlatformFilter) => {
    setPlatform(value);
  }, []);

  const handleOnSearch: CashierReportFilters['onSearch'] = useCallback(
    (deviceId, platform, dateRange) => {
      if (filterType === CashierFilterTypes.PERIOD) {
        const errorFields = [];
        if (!platform) {
          errorFields.push('plataforma');
        }
        if (!dateRange?.startDate) {
          errorFields.push('início');
        }
        if (!dateRange?.endDate) {
          errorFields.push('fim');
        }

        if (errorFields.length > 0) {
          const suffix = errorFields.length === 1 ? '' : 's';
          return addToast({
            type: 'error',
            description: `Preencha o${suffix} campo${suffix} ${errorFields.join()}.`,
          });
        }
      }

      return onSearch(deviceId, platform, dateRange);
    },
    [addToast, filterType, onSearch],
  );

  return (
    <Container>
      <InputLabel>Filtrar por:</InputLabel>
      <Options>
        <Option
          active={filterType === CashierFilterTypes.CASHIER}
          onClick={() => handleOnFilterTypeChange(CashierFilterTypes.CASHIER)}
        >
          Caixa
        </Option>
        <Option
          active={filterType === CashierFilterTypes.PERIOD}
          onClick={() => handleOnFilterTypeChange(CashierFilterTypes.PERIOD)}
        >
          Período
        </Option>
      </Options>

      {filterType === CashierFilterTypes.CASHIER ? (
        <>
          <OperatorAndDevice />
          {filtersStates.device.get && (
            <SaveButton
              type="button"
              onClick={() => onSearch(filtersStates.device.get)}
              loading={isLoading}
              style={{ marginRight: 'auto' }}
            >
              Pesquisar
            </SaveButton>
          )}
        </>
      ) : (
        <>
          <InputLabel>Plataforma:</InputLabel>
          <Options style={{ margin: '1rem 0', marginTop: '0.5rem' }}>
            <Option
              active={platform === CashierPlatformFilter.BSTICKET}
              onClick={() =>
                handleOnPlatformChange(CashierPlatformFilter.BSTICKET)
              }
            >
              BS Ticket
            </Option>
            <Option
              active={platform === CashierPlatformFilter.BSSELFCHECKOUT}
              onClick={() =>
                handleOnPlatformChange(CashierPlatformFilter.BSSELFCHECKOUT)
              }
            >
              BS Kiosk
            </Option>
          </Options>

          <DateRangePicker
            selectedTag={selectedDateRange.selectedTag}
            onConfirm={handleOnDateRangeConfirm}
          />
          {selectedDateRange.dates?.startDate &&
            selectedDateRange.dates?.endDate && (
              <SaveButton
                type="button"
                onClick={() =>
                  handleOnSearch(undefined, platform, selectedDateRange.dates)
                }
                style={{ marginRight: 'auto', marginTop: '2rem' }}
              >
                Pesquisar
              </SaveButton>
            )}
          <div style={{ width: '100%', minHeight: '4rem' }} />
        </>
      )}
      {/* <CashierAndPlatform selectedDeviceId={selectedDevice} /> */}
    </Container>
  );
};

export default CashierReportFilters;
