import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface IInputProps {
  hasError: boolean;
  hide?: boolean;
}

export const Container = styled.div<IInputProps>`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  position: relative;
  flex-direction: column;

  input {
    border: 0;
    background: none;
    border-bottom: 1px solid ${({ theme }) => theme.palette.control};

    ${({ hasError, theme }) =>
      hasError &&
      css`
        border-bottom: 1px solid ${theme.palette.primary};
      `}

    padding: 16px 36px 16px 0;
  }
`;

export const Label = styled.label`
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  font-size: 12px;
  color: #aaa;

  > a {
    text-decoration: none;
    color: #db1622;
    font-size: 14px;
    transition: opacity 0.3s;
  }

  > a:hover {
    opacity: 0.6;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  position: absolute;
  top: 36px;
  right: 12px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
