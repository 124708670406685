/* eslint-disable no-useless-return */
import React, { useCallback, useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useProductGroups } from '../../hooks/product_groups';
import { IProductGroup } from '../../models/IProductGroups';

import FormlessInput from '../FormlessInput';
import Loading from '../Loading';

import {
  StyledModal,
  Container,
  Header,
  ModalTitle,
  CloseButton,
  ButtonsContainer,
  CancelButton,
  SaveButton,
} from './styles';

interface AddGroupModal {
  group: IProductGroup | null;
  isOpen: boolean;
  onClose: () => void;
}

const AddGroupModal: React.FC<AddGroupModal> = ({ group, isOpen, onClose }) => {
  const {
    productGroups,
    createProductGroup,
    editProductGroup,
  } = useProductGroups();

  const [isSaving, setIsSaving] = useState(false);

  const [groupName, setGroupName] = useState('');
  const [groupNameError, setGroupNameError] = useState('');

  useEffect(() => {
    if (group) {
      setGroupName(group.name);
    }
  }, [group]);

  const handleOnGroupNameChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setGroupName(value);
      setGroupNameError('');
    },
    [],
  );

  const handleOnSaveGroup = useCallback(async () => {
    if (groupName.length < 3) {
      setGroupNameError(
        !groupName
          ? 'O nome do grupo é obrigatório.'
          : 'O nome do grupo precisa conter, no mínimo, 3 caracteres.',
      );

      return;
    }

    setIsSaving(true);

    if (group) {
      await editProductGroup({
        id: group.id,
        name: groupName,
        position: group.position,
      });
    } else {
      await createProductGroup({
        name: groupName,
        position: productGroups?.length || 999,
      });
    }

    setIsSaving(false);

    setGroupName('');

    onClose();
  }, [
    groupName,
    group,
    onClose,
    createProductGroup,
    editProductGroup,
    productGroups,
  ]);

  return (
    <StyledModal
      isOpen={isOpen}
      shouldCloseOnEsc
      id="add-group-Modal"
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      style={{
        overlay: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <Container>
        <Header>
          <ModalTitle>Novo grupo</ModalTitle>
          <CloseButton onClick={onClose}>
            <FiX size={20} />
          </CloseButton>
        </Header>
        <div className="error-container">
          <FormlessInput
            placeholder="Nome"
            name="group_name"
            value={groupName}
            onChange={handleOnGroupNameChanged}
          />
          {groupNameError && <span className="error">{groupNameError}</span>}
        </div>
        <ButtonsContainer>
          <CancelButton onClick={onClose}>Cancelar</CancelButton>
          <SaveButton onClick={handleOnSaveGroup}>
            {isSaving ? (
              <Loading color="text_white" stroke={2} radius={16} />
            ) : (
              'Salvar'
            )}
          </SaveButton>
        </ButtonsContainer>
      </Container>
    </StyledModal>
  );
};

export default AddGroupModal;
