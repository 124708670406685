import React, { createContext, useCallback, useState, useContext } from 'react';

import IProduct, { IProductMeasurement } from '../models/IProduct';
import IProductSku from '../models/IProductSku';

import api from '../services/api';
import { useCompany } from './company';

interface ProductsContextData {
  products: IProduct[];
  isProductsLoading: boolean;
  selectedProduct: IProduct | null;
  lastEditedProductId: number;
  hasProducts: boolean;
  productMeasurements: IProductMeasurement[];
  unselectProduct: () => void;
  loadProducts(): Promise<void>;
  selectProduct: (product: IProduct) => void;
  deleteProduct: (id: number) => Promise<void>;
  removeImage: (id: number) => Promise<void>;
  inactivateProduct: (id: number) => Promise<void>;
  saveProduct(data: FormData, productId: number): Promise<IProduct | null>;
  saveSku(sku: IProductSku[]): Promise<void>;
}

const ProductsContext = createContext<ProductsContextData>(
  {} as ProductsContextData,
);

export const ProductsProvider: React.FC = ({ children }) => {
  const { company } = useCompany();

  const [data, setData] = useState<IProduct[]>([]);
  const [hasProducts, setHasProducts] = useState(true);
  const [isProductsLoading, setIsProductsLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  const [lastEditedProductId, setLastEditedProductId] = useState(0);
  const [productMeasurements, setProductMeasurements] = useState<
    IProductMeasurement[]
  >([]);

  const loadProducts = useCallback(async () => {
    try {
      setIsProductsLoading(true);
      const response = await api.get<IProduct[]>('restricted/products');
      setData(response.data);
      setIsProductsLoading(false);

      setHasProducts(response.data?.length > 0);

      const measurementsResponse = await api.get<IProductMeasurement[]>(
        '/products/measurements',
      );

      setProductMeasurements(measurementsResponse.data || []);
    } catch (e) {
      throw e;
    }
  }, []);

  const saveSku = useCallback(
    async (sku: IProductSku[]) => {
      await api.post<IProduct[]>(
        `restricted/products/${company?.id}/skus`,
        sku,
      );
    },
    [company],
  );

  const saveProduct = useCallback(
    async (formData: FormData, productId: number) => {
      if (productId > 0) {
        const response = await api.put<IProduct>(
          `restricted/products/${productId}`,
          formData,
        );

        const newProducts = [...data];

        const index = newProducts.findIndex(p => p.id === productId);

        if (index > -1) {
          newProducts[index] = response.data;
          setData(newProducts);
        } else {
          setData([
            response.data,
            ...data.filter(product => product.id !== productId),
          ]);
          return response.data;
        }

        setLastEditedProductId(productId);
      } else {
        const response = await api.post<IProduct>(
          'restricted/products',
          formData,
        );

        setData([response.data, ...data]);
        setLastEditedProductId(response.data?.id || 0);
        return response.data;
      }

      return null;
    },
    [data],
  );

  const deleteProduct = useCallback(
    async (id: number) => {
      await api.delete(`restricted/products/${id}/${company?.id}`);
      setData(oldState => oldState.filter(product => product.id !== id));
    },
    [company],
  );

  const inactivateProduct = useCallback(
    async (id: number) => {
      const productToBeAltered = data.find(product => product.id === id);

      await api.patch(
        `restricted/products/${id}/${company?.id}/${
          productToBeAltered?.active ? 'deactivate' : 'activate'
        }`,
      );

      const newProducts = [...data];

      const index = newProducts.findIndex(
        product => product.id === productToBeAltered?.id,
      );

      newProducts[index].active = !newProducts[index].active;

      setData(newProducts);
    },
    [data, company],
  );

  const removeImage = useCallback(
    async (id: number) => {
      await api.delete(`restricted/products/${id}/${company?.id}/image`);

      const newData = [...data];
      const index = newData.findIndex(p => p.id === id);

      if (index > -1) {
        newData[index].imageUrl = '';
        setData(newData);
      }
    },
    [company, data],
  );

  const selectProduct = useCallback((product: IProduct) => {
    setSelectedProduct(product);
  }, []);

  const unselectProduct = useCallback(() => {
    setSelectedProduct(null);
  }, []);

  return (
    <ProductsContext.Provider
      value={{
        products: data,
        selectedProduct,
        lastEditedProductId,
        isProductsLoading,
        hasProducts,
        productMeasurements,
        saveSku,
        removeImage,
        saveProduct,
        loadProducts,
        selectProduct,
        deleteProduct,
        unselectProduct,
        inactivateProduct,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export function useProducts(): ProductsContextData {
  const context = useContext(ProductsContext);

  if (!context) {
    throw new Error('useProducts must be used within ProductsProvider');
  }

  return context;
}
