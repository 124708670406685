import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.background_white};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  width: min(300px, 100%);
  padding: 12px;
  border-radius: 8px;
  margin-right: 12px;

  h2 {
    width: 100%;
  }
`;

export const CustomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

interface ICustomColorProps {
  cssVarPrefix: string;
}

export const CustomColor = styled.div<ICustomColorProps>`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: ${({ cssVarPrefix }) => `var(--${cssVarPrefix}-primary)`};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    color: ${({ cssVarPrefix }) => `var(--${cssVarPrefix}-contrast)`};
  }

  input {
    position: absolute;
    opacity: 0;
  }
`;

interface IColorProps {
  color: string;
  isSelected?: boolean;
}

export const Color = styled.button<IColorProps>`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: ${({ color }) => color || '#ccc'};

  margin: 4px;
  border: none;
`;
