import React, { useCallback, useMemo } from 'react';

import { FiCopy } from 'react-icons/fi';
import IDiscountCoupon from '../../models/IDiscountCoupon';

import { useDiscountsCoupons } from '../../hooks/discounts_coupons';

import { formatToMoney } from '../../utils/format';
import money from '../../assets/icons/money-flat.svg';
import delivery from '../../assets/icons/delivery-bike.svg';

import {
  Left,
  Image,
  Right,
  Amount,
  Content,
  Details,
  Wrapper,
  Discount,
  Container,
  Reference,
  AmountTitle,
  DisableCoupomButton,
  DisableCoupomButtonContainer,
  CreateCoupomArtButton,
  CopyLinkButton,
} from './styles';

interface IDiscountCouponProps {
  coupon: IDiscountCoupon;
  onClick: () => void;
  onCreateArtClick: () => void;
  onDelete: (id: number) => void;
  onCopyLinkClick: () => void;
}

const DiscountCoupon: React.FC<IDiscountCouponProps> = ({
  coupon,
  onClick,
  onDelete,
  onCreateArtClick,
  onCopyLinkClick,
}) => {
  const { selectCoupon } = useDiscountsCoupons();

  const info = useMemo(() => {
    return {
      amount:
        coupon.amountType === 'FIXED'
          ? formatToMoney(coupon.amount)
          : `${coupon.amount}%`,
      title:
        coupon.operationType === 'DELIVERY_FEE'
          ? 'de desconto na taxa de entrega'
          : 'de desconto no total do pedido',
      image: coupon.operationType === 'DELIVERY_FEE' ? delivery : money,
    };
  }, [coupon]);

  const handleOnDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onDelete(coupon.id);
    },
    [coupon, onDelete],
  );

  const handleOnCreateArtClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onCreateArtClick();
    },
    [onCreateArtClick],
  );

  const handleOnClick = useCallback(() => {
    selectCoupon(coupon);
    onClick();
  }, [selectCoupon, onClick, coupon]);

  return (
    <Container onClick={handleOnClick}>
      <Content>
        <Left>
          <Discount>
            <Image src={info.image} />
            <Wrapper id="discount-info">
              <Amount>{info.amount}</Amount>
              <AmountTitle>{info.title}</AmountTitle>
            </Wrapper>
          </Discount>
          <Details id="discount-details">clique para detalhes</Details>
        </Left>
        <Right>
          <Reference id="discount-ref">
            <span>referência</span>
            <strong>{coupon.ref}</strong>
          </Reference>
          <CopyLinkButton
            title="Copiar link"
            onClick={e => {
              e.stopPropagation();
              onCopyLinkClick();
            }}
          >
            <FiCopy size={24} />
          </CopyLinkButton>
        </Right>
      </Content>
      <DisableCoupomButtonContainer id="discount-deactivate">
        <CreateCoupomArtButton onClick={handleOnCreateArtClick}>
          Gerar arte
        </CreateCoupomArtButton>
        <DisableCoupomButton onClick={handleOnDelete}>
          {coupon.deprecatedAt ? 'Excluir' : 'Desativar'}
        </DisableCoupomButton>
      </DisableCoupomButtonContainer>
    </Container>
  );
};

export default DiscountCoupon;
