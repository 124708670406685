/* eslint-disable react/forbid-prop-types */
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiAlertCircle, FiCheckSquare, FiPlus, FiSquare } from 'react-icons/fi';

import {
  IAddressDTO,
  IDeliveryPlace,
  IDeliveryRange,
} from '../../dtos/IAddressDTO';

import NamePriceInput, {
  RangePriceInput,
} from '../../components/NamePriceInput';

import { useToast } from '../../hooks/toast';
import { useSidebar } from '../../hooks/sidebar';
import { useConfirmDialog } from '../../hooks/confim_dialog';

import Radio from '../../components/Radio';
import Search from '../../components/Search';
import Loading from '../../components/Loading';
import { NumberInputForm } from '../../components/NumberInput';

import api from '../../services/api';
import { search } from '../../utils/search';
import { PageNames } from '../../enums/pages';
import { AddressType } from '../../enums/addressType';
import { getValidationErrors } from '../../utils/errors';

import {
  Header,
  Container,
  AddButton,
  Placeholder,
  ErrorMessage,
  EmptyMessage,
  TypeContainer,
  RadioContainer,
  NamePriceContainer,
} from './styles';

import SaveButton from '../../components/SaveButton';

interface IDeliveryPlaceOverride extends IDeliveryPlace {
  local?: boolean;
}

interface IDeliveryRangeOverride extends IDeliveryRange {
  local?: boolean;
}

interface IFormData {
  deliveryPlaces: IDeliveryPlace[];
  deliveryRanges: IDeliveryRange[];
  price: number;
}

const AddressPage: React.FC = () => {
  const { addToast } = useToast();
  const { showConfirmDialog } = useConfirmDialog();

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const typeRef = useRef<FormHandles>(null);
  const { selectedPage, setSelectedPage } = useSidebar();

  const [searchedIds, setSearchedIds] = useState<string[]>([]);
  const [searchCriteria, setSearchCriteria] = useState('');
  const [deliveryPlaces, setDeliveryPlaces] = useState<
    IDeliveryPlaceOverride[]
  >([]);
  const [deliveryRanges, setDeliveryRanges] = useState<
    IDeliveryRangeOverride[]
  >([]);
  const [price, setPrice] = useState(0);

  const [type, setType] = useState<AddressType>(AddressType.STANDARD);

  const [errorMessage, setErrorMessage] = useState('');

  const handleOnSearchCriteriaChanged = useCallback((text: string) => {
    const data = formRef.current?.getData() as IFormData;

    const searchElements = search(
      data.deliveryPlaces,
      place => place.name,
      text,
    );
    setSearchedIds(searchElements.map(place => place.id));

    setSearchCriteria(text);
  }, []);

  const loadAddress = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get<IAddressDTO>(
      '/restricted/companies/deliveries',
    );
    setType(data.type);
    setPrice(data?.price || 0);
    setDeliveryPlaces(data.deliveryPlaces || []);
    setDeliveryRanges(
      data?.deliveryRanges
        ? data.deliveryRanges.map(item => ({
            ...item,
            maxRange: item.maxRange / 1000,
          }))
        : [],
    );
    setLoading(false);
  }, []);

  const saveAddress = useCallback(
    async (data: IAddressDTO) => {
      setSaving(true);
      try {
        const request = {
          ...data,
          deliveryPlaces: (data?.deliveryPlaces || []).map(item => ({
            ...item,
            id: undefined,
          })),
          deliveryRanges: (data?.deliveryRanges || []).map(item => ({
            ...item,
            id: undefined,
          })),
        };

        const response = await api.post<IAddressDTO>(
          '/restricted/companies/deliveries',
          request,
        );

        setType(response.data.type);
        setPrice(response.data?.price || 0);
        setDeliveryPlaces(response.data.deliveryPlaces || []);
        setDeliveryRanges(
          response.data?.deliveryRanges
            ? response.data.deliveryRanges.map(item => ({
                ...item,
                maxRange: item.maxRange / 1000,
              }))
            : [],
        );

        addToast({
          type: 'success',
          description: 'Endereço salvo com sucesso.',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro ao salvar endereço.',
        });
      }
      setSaving(false);
    },
    [addToast],
  );

  const changePlaceActiveStatus = useCallback(
    async (id: string, value: boolean) => {
      try {
        await api.patch(
          `/restricted/companies/deliveries/places/${id}/${
            value ? 'deactivate' : 'activate'
          }`,
        );
        setDeliveryPlaces(old =>
          old.map(item =>
            item.id === id ? { ...item, active: !value } : item,
          ),
        );
        addToast({
          type: 'success',
          description: `${!value ? 'Ativado' : 'Desativado'} com sucesso.`,
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro ao salvar endereço.',
        });
      }
    },
    [addToast],
  );

  const changeRangeActiveStatus = useCallback(
    async (id: string, value: boolean) => {
      try {
        await api.patch(
          `/restricted/companies/deliveries/ranges/${id}/${
            value ? 'deactivate' : 'activate'
          }`,
        );
        setDeliveryRanges(old =>
          old.map(item =>
            item.id === id ? { ...item, active: !value } : item,
          ),
        );
        addToast({
          type: 'success',
          description: `${!value ? 'Ativado' : 'Desativado'} com sucesso.`,
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro ao salvar endereço.',
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    loadAddress();
  }, [loadAddress]);

  useEffect(() => {
    setSelectedPage(PageNames.ADDRESSES);
  }, [setSelectedPage]);

  const handleAdd = useCallback(() => {
    if (type === AddressType.DISTANCE) {
      return setDeliveryRanges(old => [
        { id: uuid(), maxRange: 0, price: 0, active: true, local: true },
        ...old,
      ]);
    }

    return setDeliveryPlaces(old => [
      { id: uuid(), name: '', price: 0, active: true, local: true },
      ...old,
    ]);
  }, [type]);

  const handleDelete = useCallback(
    (id: string) => {
      showConfirmDialog({
        title: `Excluir ${
          type === AddressType.DISTANCE ? 'raio de entrega' : 'região'
        }.`,
        message: `Deseja mesmo excluir ${
          type === AddressType.DISTANCE ? 'o raio de entrega' : 'a região'
        }?`,
        onConfirm: () => {
          if (type === AddressType.DISTANCE) {
            setDeliveryRanges(old => old.filter(item => item.id !== id));
          } else {
            setDeliveryPlaces(old => old.filter(item => item.id !== id));
          }
        },
        onCancel: () => null,
      });
    },
    [type, showConfirmDialog],
  );

  const handleTypeChange = useCallback((value: string) => {
    setType(value as AddressType);
    setErrorMessage('');
  }, []);

  const handleSubmit = useCallback(async () => {
    const data = formRef.current?.getData() as IAddressDTO;

    formRef.current?.setErrors({});
    setErrorMessage('');

    if (type === AddressType.DISTANCE && !data?.deliveryRanges) {
      setErrorMessage('É necessário no mínimo uma taxa');
    }

    if (type === AddressType.PLACE && !data?.deliveryPlaces) {
      setErrorMessage('É necessário no mínimo uma região');
    }

    const deliveryRangesSchema = Yup.object().shape({
      maxRange: Yup.number()
        .transform(value =>
          Number.isNaN(Number(value)) ? undefined : Number(value),
        )
        .required('O raio não pode ser vazio')
        .typeError('Deve ser um número válido'),
      price: Yup.number(),
    });

    const neighborhoodSchema = Yup.object().shape({
      name: Yup.string().required('O nome não pode ser vazio'),
      price: Yup.number(),
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let validation: any = {
      price: Yup.number(),
    };

    if (type === AddressType.DISTANCE) {
      validation = {
        deliveryRanges: Yup.array(deliveryRangesSchema),
      };
    }

    if (type === AddressType.PLACE) {
      validation = {
        deliveryPlaces: Yup.array(neighborhoodSchema),
      };
    }

    const schema = Yup.object().shape(validation);

    try {
      await schema.validate(data, { abortEarly: false });
      saveAddress({
        ...data,
        deliveryRanges: data.deliveryRanges?.map(item => {
          return {
            ...item,
            maxRange: item.maxRange * 1000,
          };
        }),
        type,
      });

      const element = document.getElementById('formContainer');

      if (element) {
        element.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError && formRef.current) {
        formRef.current?.setErrors(getValidationErrors(err));
      }
    }
  }, [type, saveAddress]);

  const sortedPriceRange = useMemo(() => {
    return deliveryRanges.sort((current, next) => {
      if (current.id > next.id) {
        return -1;
      }

      return 1;
    });
  }, [deliveryRanges]);

  const sortedNamePlaces = useMemo(() => {
    return deliveryPlaces.sort((current, next) => {
      if (current.id > next.id) {
        return -1;
      }

      return 1;
    });
  }, [deliveryPlaces]);

  if (loading) {
    return (
      <Container>
        <Loading radius={32} />
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <h1>{selectedPage}</h1>
        {type === AddressType.PLACE && (
          <Search
            value={searchCriteria}
            onChange={handleOnSearchCriteriaChanged}
          />
        )}
      </Header>
      <Form onSubmit={() => null} ref={typeRef} initialData={{ type }}>
        <TypeContainer>
          <RadioContainer name="type" onChange={handleTypeChange}>
            <Radio.Option value={AddressType.STANDARD}>
              Taxa única
              <FiSquare size={20} className="unchecked" />
              <FiCheckSquare size={20} className="checked" />
            </Radio.Option>
            <Radio.Option value={AddressType.DISTANCE}>
              Taxa por distância
              <FiSquare size={20} className="unchecked" />
              <FiCheckSquare size={20} className="checked" />
            </Radio.Option>
            <Radio.Option value={AddressType.PLACE}>
              Taxa por bairro/região
              <FiSquare size={20} className="unchecked" />
              <FiCheckSquare size={20} className="checked" />
            </Radio.Option>
          </RadioContainer>
          {type !== AddressType.STANDARD && (
            <AddButton onClick={handleAdd}>
              <span>Adicionar</span>
              <FiPlus size={22} />
            </AddButton>
          )}
        </TypeContainer>
      </Form>
      <NamePriceContainer
        onSubmit={() => null}
        id="formContainer"
        className="has-custom-scroll-bar"
        ref={formRef}
        initialData={{ deliveryRanges, deliveryPlaces, price }}
      >
        <NumberInputForm
          name="price"
          title="Preço"
          hide={type !== AddressType.STANDARD}
        />
        {sortedPriceRange.map((item, index) => (
          <RangePriceInput
            name="deliveryRanges"
            title="Raio de entrega (em km)"
            key={item.id}
            index={index}
            active={item?.active}
            onDelete={() => handleDelete(item.id)}
            hide={type !== AddressType.DISTANCE}
            local={item?.local}
            onChangeActive={() =>
              changeRangeActiveStatus(item.id, item.active || false)
            }
          />
        ))}
        {type === AddressType.DISTANCE && deliveryRanges.length === 0 && (
          <EmptyMessage>Nenhuma taxa cadastrada.</EmptyMessage>
        )}
        {sortedNamePlaces.map((item, index) => (
          <NamePriceInput
            name="deliveryPlaces"
            title="Nome do bairro"
            key={item.id}
            index={index}
            onDelete={() => handleDelete(item.id)}
            hide={
              type !== AddressType.PLACE ||
              (!!searchCriteria &&
                searchedIds.indexOf(item.id.toString()) === -1)
            }
            id={item.id}
            local={item?.local}
            active={item?.active}
            onChangeActive={() =>
              changePlaceActiveStatus(item.id, item.active || false)
            }
          />
        ))}
        {type === AddressType.PLACE && deliveryPlaces.length === 0 && (
          <EmptyMessage>Nenhuma região cadastrada.</EmptyMessage>
        )}
        {!!errorMessage && (
          <ErrorMessage>
            <FiAlertCircle size={22} />
            {errorMessage}
          </ErrorMessage>
        )}
        <Placeholder />
      </NamePriceContainer>
      <SaveButton onClick={handleSubmit} loading={saving} />
    </Container>
  );
};

export default AddressPage;
