import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const Content = styled.main`
  height: 100%;
  width: 100%;
  padding-top: 32px;

  display: flex;
  flex-direction: column;

  background: #f4f4f6;
  position: relative;

  transition: all 0.2s;

  @media (max-width: 1270px) {
    width: 100vw;
  }
`;

export const Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  max-height: 100px;
  padding: 0 32px;
  padding-bottom: 0;
  margin-bottom: 16px;

  .right {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const SaveSkuButton = styled.button`
  display: flex;
  align-items: center;
  margin: 8px 32px;
  font-size: 32px;

  background: none;
  border: 0;

  padding-bottom: 8px;

  border-bottom: 2px solid #db1622;
  svg {
    margin-right: 8px;
  }

  div {
    margin-right: 16px;
    border-color: ${({ theme }) => theme.palette.text} !important;
  }

  @media (max-width: 650px) {
    svg,
    div {
      margin: 0;
    }

    div {
      border-color: ${({ theme }) => theme.palette.text_white} !important;
    }

    svg {
      stroke: ${({ theme }) => theme.palette.text_white};
    }
    span {
      display: none;
    }
    margin: none;
    position: fixed;
    justify-content: center;
    bottom: 2px;
    right: -16px;
    width: 48px;
    height: 48px;
    padding: 4px;
    border-radius: 32px;
    border: none;
    z-index: 10;
    background: #db1622;
    box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  }
`;

export const PageInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PageName = styled.strong`
  font-size: 32px;
  margin-left: 8px;
  white-space: nowrap;
  overflow: visible;
  max-width: 20%;

  @media (max-width: 1027px) {
    display: none;
  }
`;

export const PageDescription = styled.span`
  margin-left: 64px;
  color: #0008;
  margin-top: 9px;
  margin-right: 16px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Main = styled.main`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: start;
  justify-content: space-between;

  flex: 1 1 auto;
  overflow: auto;

  padding: 32px 16px;
  padding-top: 0;
  gap: 12px;

  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 654px) {
    padding-bottom: 82px;
  }

  @media (max-width: 480px) {
    padding-bottom: 160px;
  }
`;

export const NoProductsFound = styled.div`
  width: 100%;
  text-align: center;

  margin-top: 128px;
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
