import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  overflow: hidden;
`;

export const Content = styled.main`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  position: relative;
  background: ${({ theme }) => theme.palette.background};
`;

export const Header = styled.header`
  width: 100%;
  max-height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 32px;
  padding-top: 24px;
  padding-bottom: 0;
`;

export const PageInfo = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;

    @media (min-width: 1271px) {
      display: none;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PageName = styled.strong`
  font-size: 32px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const PageDescription = styled.span`
  margin-top: 9px;
  margin-left: 64px;
  margin-right: 16px;

  color: ${({ theme }) => theme.palette.black_transparency};

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Main = styled.div`
  height: 100%;

  padding: 0 24px;
  padding-top: 16px;
  padding-right: 0px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const NoRecentOrdersMessage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
`;

export const OrdersContainer = styled.div`
  width: 100%;
  height: calc(100vh - 155px);

  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  padding-right: 24px;
  padding-bottom: 16px;

  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 1270px) {
    height: calc(100vh - 152px);
  }

  @media (max-width: 1270px) {
    height: calc(100vh - 152px);
  }

  @media (max-width: 1025px) {
    height: calc(100vh - 145px);
  }

  @media (max-width: 480px) {
    padding-bottom: 80px;
  }
`;
