/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useContext, useCallback, createContext } from 'react';

import { useCompany } from './company';

import api from '../services/api';
import IOverview from '../models/IOverview';

export type EModule = 'MENU' | 'TICKET' | 'NONE';

interface OverviewContextData {
  module: EModule;
  overview: IOverview | null;
  isOverviewLoading: boolean;
  resetModule: () => void;
  chooseModule: (selectedModule: EModule) => void;
  getOverview: (startAt: string, endAt: string) => Promise<void>;
  getAllItems: (startAt: string, endAt: string) => Promise<void>;
}

const OverviewContext = createContext<OverviewContextData>(
  {} as OverviewContextData,
);

export const OverviewProvider: React.FC = ({ children }) => {
  const { company } = useCompany();

  const [data, setData] = useState<IOverview | null>(null);

  const [module, setModule] = useState<EModule>('MENU');

  const [isOverviewLoading, setIsOverviewLoading] = useState(false);

  const chooseModule = useCallback((selectedModule: EModule) => {
    setModule(selectedModule);
  }, []);

  const resetModule = useCallback(() => {
    setModule('NONE');
  }, []);

  const getOverview = useCallback(
    async (startAt: string, endAt: string) => {
      setIsOverviewLoading(true);

      const response = await api.get<IOverview>(
        `/restricted/orders/${company?.id}/overview`,
        {
          params: {
            endAt,
            module,
            startAt,
            limit: 10,
          },
        },
      );

      setIsOverviewLoading(false);

      setData(response.data);
    },
    [company, module],
  );

  const handleGetAllItems = useCallback(
    async (startAt: string, endAt: string) => {
      setIsOverviewLoading(true);

      const response = await api.get<IOverview>(
        `/restricted/orders/${company?.id}/overview`,
        {
          params: {
            endAt,
            module,
            startAt,
          },
        },
      );

      setData(
        old =>
          ({
            ...old,
            items: response?.data?.items || [],
            complements: response?.data?.complements || [],
          } as any),
      );
      setIsOverviewLoading(false);
    },
    [company, module],
  );

  return (
    <OverviewContext.Provider
      value={{
        overview: data,
        module,
        isOverviewLoading,
        getOverview,
        resetModule,
        chooseModule,
        getAllItems: handleGetAllItems,
      }}
    >
      {children}
    </OverviewContext.Provider>
  );
};

export function useOverview(): OverviewContextData {
  const context = useContext(OverviewContext);

  if (!context) {
    throw new Error('useOverview must be used within OverviewProvider');
  }

  return context;
}
