import React, { useRef } from 'react';
import { ControlledMenu, useMenuState } from '@szhsin/react-menu';

import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import { HiDotsVertical } from 'react-icons/hi';
import { FiTrash2 } from 'react-icons/fi';
import { stopPropagation } from '../../utils/html';

import { MenuButton, Item } from './styles';

interface PizzaCrustActionsMenuProps {
  enableEdges: boolean;
  setEnableEdges: (value: boolean) => void;
  onDelete: () => void;
}

const PizzaCrustActionsMenu: React.FC<PizzaCrustActionsMenuProps> = ({
  enableEdges,
  setEnableEdges,
  onDelete,
}) => {
  const { toggleMenu, ...menuProps } = useMenuState({ transition: true });
  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <MenuButton ref={anchorRef} onClick={stopPropagation(toggleMenu)}>
        <HiDotsVertical size={22} />
      </MenuButton>
      <ControlledMenu
        {...menuProps}
        anchorRef={anchorRef}
        direction="left"
        onMouseLeave={() => toggleMenu(false)}
      >
        <Item
          type="checkbox"
          checked={enableEdges}
          onClick={e => {
            e.syntheticEvent.stopPropagation();
            setEnableEdges(e?.checked || false);
          }}
        >
          {enableEdges ? <FaCheckCircle /> : <FaRegCircle />}
          <span>Permitir bordas</span>
        </Item>
        <Item
          className="warn"
          onClick={e => {
            e.syntheticEvent.stopPropagation();
            onDelete();
          }}
        >
          <FiTrash2 />
          <span>Excluir</span>
        </Item>
      </ControlledMenu>
    </>
  );
};

export default PizzaCrustActionsMenu;
