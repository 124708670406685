import React, { useCallback, useRef } from 'react';
import { FiX } from 'react-icons/fi';
import { Container, Input, InputContainer, Chip } from './styles';

interface ITagsInputProps {
  title: string;
  name: string;
  value: string[];
  onChange: (newValue: string[]) => void;
  placeholder: string;
}

const TagsInput: React.FC<ITagsInputProps> = ({
  title,
  name,
  placeholder,
  value,
  onChange,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (inputRef.current) {
        const inputValue = inputRef.current.value.trim();

        if (event.key === 'Enter') {
          event.preventDefault();
          inputRef.current.value = '';

          if (inputValue) {
            if (!value.includes(inputValue)) {
              return onChange([...value, inputValue]);
            }
          }
        }

        if (event.key === 'Backspace' && !inputValue && value?.length > 0) {
          event.preventDefault();
          onChange(value.slice(0, -1));
          inputRef.current.value = value[value.length - 1];
          return null;
        }
      }
      return null;
    },
    [value, onChange],
  );

  const handleBlur = useCallback(() => {
    if (inputRef.current) {
      const inputValue = inputRef.current.value.trim();

      if (inputValue) {
        if (!value.includes(inputValue)) {
          inputRef.current.value = '';
          return onChange([...value, inputValue]);
        }
      }
    }
    return null;
  }, [value, onChange]);

  const handleRemoveButtonClick = useCallback(
    (index: number) => {
      onChange(value.filter((_, itemIndex) => itemIndex !== index));
    },
    [value, onChange],
  );

  const handleChipMiddleClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    if (event.button === 1) {
      event.preventDefault();

      handleRemoveButtonClick(index);
    }
  };

  return (
    <Container>
      <label htmlFor={`tags-input-${name}`}>{title}</label>
      <InputContainer>
        {value.map((item, index) => (
          <Chip
            key={`${item}-${index.toString()}`}
            onMouseDown={event => handleChipMiddleClick(event, index)}
          >
            {item}
            <button
              type="button"
              onClick={() => handleRemoveButtonClick(index)}
              {...rest}
            >
              <FiX size={16} />
            </button>
          </Chip>
        ))}
        <Input
          ref={inputRef}
          type="text"
          name={name}
          placeholder={placeholder}
          id={`tags-input-${name}`}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          {...rest}
        />
      </InputContainer>
    </Container>
  );
};

export default TagsInput;
