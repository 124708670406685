import ICustomer, { CustomerRolesTypes } from '../models/IUser';

interface IRedirectRoutesFromRole {
  [route: string]: CustomerRolesTypes[];
}

export const allRoles: CustomerRolesTypes[] = [
  'Company',
  'Manager',
  'Marketing',
  'Employee',
];

const redirectPages: IRedirectRoutesFromRole = {
  '/': ['Company'],
  '/recent-orders': ['Manager', 'Employee'],
  // '/orders': [],
  // '/tables': [],
  '/categories': ['Marketing'],
  // '/products': [],
  // '/highlights': [],
  // '/complements': [],
  // '/discounts': [],
  // '/updatesku': [],
  // '/addresses': [],
  // '/scheduled-products': [],
  // '/messages': [],
  // '/operators': [],
  // '/devices': [],
  // '/social': [],
  // '/history': [],
  // '/theme': [],
  // '/settings': [],
  // '/edit-product': [],
};

export const verifyUserRole = (
  user: ICustomer | null,
  whiteList?: CustomerRolesTypes[],
  blackList?: CustomerRolesTypes[],
): boolean => {
  if (!user) {
    return false;
  }

  let allowedRoles: CustomerRolesTypes[] = blackList ? allRoles : ['Company'];

  if (whiteList) {
    allowedRoles = allowedRoles.concat(whiteList);
  }

  if (blackList) {
    allowedRoles = allowedRoles.filter(role => !blackList.includes(role));
  }

  return allowedRoles.some(role => user?.roles?.includes(role));
};

export const findRedirectRoute = (userRoles: CustomerRolesTypes[]): string => {
  let result = '';

  Object.keys(redirectPages).forEach(key => {
    const value = redirectPages[key];
    if (value.some(role => userRoles.includes(role))) {
      result = key;
    }
  });

  return result;
};
