import 'react-toggle/style.css';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Toggle from 'react-toggle';
import { Link, useHistory } from 'react-router-dom';
import * as clipboard from 'clipboard-polyfill/text';

import {
  FiMenu,
  FiLogOut,
  FiShare2,
  FiArrowLeft,
  FiChevronDown,
} from 'react-icons/fi';

import { AuthRole, PagesAccessProfiles, useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { useOrders } from '../../hooks/orders';
import { useCompany } from '../../hooks/company';
import { useSidebar } from '../../hooks/sidebar';

import avatar from '../../assets/avatar.png';
import { PageNames } from '../../enums/pages';
import { choosePageIcon } from '../../utils/icons';
import { chooseBsFoodOrVarejo } from '../../utils/subdomain';

import {
  Logo,
  Badge,
  Image,
  Pages,
  Header,
  Welcome,
  Content,
  PageName,
  Container,
  Indicator,
  InnerContent,
  HeaderBottom,
  IconContainer,
  SidebarHeader,
  ProductsGroup,
  SettingsGroup,
  ToggleContainer,
  ChildrenContainer,
  SwitchesContainer,
} from './styles';
import { SidebarTab } from '../SidebarTab';

export interface ISidebarRouteProps {
  isProductsActive: boolean;
  isSettingsActive: boolean;
}

const Sidebar: React.FC = ({ children }) => {
  const history = useHistory();

  const { addToast } = useToast();
  const { user, signOut } = useAuth();
  const { company, toggleIsOpen, toggleSendWhatsapp } = useCompany();
  const { visible, selectedPage, setVisible, toggleVisible } = useSidebar();

  const {
    recentOrders,
    pending: pendingOrders,
    connectToWebSocket,
  } = useOrders();

  const [isProductsActive, setIsProductsActive] = useState(true);

  const [isSettingsActive, setIsSettingsActive] = useState(false);

  useEffect(() => {
    if (company) {
      connectToWebSocket(company);
    }
  }, [connectToWebSocket, company]);

  useEffect(() => {
    const name = chooseBsFoodOrVarejo('Food', 'Varejo');
    if (recentOrders.length > 0) {
      document.title = `(${recentOrders.length}) BS ${name} Dashboard`;
    } else {
      document.title = `BS ${name} Dashboard`;
    }
  }, [recentOrders]);

  useEffect(() => {
    if ('Notification' in window) {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission();
      }
    }
  }, []);

  const handleNavigateTo = useCallback(
    (route: string) => {
      setVisible(false);
      history.push(route);
    },
    [setVisible, history],
  );

  const handleOnSignOutClick = useCallback(() => {
    signOut();
    const name = chooseBsFoodOrVarejo('Food', 'Varejo');
    document.title = `BS ${name} Dashboard`;
  }, [signOut]);

  const handleToggleCompanyOpen = useCallback(() => {
    toggleIsOpen();
  }, [toggleIsOpen]);

  const handleToggle = useCallback(() => {
    toggleVisible();
  }, [toggleVisible]);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleShare = useCallback(async () => {
    try {
      if (navigator.share) {
        const name = chooseBsFoodOrVarejo('Food', 'Varejo');
        await navigator.share({
          title: `${company?.tradingName} no BS ${name}`,
          text: 'Faça já o seu pedido!',
          url: `https://${company?.subdomain}.${chooseBsFoodOrVarejo(
            'bsfood',
            'bsvarejo',
          )}.com.br/`,
        });
      } else {
        await clipboard.writeText(
          `https://${company?.subdomain}.${chooseBsFoodOrVarejo(
            'bsfood',
            'bsvarejo',
          )}.com.br/`,
        );

        addToast({
          type: 'info',
          description: 'Copiado para àrea de transferência.',
        });
      }
    } catch {
      try {
        await clipboard.writeText(
          `https://${company?.subdomain}.${chooseBsFoodOrVarejo(
            'bsfood',
            'bsvarejo',
          )}.com.br/`,
        );

        addToast({
          type: 'info',
          description: 'Copiado para àrea de transferência.',
        });
      } catch {
        // eslint-disable-next-line no-console
        console.log('Sharing not available!');
      }
    }
  }, [addToast, company]);

  const handleToggleSendWhatsApp = useCallback(() => {
    toggleSendWhatsapp();
  }, [toggleSendWhatsapp]);

  const handleOnGroupClicked = useCallback(
    (group: PageNames.PRODUCTS | PageNames.SETTINGS) => {
      if (group === PageNames.PRODUCTS) {
        setIsProductsActive(prevState => !prevState);
      } else {
        setIsSettingsActive(prevState => !prevState);
      }
    },
    [],
  );

  const subPageProductIndicator = useMemo(() => {
    const pages = [
      PageNames.PRODUCTS,
      PageNames.STOCK,
      PageNames.HIGHLIGHTS,
      PageNames.COMPLEMENTS,
      PageNames.DISCOUNTS,
      PageNames.UPDATE_SKU,
    ];

    return {
      index: pages.indexOf(selectedPage),
      length: pages.length,
    };
  }, [selectedPage]);

  const subPagePreferencesIndicator = useMemo(() => {
    const pages = [
      PageNames.ADDRESSES,
      PageNames.SCHEDULED_PRODUCTS,
      PageNames.MESSAGES,
      PageNames.TEAM,
      PageNames.OPERATORS,
      PageNames.DEVICES,
      PageNames.SOCIAL,
      PageNames.HISTORY,
      PageNames.THEME,
      PageNames.SETTINGS,
    ];

    return {
      index: pages.indexOf(selectedPage),
      length: pages.length,
    };
  }, [selectedPage]);

  return (
    <Container visible={visible}>
      <Content className="content">
        <Header>
          <FiArrowLeft size={22} onClick={handleClose} />
        </Header>
        <Welcome className="welcome">
          <div>
            <Image>
              <img src={avatar} alt="" />
            </Image>
            <div>
              <span>seja bem-vindo,</span>
              <strong>{user?.name}</strong>
            </div>
          </div>
        </Welcome>
        <SwitchesContainer className="switches-container">
          <AuthRole whiteList={['Manager']}>
            <ToggleContainer>
              <Toggle
                className="custom-toggle"
                icons={false}
                onChange={handleToggleCompanyOpen}
                checked={company?.isOpen || false}
              />
              {company?.isOpen ? (
                <span>Empresa aberta</span>
              ) : (
                <span>Empresa fechada</span>
              )}
            </ToggleContainer>
          </AuthRole>
          <AuthRole>
            {company?.subscription?.plan?.sendWhatsApp && (
              <ToggleContainer>
                <Toggle
                  className="custom-toggle"
                  icons={false}
                  onChange={handleToggleSendWhatsApp}
                  checked={company?.sendWhatsapp || false}
                />
                <span>Envia WhatsApp</span>
              </ToggleContainer>
            )}
          </AuthRole>
        </SwitchesContainer>
        <InnerContent className="has-custom-scroll-bar-2">
          <Pages>
            <SidebarTab
              {...PagesAccessProfiles.DASHBOARD}
              selected={selectedPage === PageNames.DASHBOARD}
              onClick={() => handleNavigateTo('/')}
            >
              <div>
                <img
                  src={choosePageIcon(PageNames.DASHBOARD)}
                  alt="Dashboard"
                />
                <span>Dashboard</span>
              </div>
            </SidebarTab>
            {company?.subscription?.plan?.order && (
              <>
                <SidebarTab
                  {...PagesAccessProfiles.RECENT_ORDERS}
                  selected={selectedPage === PageNames.RECENT_ORDERS}
                  onClick={() => handleNavigateTo('/recent-orders')}
                  // hideIcon={recentOrders.length > 0}
                >
                  <div>
                    <img
                      src={choosePageIcon(PageNames.RECENT_ORDERS)}
                      alt="Pedidos novos"
                    />
                    <span>Pedidos novos</span>
                  </div>
                  {recentOrders.length > 0 && (
                    <Badge selected={selectedPage === PageNames.RECENT_ORDERS}>
                      {recentOrders.length}
                    </Badge>
                  )}
                </SidebarTab>
                <SidebarTab
                  {...PagesAccessProfiles.ORDERS}
                  selected={selectedPage === PageNames.ORDERS}
                  onClick={() => handleNavigateTo('/orders')}
                >
                  <div>
                    <img src={choosePageIcon(PageNames.ORDERS)} alt="Pedidos" />
                    <span>Pedidos</span>
                  </div>
                  {pendingOrders > 0 && (
                    <Badge selected={selectedPage === PageNames.ORDERS}>
                      {pendingOrders}
                    </Badge>
                  )}
                </SidebarTab>
              </>
            )}
            <SidebarTab
              {...PagesAccessProfiles.TABLES}
              selected={selectedPage === PageNames.TABLES}
              onClick={() => handleNavigateTo('/tables')}
            >
              <div>
                <img src={choosePageIcon(PageNames.TABLES)} alt="Mesas" />
                <span>Mesas</span>
              </div>
            </SidebarTab>
            <SidebarTab
              {...PagesAccessProfiles.CASHIER_REPORT}
              selected={selectedPage === PageNames.CASHIER_REPORT}
              onClick={() => handleNavigateTo('/cashier-report')}
            >
              <div>
                <img
                  src={choosePageIcon(PageNames.CASHIER_REPORT)}
                  alt="Relatórios de caixa"
                />
                <span>Relatórios de caixa</span>
              </div>
            </SidebarTab>
            <SidebarTab
              {...PagesAccessProfiles.CATEGORIES}
              selected={selectedPage === PageNames.CATEGORIES}
              onClick={() => handleNavigateTo('/categories')}
            >
              <div>
                <img
                  src={choosePageIcon(PageNames.CATEGORIES)}
                  alt="Categorias"
                />
                <span>Categorias</span>
              </div>
            </SidebarTab>
            <SidebarTab
              {...PagesAccessProfiles.PRODUCTS}
              selected={
                selectedPage === PageNames.PRODUCTS ||
                selectedPage === PageNames.STOCK ||
                selectedPage === PageNames.HIGHLIGHTS ||
                selectedPage === PageNames.COMPLEMENTS ||
                selectedPage === PageNames.UPDATE_SKU ||
                selectedPage === PageNames.DISCOUNTS
              }
              onClick={() => handleOnGroupClicked(PageNames.PRODUCTS)}
            >
              <div>
                <img src={choosePageIcon(PageNames.PRODUCTS)} alt="Products" />
                <span>{chooseBsFoodOrVarejo('Cardápio', 'Catálogo')}</span>
              </div>
              <IconContainer selected={isProductsActive}>
                <FiChevronDown
                  size={24}
                  style={{
                    marginRight:
                      selectedPage === PageNames.PRODUCTS ||
                      selectedPage === PageNames.COMPLEMENTS
                        ? 0
                        : -4,
                  }}
                />
              </IconContainer>
            </SidebarTab>
            <ProductsGroup
              visible={isProductsActive}
              {...subPageProductIndicator}
            >
              <Indicator className="indicator" />
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/products')}
                {...PagesAccessProfiles.PRODUCTS}
              >
                <span>Produtos</span>
              </SidebarTab>
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/stock')}
                {...PagesAccessProfiles.STOCK}
              >
                <span>Estoque</span>
              </SidebarTab>
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/highlights')}
                {...PagesAccessProfiles.HIGHLIGHTS}
              >
                <span>Destaques</span>
              </SidebarTab>
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/complements')}
                {...PagesAccessProfiles.COMPLEMENTS}
              >
                <span>Complementos</span>
              </SidebarTab>
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/discounts')}
                {...PagesAccessProfiles.DISCOUNTS}
              >
                <span>Cupons de desconto</span>
              </SidebarTab>
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/updatesku')}
                {...PagesAccessProfiles.UPDATE_SKU}
              >
                <span>Alterar código de integração</span>
              </SidebarTab>
            </ProductsGroup>
            <SidebarTab
              {...PagesAccessProfiles.SETTINGS}
              selected={
                selectedPage === PageNames.ADDRESSES ||
                selectedPage === PageNames.SCHEDULED_PRODUCTS ||
                selectedPage === PageNames.MESSAGES ||
                selectedPage === PageNames.OPERATORS ||
                selectedPage === PageNames.DEVICES ||
                selectedPage === PageNames.SETTINGS ||
                selectedPage === PageNames.TEAM ||
                selectedPage === PageNames.SOCIAL ||
                selectedPage === PageNames.HISTORY ||
                selectedPage === PageNames.THEME
              }
              onClick={() => handleOnGroupClicked(PageNames.SETTINGS)}
            >
              <div>
                <img
                  src={choosePageIcon(PageNames.SETTINGS)}
                  alt="Configurações"
                />
                <span>Configurações</span>
              </div>
              <IconContainer selected={isSettingsActive}>
                <FiChevronDown
                  size={24}
                  style={{
                    marginRight:
                      selectedPage === PageNames.ADDRESSES ||
                      selectedPage === PageNames.SCHEDULED_PRODUCTS ||
                      selectedPage === PageNames.MESSAGES ||
                      selectedPage === PageNames.SETTINGS ||
                      selectedPage === PageNames.TEAM ||
                      selectedPage === PageNames.OPERATORS ||
                      selectedPage === PageNames.DEVICES
                        ? 0
                        : -4,
                  }}
                />
              </IconContainer>
            </SidebarTab>
            <SettingsGroup
              visible={isSettingsActive}
              {...subPagePreferencesIndicator}
            >
              <Indicator className="indicator" />
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/addresses')}
                {...PagesAccessProfiles.ADDRESSES}
              >
                <span>Taxas de Entrega</span>
              </SidebarTab>
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/scheduled-products')}
                {...PagesAccessProfiles.SCHEDULED_PRODUCTS}
              >
                <span>Produtos agendados</span>
              </SidebarTab>
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/messages')}
                {...PagesAccessProfiles.MESSAGES}
              >
                <span>Mensagens</span>
              </SidebarTab>
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/team')}
                {...PagesAccessProfiles.TEAM}
              >
                <span>Equipe</span>
              </SidebarTab>
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/operators')}
                {...PagesAccessProfiles.OPERATORS}
              >
                <span>Operadores</span>
              </SidebarTab>
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/devices')}
                {...PagesAccessProfiles.DEVICES}
              >
                <span>Dispositivos</span>
              </SidebarTab>
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/social')}
                {...PagesAccessProfiles.SOCIAL}
              >
                <span>Links</span>
              </SidebarTab>
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/history')}
                {...PagesAccessProfiles.HISTORY}
              >
                <span>História</span>
              </SidebarTab>
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/theme')}
                {...PagesAccessProfiles.THEME}
              >
                <span>Tema</span>
              </SidebarTab>
              <SidebarTab
                isSubPageTab
                onClick={() => handleNavigateTo('/settings')}
                {...PagesAccessProfiles.SETTINGS}
              >
                <span>Preferências</span>
              </SidebarTab>
            </SettingsGroup>
          </Pages>
        </InnerContent>
        <HeaderBottom id="share-icon">
          <FiShare2 size={22} onClick={handleShare} />
          <FiLogOut size={22} onClick={handleOnSignOutClick} />
          <div className="privacy-policy">
            <Link to="/privacy-policy">Política de Privacidade</Link>
          </div>
        </HeaderBottom>
      </Content>
      <ChildrenContainer className="children">
        <SidebarHeader>
          <FiMenu size={28} onClick={handleToggle} />
          <PageName>{selectedPage}</PageName>
          <SwitchesContainer className="switches-container">
            <AuthRole whiteList={['Manager']}>
              <ToggleContainer>
                <Toggle
                  icons={false}
                  className="custom-toggle"
                  onChange={handleToggleCompanyOpen}
                  checked={company?.isOpen || false}
                />
                {company?.isOpen ? (
                  <span>Empresa aberta</span>
                ) : (
                  <span>Empresa fechada</span>
                )}
              </ToggleContainer>
            </AuthRole>
            <AuthRole>
              {company?.subscription?.plan?.sendWhatsApp && (
                <ToggleContainer>
                  <Toggle
                    icons={false}
                    className="custom-toggle"
                    onChange={handleToggleSendWhatsApp}
                    checked={company?.sendWhatsapp || false}
                  />
                  <span>Envia WhatsApp</span>
                </ToggleContainer>
              )}
            </AuthRole>
          </SwitchesContainer>
          <Logo />
        </SidebarHeader>
        <div className="children-content">{children}</div>
      </ChildrenContainer>
    </Container>
  );
};

export default Sidebar;
