import { ICouponArtTemplate } from '../../models/ICouponArt';
import { amigo_variants } from './amigo';
import { ano_novo_variants } from './ano_novo';
import { avos_variants } from './avos';
import { beijo_variants } from './beijo';
import { black_friday_variants } from './black_friday';
import { carnaval_variants } from './carnaval';
import { cliente_variants } from './cliente';
import { comsumidor_variants } from './comsumidor';
import { copa_variants } from './copa';
import { crianca_variants } from './crianca';
import { cyber_monday_variants } from './cyber_monday';
import { halloween_variants } from './halloween';
import { hamburguer_variants } from './hamburguer';
import { independencia_variants } from './independencia';
import { julina_variants } from './julina';
import { junina_variants } from './junina';
import { maes_variants } from './maes';
import { mentira_variants } from './mentira';
import { mulher_variants } from './mulher';
import { namorados_variants } from './namorados';
import { natal_variants } from './natal';
import { pais_variants } from './pais';
import { pascoa_variants } from './pascoa';
import { pizza_variants } from './pizza';
import { sao_joao_variants } from './sao_joao';
import { tiradentes_variants } from './tiradentes';
import { trabalho_variants } from './trabalho';
import { valentines_variants } from './valentines';

function getDateFromMMdd(date: string) {
  const tempDate = new Date();
  const [MM, dd] = date.split('/');

  tempDate.setHours(0);
  tempDate.setMinutes(0);
  tempDate.setSeconds(0);
  tempDate.setMonth(Number(MM) - 1);
  tempDate.setDate(Number(dd));

  return tempDate;
}

const today = new Date();
today.setHours(0);
today.setMinutes(0);
today.setSeconds(0);

const templates: ICouponArtTemplate[] = [
  {
    id: 1,
    name: 'Dia do Amigo',
    variants: amigo_variants,
    date: '07/30',
    thumb: '/couponArts/amigo/thumb.webp',
  },
  {
    id: 2,
    name: 'Ano Novo',
    variants: ano_novo_variants,
    date: '12/31',
    thumb: '/couponArts/ano_novo/thumb.webp',
  },
  {
    id: 3,
    name: 'Dia dos Avós',
    variants: avos_variants,
    date: '08/26',
    thumb: '/couponArts/avos/thumb.webp',
  },
  {
    id: 4,
    name: 'Dia do Beijo',
    variants: beijo_variants,
    date: '04/13',
    thumb: '/couponArts/beijo/thumb.webp',
  },
  {
    id: 5,
    name: 'Black Friday',
    variants: black_friday_variants,
    date: '11/25',
    thumb: '/couponArts/black_friday/thumb.webp',
  },
  {
    id: 6,
    name: 'Carnaval',
    variants: carnaval_variants,
    date: '02/20',
    thumb: '/couponArts/carnaval/thumb.webp',
  },
  {
    id: 7,
    name: 'Dia do Cliente',
    variants: cliente_variants,
    date: '09/15',
    thumb: '/couponArts/cliente/thumb.webp',
  },
  {
    id: 8,
    name: 'Dia do Comsumidor',
    variants: comsumidor_variants,
    date: '03/15',
    thumb: '/couponArts/comsumidor/thumb.webp',
  },
  {
    id: 9,
    name: 'Copa do Mundo',
    variants: copa_variants,
    date: '11/20',
    thumb: '/couponArts/copa/thumb.webp',
  },
  {
    id: 10,
    name: 'Cyber Monday',
    variants: cyber_monday_variants,
    date: '11/28',
    thumb: '/couponArts/cyber_monday/thumb.webp',
  },
  {
    id: 11,
    name: 'Dia das Bruxas (Halloween)',
    variants: halloween_variants,
    date: '10/31',
    thumb: '/couponArts/halloween/thumb.webp',
  },
  {
    id: 12,
    name: 'Dia do Hambúrguer',
    variants: hamburguer_variants,
    date: '05/28',
    thumb: '/couponArts/hamburguer/thumb.webp',
  },
  {
    id: 13,
    name: 'Dia da Independência',
    variants: independencia_variants,
    date: '09/07',
    thumb: '/couponArts/independencia/thumb.webp',
  },
  {
    id: 14,
    name: 'Festa Julina',
    variants: julina_variants,
    date: '07/01',
    thumb: '/couponArts/julina/thumb.webp',
  },
  {
    id: 15,
    name: 'Festa Junina',
    variants: junina_variants,
    date: '06/01',
    thumb: '/couponArts/junina/thumb.webp',
  },
  {
    id: 16,
    name: 'Dia das Mães',
    variants: maes_variants,
    date: '05/14',
    thumb: '/couponArts/maes/thumb.webp',
  },
  {
    id: 17,
    name: 'Dia da Mentira',
    variants: mentira_variants,
    date: '04/01',
    thumb: '/couponArts/mentira/thumb.webp',
  },
  {
    id: 18,
    name: 'Dia Internacional das Mulheres',
    variants: mulher_variants,
    date: '03/08',
    thumb: '/couponArts/mulher/thumb.webp',
  },
  {
    id: 19,
    name: 'Dia dos Namorados',
    variants: namorados_variants,
    date: '06/12',
    thumb: '/couponArts/namorados/thumb.webp',
  },
  {
    id: 20,
    name: 'Dia dos Pais',
    variants: pais_variants,
    date: '08/13',
    thumb: '/couponArts/pais/thumb.webp',
  },
  {
    id: 21,
    name: 'Páscoa',
    variants: pascoa_variants,
    date: '04/09',
    thumb: '/couponArts/pascoa/thumb.webp',
  },
  {
    id: 22,
    name: 'Dia da Pizza',
    variants: pizza_variants,
    date: '07/10',
    thumb: '/couponArts/pizza/thumb.webp',
  },
  {
    id: 23,
    name: 'Dia de São João',
    variants: sao_joao_variants,
    date: '06/24',
    thumb: '/couponArts/sao_joao/thumb.webp',
  },
  {
    id: 24,
    name: 'Tiradentes',
    variants: tiradentes_variants,
    date: '04/21',
    thumb: '/couponArts/tiradentes/thumb.webp',
  },
  {
    id: 25,
    name: 'Dia do Trabalho',
    variants: trabalho_variants,
    date: '05/01',
    thumb: '/couponArts/trabalho/thumb.webp',
  },
  {
    id: 26,
    name: 'Valentines Day',
    variants: valentines_variants,
    date: '02/14',
    thumb: '/couponArts/valentines/thumb.webp',
  },
  {
    id: 27,
    name: 'Natal',
    variants: natal_variants,
    date: '12/25',
    thumb: '/couponArts/natal/thumb.webp',
  },
  {
    id: 28,
    name: 'Dia das Crianças',
    variants: crianca_variants,
    date: '10/12',
    thumb: '/couponArts/crianca/thumb.webp',
  },
];

// const datesArray = templates.map(template => getDateFromMMdd(template.date));

export const couponArtTemplates = templates.sort((a, b) => {
  const aDate = getDateFromMMdd(a.date);
  const bDate = getDateFromMMdd(b.date);

  return aDate.valueOf() - bDate.valueOf();
});
