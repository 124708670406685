import React, { createContext, useCallback, useContext, useState } from 'react';
import api from '../services/api';

import ISaveTeammateDTO from '../dtos/ISaveTeammateDTO';
import { ITeammate, CustomerRolesTypes } from '../models/IUser';

interface TeamContextData {
  team: ITeammate[];
  isTeamLoading: boolean;
  getTeam: () => Promise<void>;
  createTeammate: (
    user: Pick<ITeammate, 'name' | 'email' | 'roles'>,
  ) => Promise<void>;
  revokeTeammate: (email: string) => Promise<void>;
  updtateTeammateRoles: (
    email: string,
    roles: CustomerRolesTypes[],
  ) => Promise<void>;
}

const TeamContext = createContext<TeamContextData>({} as TeamContextData);

export const TeamProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<ISaveTeammateDTO[]>([]);

  const [isTeamLoading, setIsTeamLoading] = useState(false);

  const getTeam = useCallback(async () => {
    setIsTeamLoading(true);

    const response = await api.get('/restricted/dashboard-users');

    setData(response.data);

    setIsTeamLoading(false);
  }, []);

  const createTeammate = useCallback(
    async (teammate: Pick<ITeammate, 'name' | 'email' | 'roles'>) => {
      setIsTeamLoading(true);

      const response = await api.post<ISaveTeammateDTO>(
        '/restricted/dashboard-users',
        { ...teammate, roles: undefined, role: teammate.roles[0] },
      );

      setData(old => [...old, response.data]);

      setIsTeamLoading(false);
    },
    [],
  );

  const revokeTeammate = useCallback(async (email: string) => {
    setIsTeamLoading(true);

    await api.patch('/restricted/dashboard-users/revoke', { email });

    setData(old => old.filter(teammate => teammate.email !== email));

    setIsTeamLoading(false);
  }, []);

  const updtateTeammateRoles = useCallback(
    async (email: string, roles: CustomerRolesTypes[]) => {
      setIsTeamLoading(true);

      const response = await api.patch('/restricted/dashboard-users/role', {
        email,
        role: roles[0],
      });

      setData(old =>
        old.map(teammate =>
          teammate.email === email ? response.data : teammate,
        ),
      );

      setIsTeamLoading(false);
    },
    [],
  );

  return (
    <TeamContext.Provider
      value={{
        team: data,
        isTeamLoading,
        getTeam,
        createTeammate,
        revokeTeammate,
        updtateTeammateRoles,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export function useTeam(): TeamContextData {
  const context = useContext(TeamContext);

  if (!context) {
    throw new Error('useTeam must be used within TeamProvider');
  }

  return context;
}
