import styled, { css } from 'styled-components';

interface IFilterProps {
  selected: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  overflow: hidden;
`;

export const Content = styled.main`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  position: relative;
  background: ${({ theme }) => theme.palette.background};
`;

export const Header = styled.header`
  width: 100%;
  max-height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 24px;
  padding-top: 24px;
  padding-bottom: 0;

  .filter {
    cursor: pointer;
    margin-left: 16px;

    @media (min-width: 800px) {
      display: none;
    }
  }
`;

export const PageInfo = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;

    @media (min-width: 1271px) {
      display: none;
    }
  }
`;

export const PageName = styled.strong`
  font-size: 32px;
  margin-left: 8px;

  @media (max-width: 1027px) {
    display: none;
  }
`;

export const PageDescription = styled.span`
  margin-left: 64px;
  color: #0008;
  margin-top: 9px;

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Main = styled.div`
  height: 100%;

  padding: 0 24px;
  padding-right: 0;
  padding-top: 16px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const NoOrdersMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 18px;
  padding: 32px 0;
  margin-top: 120px;
`;

export const NoOrdersToLoadMessage = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 64px;

  span {
    text-align: center;
  }
`;

export const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 24px;

  @media (min-width: 800px) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.control};
  }
`;

export const Filters = styled.div`
  width: 100%;

  display: flex;

  span + span {
    margin-left: 32px;
  }

  @media (max-width: 950px) {
    font-size: 14px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Filter = styled.span<IFilterProps>`
  padding: 6px;
  padding-bottom: 16px;

  cursor: pointer;
  user-select: none;

  ${({ selected }) =>
    selected &&
    css`
      border-bottom: 3px solid ${({ theme }) => theme.palette.primary};
    `}

  &:hover {
    ${({ selected }) =>
      !selected &&
      css`
        border-bottom: 1px solid #1b1c1e;
      `};
  }
`;

interface ISelectContainerProps {
  width: number;
}

export const SelectsContainer = styled.div`
  display: flex;
  gap: 8px;
  width: min-content;
`;

export const SelectContainer = styled.div<ISelectContainerProps>`
  margin-left: auto;
  width: ${({ width }) => width}px;
`;

export const OrdersContainer = styled.div`
  width: 100%;
  height: calc(100vh - 190px);

  display: flex;
  flex-direction: column;
  align-content: flex-start;

  overflow-y: hidden;
  padding-bottom: 16px;

  margin-top: 8px;

  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 1025px) {
    height: calc(100vh - 180px);
  }

  @media (max-width: 1025px) {
    height: calc(100vh - 180px);
  }

  @media (max-width: 800px) {
    height: calc(100vh - 132px);
  }
`;
