import React, { useCallback } from 'react';

import { FiTrash, FiEdit } from 'react-icons/fi';
import { IProductGroup } from '../../models/IProductGroups';

import { useProducts } from '../../hooks/products';

import {
  GridContainer,
  GridContent,
  ListContainer,
  ButtonsContainer,
  Button,
} from './styles';
import { AuthRole } from '../../hooks/auth';

interface IPizzaGroupProps {
  group: IProductGroup;
  display: 'GRID' | 'LIST';
  onEdit: (group: IProductGroup) => void;
  onDelete: (id: number) => void;
}

const PizzaGroup: React.FC<IPizzaGroupProps> = ({
  group,
  display,
  onEdit,
  onDelete,
}) => {
  const { products } = useProducts();

  const getGroupItemsCount = useCallback(
    (groupId: number) => {
      const count = products.filter(
        product => product?.productGroup?.id === groupId,
      ).length;

      const itemCount = count > 1 ? `${count} itens` : `${count} item`;

      return itemCount;
    },
    [products],
  );

  const handleOnEditGroup = useCallback(
    (e: React.MouseEvent<SVGElement>) => {
      e.stopPropagation();
      onEdit(group);
    },
    [group, onEdit],
  );

  const handleOnDeleteGroup = useCallback(
    (e: React.MouseEvent<SVGElement>) => {
      e.stopPropagation();
      onDelete(group.id);
    },
    [group, onDelete],
  );

  if (display === 'GRID') {
    return (
      <GridContainer>
        <GridContent>
          <strong>{group.name}</strong>
          <span>{getGroupItemsCount(group.id)}</span>
        </GridContent>

        <AuthRole blackList={['Employee']}>
          <ButtonsContainer>
            <Button title="Editar">
              <FiEdit onClick={handleOnEditGroup} size={27} />
            </Button>

            <Button title="Excluir">
              <FiTrash onClick={handleOnDeleteGroup} size={27} />
            </Button>
          </ButtonsContainer>
        </AuthRole>
      </GridContainer>
    );
  }

  return (
    <ListContainer>
      <strong>{group.name}</strong>

      <span>{getGroupItemsCount(group.id)}</span>

      <ButtonsContainer>
        <Button title="Editar">
          <FiEdit onClick={handleOnEditGroup} size={27} />
        </Button>

        <Button title="Excluir">
          <FiTrash onClick={handleOnDeleteGroup} size={27} />
        </Button>
      </ButtonsContainer>
    </ListContainer>
  );
};

export default PizzaGroup;
