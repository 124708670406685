import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import IOrder from '../../models/IOrder';

import { useOrders } from '../../hooks/orders';
import { useTables } from '../../hooks/tables';
import { useCompany } from '../../hooks/company';
import { useSidebar } from '../../hooks/sidebar';

import Table from '../../components/Table';
import Search from '../../components/Search';
import LoadingAnimation from '../../components/LoadingAnimation';
import OrderSummaryModal from '../../components/OrderSummaryModal';
import TableSummaryModal from '../../components/TableSummaryModal';

import { PageNames } from '../../enums/pages';

import {
  Main,
  Header,
  Padding,
  Content,
  PageInfo,
  PageName,
  Container,
  FlexPlaceholder,
  CreateTableTutorial,
} from './styles';

const TablesPage: React.FC = () => {
  const { company } = useCompany();
  const { setSelectedPage, selectedPage } = useSidebar();
  const { setSelectedOrder, selectedOrder } = useOrders();
  const { loadTables, unselectTable, tables, isTablesLoading } = useTables();

  const [filter, setFilter] = useState('');

  const [isOrderSummaryModalVisible, setIsOrderSummaryModalVisible] = useState(
    false,
  );

  useEffect(() => {
    async function loadData() {
      await loadTables();
    }

    if (company) {
      loadData();
    }
  }, [loadTables, company]);

  const handleOnFilterChanged = useCallback((text: string) => {
    setFilter(text);
  }, []);

  const handleOnOrderSummaryModalClose = useCallback(() => {
    setIsOrderSummaryModalVisible(false);
    setSelectedOrder(null);
  }, [setSelectedOrder]);

  const handleOnOrderClick = useCallback(
    (order: IOrder) => {
      unselectTable();
      setSelectedOrder(order);
      setIsOrderSummaryModalVisible(true);
    },
    [setSelectedOrder, unselectTable],
  );

  const filteredTables = useMemo(() => {
    const filtered = tables.filter(table =>
      `Mesa ${table.tableNumber}`.includes(filter),
    );

    if (filtered.length > 0) {
      return filtered;
    }

    return tables;
  }, [tables, filter]);

  useEffect(() => {
    setSelectedPage(PageNames.TABLES);
  }, [setSelectedPage]);

  return (
    <Container>
      <Content>
        <Header>
          <PageInfo>
            <PageName>{selectedPage}</PageName>
          </PageInfo>
          <Search value={filter} onChange={handleOnFilterChanged} />
        </Header>
        {isTablesLoading ? (
          <LoadingAnimation />
        ) : (
          <Main className="has-custom-scroll-bar-2">
            {filteredTables.length > 0 ? (
              <>
                {filteredTables.map(table => (
                  <Table table={table} key={table.tableNumber} />
                ))}
                <FlexPlaceholder />
                <FlexPlaceholder />
                <FlexPlaceholder />
                <FlexPlaceholder />
                <FlexPlaceholder />
                <Padding />
              </>
            ) : (
              <CreateTableTutorial>
                <span>
                  Para adicionar mesas ao seu estabelecimento, faça o seguinte
                  procedimento:
                </span>
                <ol>
                  <li>
                    <span>Vá até </span>
                    <Link to="/settings#tables">configurações</Link>
                    <span>;</span>
                  </li>
                  <li>
                    <span>Vá até a seção de pagamento e entrega;</span>
                  </li>
                  <li>
                    <span>
                      No campo &quot;Quantidade de mesas&quot; insira a
                      quantidade de mesas que seu estabelecimento possui;
                    </span>
                  </li>
                  <li>
                    <span>Salve as alterações;</span>
                  </li>
                  <li>
                    <span>Pronto! Suas mesas estão prontas para uso.</span>
                  </li>
                </ol>
              </CreateTableTutorial>
            )}
          </Main>
        )}
        <OrderSummaryModal
          order={selectedOrder}
          visible={isOrderSummaryModalVisible}
          onClose={handleOnOrderSummaryModalClose}
        />
      </Content>
      <TableSummaryModal onOrderClicked={handleOnOrderClick} />
    </Container>
  );
};

export default TablesPage;
