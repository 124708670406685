import React from 'react';

import { FiEye, FiEyeOff, FiTrash2 } from 'react-icons/fi';

import { Scope } from '@unform/core';

import Input from '../Input';
import { NumberInputForm } from '../NumberInput';

import {
  ActiveContainer,
  Container,
  DeleteContainer,
  IconContainer,
} from './styles';

import Checkbox from '../FormlessCheckbox';
import HiddenInput from '../HiddenInput';

interface INumberPriceInputProps {
  index: number;
  name: string;
  title: string;
  hide?: boolean;
  active?: boolean;
  local?: boolean;
  id?: string;
  onChangeActive?: (value?: boolean) => void;
  onDelete: () => void;
}

const NumberPriceInput: React.FC<INumberPriceInputProps> = ({
  id,
  name,
  hide,
  local,
  index,
  title,
  active,
  onDelete,
  onChangeActive,
}) => {
  return (
    <Scope path={`${name}[${index}]`}>
      <Container {...(hide && { style: { display: 'none' } })} active={active}>
        <Input title={title} name="name" bottom />
        <HiddenInput name="id" value={id} />
        <NumberInputForm title="Preço" name="price" />
        <IconContainer>
          {!local && (
            <ActiveContainer>
              <Checkbox
                name="active"
                checkedElement={<FiEye />}
                uncheckedElement={<FiEyeOff />}
                value={active}
                onChange={onChangeActive}
              />
            </ActiveContainer>
          )}
          <DeleteContainer onClick={onDelete}>
            <FiTrash2 />
          </DeleteContainer>
        </IconContainer>
      </Container>
    </Scope>
  );
};

export const RangePriceInput: React.FC<INumberPriceInputProps> = ({
  index,
  onDelete,
  name,
  title,
  hide,
  active,
  local,
  onChangeActive,
}) => {
  return (
    <Scope path={`${name}[${index}]`}>
      <Container {...(hide && { style: { display: 'none' } })} active={active}>
        <Input title={title} name="maxRange" type="number" bottom />
        <NumberInputForm title="Preço" name="price" />
        <IconContainer>
          {!local && (
            <ActiveContainer>
              <Checkbox
                name="active"
                checkedElement={<FiEye />}
                uncheckedElement={<FiEyeOff />}
                onChange={onChangeActive}
                value={active}
              />
            </ActiveContainer>
          )}
          <DeleteContainer onClick={onDelete}>
            <FiTrash2 />
          </DeleteContainer>
        </IconContainer>
      </Container>
    </Scope>
  );
};

export default NumberPriceInput;
