import { normalizeString } from './string';

export const search = <T>(
  data: T[],
  transformer: (item: T) => string,
  searchTerm: string,
): T[] => {
  if (!searchTerm) {
    return data;
  }

  const normalizedTerm = normalizeString(searchTerm);

  return data.filter(item => {
    const searchString = normalizeString(transformer(item));
    return searchString.includes(normalizedTerm);
  });
};
