/* eslint-disable react/jsx-wrap-multilines */
import React, { useRef } from 'react';
import { FiPrinter, FiX } from 'react-icons/fi';
import { useReactToPrint } from 'react-to-print';
import {
  CloseButton,
  Header,
  LeftHeader,
  PrintButton,
  ScrollContainer,
} from '../styles';
import { IPeriodCashierReport } from '../../../models/ICashierReport';
import {
  Items,
  DaySummary,
  GridHeader,
  Payment,
  Divider,
  Title,
  GridFooter,
  GridDivider,
  Container,
  Devices,
  DayRight,
  DeviceIconContainer,
} from './styles';
import { formatToLocalDate, formatToMoney } from '../../../utils/format';
import { getPaymentTypeName } from '../../../utils/string';

import posIcon from '../../../assets/icons/device.svg';
import totemIcon from '../../../assets/icons/totem.svg';

interface IPeriodCashierReportProps {
  report: IPeriodCashierReport;
  onClose: () => void;
}

interface ICommonSectionProps {
  full?: boolean;
}

interface IItemsSectionProps
  extends ICommonSectionProps,
    Pick<IPeriodCashierReport['summary'], 'items'> {
  itemsAmountTotal: number;
  itemsQtyTotal: number;
  header: React.ReactNode;
}

interface IPaymentSectionProps
  extends Pick<IPeriodCashierReport['summary'], 'payments'>,
    ICommonSectionProps {
  paymentTotal: number;
  header: React.ReactNode;
}

interface IDevicesSectionProps
  extends Pick<IPeriodCashierReport['summary'], 'devices'>,
    ICommonSectionProps {
  devicesTotal: number;
  header: React.ReactNode;
}

const ItemsSection: React.FC<IItemsSectionProps> = ({
  items,
  itemsQtyTotal,
  itemsAmountTotal,
  header,
  full,
}) => {
  return (
    <Items className={full ? 'full' : ''}>
      {header}

      {items.map(item => (
        <React.Fragment key={item.id}>
          <span>{item.title}</span>
          <span className="center">{item.qty}</span>
          <span className="end">{formatToMoney(item.amount)}</span>
        </React.Fragment>
      ))}

      <GridDivider />
      <GridFooter>Totais</GridFooter>
      <GridFooter className="center">{itemsQtyTotal}</GridFooter>
      <GridFooter className="end">{formatToMoney(itemsAmountTotal)}</GridFooter>
    </Items>
  );
};

const PaymentSection: React.FC<IPaymentSectionProps> = ({
  payments,
  paymentTotal,
  header,
  full,
}) => {
  return (
    <Payment style={full ? { marginTop: '1rem', width: '100%' } : {}}>
      {header}

      {payments.map(payment => (
        <React.Fragment key={payment.id}>
          <span>{getPaymentTypeName(payment.name)}</span>
          <span className="end">{formatToMoney(payment.amount)}</span>
        </React.Fragment>
      ))}

      <GridDivider />
      <GridFooter>Total</GridFooter>
      <GridFooter className="end">{formatToMoney(paymentTotal)}</GridFooter>
    </Payment>
  );
};

const DevicesSection: React.FC<IDevicesSectionProps> = ({
  devices,
  devicesTotal,
  header,
  full,
}) => {
  return devices.length <= 0 ? null : (
    <Devices style={full ? { marginTop: '1rem', width: '100%' } : {}}>
      {header}

      {devices.map(device => (
        <React.Fragment key={device.id.toString()}>
          <DeviceIconContainer>
            {device.type === 'KIOSK' ? (
              <img src={totemIcon} alt="ícone KIOSK" />
            ) : (
              <img src={posIcon} alt="ícone POS" />
            )}
          </DeviceIconContainer>
          <span className="name">{device.name}</span>
          <span className="end">{formatToMoney(device.amount)}</span>
        </React.Fragment>
      ))}

      <GridDivider />
      <GridFooter style={{ gridColumn: '1 / 3' }}>Total</GridFooter>
      <GridFooter className="end">{formatToMoney(devicesTotal)}</GridFooter>
    </Devices>
  );
};

const ReportContent: React.FC<Pick<IPeriodCashierReportProps, 'report'>> = ({
  report,
}) => {
  const summaryItemsQtyTotal = report.summary.items.reduce(
    (acc, current) => current.qty + acc,
    0,
  );

  const summaryDevicesTotalAmount = report.summary.devices.reduce(
    (acc, current) => current.amount + acc,
    0,
  );

  const summaryTitle =
    report.days.length === 1
      ? formatToLocalDate(report.days[0]?.ordersCreatedAt)
      : `De ${formatToLocalDate(
          report.days[0]?.ordersCreatedAt,
        )} a ${formatToLocalDate(
          report.days[report.days.length - 1]?.ordersCreatedAt,
        )}`;

  return (
    <>
      {report.days.map((day, dayIndex) => {
        const itemsQtyTotal = day.items.reduce(
          (acc, current) => current.qty + acc,
          0,
        );

        const paymentTotal = day.payments.reduce(
          (acc, current) => current.amount + acc,
          0,
        );

        const devicesTotal = day.devices.reduce(
          (acc, current) => current.amount + acc,
          0,
        );

        return (
          <React.Fragment key={day.ordersCreatedAt}>
            <Title>{`${formatToLocalDate(day.ordersCreatedAt)}:`}</Title>

            <DaySummary>
              <ItemsSection
                items={day.items}
                itemsQtyTotal={itemsQtyTotal}
                itemsAmountTotal={day.itemsAmountTotal}
                header={
                  <>
                    <GridHeader>Produto</GridHeader>
                    <GridHeader className="center">Quantidade</GridHeader>
                    <GridHeader className="end">Total</GridHeader>
                  </>
                }
              />

              <DayRight>
                <PaymentSection
                  payments={day.payments}
                  paymentTotal={paymentTotal}
                  header={
                    <>
                      <GridHeader>Tipo de pagamento</GridHeader>
                      <GridHeader className="end">Total</GridHeader>
                    </>
                  }
                />

                <DevicesSection
                  devices={day.devices}
                  devicesTotal={devicesTotal}
                  header={
                    <>
                      <GridHeader className="fill-2">Dispositivo</GridHeader>
                      <GridHeader className="end">Total</GridHeader>
                    </>
                  }
                />
              </DayRight>
            </DaySummary>
            {dayIndex !== report.days.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}

      <Divider />
      <Title>Listagem de produtos (Geral)</Title>

      <ItemsSection
        items={report.summary.items}
        itemsQtyTotal={summaryItemsQtyTotal}
        itemsAmountTotal={report.summary.itemsAmountTotal}
        full
        header={
          <>
            <GridHeader className="fill-3 center">{summaryTitle}</GridHeader>
            <GridHeader>Produto</GridHeader>
            <GridHeader className="center">Quantidade</GridHeader>
            <GridHeader className="end">Total</GridHeader>
          </>
        }
      />

      <PaymentSection
        payments={report.summary.payments}
        paymentTotal={report.summary.itemsAmountTotal}
        full
        header={
          <>
            <GridHeader className="fill-2 center">Total geral</GridHeader>
            <GridHeader>Tipo de pagamento</GridHeader>
            <GridHeader className="end">Total</GridHeader>
          </>
        }
      />

      <DevicesSection
        full
        devices={report.summary.devices}
        devicesTotal={summaryDevicesTotalAmount}
        header={
          <>
            <GridHeader className="fill-3 center">Dispositivos</GridHeader>
            <GridHeader className="fill-2">Dispositivo</GridHeader>
            <GridHeader className="end">Total</GridHeader>
          </>
        }
      />
    </>
  );
};

const PeriodCashierReport: React.FC<IPeriodCashierReportProps> = ({
  report,
  onClose,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => containerRef.current,
  });

  const isEmpty = (report?.days?.length || 0) === 0;

  return (
    <ScrollContainer>
      <Container ref={containerRef}>
        <Header disableMb={isEmpty}>
          <LeftHeader>
            <CloseButton onClick={onClose}>
              <FiX size={32} />
            </CloseButton>
            {isEmpty ? (
              <h3>Nenhum relatório encontrado!</h3>
            ) : (
              <h2>Fechamento diário de caixa</h2>
            )}
          </LeftHeader>

          {!isEmpty && (
            <PrintButton type="button" onClick={handlePrint}>
              <FiPrinter size={22} />
            </PrintButton>
          )}
        </Header>

        {!isEmpty && <ReportContent report={report} />}
      </Container>
    </ScrollContainer>
  );
};
export default PeriodCashierReport;
