import React, { useCallback, useMemo, useState } from 'react';

import {
  Droppable,
  Draggable,
  DropResult,
  DragDropContext,
} from 'react-beautiful-dnd';

import { CgMenuGridO } from 'react-icons/cg';
import { HiOutlinePencil } from 'react-icons/hi';
import { FiMinus, FiPlus, FiTrash2 } from 'react-icons/fi';

import { useProducts } from '../../hooks/products';

import ProductsSelectorModal from '../ProductsSelectorModal';

import {
  Header,
  AddButton,
  Container,
  InfoWrapper,
  LeftContainer,
  Suggestion as ComboItem,
  EditSuggestion,
  SuggestionTitle,
  AddButtonWrapper,
  NoSuggestionsMessage as NoComboItemsMessage,
} from '../SuggestionsModal/styles';

import { ECategoryType } from '../../enums/categoryType';
import {
  ActionsContainer,
  QtyAddButton,
  QtyContainer,
  QtyInput,
  QtyRemoveButton,
  RemoveEditContainer,
} from './styles';

export interface ComboItemProduct {
  productId: number;
  qty: number;
  title: string;
}

interface ComboItemsModalProps {
  comboItems: ComboItemProduct[] | null;
  editingProductId?: number;
  onChange: (comboItems?: ComboItemProduct[] | null) => void;
}

const ComboItemsModal: React.FC<ComboItemsModalProps> = ({
  comboItems,
  editingProductId,
  onChange,
}) => {
  const [editingComboItem, setEditingComboItem] = useState<number | null>(null);

  const [modalOpen, setModalOpen] = useState(false);

  const { products } = useProducts();

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
    setEditingComboItem(null);
  }, []);

  const reorderSuggestions = useCallback(
    (list, startIndex: number, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    },
    [],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination) {
        const newComboItems = reorderSuggestions(
          comboItems,
          result.source.index,
          result?.destination?.index,
        );

        onChange(newComboItems as ComboItemProduct[]);
      }
    },
    [reorderSuggestions, comboItems, onChange],
  );

  const handleAddClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleDeleteClick = useCallback(
    (id: number) => {
      onChange(comboItems?.filter(item => item.productId !== id));
    },
    [onChange, comboItems],
  );

  const handleEditClick = useCallback((id: number) => {
    setEditingComboItem(id);
    setModalOpen(true);
  }, []);

  const handleQtyChange = useCallback(
    (id: number, qty: number) => {
      onChange(
        comboItems?.map(item => {
          if (item.productId === id) {
            return {
              ...item,
              qty,
            };
          }
          return item;
        }),
      );
    },
    [comboItems, onChange],
  );

  const handleModalSelect = useCallback(
    (id?: number) => {
      if (editingComboItem) {
        onChange(
          comboItems &&
            comboItems.map(item => {
              if (item.productId === editingComboItem) {
                const newProduct = products.find(product => product.id === id);

                return {
                  title: newProduct?.title || item.title,
                  productId: newProduct?.id || item.productId,
                  qty: item.qty,
                };
              }
              return item;
            }),
        );
        setEditingComboItem(null);
      } else {
        const newProduct = products.find(product => product.id === id);

        if (newProduct && comboItems) {
          onChange([
            ...comboItems,
            {
              title: newProduct.title,
              productId: newProduct.id,
              qty: 1,
            },
          ]);
        }
      }
      setModalOpen(false);
    },
    [editingComboItem, products, comboItems, onChange],
  );

  const modalProducts = useMemo(() => {
    if (products) {
      return products?.filter(
        product =>
          product.id !== editingProductId &&
          !product?.isCombo &&
          !comboItems?.some(item => item.productId === product.id) &&
          product?.categories?.every(c => c?.type !== ECategoryType.PIZZA),
      );
    }
    return [];
  }, [comboItems, editingProductId, products]);

  return (
    <Container>
      <ProductsSelectorModal
        open={modalOpen}
        products={modalProducts}
        onClose={handleModalClose}
        onSelect={handleModalSelect}
      />
      <Header>Itens do combo</Header>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="comboItems" direction="vertical">
          {providedDroppable => (
            <div
              {...providedDroppable.droppableProps}
              ref={providedDroppable.innerRef}
            >
              {comboItems?.map((item, index) => (
                <Draggable
                  key={item.productId}
                  draggableId={item.productId.toString()}
                  index={index}
                  isDragDisabled
                >
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ComboItem>
                        <InfoWrapper className="wrapper">
                          <LeftContainer>
                            <CgMenuGridO size={26} />
                            <SuggestionTitle>{item.title}</SuggestionTitle>
                          </LeftContainer>

                          <ActionsContainer>
                            <QtyContainer>
                              <QtyAddButton
                                onClick={() =>
                                  handleQtyChange(item.productId, item.qty - 1)
                                }
                              >
                                <FiMinus size={18} />
                              </QtyAddButton>
                              <QtyInput
                                type="number"
                                value={item.qty}
                                onChange={e =>
                                  handleQtyChange(
                                    item.productId,
                                    Number(e.target.value),
                                  )
                                }
                              />
                              <QtyRemoveButton
                                onClick={() =>
                                  handleQtyChange(item.productId, item.qty + 1)
                                }
                              >
                                <FiPlus size={18} />
                              </QtyRemoveButton>
                            </QtyContainer>

                            <RemoveEditContainer>
                              <EditSuggestion
                                onClick={() =>
                                  handleDeleteClick(item.productId)
                                }
                              >
                                <FiTrash2 size={26} />
                              </EditSuggestion>
                              <EditSuggestion
                                onClick={() => handleEditClick(item.productId)}
                              >
                                <HiOutlinePencil size={26} />
                              </EditSuggestion>
                            </RemoveEditContainer>
                          </ActionsContainer>
                        </InfoWrapper>
                      </ComboItem>
                    </div>
                  )}
                </Draggable>
              ))}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {comboItems && comboItems?.length === 0 && (
        <NoComboItemsMessage>
          Não há itens cadastrados. Clique no + para começar.
        </NoComboItemsMessage>
      )}

      <AddButtonWrapper>
        <AddButton onClick={handleAddClick}>
          <FiPlus size={26} />
        </AddButton>
      </AddButtonWrapper>
    </Container>
  );
};

export default ComboItemsModal;
