import styled from 'styled-components';
import Modal from 'react-modal';

import { appearFromBottom } from '../../styles/animations';

export const StyledModal = styled(Modal)`
  animation: ${appearFromBottom} 0.5s;
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.background};
  padding: 1rem;
  width: min(800px, 90vw);
  border-radius: 4px;
`;

export const Label = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;

export const RulesContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const RuleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.8rem;
  background: ${({ theme }) => theme.palette.background_white};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};
  gap: 1.5rem;
  border-radius: 4px;
  margin-bottom: 1rem;

  > div {
    flex: 1;
  }

  span {
    font-size: 0.8rem;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  text-align: center;
  flex: 0.5;
  padding: 0.5rem;
  font-size: 0.8rem;
  background: 0;
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.palette.control};
`;

export const AddButton = styled.button`
  align-self: center;
  padding: 0;
  background: none;
  border: none;
  svg {
    color: ${({ theme }) => theme.palette.control};
  }
`;

export const RemoveButton = styled(AddButton)`
  align-self: center;
  padding: 0;
  background: ${({ theme }) => theme.palette.primary};
  border: none;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};

  svg {
    margin-bottom: -3px;
    color: ${({ theme }) => theme.palette.text_white};
  }
`;

export const Footer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  gap: 1rem;
`;

export const CancelButton = styled.button`
  padding: 0.5rem;
  border: solid 2px ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.primary};
  font-size: 1rem;
  font-weight: 500;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConfirmButton = styled(CancelButton)`
  border: none;
  background: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.text_white};
`;
