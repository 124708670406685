/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { FiCheck } from 'react-icons/fi';
import { AuthRole } from '../../hooks/auth';

import ICardBrand from '../../models/ICardBrand';
import { chooseBrandIcon } from '../../utils/icons';

import { Container, Checkbox, CheckboxContainer } from './styles';

interface ICardBrandProps {
  brand: ICardBrand;
  onChange: (id: number, type: 'LOCAL' | 'DELIVERY') => void;
}

const CardBrand: React.FC<ICardBrandProps> = ({ brand, onChange }) => {
  return (
    <Container>
      <CheckboxContainer>
        <AuthRole blackList={['Employee']} disableOnly>
          <Checkbox htmlFor={`${brand.paymentCardId}-local`}>
            <span>Local</span>
            <input
              type="checkbox"
              checked={brand.local}
              id={`${brand.paymentCardId}-local`}
              onChange={() => onChange(brand.paymentCardId, 'LOCAL')}
            />
            {brand.local && <FiCheck size={28} />}
          </Checkbox>
        </AuthRole>
        <AuthRole blackList={['Employee']} disableOnly>
          <Checkbox htmlFor={`${brand.paymentCardId}-delivery`}>
            <span>Delivery</span>
            <input
              type="checkbox"
              checked={brand.delivery}
              id={`${brand.paymentCardId}-delivery`}
              onChange={() => onChange(brand.paymentCardId, 'DELIVERY')}
            />
            {brand.delivery && <FiCheck size={28} />}
          </Checkbox>
        </AuthRole>
      </CheckboxContainer>
      <span>{brand.description}</span>
      <img src={chooseBrandIcon(brand.description)} alt="" />
    </Container>
  );
};

export default CardBrand;
