import React from 'react';

import {
  Title,
  Buttons,
  Message,
  NoButton,
  YesButton,
  Container,
  StyledModal,
} from './styles';

interface IConfirmActionDialog {
  title: string;
  message: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

StyledModal.setAppElement('#root');

const ConfirmActionDialog: React.FC<IConfirmActionDialog> = ({
  title,
  isOpen,
  message,
  onClose,
  onConfirm,
}) => {
  return (
    <StyledModal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      isOpen={isOpen}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        },
      }}
    >
      <Container>
        <Title>{title}</Title>
        <Message>{message}</Message>
        <Buttons>
          <YesButton onClick={onConfirm}>SIM</YesButton>
          <NoButton onClick={onClose}>NÃO</NoButton>
        </Buttons>
      </Container>
    </StyledModal>
  );
};

export default ConfirmActionDialog;
