import React, { useCallback, useEffect, useState } from 'react';
import { FiPlusCircle, FiTrash } from 'react-icons/fi';
import { IStockCheck, IStockCheckProduct } from '../../models/IStock';
import SummaryModal from '../SummaryModal';
import ButtonSm from '../ButtonSm';
import {
  AddProductButton,
  Info,
  InfoWrapper,
  Label,
  LeftContainer,
  Line,
  Product,
  ProductInfo,
  ProductsContainer,
  ProductsList,
  RemoveButton,
  Title,
} from './styles';
import ProductsSelectorModal from '../ProductsSelectorModal';
import { useProducts } from '../../hooks/products';
import FormlessInput from '../FormlessInput';
import { useConfirmDialog } from '../../hooks/confim_dialog';

interface StockCheckSummaryModal {
  isVisible: boolean;
  stockCheck?: IStockCheck;
  onSave: (stockCheck: IStockCheck) => void;
  onClose: () => void;
}

enum EOnSelectProductActions {
  CREATE = 0,
  SCROLL = 1,
  DONT_SCROLL = 2,
}

function scrollToProduct(id: number) {
  const domId = `stock-check-product-${id}`;

  const element = document.getElementById(domId);

  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    element.classList.add('focus-animation');

    setTimeout(() => {
      element.classList.remove('focus-animation');
    }, 2000);
  }
}

const StockCheckSummaryModal: React.FC<StockCheckSummaryModal> = ({
  isVisible,
  stockCheck,
  onSave,
  onClose,
}) => {
  const { products, loadProducts } = useProducts();
  const { showConfirmDialog } = useConfirmDialog();

  const [stockCheckProducts, setStockCheckProducts] = useState<
    IStockCheckProduct[]
  >([]);

  const [isSelectProductModalOpen, setIsSelectProductModalOpen] = useState(
    false,
  );

  const handleOnCancelSummaryModal = useCallback(() => {
    setStockCheckProducts([]);
    onClose();
  }, [onClose]);

  const verifyProductAlreadyAdded = useCallback(
    (stockCheckProductId: number) => {
      const stockCheckProduct = stockCheckProducts.find(
        product => product.id === stockCheckProductId,
      );

      if (stockCheckProduct) {
        return new Promise<EOnSelectProductActions>(resolve => {
          showConfirmDialog({
            title: 'Produto já lançado',
            message: `O produto "${stockCheckProduct.title}" já foi lançado com a quantidade de ${stockCheckProduct.qty}.\nDeseja ir até ele?`,
            onCancel: () => {
              resolve(EOnSelectProductActions.DONT_SCROLL);
            },
            onConfirm: () => {
              resolve(EOnSelectProductActions.SCROLL);
            },
          });
        });
      }

      return EOnSelectProductActions.CREATE;
    },
    [showConfirmDialog, stockCheckProducts],
  );

  const handleOnStockCheckProductQtyChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, stockCheckProductId: number) => {
      const { value } = e.target;

      setStockCheckProducts(old =>
        old.map(product => {
          if (product.id === stockCheckProductId) {
            return {
              ...product,
              qty: Number(value),
            };
          }

          return product;
        }),
      );
    },
    [],
  );

  const handleOnSelectProductModalClose = useCallback(() => {
    setIsSelectProductModalOpen(false);
  }, []);

  const handleOnSelectProduct = useCallback(
    async (id?: number) => {
      const newProduct = products.find(product => product.id === id);

      if (newProduct) {
        const action = await verifyProductAlreadyAdded(newProduct.id);

        if (action === EOnSelectProductActions.CREATE) {
          const formattedNewProduct: IStockCheckProduct = {
            id: newProduct.id,
            title: newProduct?.title,
            availableStock: newProduct.availableStock,
            qty: newProduct.availableStock,
          };

          setStockCheckProducts(old => [formattedNewProduct, ...old]);

          scrollToProduct(formattedNewProduct.id);
        }

        if (action === EOnSelectProductActions.SCROLL) {
          scrollToProduct(newProduct.id);
        }
      }

      setIsSelectProductModalOpen(false);
    },
    [products, verifyProductAlreadyAdded],
  );

  const handleOnRemoveProduct = useCallback(
    (stockCheckProductId: number) => {
      const newStockCheckProducts = [...stockCheckProducts];

      const filteredStockCheckProducts = newStockCheckProducts.filter(
        product => product.id !== stockCheckProductId,
      );

      setStockCheckProducts(filteredStockCheckProducts);
    },
    [stockCheckProducts],
  );

  useEffect(() => {
    async function loadData() {
      await loadProducts();
    }

    loadData();

    if (stockCheck?.products) {
      setStockCheckProducts(stockCheck.products);
    }
  }, [loadProducts, stockCheck]);

  return (
    <SummaryModal
      isVisible={isVisible}
      title={stockCheck?.title || ''}
      onQuit={handleOnCancelSummaryModal}
      actionComponents={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <>
          {(stockCheck?.products || []).length > 0 && (
            <ButtonSm
              buttonStyle="filled"
              onClick={() => (stockCheck ? onSave(stockCheck) : null)}
            >
              Salvar
            </ButtonSm>
          )}
          <ButtonSm buttonStyle="outline" onClick={handleOnCancelSummaryModal}>
            Cancelar
          </ButtonSm>
        </>
      }
    >
      <ProductsContainer className="has-custom-scroll-bar-2">
        <AddProductButton
          type="button"
          onClick={() => setIsSelectProductModalOpen(true)}
        >
          <Line />
          <FiPlusCircle />
          <Line />
        </AddProductButton>
        <ProductsList>
          {stockCheckProducts?.map(product => (
            <Product key={product.id} id={`stock-check-product-${product.id}`}>
              <LeftContainer>
                <RemoveButton
                  type="button"
                  onClick={() => handleOnRemoveProduct(product.id)}
                >
                  <FiTrash size={20} />
                </RemoveButton>
                <Title>{product.title}</Title>
              </LeftContainer>
              <ProductInfo>
                <InfoWrapper>
                  <Label>Novo estoque</Label>
                  <FormlessInput
                    placeholder="Quantidade"
                    name="stock_check_product_qty"
                    value={product.qty}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleOnStockCheckProductQtyChanged(e, product.id)
                    }
                    type="number"
                    min={0}
                    style={{ padding: '0', width: '100%' }}
                  />
                </InfoWrapper>
                <InfoWrapper>
                  <Label>Estoque atual</Label>
                  <Info>{product.availableStock}</Info>
                </InfoWrapper>
              </ProductInfo>
            </Product>
          ))}
        </ProductsList>
      </ProductsContainer>

      <ProductsSelectorModal
        products={products}
        open={isSelectProductModalOpen}
        onClose={handleOnSelectProductModalClose}
        onSelect={handleOnSelectProduct}
      />
    </SummaryModal>
  );
};

export default StockCheckSummaryModal;
