import React from 'react';

import { Container, DispositionContainer, DispositionText } from './styles';

export type EDisposition =
  | 'DELIVERY'
  | 'TABLE'
  | 'CHECKOUT'
  | 'TICKET'
  | 'SELFCHECKOUT'
  | 'DRIVE_THRU';

interface IDispositionProps {
  disabled?: boolean;
  dispositionTypes: EDisposition[];
  onDispositionClicked(disposition: string): void;
}

const Disposition: React.FC<IDispositionProps> = ({
  disabled,
  dispositionTypes,
  onDispositionClicked,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <h3>Exibido em:</h3>
      <DispositionContainer>
        <DispositionText
          onClick={() => onDispositionClicked('DELIVERY')}
          selected={dispositionTypes.includes('DELIVERY')}
          disabled={disabled}
        >
          Delivery
        </DispositionText>
        <DispositionText
          onClick={() => onDispositionClicked('TABLE')}
          selected={dispositionTypes.includes('TABLE')}
          disabled={disabled}
        >
          Mesa
        </DispositionText>
        <DispositionText
          onClick={() => onDispositionClicked('CHECKOUT')}
          selected={dispositionTypes.includes('CHECKOUT')}
          disabled={disabled}
        >
          Retira
        </DispositionText>
        <DispositionText
          onClick={() => onDispositionClicked('TICKET')}
          selected={dispositionTypes.includes('TICKET')}
          disabled={disabled}
        >
          BS Ticket
        </DispositionText>
        <DispositionText
          onClick={() => onDispositionClicked('SELFCHECKOUT')}
          selected={dispositionTypes.includes('SELFCHECKOUT')}
          disabled={disabled}
        >
          BS Autoatendimento
        </DispositionText>
        <DispositionText
          onClick={() => onDispositionClicked('DRIVE_THRU')}
          selected={dispositionTypes.includes('DRIVE_THRU')}
          disabled={disabled}
        >
          Drive thru
        </DispositionText>
      </DispositionContainer>
    </Container>
  );
};

export default Disposition;
