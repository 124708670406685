import React, {
  useState,
  useContext,
  useCallback,
  createContext,
  useEffect,
} from 'react';

import { useCompany } from './company';

import api from '../services/api';
import IDiscountCoupon from '../models/IDiscountCoupon';
import ISaveDiscountCouponDTO from '../dtos/ISaveDiscountCouponDTO';

interface ILoadingController {
  get: boolean;
  post: boolean;
}

export type FilterTypes = 'ACTIVE' | 'CANCELED';

interface DiscountsCouponsContextData {
  filter: FilterTypes;
  coupons: IDiscountCoupon[];
  loadingController: ILoadingController;
  selectedCoupon: IDiscountCoupon | null;
  loadCoupons: () => Promise<void>;
  setFilter: (filter: FilterTypes) => void;
  deleteCoupon: (id: number) => Promise<void>;
  selectCoupon: (coupon: IDiscountCoupon) => void;
  saveCoupon: (request: ISaveDiscountCouponDTO) => Promise<void>;
}

const DiscountsCouponsContext = createContext<DiscountsCouponsContextData>(
  {} as DiscountsCouponsContextData,
);

export const DiscountsCouponsProvider: React.FC = ({ children }) => {
  const { company } = useCompany();

  const [coupons, setCoupons] = useState<IDiscountCoupon[]>([]);

  const [loadingController, setLoadingController] = useState<
    ILoadingController
  >({
    get: false,
    post: false,
  });

  const [selectedCoupon, setSelectedCoupon] = useState<IDiscountCoupon | null>(
    null,
  );

  const [filter, setFilter] = useState<FilterTypes>('ACTIVE');

  const selectCoupon = useCallback((coupon: IDiscountCoupon) => {
    setSelectedCoupon(coupon);
  }, []);

  const loadCoupons = useCallback(
    async (currentFilter: FilterTypes = 'ACTIVE') => {
      setLoadingController(prevState => {
        return {
          get: true,
          post: prevState.post,
        };
      });

      const response = await api.get(
        `restricted/discounts-coupons/${company?.id}${
          currentFilter ? `?active=${currentFilter === 'ACTIVE'}` : ''
        }`,
      );

      setCoupons(response.data);

      setLoadingController(prevState => {
        return {
          get: false,
          post: prevState.post,
        };
      });
    },
    [company],
  );

  const saveCoupon = useCallback(
    async (data: ISaveDiscountCouponDTO) => {
      setLoadingController(prevState => {
        return {
          get: prevState.get,
          post: true,
        };
      });

      try {
        const response = await api.post<IDiscountCoupon>(
          `/restricted/discounts-coupons/${company?.id}`,
          {
            ...data,
            companyId: company?.id,
          },
        );

        setLoadingController(prevState => {
          return {
            get: prevState.get,
            post: false,
          };
        });

        setCoupons(prevState => {
          return [...prevState, response.data];
        });
      } catch (err) {
        setLoadingController(prevState => {
          return {
            get: prevState.get,
            post: false,
          };
        });

        throw err;
      }
    },
    [company],
  );

  const deleteCoupon = useCallback(
    async (id: number) => {
      await api.delete(`restricted/discounts-coupons/${company?.id}/${id}`);

      setCoupons(prevState => {
        return prevState.filter(c => c.id !== id);
      });
    },
    [company],
  );

  const handleChangeFilter = useCallback((f: FilterTypes) => {
    setFilter(f);
  }, []);

  useEffect(() => {
    if (company?.id) {
      loadCoupons(filter);
    }
  }, [filter, company, loadCoupons]);

  return (
    <DiscountsCouponsContext.Provider
      value={{
        filter,
        coupons,
        selectedCoupon,
        loadingController,
        saveCoupon,
        loadCoupons,
        deleteCoupon,
        selectCoupon,
        setFilter: handleChangeFilter,
      }}
    >
      {children}
    </DiscountsCouponsContext.Provider>
  );
};

export function useDiscountsCoupons(): DiscountsCouponsContextData {
  const context = useContext(DiscountsCouponsContext);

  if (!context) {
    throw new Error(
      'useDiscountsCoupons must be used within DiscountsCouponsProvider',
    );
  }

  return context;
}
