import React, { useMemo } from 'react';

import { FiCheck, FiX } from 'react-icons/fi';

import IDevice from '../../models/IDevice';

import posPlaceholder from '../../assets/icons/device.svg';
import totemPlaceholder from '../../assets/icons/totem.svg';

import {
  Left,
  Image,
  Container,
  DeviceInfo,
  DeviceName,
  DeviceLogin,
  ImageContainer,
  ActivateDeviceButton,
} from './styles';
import { stopPropagation } from '../../utils/html';

interface IDeviceProps {
  device: IDevice;
  onClick: () => void;
  onActivate: () => void;
}

const Device: React.FC<IDeviceProps> = ({ device, onClick, onActivate }) => {
  const normalizedDeviceId = useMemo(() => {
    if (device.deviceId.length > 27) {
      return `${device.deviceId.substring(0, 25)}...`;
    }
    return device.deviceId;
  }, [device]);

  return (
    <Container onClick={onClick}>
      <Left>
        <ImageContainer>
          <Image
            src={
              device?.deviceType === 'POS' ? posPlaceholder : totemPlaceholder
            }
          />
        </ImageContainer>
        <DeviceInfo>
          <DeviceName>{device.name}</DeviceName>
          <DeviceLogin>{normalizedDeviceId}</DeviceLogin>
        </DeviceInfo>
      </Left>
      <ActivateDeviceButton
        active={device.status === 'ACTIVE'}
        onClick={stopPropagation(onActivate)}
      >
        {device.status === 'ACTIVE' ? (
          <>
            <FiCheck />
            <span>Ativo</span>
          </>
        ) : (
          <>
            <FiX />
            <span>Inativo</span>
          </>
        )}
      </ActivateDeviceButton>
    </Container>
  );
};

export default Device;
