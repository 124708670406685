import ReactModal from 'react-modal';
import styled from 'styled-components';

import { appearFromBottom } from '../../styles/animations';

export const StyledModal = styled(ReactModal)`
  animation: ${appearFromBottom} 0.5s;
`;

export const Container = styled.div`
  max-height: 90vh;
  width: min(100vw, 600px);

  overflow: hidden;
  padding: 32px;

  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};
  background-color: ${({ theme }) => theme.palette.background_white};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 16px;
`;

export const ModalTitle = styled.h3``;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 4px;

  border: 0;
  background: none;
`;

export const ButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  margin-top: 24px;
`;

export const CancelButton = styled.button`
  flex: 1;

  padding: 5px;

  background: none;
  color: ${({ theme }) => theme.palette.primary};
  border: 1px solid ${({ theme }) => theme.palette.primary};
`;

export const SaveButton = styled.button`
  flex: 1;
  height: 37px;
  margin-left: 16px;
  padding: 6px 18px;

  border: 0;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};
`;
