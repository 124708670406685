import styled from 'styled-components';
import { BsTicketStockCheckStatusType } from '../../enums/bsticket';

interface StatusProps {
  status: BsTicketStockCheckStatusType;
}

export const Container = styled.div<StatusProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  padding: 16px;
  gap: 16px;
  background-color: ${({ theme }) => theme.palette.background_white};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_secondary};
  cursor: ${({ status }) => (status === 'OPEN' ? 'pointer' : 'default')};
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;

export const BottomContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const HeaderContainer = styled.div``;

export const BodyContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 48px;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  #cancel-button {
    background-color: ${({ theme }) => theme.palette.error_light};
    color: ${({ theme }) => theme.palette.error};
  }

  @media (max-width: 368px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const Button = styled.button`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0px 16px;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.success_light};
  color: ${({ theme }) => theme.palette.success};

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Label = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.control};
`;

export const Info = styled.span``;

export const StockCheckId = styled.strong``;

export const Title = styled.strong``;

export const Status = styled.span<StatusProps>`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  background-color: ${({ theme, status }) =>
    status === 'CANCELED'
      ? theme.palette.status_canceled_transparency
      : status === 'DONE'
      ? theme.palette.status_approved_transparency
      : theme.palette.status_in_prepare_transparency};

  color: ${({ theme, status }) =>
    status === 'CANCELED'
      ? theme.palette.status_canceled
      : status === 'DONE'
      ? theme.palette.status_approved
      : theme.palette.status_in_prepare};
`;
