import styled from 'styled-components';
import { MovementStockTypes } from '../../models/IStock';
import { mergeColors } from '../../utils/colors';

interface IMovementStyleProps {
  transactionType?: MovementStockTypes;
}

export const Table = styled.table`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background_white};
  border-radius: 8px;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  background-color: ${({ theme }) => theme.palette.background_white};
  position: sticky;
  top: -32px;
`;

export const TableBody = styled.tbody`
  overflow-y: auto;
`;

export const Label = styled.th`
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  padding: 16px;
`;

export const Row = styled.tr<IMovementStyleProps>`
  #reason {
    font-weight: 500;
  }

  &:hover {
    background-color: ${({ transactionType, theme }) =>
      transactionType === 'INCREASE'
        ? mergeColors(
            theme.palette.success_light,
            theme.palette.background_white,
            0.8,
          )
        : transactionType === 'DECREASE'
        ? mergeColors(
            theme.palette.error_light,
            theme.palette.background_white,
            0.8,
          )
        : 'none'};
  }

  &:last-child {
    td:first-child {
      border-bottom-left-radius: 8px;
    }

    td:last-child {
      border-bottom-right-radius: 8px;
    }

    th:first-child {
      border-top-left-radius: 8px;
    }

    th:last-child {
      border-top-right-radius: 8px;
    }
  }
`;

export const Value = styled.td`
  padding: 8px 16px;
  text-align: left;
`;
