export enum BsTicketPrintCodeType {
  BARCODE = 'BARCODE',
  QRCODE = 'QRCODE',
  NONE = 'NONE',
}

export enum EBsTicketStockCheckStatusType {
  DONE = 'Concluída',
  OPEN = 'Aberta',
  CANCELED = 'Cancelada',
}

export type BsTicketStockCheckStatusType = 'DONE' | 'OPEN' | 'CANCELED';
