import React from 'react';

import { FiAlertCircle } from 'react-icons/fi';

import {
  Dialog,
  Buttons,
  Container,
  CancelButton,
  ConfirmButton,
} from './styles';

interface IProps {
  title: string;
  open?: boolean;
  message?: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmDialog: React.FC<IProps> = ({
  open,
  title,
  message,
  onCancel,
  onConfirm,
}) => (
  <Container open={open} onClick={onCancel}>
    <Dialog>
      <span>
        <FiAlertCircle size={32} />
        <h2>{title}</h2>
      </span>
      {message && <span className="confirm-message">{message}</span>}
      <Buttons>
        <ConfirmButton onClick={onConfirm}>Ok</ConfirmButton>
        <CancelButton onClick={onCancel}>Cancelar</CancelButton>
      </Buttons>
    </Dialog>
  </Container>
);

export default ConfirmDialog;
