import styled from 'styled-components';

export const ListContainer = styled.div`
  position: relative;
  width: 100%;
  background: #fff;

  border-radius: 8px;
  padding: 4px 2px;
  cursor: default;

  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.4s;

  background: #fff;

  &:hover {
    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 500px) {
    box-shadow: none;
  }
`;

export const Top = styled.div`
  min-height: 76px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px;

  input {
    width: 50%;
  }

  @media (max-width: 635px) {
    min-height: 96px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 8px;

    input {
      width: 100%;
    }
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;

  width: 40%;

  @media (max-width: 635px) {
    width: auto;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  gap: 32px;
`;

export const ListImage = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 16px;
  .blue {
    .st0 {
      fill: #85c1e9 !important;
    }
  }

  .green {
    .st0 {
      fill: #94e7b7 !important;
    }
  }

  .orange {
    .st0 {
      fill: #f2be90 !important;
    }
  }

  .red {
    .st0 {
      fill: #f2a59d !important;
    }
  }

  .yellow {
    .st0 {
      fill: #f9e79f !important;
    }
  }

  img,
  svg {
    border-radius: 8px;
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
`;

export const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListName = styled.strong`
  font-size: 18px;
  text-align: start;
  max-width: 40vw;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;
