/* eslint-disable no-underscore-dangle */
import axios, { AxiosError } from 'axios';
import IUserResponseDTO from '../dtos/IUserResponseDTO';
import { LocalStorage } from '../enums/localstorage';

import IUser from '../models/IUser';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(request => {
  const customer = localStorage.getItem(LocalStorage.CUSTOMER);

  if (customer) {
    const token = (JSON.parse(customer) as IUser).accessToken;

    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return request;
});

const refreshToken = async (user: IUser) => {
  const { data } = await api.post<IUserResponseDTO>('/accounts/refresh-token', {
    token: user.accessToken,
    refreshToken: user.refreshToken,
  });

  return data;
};

api.interceptors.response.use(
  response => response,
  async (error: AxiosError) => {
    if (error?.response?.status === 401) {
      if (error?.config?.url?.includes('refresh-token')) {
        localStorage.removeItem(LocalStorage.CUSTOMER);
        window.open('/session-expired?s=F', '_self');
        return null;
      }

      const user = JSON.parse(
        localStorage.getItem(LocalStorage.CUSTOMER) || '',
      ) as IUser;

      const userResponse = await refreshToken(user);

      localStorage.setItem(
        LocalStorage.CUSTOMER,
        JSON.stringify({
          ...userResponse.userToken,
          accessToken: userResponse.accessToken,
          refreshToken: userResponse.refreshToken,
        }),
      );
      const originalReq = error.config;

      originalReq.headers.Authorization = `Bearer ${userResponse.accessToken}`;

      return api(originalReq);
    }

    throw error;
  },
);

export default api;
