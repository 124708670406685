import { useState, useEffect, useCallback } from 'react';

const getMatches = (mediaQuery: string): boolean => {
  if (!window || !window?.matchMedia) {
    return false;
  }

  return window.matchMedia(mediaQuery).matches;
};

export default function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState<boolean>(getMatches(query));

  const handleChange = useCallback((event: MediaQueryListEvent) => {
    setMatches(event.matches);
  }, []);

  useEffect(() => {
    if (!window || !window?.matchMedia) {
      return;
    }

    const mediaQueryList = window.matchMedia(query);
    mediaQueryList.addEventListener('change', handleChange);

    // eslint-disable-next-line consistent-return
    return () => {
      mediaQueryList.removeEventListener('change', handleChange);
    };
  }, [query, handleChange]);

  return matches;
}
