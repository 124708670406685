import { Form } from '@unform/web';
import Ripple from 'react-ripples';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import background from '../../assets/login-background.jpg';

const appearFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to{
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  display: flex;
  transition: flex-direction 0.4s;
  height: 100vh;

  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;
  height: 100vh;
  top: 0;

  animation: ${appearFromLeft} 0.8s;

  background: #fff;

  @media (max-width: 800px) {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  overflow-y: auto;
`;

export const Background = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Header = styled.header`
  display: flex;
  padding: 32px;
  background: #fff;
`;

export const CompanyName = styled.h1``;

export const SignInForm = styled(Form)`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 32px 86px;

  background: #fff;

  > h2 {
    font-size: 40px;
    margin-bottom: 64px;
  }

  @media (max-width: 800px) {
    padding: 32px;
  }
`;

export const SignUpForm = styled.form`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 32px 86px;

  background: #fff;

  > h2 {
    font-size: 40px;
    margin-bottom: 32px;
  }

  @media (max-width: 800px) {
    padding: 32px;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  background: none;
  outline: 0;
  border: 0;
  color: #db1622;
  font-size: 16px;
  transition: opacity 0.3s;
  text-align: center;
  margin-top: 32px;

  &:hover {
    opacity: 0.6;
  }
`;

export const InputContainer = styled.div`
  > div {
    margin-bottom: 8px;
  }

  > div + div {
    margin-bottom: 32px;
  }
`;

export const ButtonRipple = styled(Ripple)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  min-height: 60px;
  border: 0;
  border-radius: 32px 0 32px 32px;
  width: 100%;
  background: #db1622;

  > div {
    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  min-height: 60px;
  margin-top: 32px;
  border: 0;
  border-radius: 32px 0 32px 32px;
  color: #fff;
  background: #db1622;
  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.4s;
  cursor: pointer;

  &:hover {
    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);
  }
`;

export const Footer = styled.footer`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #fff;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: #db1622;
    font-size: 16px;
    transition: opacity 0.3s;
    text-align: center;
    margin-left: 4px;
  }

  a:hover {
    opacity: 0.6;
  }

  .privacy-policy {
    a {
      text-decoration: underline;
    }
  }

  svg {
    margin: 0 4px;
  }

  @media (max-width: 850px) {
    font-size: 14px;

    a {
      font-size: 14px;
    }
  }

  @media (max-height: 800px) {
    margin-top: 32px;
  }

  @media (max-height: 680px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  span.info {
    font-size: 14px;
    font-weight: medium;
    margin-top: 16px;
    white-space: nowrap;
    text-align: center;
  }
`;
