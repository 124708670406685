import styled from 'styled-components';

export const RemoveEditContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  margin-left: auto;

  @media (max-width: 600px) {
    width: 100%;
    justify-content: space-between;
    margin-left: 0;
  }
`;

export const QtyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 32px;
  cursor: pointer;
`;

export const QtyRemoveButton = styled.button`
  background: ${({ theme }) => theme.palette.primary};
  border: none;
  border-radius: 0px 8px 8px 0px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  transition: opacity 200ms ease;
  opacity: 0.8;

  svg {
    stroke: ${({ theme }) => theme.palette.text_white};
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:hover {
    opacity: 1;
  }
`;

export const QtyAddButton = styled(QtyRemoveButton)`
  background: ${({ theme }) => theme.palette.secondary};
  border-radius: 8px 0px 0px 8px;
`;

export const QtyInput = styled.input`
  text-align: center;
  width: 42px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text};
  appearance: none;
  -moz-appearance: textfield;
  border: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
