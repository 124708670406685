import { IStep } from '../components/Tour';
import { chooseBsFoodOrVarejo } from '../utils/subdomain';

const tour: IStep[] = [
  {
    text: 'Esta é a página de Categorias!',
  },
  {
    id: 'categories-container',
    position: 'top',
    text: 'Aqui são listadas todas as categorias.',
  },
  {
    id: 'categories-filters',
    position: 'left',
    text: 'Clique para alternar os filtros...',
  },
  {
    id: 'categories-search',
    position: 'left',
    text: 'ou fazer uma busca mais específica.',
  },
  {
    id: 'categories-display-preference',
    position: 'left',
    text: 'Aqui você pode mudar a forma que elas são listadas.',
  },
  {
    id: 'categories-order',
    position: 'left',
    text: `Aqui você pode mudar a ordem de exibição das suas categorias no ${chooseBsFoodOrVarejo(
      'cardápio',
      'catálogo',
    )}.`,
  },
  {
    id: 'categories-add',
    position: 'left',
    text: 'E, para cadastrar uma nova categoria, basta clicar aqui.',
  },
];

export default tour;
