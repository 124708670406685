import React from 'react';

import { Container } from './styles';

interface ICustomCheckboxProps {
  id: string;
  text: string;
  color?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
}

const CustomCheckbox: React.FC<ICustomCheckboxProps> = ({
  id,
  text,
  color,
  checked,
  disabled = false,
  onChange,
  ...rest
}) => {
  return (
    <Container color={color} disabled={disabled} {...rest}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={id}>{text}</label>
    </Container>
  );
};

export default CustomCheckbox;
