import React from 'react';

import { FiRefreshCcw } from 'react-icons/fi';

import { CardTop, Container, CardBottom, CardDescription } from './styles';

interface CardProps {
  source: string;
  title: string;
  value: string;
}

const Card: React.FC<CardProps> = ({ source, title, value }) => {
  return (
    <Container>
      <CardTop>
        <img src={source} alt="" />
        <CardDescription>
          <span>{title}</span>
          <span>{value}</span>
        </CardDescription>
      </CardTop>
      <CardBottom>
        <FiRefreshCcw color="#878b93" />
        <span>Atualizado agora</span>
      </CardBottom>
    </Container>
  );
};

export default Card;
