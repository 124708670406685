import React, { useCallback, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { DateTime, Duration, DurationObject } from 'luxon';
import DateInput from '../DateInput';
import {
  ButtonRipple,
  ConfirmDateButton,
  CustomFilter,
  DateInputContainer,
  DateRangePickerContainer,
  Filter,
  FilterWrapper,
  Filters,
  FiltersTitle,
  Wrapper,
} from './styles';

export type EFilterTag = 'SEVEN' | 'HOURS' | 'THIRTY' | 'CUSTOM';

export interface IDateRangePickerValue {
  startDate: Date | null;
  endDate: Date | null;
}

export interface IDateRangePickerResponse {
  dates: IDateRangePickerValue;
  selectedTag: EFilterTag;
}

interface IDateRangePickerProps {
  selectedTag?: EFilterTag;
  onConfirm: (response: IDateRangePickerResponse) => void;
}

const DateRangePicker: React.FC<IDateRangePickerProps> = ({
  selectedTag,
  onConfirm,
}) => {
  const [customStartDate, setInternalStartDate] = useState<Date | null>(null);
  const [customEndDate, setInternalEndDate] = useState<Date | null>(null);

  const onChangeInternalStartDate = useCallback((date: Date) => {
    setInternalStartDate(DateTime.fromJSDate(date).endOf('day').toJSDate());
  }, []);

  const onChangeInternalEndDate = useCallback((date: Date) => {
    setInternalEndDate(DateTime.fromJSDate(date).endOf('day').toJSDate());
  }, []);

  const handleOnFilterChanged = useCallback(
    (tag: EFilterTag, range: number | Duration | DurationObject) => {
      onConfirm({
        selectedTag: tag,
        dates: {
          startDate: DateTime.fromJSDate(new Date())
            .minus(range)
            .startOf('day')
            .toJSDate(),
          endDate: DateTime.fromJSDate(new Date()).endOf('day').toJSDate(),
        },
      });
    },
    [onConfirm],
  );

  const handleOnConfirmDatePicker = useCallback(() => {
    if (customStartDate && customEndDate) {
      onConfirm({
        selectedTag: 'CUSTOM',
        dates: {
          startDate: DateTime.fromJSDate(customStartDate)
            .startOf('day')
            .toJSDate(),
          endDate: DateTime.fromJSDate(customEndDate).endOf('day').toJSDate(),
        },
      });
    }
  }, [customStartDate, customEndDate, onConfirm]);

  return (
    <DateRangePickerContainer>
      <FiltersTitle>Período:</FiltersTitle>
      <Filters>
        <FilterWrapper id="dashboard-filters">
          <Filter
            selected={selectedTag === 'HOURS'}
            onClick={() => handleOnFilterChanged('HOURS', { hours: 24 })}
          >
            <span>últimas</span>
            <strong>24</strong>
            <span>horas</span>
            <span className="arrow" />
          </Filter>
          <Filter
            selected={selectedTag === 'SEVEN'}
            onClick={() => handleOnFilterChanged('SEVEN', { days: 7 })}
          >
            <span>últimos</span>
            <strong>7</strong>
            <span>dias</span>
            <span className="arrow" />
          </Filter>
          <Filter
            selected={selectedTag === 'THIRTY'}
            onClick={() => handleOnFilterChanged('THIRTY', { days: 30 })}
          >
            <span>últimos</span>
            <strong>30</strong>
            <span>dias</span>
            <span className="arrow" />
          </Filter>
        </FilterWrapper>
        <CustomFilter
          selected={selectedTag === 'CUSTOM'}
          id="dashboard-filter-specific"
        >
          <span className="arrow" />
          <Wrapper>
            <DateInputContainer>
              <DateInput
                label="início"
                value={customStartDate}
                onChange={onChangeInternalStartDate}
              />
              <DateInput
                label="fim"
                hasMarginTop
                value={customEndDate}
                onChange={onChangeInternalEndDate}
              />
            </DateInputContainer>
            <ButtonRipple color="#fff5">
              <ConfirmDateButton onClick={handleOnConfirmDatePicker}>
                <FiCheck size={26} color="#fff" />
              </ConfirmDateButton>
            </ButtonRipple>
          </Wrapper>
        </CustomFilter>
      </Filters>
    </DateRangePickerContainer>
  );
};

export default DateRangePicker;
