import React from 'react';

import Lottie from 'lottie-react-web';

import animation from '../../assets/animations/products-loading.json';

import { Container } from './styles';

interface ILoadingProps {
  small?: boolean;
}

const LoadingAnimation: React.FC<ILoadingProps> = ({ small }) => (
  <Container small={small}>
    <Lottie
      options={{
        animationData: animation,
      }}
      height={small ? 250 : 300}
      width={small ? 300 : 600}
    />
  </Container>
);

export default LoadingAnimation;
