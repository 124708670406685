import { LoadingAnimations } from '../enums/loadingAnimation';

import asian_rice from '../assets/animations/loaders/asian-rice.json';
import beer from '../assets/animations/loaders/beer.json';
import bread from '../assets/animations/loaders/bread.json';
import build from '../assets/animations/loaders/build.json';
import cake from '../assets/animations/loaders/cake.json';
import cereal from '../assets/animations/loaders/cereal.json';
import croissant from '../assets/animations/loaders/croissant.json';
import coffee from '../assets/animations/loaders/coffee.json';
import donut from '../assets/animations/loaders/donut.json';
import dish from '../assets/animations/loaders/dish.json';
import drink from '../assets/animations/loaders/drink.json';
import fish_and_chips from '../assets/animations/loaders/fish-and-chips.json';
import french_fries from '../assets/animations/loaders/french-fries.json';
import fried_pastry from '../assets/animations/loaders/fried-pastry.json';
import hamburguer from '../assets/animations/loaders/hamburger.json';
import ice_cream from '../assets/animations/loaders/ice-cream.json';
import pancake from '../assets/animations/loaders/pancake.json';
import pizza from '../assets/animations/loaders/pizza.json';
import popcorn from '../assets/animations/loaders/popcorn.json';
import salad from '../assets/animations/loaders/salad.json';
import sushi from '../assets/animations/loaders/sushi.json';
import tacos from '../assets/animations/loaders/tacos.json';
import turkey from '../assets/animations/loaders/turkey.json';
import wine from '../assets/animations/loaders/wine.json';

type LoadingOptions = {
  [key in LoadingAnimations]: Record<string, unknown>;
};

export const loadingAnimations: LoadingOptions = {
  ASIAN_RICE: asian_rice,
  BEER: beer,
  BREAD: bread,
  CAKE: cake,
  CEREAL: cereal,
  CROISSANT: croissant,
  COFFEE: coffee,
  DONUT: donut,
  DISH: dish,
  DRINK: drink,
  FISH_AND_CHIPS: fish_and_chips,
  FRENCH_FRIES: french_fries,
  FRIED_PASTRY: fried_pastry,
  HAMBURGUER: hamburguer,
  ICE_CREAM: ice_cream,
  PANCAKE: pancake,
  PIZZA: pizza,
  POPCORN: popcorn,
  SALAD: salad,
  SUSHI: sushi,
  TACOS: tacos,
  TURKEY: turkey,
  WINE: wine,
  BUILD: build,
};

export const getAnimation = (
  type: LoadingAnimations,
): Record<string, unknown> => {
  return loadingAnimations[type];
};
