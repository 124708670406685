/* eslint-disable no-shadow */
export enum PageNames {
  DASHBOARD = 'Dashboard',
  RECENT_ORDERS = 'Pedidos novos',
  ORDERS = 'Pedidos',
  CATEGORIES = 'Categorias',
  PRODUCTS = 'Produtos',
  COMPLEMENTS = 'Complementos',
  UPDATE_SKU = 'Alterar código de integração',
  MESSAGES = 'Adicionar mensagens',
  TEAM = 'Equipe',
  TABLES = 'Mesas',
  CASHIER_REPORT = 'Relatório de Caixa',
  OPERATORS = 'Operadores',
  DEVICES = 'Dispositivos',
  SETTINGS = 'Preferências',
  HIGHLIGHTS = 'Destaques',
  SOCIAL = 'Links',
  DISCOUNTS = 'Cupons de desconto',
  ADDRESSES = 'Taxas de entrega',
  HISTORY = 'História',
  THEME = 'Tema',
  SCHEDULED_PRODUCTS = 'Produtos agendados',
  EDIT_PRODUCT = 'Editar produto',
  STOCK = 'Estoque',
}
