import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  background: #f4f4f6;
  position: relative;
`;

export const Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  max-height: 100px;
  padding: 0 32px;
  padding-top: 24px;
  margin-bottom: 16px;
`;

export const PageName = styled.strong`
  font-size: 32px;
  margin-left: 8px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ButtonSearchContainer = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const AddUserButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  color: #fff;
  padding: 8px;
  background: #db1622;

  span {
    font-size: 14px;

    &::after {
      content: 'Adicionar novo';
      margin-left: 8px;
    }
  }

  @media (max-width: 1024px) {
    position: fixed;
    bottom: 8px;
    right: 8px;
    border-radius: 32px;
    box-shadow: ${({ theme }) => theme.shadow.box_shadow};
    z-index: 18;
    padding: 12px;

    svg {
      width: 28px;
      height: 28px;
    }
    span {
      &::after {
        content: '';
        margin-left: 0;
      }
    }
  }
`;

export const Main = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  width: 100%;
  padding: 0 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  gap: 16px;
  overflow-y: scroll;
  overflow-x: hidden;

  &:last-child {
    margin-bottom: 32px;
  }

  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 480px) {
    padding-bottom: 80px;
  }
`;

export const EmptyMessageContainer = styled.div`
  width: 100%;
  text-align: center;

  margin-top: 128px;
`;
