/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import ColorPicker from '../ColorPicker';

import {
  Text,
  Title,
  Buttons,
  NoButton,
  Container,
  YesButton,
  StyledModal,
  InputContainer,
} from './styles';

import FormlessInput from '../FormlessInput';

import IMessage from '../../models/IMessage';
import { useMessage } from '../../hooks/messages';
import { useToast } from '../../hooks/toast';

const colors = ['#fc6060', '#2ecc71', '#eebd37'];

interface IAddMessageProps {
  isOpen: boolean;
  onClose: () => void;
  message: IMessage | null;
}

const AddMessageModal: React.FC<IAddMessageProps> = ({
  isOpen,
  onClose,
  message,
}) => {
  const { addToast } = useToast();
  const { newMessage, editMessage } = useMessage();
  const [color, setColor] = useState(message?.hexColor || colors[0]);
  const [title, setTitle] = useState(message?.title || '');
  const [text, setText] = useState(message?.description || '');
  const [active, setActive] = useState<boolean>(message?.active || true);

  useEffect(() => {
    if (!isOpen) {
      setActive(true);
      setTitle('');
      setText('');
    } else if (message) {
      setActive(message.active);
      setTitle(message.title || '');
      setText(message.description);
      setColor(message.hexColor);
    }
  }, [isOpen, message]);

  const handleTextChanged = (value: string) => {
    setText(value);
  };

  const handleTitleChanged = (value: string) => {
    setTitle(value);
  };

  const handleColorChange = (value: string) => {
    setColor(value);
  };

  const handleSaveMessage = useCallback(async () => {
    const schema = Yup.object().shape({
      title: Yup.string()
        .required('O título é obrigatório.')
        .min(3, 'Mínimo de 3 caracteres no título.')
        .max(255, 'Máximo de 255 caracteres no título.'),
      description: Yup.string()
        .required('A descrição é obrigatória.')
        .min(3, 'Mínimo de 3 caracteres na descrição.')
        .max(255, 'Máximo de 255 caracteres na descrição.'),
      hexColor: Yup.string().required('Selecione uma cor'),
    });

    if (message?.id) {
      try {
        await schema.validate(
          {
            title,
            description: text,
            hexColor: color,
          },
          {
            abortEarly: false,
          },
        );

        await editMessage(
          {
            id: message.id,
            title,
            active,
            description: text,
            hexColor: color,
            position: message.position,
          },
          message.id,
        );
        addToast({
          type: 'success',
          description: 'Mensagem editada com sucesso.',
        });
        onClose();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          addToast({
            type: 'error',
            description: err.inner[0].message,
          });
        } else {
          addToast({
            type: 'error',
            description: 'Ocorreu um erro inesperado.',
          });
        }
      }
    } else {
      try {
        await schema.validate(
          {
            title,
            description: text,
            hexColor: color,
          },
          {
            abortEarly: false,
          },
        );

        await newMessage({
          title,
          active: true,
          description: text,
          hexColor: color,
          position: 0,
        });
        addToast({
          type: 'success',
          description: 'Mensagem criada com sucesso.',
        });

        onClose();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          addToast({
            type: 'error',
            description: err.inner[0].message,
          });
        } else {
          addToast({
            type: 'error',
            description: 'Ocorreu um erro inesperado.',
          });
        }
      }
    }
  }, [
    text,
    color,
    title,
    active,
    message,
    onClose,
    addToast,
    newMessage,
    editMessage,
  ]);

  return (
    <StyledModal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      isOpen={isOpen}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        },
      }}
    >
      <Container>
        <Title>Escreva aqui a sua mensagem</Title>
        <ColorPicker
          value={color}
          colors={colors}
          cssVarPrefix="theme-page-color-picker"
          onChange={handleColorChange}
        />
        <InputContainer>
          <FormlessInput
            title="Titulo"
            value={title}
            onChange={e => handleTitleChanged(e.target.value)}
          />
        </InputContainer>
        <Text>
          <h4>Mensagem</h4>
          <textarea
            maxLength={255}
            value={text}
            placeholder="Escreva aqui uma mensagem de no máximo 255 cáracteres"
            onChange={e => handleTextChanged(e.target.value)}
          />
          <span>{`${text.length}/255`}</span>
        </Text>
        <Buttons>
          <YesButton onClick={handleSaveMessage}>SALVAR</YesButton>
          <NoButton onClick={onClose}>FECHAR</NoButton>
        </Buttons>
      </Container>
    </StyledModal>
  );
};

export default AddMessageModal;
