import Modal from 'react-modal';
import styled from 'styled-components';

import { appearFromBottom } from '../../styles/animations';

export const StyledModal = styled(Modal)`
  animation: ${appearFromBottom} 0.5s;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 32px;

  border-radius: 16px;

  overflow-y: auto;
  overflow-x: hidden;

  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    border-radius: 0;
  }
`;

export const Title = styled.h3`
  margin-bottom: 32px;
  color: #1b1c1e;
`;

export const Buttons = styled.div`
  display: flex;

  margin-top: 64px;

  width: 100%;
  justify-content: flex-end;
`;

export const Message = styled.span`
  color: #1b1c1e90;
  font-size: 14px;
`;

export const NoButton = styled.button`
  width: 100px;
  padding: 8px;

  border: 0;
  background: none;
  border-bottom: 2px solid #c0392b;
`;

export const YesButton = styled.button`
  width: 100px;
  padding: 8px;
  margin-right: 32px;

  border: 0;
  background: none;
  border-bottom: 2px solid #27ae60;
`;
