import styled, { css } from 'styled-components';
import { getBlackOrWhiteFromColor } from '../../utils/colors';

interface IContainerProps {
  color: string;
  active?: boolean;
}

export const Container = styled.div<IContainerProps>`
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding: 16px;
  width: 100%;
  min-height: 100px;
  background: ${({ color }) => color};
  --warning-badge-color: ${({ color }) => getBlackOrWhiteFromColor(color)};
  color: var(--warning-badge-color);
  border-radius: 8px;

  font-size: 14px;

  ${({ active }) =>
    !active &&
    css`
      opacity: 0.4;
    `}

  h2 {
    margin-bottom: 16px;
  }

  strong + strong {
    margin-top: 8px;
  }
`;

export const EditMessageContainer = styled.div`
  position: absolute;
  right: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  svg {
    color: var(--warning-badge-color);
  }

  @media (max-width: 800px) {
    position: unset;
  }
`;

export const EditMessage = styled.button`
  padding: 6px;
  border-radius: 32px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;
