import React, { useMemo, useRef } from 'react';

import { DateTime } from 'luxon';
import VMasker from 'vanilla-masker';
import ReactToPrint from 'react-to-print';
import { FiPrinter } from 'react-icons/fi';

import IOrder from '../../models/IOrder';
import IOrderItemComplements from '../../models/IOrderItemComplements';

import { useCompany } from '../../hooks/company';
import { AddressType } from '../../enums/addressType';
import { formatToDocument, formatToMoney } from '../../utils/format';
import { getDeliveryTypeName, getPaymentTypeName } from '../../utils/string';

import { IGroupedItem } from '../KitchenPrintItem';

import {
  Main,
  Item,
  Items,
  Total,
  Footer,
  Header,
  MadeBy,
  Totals,
  Discount,
  Subtotal,
  Container,
  OrderDate,
  OrderType,
  PrintDate,
  TotalPrice,
  Complement,
  ItemsTitle,
  DeliveryFee,
  PaymentType,
  CompanyInfo,
  CompanyName,
  OrderNumber,
  TotalChange,
  PrintButton,
  PaymentTitle,
  CustomerName,
  ItemComments,
  CustomerPhone,
  OrderInfoItem,
  ItemOptionals,
  ComplementName,
  ItemsContainer,
  ItemAdditionals,
  ComplementAmount,
  PaymentContainer,
  CustomerContainer,
  ItemOptionalsTitle,
  ItemAdditionalsTitle,
  OrderNumberContainer,
  ComplementsContainer,
} from './styles';
import { getProductTotal } from '../../utils/cart';
import { getOrderTime } from '../../utils/orders';

interface ISummarizedPrintItemProps {
  order: IOrder | null;
  type?: string;
}

const SummarizedPrintItem: React.FC<ISummarizedPrintItemProps> = ({
  order,
  type,
}) => {
  const printRef = useRef<HTMLDivElement | null>(null);

  const { company } = useCompany();

  const groupedItems: IGroupedItem[] = useMemo(() => {
    if (order) {
      return order.items.map(item => {
        const pizza: IOrderItemComplements[] = [];
        const optionals: IOrderItemComplements[] = [];
        const additionals: IOrderItemComplements[] = [];

        item.complementsGroups.forEach(g => {
          g.complements.forEach(c => {
            if (g.category === 'PIZZA') {
              pizza.push(c);
            } else if (c.unitPrice > 0) {
              additionals.push(c);
            } else {
              optionals.push(c);
            }
          });
        });

        return {
          item,
          pizza,
          pizzaSlices: item?.pizzaSizes?.reduce(
            (acc, current) => acc + current?.amount,
            0,
          ),
          optionals,
          additionals,
        };
      });
    }

    return [];
  }, [order]);

  const getDivisor = useMemo(() => {
    return (groupAmount: number, complementAmount: number) => {
      if (groupAmount === complementAmount) {
        return '-';
      }

      switch (groupAmount) {
        case 2:
          return `${complementAmount}/2`;
        case 3:
          return `${complementAmount}/3`;
        case 4:
          return `${complementAmount}/4`;
        default:
          return '';
      }
    };
  }, []);

  const complementTitle = useMemo(() => {
    return (complement: IOrderItemComplements, item: IGroupedItem) => {
      const group = item.item.complementsGroups.find(
        g => g.id === complement.complementGroupId,
      );

      if (group && group.category === 'PIZZA') {
        return `${getDivisor(group.minAmount, complement.amount)} ${
          complement.title
        }`;
      }

      return `${complement.amount}x ${complement.title}`;
    };
  }, [getDivisor]);

  const activeProducts = useMemo(() => {
    return groupedItems?.filter(g => !g.item.deleted);
  }, [groupedItems]);

  const address2 = useMemo(() => {
    if (order?.address2 && order.addressReference) {
      return `${order.address2} - ${order.addressReference}`;
    }

    if (order?.address2) {
      return order.address2;
    }

    if (order?.addressReference) {
      return order.addressReference;
    }

    return '';
  }, [order]);

  const orderTime = useMemo(() => getOrderTime(order), [order]);

  const tableNumber = useMemo(() => {
    if (order?.cardNo) {
      return `Mesa ${order.tableNo} - Comanda ${order.cardNo}`;
    }

    return order?.tableNo;
  }, [order]);

  const CorrectCustomerContainer = useMemo(
    () => () => {
      if (order?.orderType === 'DELIVERY') {
        return (
          <CustomerContainer>
            <CustomerName>{order?.receiverName}</CustomerName>
            <span>{`${order?.streetName}, ${order?.streetNumber}`}</span>
            <span>{order?.comments}</span>
            {address2 && <span>{address2}</span>}
            <span>{order?.neighborhood}</span>
            <span>{`${order?.city?.name}/${order?.city?.state}`}</span>
            <span>
              {`(${order?.phoneNumber.substring(
                0,
                2,
              )}) ${order?.phoneNumber.substring(2)}`}
            </span>
            {order.receiverDocument && (
              <span>{formatToDocument(order.receiverDocument)}</span>
            )}
          </CustomerContainer>
        );
      }

      if (
        order?.orderType === 'CHECKOUT' ||
        order?.orderType === 'DRIVE_THRU'
      ) {
        return (
          <CustomerContainer>
            <CustomerName>{order?.receiverName}</CustomerName>
            {order.phoneNumber && (
              <CustomerPhone>
                {VMasker.toPattern(
                  order.phoneNumber,
                  order.phoneNumber.length > 10
                    ? '(99) 9 9999 9999'
                    : '(99) 9999 9999',
                )}
              </CustomerPhone>
            )}
            <span>{order?.comments}</span>
            {order.receiverDocument && (
              <span>{formatToDocument(order.receiverDocument)}</span>
            )}
          </CustomerContainer>
        );
      }

      return (
        <CustomerContainer>
          <CustomerName>{tableNumber}</CustomerName>
        </CustomerContainer>
      );
    },
    [order, address2, tableNumber],
  );

  const deliveryFee = useMemo(() => {
    if (order?.deliveryType === AddressType.PLACE) {
      return `+${formatToMoney(order?.deliveryFee || 0)} (${
        order.deliveryPlace.name
      })`;
    }

    return `+${formatToMoney(order?.deliveryFee || 0)}`;
  }, [order]);

  return (
    <>
      <PrintButton type={type}>
        <ReactToPrint
          trigger={() => (
            <button type="button" id="print_button">
              <FiPrinter size={22} />
              Imprimir
            </button>
          )}
          content={() => printRef.current}
        />
      </PrintButton>
      <Container className="has-custom-scroll-bar-2" ref={printRef}>
        <Header>
          <CompanyInfo>
            <CompanyName>{company?.tradingName}</CompanyName>
          </CompanyInfo>
        </Header>
        <Main>
          <OrderNumberContainer>
            <OrderNumber>{`#${order?.number}`}</OrderNumber>
            <OrderType>{getDeliveryTypeName(order?.orderType || '')}</OrderType>
          </OrderNumberContainer>
          {order?.orderType === 'DELIVERY' && (
            <OrderInfoItem>
              <span className="value">{`Estimativa da entrega: ${orderTime}h`}</span>
            </OrderInfoItem>
          )}
          {order?.scheduledProduct && (
            <OrderType>
              <span className="value">
                {`Agendado para: ${order.scheduledProduct.slice(0, -3)}h`}
              </span>
            </OrderType>
          )}
          <CorrectCustomerContainer />
          <ItemsContainer>
            <ItemsTitle>
              <tr>
                <th>qtd</th>
                <th>descrição</th>
                <th>unidade</th>
                <th>total</th>
              </tr>
            </ItemsTitle>
            <Items>
              {activeProducts?.map(g => (
                <Item key={g.item.id}>
                  <tr className="main">
                    <td>{`${g.item.qty}x`}</td>
                    <td>{`${g.item.title}`}</td>
                    <td>{formatToMoney(getProductTotal(g.item), true)}</td>
                    <td>
                      {formatToMoney(
                        getProductTotal(g.item) * g.item.qty,
                        true,
                      )}
                    </td>
                  </tr>
                  <tr className="additionals">
                    <td>
                      <ItemAdditionals>
                        <ItemComments>{g.item.comments}</ItemComments>
                      </ItemAdditionals>
                      <>
                        {g.pizza.length > 0 && (
                          <ItemAdditionals>
                            <ItemAdditionalsTitle>
                              Sabores da pizza
                            </ItemAdditionalsTitle>
                            {g.pizza.map(a => (
                              <Complement key={a.id}>
                                <div>
                                  <ComplementName>
                                    {complementTitle(a, g)}
                                  </ComplementName>
                                </div>
                              </Complement>
                            ))}
                          </ItemAdditionals>
                        )}
                        {g?.item?.isCustomPizza && (
                          <>
                            {g?.item?.pizzaSizes && (
                              <ItemAdditionals>
                                <ItemAdditionalsTitle>
                                  Sabores
                                </ItemAdditionalsTitle>
                                {g?.item?.pizzaSizes?.map(size => (
                                  <Complement key={`pizzaSize-${size.id}`}>
                                    <div>
                                      <ComplementAmount>
                                        {`${size.amount}/${
                                          g?.pizzaSlices || 0
                                        }`}
                                      </ComplementAmount>
                                      <ComplementName>
                                        {size.title}
                                      </ComplementName>
                                    </div>
                                  </Complement>
                                ))}
                              </ItemAdditionals>
                            )}
                            {(g?.item?.pizzaCrusts?.length || 0) > 0 && (
                              <ItemAdditionals>
                                <ItemAdditionalsTitle>
                                  Massa
                                </ItemAdditionalsTitle>
                                {g?.item?.pizzaCrusts?.map(crust => (
                                  <Complement key={`pizzaCrust-${crust.id}`}>
                                    <div>
                                      <ComplementName>
                                        {crust.title}
                                      </ComplementName>
                                    </div>
                                  </Complement>
                                ))}
                              </ItemAdditionals>
                            )}
                            {(g?.item?.pizzaEdges?.length || 0) > 0 && (
                              <ItemAdditionals>
                                <ItemAdditionalsTitle>
                                  Borda
                                </ItemAdditionalsTitle>
                                {g?.item?.pizzaEdges?.map(edge => (
                                  <Complement key={`pizzaEdge-${edge.id}`}>
                                    <div>
                                      <ComplementName>
                                        {edge.title}
                                      </ComplementName>
                                    </div>
                                  </Complement>
                                ))}
                              </ItemAdditionals>
                            )}
                          </>
                        )}
                      </>
                      {company?.showAdditionalsInPrint && (
                        <>
                          <ComplementsContainer
                            inverse={company?.orderPrintInvertComplements}
                          >
                            <ItemAdditionals>
                              {g.additionals.length > 0 && (
                                <>
                                  {company?.showPrintComplementsTitle && (
                                    <ItemAdditionalsTitle>
                                      Adicionais
                                    </ItemAdditionalsTitle>
                                  )}
                                  {g.additionals.map(complement => (
                                    <Complement key={complement.id}>
                                      <div>
                                        <ComplementAmount>
                                          {`${complement.amount}x`}
                                        </ComplementAmount>
                                        <ComplementName>
                                          {complement.title}
                                        </ComplementName>
                                      </div>
                                    </Complement>
                                  ))}
                                </>
                              )}
                            </ItemAdditionals>
                            <ItemOptionals>
                              {g.optionals.length > 0 && (
                                <>
                                  {company?.showPrintComplementsTitle && (
                                    <ItemOptionalsTitle>
                                      Opcionais
                                    </ItemOptionalsTitle>
                                  )}
                                  {g.optionals.map(complement => (
                                    <Complement key={complement.id}>
                                      <div>
                                        <ComplementAmount>
                                          {`${complement.amount}x`}
                                        </ComplementAmount>
                                        <ComplementName>
                                          {complement.title}
                                        </ComplementName>
                                      </div>
                                    </Complement>
                                  ))}
                                </>
                              )}
                            </ItemOptionals>
                          </ComplementsContainer>
                        </>
                      )}
                    </td>
                  </tr>
                </Item>
              ))}
            </Items>
          </ItemsContainer>
          <Totals>
            <Subtotal>
              <span>Subtotal</span>
              {formatToMoney(order?.subTotal || 0)}
            </Subtotal>
            {!!order?.discount && order.discount > 0 && (
              <Discount>
                <span>Desconto</span>
                {`-${formatToMoney(order?.discount || 0)}`}
              </Discount>
            )}
            {order?.orderType === 'DELIVERY' && (
              <DeliveryFee>
                <span>Entrega</span>
                {deliveryFee}
              </DeliveryFee>
            )}
            <Total>
              <strong>Total</strong>
              <strong>{formatToMoney(order?.total || 0)}</strong>
            </Total>
          </Totals>
          <PaymentContainer>
            <PaymentTitle>Pagamento</PaymentTitle>
            <PaymentType>
              <span>Tipo do Pagamento: </span>
              {getPaymentTypeName(order?.paymentType || '')}
            </PaymentType>
            {order?.cardBrandId && (
              <PaymentType>
                <span>Bandeira: </span>
                {company?.paymentCards.find(
                  currendBrand =>
                    currendBrand.paymentCardId === order?.cardBrandId,
                )?.description || ''}
              </PaymentType>
            )}
            {!!order?.cashAdvance && order?.cashAdvance > 0 ? (
              <>
                <TotalPrice>
                  <span>Valor a receber: </span>
                  {formatToMoney(order?.cashAdvance)}
                </TotalPrice>
                <TotalChange>
                  <span>Troco para levar: </span>
                  {formatToMoney(order?.cashAdvance - order?.total)}
                </TotalChange>
              </>
            ) : (
              <TotalPrice>
                <span>Valor a receber: </span>
                {formatToMoney(order?.total || 0)}
              </TotalPrice>
            )}
          </PaymentContainer>
        </Main>
        <Footer>
          <OrderDate>
            {`Data do pedido: ${DateTime.fromISO(
              order?.createdAt || '',
            ).toFormat("dd/MM/yyyy 'às' HH:mm:ss")}`}
          </OrderDate>
          <PrintDate>
            {`Data da impressão: ${DateTime.utc()
              .minus({ hours: 3 })
              .toFormat("dd/MM/yyyy 'às' HH:mm:ss")}`}
          </PrintDate>
          <MadeBy>Feito com ❤ por Build Solutions.</MadeBy>
        </Footer>
      </Container>
    </>
  );
};

export default SummarizedPrintItem;
