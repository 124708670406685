import React, { useCallback, useRef, useState, useEffect } from 'react';

import * as Yup from 'yup';
import { Base64 } from 'js-base64';
import Lottie from 'lottie-react-web';
import { FormHandles } from '@unform/core';
import { AiFillHeart } from 'react-icons/ai';
import { Link, useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import { useAuth } from '../../hooks/auth';
import { useAudio } from '../../hooks/audio';
import { useToast } from '../../hooks/toast';
import { useCompany } from '../../hooks/company';

import { LocalStorage } from '../../enums/localstorage';
import { getValidationErrors } from '../../utils/errors';
import { decryptCredentials } from '../../utils/password';
import animation from '../../assets/animations/loading.json';

import Input from '../../components/Input';
import CustomCheckbox from '../../components/CustomCheckbox';

import {
  Header,
  Button,
  Footer,
  Content,
  Container,
  Background,
  SignInForm,
  CompanyName,
  ButtonRipple,
  InputContainer,
} from './styles';

interface ISignInFormDataProps {
  e7w2ycmv: string;
  oi6auv7q: string;
}

interface IRouteState {
  redirect?: string;
}

const SignInPage: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const history = useHistory();

  const { signIn } = useAuth();
  const { stop } = useAudio();
  const { addToast } = useToast();
  const { company } = useCompany();

  const [redirect, setRedirect] = useState('');
  const [loading, setLoading] = useState(false);

  const [isRememberMeChecked, setIsRememberMeChecked] = useState(() => {
    const rememberMe = localStorage.getItem(LocalStorage.REMEMBERME);

    if (rememberMe) {
      if (rememberMe === 'false') {
        return false;
      }

      return true;
    }

    return false;
  });

  useEffect(() => {
    const credentials = localStorage.getItem(LocalStorage.CREDENTIALS);

    stop();

    if (credentials) {
      const decrypted = decryptCredentials(credentials);

      formRef.current?.setFieldValue('e7w2ycmv', decrypted.e7w2ycmv);
      formRef.current?.setFieldValue('oi6auv7q', decrypted.oi6auv7q);
    }
  }, [stop]);

  useEffect(() => {
    if (history.location.state) {
      const routeState = history.location.state as IRouteState;
      setRedirect(routeState.redirect || '');
    }
  }, [history]);

  useEffect(() => {
    if (history.location.pathname.includes('/session-expired')) {
      const params = new URLSearchParams(window.location.search);

      addToast({
        type: 'error',
        description:
          params?.get('s') === 'F'
            ? 'Seu acesso ao sistema foi negado.'
            : 'Entre novamente e continue usando.',
      });
    }
  }, [addToast, history]);

  const handleSignIn = useCallback(
    async ({ e7w2ycmv, oi6auv7q }: ISignInFormDataProps) => {
      if (loading) {
        return;
      }

      const captcha = await recaptchaRef.current?.executeAsync();
      recaptchaRef.current?.reset();

      if (!captcha) {
        addToast({
          type: 'error',
          description: 'CAPTCHA inválido!',
        });

        return;
      }

      if (process.env.NODE_ENV !== 'production') {
        console.log('recaptcha: ', captcha);
      }

      setLoading(true);
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          e7w2ycmv: Yup.string()
            .email('Digite um email válido.')
            .required('O email é obrigatório.'),
          oi6auv7q: Yup.string().required('A senha é obrigatória.'),
        });

        await schema.validate({ e7w2ycmv, oi6auv7q }, { abortEarly: false });

        await signIn({
          email: e7w2ycmv,
          password: oi6auv7q,
          token: captcha,
        });

        if (isRememberMeChecked) {
          const encrypted = Base64.encode(
            `{"e7w2ycmv":"${e7w2ycmv}","oi6auv7q":"${oi6auv7q}"}`,
          );
          localStorage.setItem(LocalStorage.CREDENTIALS, encrypted);
        }

        history.push(redirect || '/');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        } else {
          addToast({
            type: 'error',
            description: 'Verifique seus dados e tente novamente.',
          });
        }
      }
    },
    [history, loading, redirect, isRememberMeChecked, signIn, addToast],
  );

  const handleOnRememberMeClicked = useCallback(() => {
    setIsRememberMeChecked(oldState => {
      if (oldState) {
        localStorage.setItem(LocalStorage.REMEMBERME, 'false');
        localStorage.removeItem(LocalStorage.CREDENTIALS);
      } else {
        localStorage.setItem(LocalStorage.REMEMBERME, 'true');
      }

      return !oldState;
    });
  }, []);

  return (
    <Container>
      <Content>
        <Header>
          <CompanyName>{company?.tradingName}</CompanyName>
        </Header>
        <SignInForm ref={formRef} onSubmit={handleSignIn}>
          <h2>Login.</h2>
          <InputContainer>
            <Input
              placeholder="você@exemplo.com.br"
              name="e7w2ycmv"
              title="Email"
              type="email"
              autoComplete="off"
            />
            <CustomCheckbox
              id="sign-in-checkbox"
              text="Lembrar de mim"
              checked={isRememberMeChecked}
              onChange={handleOnRememberMeClicked}
            />
            <Input
              placeholder="sua senha"
              name="oi6auv7q"
              title="Senha"
              type="password"
              autoComplete="off"
              hasForgotLink
              hasPasswordEye
            />
          </InputContainer>
          <Button type="submit">
            <ButtonRipple color="#fff8">
              {loading ? (
                <Lottie
                  options={{
                    animationData: animation,
                  }}
                  height={60}
                  width={60}
                />
              ) : (
                <span>Entrar</span>
              )}
            </ButtonRipple>
          </Button>
          <Footer>
            <div className="privacy-policy">
              <Link to="/privacy-policy">Política de Privacidade</Link>
            </div>
            <span>
              Feito com
              <AiFillHeart color="#db1622" size={20} />
              por
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://buildsolutions.com.br/"
              >
                Build Solutions
              </a>
              <span>.</span>
            </span>
            <span className="info">
              João Reginaldo Pereira Queiroz Informatica
              <br />
              CNPJ CNPJ 12.566.641/0001-97
            </span>
          </Footer>
        </SignInForm>
      </Content>
      <Background />
      <ReCAPTCHA
        size="invisible"
        ref={recaptchaRef}
        sitekey={`${process.env.REACT_APP_CAPTCHA_KEY}`}
      />
    </Container>
  );
};

export default SignInPage;
