/* eslint-disable no-return-assign */
import React from 'react';

export const isElementOverflowing = (element: HTMLDivElement): boolean => {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
};

export const parseStringToTags = (string: string): React.ReactNode => {
  return string.split('\n').map((line, index) => {
    const children: React.ReactNode[] = [];

    line.split(' ').forEach((text, index) => {
      if (text.startsWith('*') && text.endsWith('*')) {
        return children.push(
          React.createElement(
            'strong',
            { key: index?.toString() },
            ` ${text.slice(1, -1)}${index === line.length - 1 ? '' : ' '}`,
          ),
        );
      }

      if (typeof children[children.length - 1] === 'string') {
        return (children[children.length - 1] += ` ${text}`);
      }

      return children.push(text);
    });

    return React.createElement('p', { key: index?.toString() }, ...children);
  });
};

export function stopPropagation(
  handler: () => unknown,
): (e: React.MouseEvent) => void {
  return (e: React.MouseEvent) => {
    e?.stopPropagation();
    handler();
  };
}
