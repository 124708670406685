import styled from 'styled-components';

export const AppliedFiltersContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const AppliedFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 16px;
  border: 1px solid ${({ theme }) => theme.palette.control};
  border-radius: 4px;
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  color: ${({ theme }) => theme.palette.text_light};
`;

export const Label = styled.span`
  font-size: 14px;
`;

export const Value = styled.strong``;
