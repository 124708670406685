/* eslint-disable */
export const selectStyles = {
  option: (provided: any, state: { isSelected: any }) => ({
    ...provided,
    color: '#000',
    padding: 20,
    backgroundColor: state.isSelected ? '#db1622' : '#fff',
    '&:hover': {
      backgroundColor: '#db162220',
    },
  }),
  control: (provided: any) => ({
    ...provided,
    border: '1px solid #aaa',
    borderColor: '#aaa',
    boxShadow: 0,
    '&:hover': {
      borderColor: '#db1622!important',
    },
  }),
};
