/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  FormEvent,
  useCallback,
} from 'react';

import * as Yup from 'yup';
import Toggle from 'react-toggle';
import VMasker from 'vanilla-masker';
import { FormHandles } from '@unform/core';
import { useHistory, useLocation } from 'react-router-dom';

import { writeText } from 'clipboard-polyfill/text';

import {
  MdContentCopy,
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
} from 'react-icons/md';

import { PixKeyType } from '../../enums/pixKeyType';
import IBusinessHours from '../../models/IBusinessHours';

import { AuthRole, useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { PageNames } from '../../enums/pages';
import { useCompany } from '../../hooks/company';
import { useSidebar } from '../../hooks/sidebar';

import Tour from '../../components/Tour';
import Input from '../../components/Input';
import Radio from '../../components/Radio';
import TextArea from '../../components/Textarea';
import CardBrand from '../../components/CardBrand';
import HelpButton from '../../components/HelpButton';
import TypeSelect from '../../components/TypeSelect';
import NumberInput from '../../components/NumberInput';
import FormlessInput from '../../components/FormlessInput';

import tourData from '../../tour/settings';
import { unmaskPixKey } from '../../utils/masks';
import { chooseBsFoodOrVarejo } from '../../utils/subdomain';
import { getValidationErrors } from '../../utils/errors';

import {
  Main,
  Span,
  Title,
  Header,
  Content,
  SubPage,
  PageName,
  SubPages,
  Container,
  GroupTitle,
  CompanyName,
  DisplayName,
  UserSettings,
  PixContainer,
  TypeContainer,
  RadioContainer,
  GroupSubTitles,
  GroupContainer,
  PricesContaier,
  BrandsContainer,
  CompanySettings,
  ToggleContainer,
  AddressContainer,
  GroupDescription,
  HorizontalWrapper,
  HorizontalWrapper2,
  InnerGroupContainer,
  PaymentTypesContainer,
  DeliveryTypesContainer,
  BusinessHoursGroupTitle,
  BusinessHoursGroupContainer,
  Options,
  DivPadding,
  IntegrationContainer,
  ContentContainer,
  CompanyCode,
  CompanyCodeContainer,
  ChatbotContainer,
  BsTicketContainer,
} from './styles';

import BusinessHours from '../../components/BusinessHours';
// import { convertHHmmssToNumber, getWeekDayString } from '../../utils/date';
import * as chatbotService from '../../services/chatbot';
import SaveButton from '../../components/SaveButton';
import api from '../../services/api';
import { IFacebookPixelIntegrationDTO } from '../../dtos/IIntegrationDTO';
import { PizzaCharges } from '../../enums/pizza';
import CustomPizzaChargeModal from '../../components/CustomPizzaChargeModal';
import { ICustomPizzaCharge } from '../../models/IPizza';
import OrderButton from '../../components/OrderButton';
import OrderGroupModal, { IGroupProps } from '../../components/OrderGroupModal';
import { EPlanModule } from '../../models/IPlan';
import { useBsTicket } from '../../hooks/bsticket';
import { BsTicketPrintCodeType } from '../../enums/bsticket';

interface IUpdateCompanyFormData {
  city: string;
  state: string;
  address2: string;
  reference: string;
  postalCode: string;
  streetName: string;
  tradingName: string;
  phoneNumber: string;
  tableAmount: string;
  streetNumber: string;
  neighborhood: string;
  welcomeMessage: string;
  cellPhoneNumber: string;
  timeToDelivery: number;
  timeToPickup: number;
}

interface IUpdateUserFormData {
  password: string;
  confirmPassword: string;
  currentPassword?: string;
}

const SettingsPage: React.FC = () => {
  const userFormRef = useRef<FormHandles | null>(null);
  const companyFormRef = useRef<FormHandles | null>(null);
  const bsTicketFormRef = useRef<FormHandles | null>(null);
  const optionsFormRef = useRef<FormHandles | null>(null);
  const integrationFormRef = useRef<FormHandles | null>(null);

  const history = useHistory();

  const { setSelectedPage, selectedPage } = useSidebar();

  const { addToast } = useToast();
  const { user, updateUser } = useAuth();

  const {
    company,
    isSavingCompany,
    saveCompany,
    saveBusinessHours,
    saveFacebookPixelIntegration,
    toggleOption,
    togglePaymentCard,
    saveCustomPizzaCharges,
  } = useCompany();

  const {
    productsToOrder,
    loadProductsToOrder,
    saveProductsOrder,
    changeBsTicketCodeType,
  } = useBsTicket();

  const location = useLocation();
  const [isTourVisible, setIsTourVisible] = useState(false);

  const [pixKey, setPixKey] = useState('');
  const [pixType, setPixType] = useState<PixKeyType>(PixKeyType.OTHER);

  const [page, setPage] = useState<1 | 2 | 3 | 4 | 5 | 6>(1);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [minOrderPrice, setMinOrderPrice] = useState(0);
  // const [autoApprovesOrder, setAutoApprovesOrder] = useState(false);

  const [businessHours, setBusinessHours] = useState<IBusinessHours>([]);

  const [showMenuDocumentInput, setShowMenuDocumentInput] = useState(false);

  const [clientId, setClientId] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [showApiKey, setShowApiKey] = useState(false);

  const [chatbotUrl, setChatbotUrl] = useState('');

  const [
    isCustomPizzaChargeModalOpen,
    setIsCustomPizzaChargeModalOpen,
  ] = useState(false);

  const pixMask = useMemo(() => {
    if (pixType === PixKeyType.CPF) {
      return '999.999.999-99';
    }

    if (pixType === PixKeyType.CNPJ) {
      return '99.999.999/9999-99';
    }

    return '(99) 9 9999 9999';
  }, [pixType]);

  const [
    isBsTicketProductsOrderModalOpen,
    setIsBsTicketProductsOrderModalOpen,
  ] = useState(false);

  useEffect(() => {
    if (company) {
      setDeliveryFee(company.deliveryFee || 0);

      setMinOrderPrice(company.minOrderPrice || 0);
      setBusinessHours(company.businessHours || []);

      // setAutoApprovesOrder(company.autoApprovesOrder);
      setShowMenuDocumentInput(company?.showMenuDocumentInput || false);

      setBusinessHours(
        Array.from({ length: 7 }).map((_, index) => {
          const foundDay = company.businessHours.find(
            hour => hour.day === index,
          );

          if (foundDay) {
            return foundDay;
          }

          return {
            day: index,
            isOpen: false,
            shifts: [{ open: '00:00', close: '23:59' }],
          };
        }),
      );

      let pixMaskLocal = '';

      if (company.pixType === PixKeyType.CPF) {
        pixMaskLocal = '999.999.999-99';
      }

      if (company.pixType === PixKeyType.CNPJ) {
        pixMaskLocal = '99.999.999/9999-99';
      }
      if (company.pixType === PixKeyType.PHONE) {
        pixMaskLocal = '(99) 9 9999 9999';
      }

      if (
        company.pixType === PixKeyType.CPF ||
        company.pixType === PixKeyType.CNPJ ||
        company.pixType === PixKeyType.PHONE
      ) {
        setPixKey(VMasker.toPattern(company.pixKey, pixMaskLocal));
      } else {
        setPixKey(company.pixKey);
      }

      setPixType(company.pixType || PixKeyType.OTHER);

      companyFormRef.current?.setFieldValue(
        'pixType',
        company.pixType || PixKeyType.OTHER,
      );
    }
  }, [company]);

  useEffect(() => {
    if (history.location.hash === '#tables') {
      const tableAmountInput = document.getElementById(
        'tableAmount',
      ) as HTMLInputElement;

      tableAmountInput?.scrollIntoView({ behavior: 'smooth' });
      tableAmountInput?.focus();
    }
  }, [history]);

  useEffect(() => {
    if (company?.enablesWhatsAppBot) {
      const search = new URLSearchParams(location.search);
      if (search.get('fromBot')) {
        setPage(5);
      }
    }
  }, [location, company]);

  const handleOnSubPageChanged = async (subPage: typeof page) => {
    setPage(subPage);

    if (subPage === 4) {
      loadIntegrationData();
    }

    if (subPage === 5) {
      const arch = window?.navigator?.userAgent.includes('Win64')
        ? 'x64'
        : 'ia32';

      const url = await chatbotService.getReleaseUrl(arch);
      setChatbotUrl(url);
    }

    setShowApiKey(false);
  };

  const handleOnMinOrderPriceChanged = useCallback((value: number) => {
    setMinOrderPrice(value);
  }, []);

  const handleOnUpdateCompany = useCallback(
    async (data: IUpdateCompanyFormData) => {
      const schema = Yup.object().shape({
        tradingName: Yup.string()
          .min(3, 'Mínimo de 3 caracteres.')
          .required('O nome de exibição é obrigatório.'),
        phoneNumber: Yup.string()
          .min(10, 'Mínimo de 10 caracteres.')
          .required('O telefone é obrigatório.'),
        cellPhoneNumber: Yup.string()
          .min(10, 'Mínimo de 10 caracteres.')
          .required('O celular é obrigatório.'),
        postalCode: Yup.number()
          .typeError('CEP inválido')
          .min(8, 'Mínimo de 3 caracteres.')
          .required('O CEP é obrigatório.'),
        state: Yup.string()
          .min(2, 'Mínimo de 2 caracteres.')
          .max(2, 'Máximo de 8 caracteres.'),
        city: Yup.string().min(3, 'Mínimo de 3 caracteres.'),
        streetName: Yup.string()
          .min(3, 'Mínimo de 3 caracteres.')
          .required('O logradouro é obrigatório.'),
        neighborhood: Yup.string().min(3, 'Mínimo de 3 caracteres.'),
        address2: Yup.string().test(
          'address2-length',
          'Se presente, mínimo de 3 caracteres.',
          async (value: string | null | undefined) => {
            if (value && value.length < 3) {
              return false;
            }
            return true;
          },
        ),
        reference: Yup.string().test(
          'reference-length',
          'Se presente, mínimo de 3 caracteres.',
          async (value: string | null | undefined) => {
            if (value && value.length < 3) {
              return false;
            }
            return true;
          },
        ),
        welcomeMessage: Yup.string().test(
          'welcome-message-length',
          'Se presente, mínimo de 10 caracteres.',
          async (value: string | null | undefined) => {
            if (value && value.length < 10) {
              return false;
            }
            return true;
          },
        ),
        timeToDelivery: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .required('Não pode ser vazio'),
        timeToPickup: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .required('Não pode ser vazio'),
      });

      companyFormRef.current?.setErrors({});

      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        // let businessHourError = '';

        // businessHours.every(hour => {
        //   return hour.shifts.every(shift => {
        //     const openHourNumber = convertHHmmssToNumber(shift.open);
        //     const closeHourNumber = convertHHmmssToNumber(shift.close);

        //     const weekDayName = getWeekDayString(hour.day);

        //     if (openHourNumber >= convertHHmmssToNumber('23:59')) {
        //       businessHourError = `O horário de abertura de ${weekDayName} deve ser menor que 23:59`;
        //       return false;
        //     }

        //     if (closeHourNumber <= openHourNumber) {
        //       businessHourError = `O horário de fechamento de ${weekDayName} deve ser maior que ${shift.open.substring(
        //         0,
        //         5,
        //       )}`;

        //       return false;
        //     }

        //     return true;
        //   });
        // });

        // if (businessHourError) {
        //   return addToast({ type: 'error', description: businessHourError });
        // }

        await saveBusinessHours(businessHours);

        await saveCompany({
          city: data.city,
          tradingName: data.tradingName,
          companyName: company?.companyName || '',
          document: company?.document || '',
          phoneNumber: data.phoneNumber,
          deliveryFee,
          minOrderPrice,
          neighborhood: data.neighborhood,
          postalCode: data.postalCode,
          state: data.state,
          streetName: data.streetName,
          streetNumber: data.streetNumber,
          address2: data.address2,
          reference: data.reference,
          cellPhoneNumber: data.cellPhoneNumber,
          tableAmount: Number(data.tableAmount),
          welcomeMessage: data.welcomeMessage,
          timeToDelivery: Number(data.timeToDelivery),
          timeToPickup: Number(data.timeToPickup),
          deliveryRanges: company?.deliveryRanges || [],
          showsWhatsApp: company?.showsWhatsApp || false,
          whatsApp: company?.whatsApp || '',
          showsFacebook: company?.showsFacebook || false,
          facebook: company?.facebook || '',
          showsInstagram: company?.showsInstagram || false,
          instagram: company?.instagram || '',
          companyLinks: company?.companyLinks || [],
          pixKey: unmaskPixKey(pixKey, pixType),
          pixType,
          primaryColor: company?.primaryColor,
          isDarkTheme: company?.isDarkTheme,
          loadingAnimation: company?.loadingAnimation,
          showMenuDocumentInput,
          scheduledProducts: company?.scheduledProducts || false,
          fbPixelId: company?.fbPixelId || '',
          fbMetaContent: company?.fbMetaContent || '',
        });

        addToast({
          type: 'success',
          description: 'Você alterou as informações da sua empresa.',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          companyFormRef.current?.setErrors(getValidationErrors(err));

          if (err instanceof Yup.ValidationError) {
            document
              .getElementById(err.inner[0].path)
              ?.scrollIntoView({ behavior: 'smooth' });
          }
          addToast({
            type: 'error',
            description: 'Verifique os campos em vermelho.',
          });
        } else {
          addToast({
            type: 'error',
            description: 'Verifique a conexão e tente novamente.',
          });
        }
      }
    },
    [
      addToast,
      saveCompany,
      saveBusinessHours,
      pixKey,
      company,
      pixType,
      deliveryFee,
      businessHours,
      minOrderPrice,
      showMenuDocumentInput,
    ],
  );

  const handleOnPixChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        pixType === PixKeyType.CPF ||
        pixType === PixKeyType.CNPJ ||
        pixType === PixKeyType.PHONE
      ) {
        setPixKey(VMasker.toPattern(e.target.value, pixMask));
      } else {
        setPixKey(e.target.value);
      }
    },
    [pixType, pixMask],
  );

  const handleOnUserSaved = useCallback(
    async (
      data: IUpdateUserFormData,
      event: FormEvent<Element> | undefined,
    ) => {
      event?.stopPropagation();

      const schema = Yup.object().shape({
        name: Yup.string()
          .min(3, 'Mínimo de 3 caracteres.')
          .required('O nome é obrigatório.'),
        currentPassword: Yup.string().test(
          'password-length',
          'Se presente, mínimo de 4 caracteres.',
          async (value: string | null | undefined) => {
            if (value && value.length < 4) {
              return false;
            }
            return true;
          },
        ),
        password: Yup.string()
          .when('currentPassword', {
            is: password => password.length >= 4,
            then: Yup.string().required('Nova senha é obrigatória.'),
          })
          .test(
            'password-length',
            'Se presente, mínimo de 4 caracteres.',
            async (value: string | null | undefined) => {
              if (value && value.length < 4) {
                return false;
              }
              return true;
            },
          ),
        confirmPassword: Yup.string()
          .when('currentPassword', {
            is: password => password >= 4,
            then: Yup.string().required('Confirmação de senha é obrigatória.'),
          })
          .when('password', {
            is: val => !!val.length,
            then: Yup.string().required(
              'A confirmação de senha é obrigatória.',
            ),
            otherwise: Yup.string(),
          })
          .oneOf([Yup.ref('password'), undefined], 'As senhas não conferem.'),
      });

      userFormRef.current?.setErrors({});

      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        await updateUser({
          ...data,
          email: user?.email || '',
        });

        addToast({
          type: 'success',
          description: 'Você alterou as sua informações.',
        });

        userFormRef.current?.setFieldValue('password', '');
        userFormRef.current?.setFieldValue('confirmPassword', '');
        userFormRef.current?.setFieldValue('currentPassword', '');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          userFormRef.current?.setErrors(getValidationErrors(err));

          if (err instanceof Yup.ValidationError) {
            document
              .getElementById(err.inner[0].path)
              ?.scrollIntoView({ behavior: 'smooth' });
          }

          addToast({
            type: 'error',
            description: 'Verifique os campos em vermelho.',
          });
        } else {
          addToast({
            type: 'error',
            description: 'Verifique a conexão e tente novamente.',
          });
        }
      }
    },
    [addToast, updateUser, user],
  );

  const handleOnIntegrationSaved = useCallback(
    async (data: IFacebookPixelIntegrationDTO) => {
      const schema = Yup.object().shape({
        fbPixelId: Yup.string().test(
          'length',
          'ID do Pixel precisa ter 15 caracteres.',
          value => (value ? value?.length === 15 : true),
        ),
        fbMetaContent: Yup.string().test(
          'length',
          'Código de verificação precisa ter entre 15 e 30 caracteres.',
          value => (value ? value?.length >= 15 && value?.length <= 30 : true),
        ),
      });

      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        await saveFacebookPixelIntegration(data);
        addToast({
          type: 'success',
          description: 'As alterações foram salvas com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          integrationFormRef.current?.setErrors(getValidationErrors(err));

          addToast({
            type: 'error',
            description: 'Verifique os campos em vermelho.',
          });
        } else {
          addToast({
            type: 'error',
            description: 'Ocorreu um erro inesperado.',
          });
        }
      }
    },
    [addToast, saveFacebookPixelIntegration],
  );

  const handleTypeChange = useCallback((value: string) => {
    setPixKey('');
    setPixType(value as PixKeyType);

    const element = document.getElementById('pix-key');
    if (element) {
      element.focus();
    }
  }, []);

  const handleOnPizzaChargeTypeChange = useCallback(
    async (value: PizzaCharges, force = false) => {
      if (!force && value === PizzaCharges.CUSTOM) {
        setIsCustomPizzaChargeModalOpen(true);
        return;
      }

      try {
        await api.patch('/restricted/companies/pizza-charges', {
          pizzaCharge: value,
        });

        addToast({
          type: 'success',
          description: 'Método de cobrança da pizza alterado com sucesso.',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro inesperado.',
        });
      }
    },
    [addToast],
  );

  const handleOnCustomPizzaChargeModalClose = useCallback(() => {
    setIsCustomPizzaChargeModalOpen(false);
  }, []);

  const handleOnCustomPizzaChargeModalConfirm = useCallback(
    async (customPizzaCharge: ICustomPizzaCharge) => {
      await saveCustomPizzaCharges(customPizzaCharge);
      await handleOnPizzaChargeTypeChange(PizzaCharges.CUSTOM, true);
    },
    [handleOnPizzaChargeTypeChange, saveCustomPizzaCharges],
  );

  // const handleOnAutoApprovesOrderChanged = useCallback(() => {
  //   setAutoApprovesOrder(prevState => !prevState);
  // }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsTourVisible(true);
  }, []);

  const handleTourFinish = useCallback(() => {
    setIsTourVisible(false);
  }, []);

  const handleOnChangeBusinessHours = useCallback(
    (newBusinessHours: IBusinessHours) => {
      setBusinessHours(newBusinessHours);
    },
    [],
  );

  const handleOnToggleIntegration = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const { data } = await api.post(
          `/restricted/companies/${company?.id}/api-key`,
        );

        setClientId(data.clientId);
        setApiKey(data.apiKey);
      }

      await toggleOption('pdvIntegration');
    },
    [company, toggleOption],
  );

  const loadIntegrationData = useCallback(async () => {
    const { data } = await api.get(
      `/restricted/companies/${company?.id}/api-key`,
    );

    setClientId(data.clientId);

    return setApiKey(data.apiKey);
  }, [company]);

  const handleToggleShowApiKey = useCallback(() => {
    if (apiKey) {
      setShowApiKey(old => !old);
    }
  }, [apiKey]);

  const handleOpenChatbot = useCallback(() => {
    if (user && company) {
      const url = `${process.env.REACT_APP_CHATBOT_PROTOCOL}config`;
      const query = `accessToken=${user.accessToken}&companyId=${company.id}&subdomain=${company.subdomain}`;

      window.open(`${url}?${query}`, '_parent');
    }
  }, [company, user]);

  const handleCopy = useCallback(
    async value => {
      await writeText(value);

      addToast({
        type: 'info',
        description: 'Copiado para àrea de transferência.',
      });
    },
    [addToast],
  );

  const formattedDocument = useMemo(() => {
    return VMasker.toPattern(company?.document || '', '99.999.999/9999-99');
  }, [company]);

  const handleFbMetaContentInput = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value || '';

      if (/name="facebook-domain-verification"/s.test(value)) {
        const content = (/(content=".*?")/.exec(value)![0] ?? '').replace(
          /content|"|=/g,
          '',
        );
        integrationFormRef?.current?.setFieldValue('fbMetaContent', content);
        return null;
      }

      if (/facebook-domain-verification/.test(value)) {
        try {
          const content = value.replace(/facebook-domain-verification=/g, '');
          integrationFormRef?.current?.setFieldValue('fbMetaContent', content);
        } catch {
          integrationFormRef?.current?.setFieldValue('fbMetaContent', '');
        }
        return null;
      }

      return null;
    },
    [],
  );

  const bsTickectProductsToOrder = useMemo<IGroupProps[]>(() => {
    return productsToOrder;
  }, [productsToOrder]);

  const handleOnOrderButtonBsTicketClicked = useCallback(() => {
    loadProductsToOrder();

    setIsBsTicketProductsOrderModalOpen(true);
  }, [loadProductsToOrder]);

  const handleOnBsTicketProductsOrderModalClose = useCallback(() => {
    setIsBsTicketProductsOrderModalOpen(false);
  }, []);

  const handleOnBsTicketProductsOrderModalConfirm = useCallback(
    async (newOrderedProducts: IGroupProps[]) => {
      try {
        await saveProductsOrder(newOrderedProducts);

        addToast({
          type: 'success',
          description: 'Produtos salvos com sucesso!',
        });

        setIsBsTicketProductsOrderModalOpen(false);
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro ao salvar os produtos!',
        });

        setIsBsTicketProductsOrderModalOpen(false);
      }
    },
    [addToast, saveProductsOrder],
  );

  const handleOnChangeBsTicketCodeType = useCallback(
    async (newCodeType: BsTicketPrintCodeType) => {
      try {
        await changeBsTicketCodeType(newCodeType);

        addToast({
          type: 'success',
          description: 'Código de impressão alterado com sucesso!',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro ao alterar o código de impressão!',
        });
      }
    },
    [addToast, changeBsTicketCodeType],
  );

  useEffect(() => {
    setSelectedPage(PageNames.SETTINGS);
  }, [setSelectedPage]);

  return (
    <Container>
      <Content>
        <Header>
          <PageName>
            {selectedPage}
            {page !== 6 && <HelpButton onClick={handleHelpButtonClick} />}
          </PageName>
        </Header>
        <SubPages>
          <SubPage
            onClick={() => handleOnSubPageChanged(1)}
            selected={page === 1}
          >
            EMPRESA
          </SubPage>
          <SubPage
            onClick={() => handleOnSubPageChanged(2)}
            selected={page === 2}
          >
            OPÇÕES
          </SubPage>
          <SubPage
            onClick={() => handleOnSubPageChanged(3)}
            selected={page === 3}
          >
            MEUS DADOS
          </SubPage>
          <AuthRole>
            <SubPage
              onClick={() => handleOnSubPageChanged(4)}
              selected={page === 4}
            >
              INTEGRAÇÃO
            </SubPage>
          </AuthRole>
          <AuthRole blackList={['Marketing']}>
            {company?.enablesWhatsAppBot && (
              <SubPage
                onClick={() => handleOnSubPageChanged(5)}
                selected={page === 5}
              >
                WHATSAPP
              </SubPage>
            )}
          </AuthRole>
          <AuthRole>
            {company?.subscription?.plan.modules?.includes(
              EPlanModule.BSTICKET,
            ) && (
              <SubPage
                onClick={() => handleOnSubPageChanged(6)}
                selected={page === 6}
              >
                BS TICKET
              </SubPage>
            )}
          </AuthRole>
        </SubPages>
        <Main className="has-custom-scroll-bar-2">
          {page === 1 ? (
            <CompanySettings
              ref={companyFormRef}
              onSubmit={handleOnUpdateCompany}
              initialData={{
                ...company,
                ...(company?.city && { city: company?.city.name }),
                ...(company?.city && { state: company?.city.state }),
              }}
            >
              <HorizontalWrapper id="settings-info">
                <DisplayName>
                  <div>
                    <Span>
                      <Title>Razão social</Title>
                      <CompanyName>{company?.companyName}</CompanyName>
                    </Span>
                    <Span>
                      <Title>CNPJ</Title>
                      <CompanyName>{formattedDocument}</CompanyName>
                    </Span>
                  </div>
                </DisplayName>
              </HorizontalWrapper>
              <GroupContainer id="settings-contact">
                <GroupTitle>Contato</GroupTitle>
                <AuthRole disableOnly>
                  <Input
                    id="tradingName"
                    name="tradingName"
                    title="Nome de exibição"
                  />
                </AuthRole>
                <HorizontalWrapper>
                  <AuthRole disableOnly>
                    <Input
                      id="phoneNumber"
                      title="Telefone"
                      name="phoneNumber"
                    />
                  </AuthRole>
                  <AuthRole disableOnly>
                    <Input
                      title="Celular"
                      id="cellPhoneNumber"
                      name="cellPhoneNumber"
                    />
                  </AuthRole>
                </HorizontalWrapper>
              </GroupContainer>
              <GroupContainer id="settings-address">
                <GroupTitle>Endereço</GroupTitle>
                <AddressContainer>
                  <AuthRole disableOnly>
                    <Input title="CEP" name="postalCode" id="postalCode" />
                  </AuthRole>
                  <AuthRole disableOnly>
                    <Input title="Estado" name="state" id="state" />
                  </AuthRole>
                  <AuthRole disableOnly>
                    <Input title="Cidade" name="city" id="city" />
                  </AuthRole>
                </AddressContainer>
                <HorizontalWrapper>
                  <AuthRole disableOnly>
                    <Input title="Rua" name="streetName" id="streetName" />
                  </AuthRole>
                  <AuthRole disableOnly>
                    <Input
                      title="Número"
                      id="streetNumber"
                      name="streetNumber"
                    />
                  </AuthRole>
                </HorizontalWrapper>
                <HorizontalWrapper>
                  <AuthRole disableOnly>
                    <Input
                      id="address2"
                      name="address2"
                      title="Complemento (não obrigatório)"
                    />
                  </AuthRole>
                  <AuthRole disableOnly>
                    <Input
                      title="Bairro"
                      id="neighborhood"
                      name="neighborhood"
                    />
                  </AuthRole>
                </HorizontalWrapper>
                <AuthRole disableOnly>
                  <Input
                    id="reference"
                    name="reference"
                    title="Referência (não obrigatório)"
                  />
                </AuthRole>
              </GroupContainer>
              <GroupContainer id="settings-business-hours">
                <BusinessHoursGroupTitle>
                  Horários de atendimento
                </BusinessHoursGroupTitle>
                <BusinessHoursGroupContainer>
                  <AuthRole disableOnly>
                    <BusinessHours
                      businessHours={businessHours}
                      onChange={handleOnChangeBusinessHours}
                    />
                  </AuthRole>
                </BusinessHoursGroupContainer>
              </GroupContainer>
              <GroupContainer id="settings-welcome">
                <GroupTitle>Mensagem de boas-vindas</GroupTitle>
                <AuthRole disableOnly>
                  <TextArea name="welcomeMessage" title="Mensagem" />
                </AuthRole>
              </GroupContainer>
              <GroupContainer id="settings-prices">
                <GroupTitle>Atendimento e PIX</GroupTitle>
                <PricesContaier>
                  <HorizontalWrapper2>
                    <AuthRole disableOnly>
                      <NumberInput
                        value={minOrderPrice}
                        onChange={handleOnMinOrderPriceChanged}
                        title="Pedido mínimo"
                        defaultValue={company?.minOrderPrice || 0}
                      />
                    </AuthRole>
                    <AuthRole disableOnly>
                      <Input
                        type="number"
                        id="tableAmount"
                        name="tableAmount"
                        title="Quantidade de mesas"
                      />
                    </AuthRole>
                  </HorizontalWrapper2>
                  <HorizontalWrapper2>
                    <AuthRole disableOnly>
                      <Input
                        type="number"
                        id="timeToDelivery"
                        name="timeToDelivery"
                        title="Tempo de entrega (em minutos)"
                        defaultValue={company?.timeToDelivery}
                      />
                    </AuthRole>
                    <AuthRole disableOnly>
                      <Input
                        type="number"
                        id="timeToPickup"
                        name="timeToPickup"
                        title="Tempo de retirada (em minutos)"
                        defaultValue={company?.timeToPickup}
                      />
                    </AuthRole>
                  </HorizontalWrapper2>
                </PricesContaier>
                <GroupSubTitles>Chave pix</GroupSubTitles>
                <PixContainer>
                  <AuthRole disableOnly>
                    <FormlessInput
                      id="pix-key"
                      name="pixKey"
                      value={pixKey}
                      title="Chave PIX"
                      onChange={handleOnPixChanged}
                    />
                  </AuthRole>
                  <TypeContainer>
                    <h4>Selecione o tipo de chave:</h4>
                    <AuthRole disableOnly>
                      <RadioContainer
                        name="pixType"
                        onChange={handleTypeChange}
                      >
                        <Radio.Option value={PixKeyType.EMAIL}>
                          E-mail
                          <MdRadioButtonUnchecked
                            size={20}
                            className="unchecked"
                          />
                          <MdRadioButtonChecked size={20} className="checked" />
                        </Radio.Option>
                        <Radio.Option value={PixKeyType.CNPJ}>
                          CNPJ
                          <MdRadioButtonUnchecked
                            size={20}
                            className="unchecked"
                          />
                          <MdRadioButtonChecked size={20} className="checked" />
                        </Radio.Option>
                        <Radio.Option value={PixKeyType.CPF}>
                          CPF
                          <MdRadioButtonUnchecked
                            size={20}
                            className="unchecked"
                          />
                          <MdRadioButtonChecked size={20} className="checked" />
                        </Radio.Option>
                        <Radio.Option value={PixKeyType.PHONE}>
                          Telefone
                          <MdRadioButtonUnchecked
                            size={20}
                            className="unchecked"
                          />
                          <MdRadioButtonChecked size={20} className="checked" />
                        </Radio.Option>
                        <Radio.Option value={PixKeyType.OTHER}>
                          Outro
                          <MdRadioButtonUnchecked
                            size={20}
                            className="unchecked"
                          />
                          <MdRadioButtonChecked size={20} className="checked" />
                        </Radio.Option>
                      </RadioContainer>
                    </AuthRole>
                  </TypeContainer>
                </PixContainer>
              </GroupContainer>
              <AuthRole>
                <SaveButton type="submit" id="settings-save" />
              </AuthRole>
              <DivPadding />
            </CompanySettings>
          ) : page === 2 ? (
            <Options
              ref={companyFormRef}
              onSubmit={handleOnUpdateCompany}
              initialData={{
                ...company,
                ...(company?.city && { city: company?.city.name }),
                ...(company?.city && { state: company?.city.state }),
              }}
            >
              <CustomPizzaChargeModal
                charge={company?.pizzaChargeCustom}
                isOpen={isCustomPizzaChargeModalOpen}
                onClose={handleOnCustomPizzaChargeModalClose}
                onConfirm={handleOnCustomPizzaChargeModalConfirm}
              />
              <GroupContainer>
                <GroupTitle>Pagamento e Entrega</GroupTitle>
                <div id="settings-payment">
                  <GroupSubTitles>Tipos de Pagamento</GroupSubTitles>
                  <AuthRole disableOnly>
                    <PaymentTypesContainer id="settings-payment">
                      <TypeSelect
                        source="CASH"
                        name="Dinheiro"
                        type="payment"
                      />
                      <TypeSelect
                        source="DEBIT_CARD"
                        name="Cartão de Débito"
                        type="payment"
                      />
                      <TypeSelect
                        source="CREDIT_CARD"
                        name="Cartão de Crédito"
                        type="payment"
                      />
                      <TypeSelect source="PIX" name="Pix" type="payment" />
                      <TypeSelect
                        source="PIC_PAY"
                        name="PicPay"
                        type="payment"
                      />
                      <TypeSelect source="AME" name="Ame" type="payment" />
                      <TypeSelect
                        source="FB_PAYMENT"
                        name="Whatsapp"
                        type="payment"
                      />
                      <TypeSelect source="CHECK" name="Cheque" type="payment" />
                      <TypeSelect
                        source="VOUCHER"
                        name="Vale Refeição"
                        type="payment"
                      />
                      <TypeSelect
                        source="MONTHLY_PAYER"
                        name="Mensalista"
                        type="payment"
                      />
                    </PaymentTypesContainer>
                  </AuthRole>
                </div>
                <div id="settings-delivery">
                  <GroupSubTitles>Tipos de Entrega</GroupSubTitles>
                  <AuthRole disableOnly>
                    <DeliveryTypesContainer>
                      <TypeSelect
                        source="DELIVERY"
                        name="Delivery"
                        type="delivery"
                      />
                      <TypeSelect
                        source="CHECKOUT"
                        name="Retira"
                        type="delivery"
                      />
                      <TypeSelect
                        source="DRIVE_THRU"
                        name="Drive Thru"
                        type="delivery"
                      />
                      <TypeSelect
                        source="ON_TABLE"
                        name="Mesa"
                        type="delivery"
                      />
                    </DeliveryTypesContainer>
                  </AuthRole>
                </div>
                <div id="settings-delivery-area">
                  <AuthRole blackList={['Employee']} disableOnly>
                    <GroupSubTitles>Entrega</GroupSubTitles>
                    <ToggleContainer>
                      <Toggle
                        icons={false}
                        checked={company?.ordersAnotherCity}
                        className="print-additionals-toggle"
                        onChange={() => toggleOption('ordersAnotherCity')}
                      />
                      <span>Aceitar pedidos de outra cidade.</span>
                    </ToggleContainer>
                    <ToggleContainer>
                      <Toggle
                        icons={false}
                        checked={company?.singleZipCode}
                        className="single-zip-toggle"
                        onChange={() => toggleOption('singleZipCode')}
                      />
                      <span>CEP único.</span>
                    </ToggleContainer>
                  </AuthRole>
                </div>
                <div id="settings-print">
                  <GroupSubTitles>Impressão</GroupSubTitles>
                  <ToggleContainer>
                    <AuthRole blackList={['Employee']} disableOnly>
                      <Toggle
                        icons={false}
                        checked={company?.showAdditionalsInPrint}
                        className="print-additionals-toggle"
                        onChange={() => toggleOption('showAdditionalsInPrint')}
                      />
                    </AuthRole>
                    <span>Mostrar adicionais na impressão do cliente.</span>
                  </ToggleContainer>
                  <ToggleContainer>
                    <AuthRole blackList={['Employee']} disableOnly>
                      <Toggle
                        icons={false}
                        checked={company?.isPrintSummedUp}
                        className="print-additionals-toggle"
                        onChange={() => toggleOption('isPrintSummedUp')}
                      />
                    </AuthRole>
                    <span>Impressão resumida.</span>
                  </ToggleContainer>
                  <ToggleContainer>
                    <AuthRole blackList={['Employee']} disableOnly>
                      <Toggle
                        icons={false}
                        checked={company?.orderPrintInvertComplements}
                        className="print-additionals-toggle"
                        onChange={() =>
                          toggleOption('orderPrintInvertComplements')
                        }
                      />
                    </AuthRole>
                    <span>
                      Inverter ordem de exibição de complementos na impressão.
                    </span>
                  </ToggleContainer>
                  <ToggleContainer>
                    <AuthRole blackList={['Employee']} disableOnly>
                      <Toggle
                        icons={false}
                        checked={company?.showPrintComplementsTitle}
                        className="print-kitchen-layout"
                        onChange={() =>
                          toggleOption('showPrintComplementsTitle')
                        }
                      />
                    </AuthRole>
                    <span>Exibir título dos complementos na impressão.</span>
                  </ToggleContainer>
                  <ToggleContainer>
                    <AuthRole blackList={['Employee']} disableOnly>
                      <Toggle
                        icons={false}
                        checked={company?.printKitchenLayout}
                        className="print-kitchen-layout"
                        onChange={() => toggleOption('printKitchenLayout')}
                      />
                    </AuthRole>
                    <span>Exibir impressão da cozinha por padrão.</span>
                  </ToggleContainer>
                </div>
                <div id="settings-show-card-brands">
                  <GroupSubTitles>Bandeiras</GroupSubTitles>
                  <ToggleContainer>
                    <AuthRole blackList={['Employee']} disableOnly>
                      <Toggle
                        icons={false}
                        checked={company?.showCardBrands}
                        className="show-card-brands-toggle"
                        onChange={() => toggleOption('showCardBrands')}
                      />
                    </AuthRole>
                    <span>Mostrar bandeiras de cartões aceitas.</span>
                  </ToggleContainer>
                </div>
              </GroupContainer>
              <GroupContainer id="settings-menu">
                <GroupTitle>
                  {chooseBsFoodOrVarejo('Cardápio', 'Catálogo')}
                </GroupTitle>
                <GroupSubTitles>Exibição</GroupSubTitles>
                <ToggleContainer>
                  <AuthRole blackList={['Employee']} disableOnly>
                    <Toggle
                      icons={false}
                      checked={company?.enablesSubcategories}
                      className="enable-subcategories-toggle"
                      onChange={() => toggleOption('enablesSubcategories')}
                    />
                  </AuthRole>
                  <span>Habilita agrupamento de subcategorias.</span>
                </ToggleContainer>
                <ToggleContainer>
                  <AuthRole blackList={['Employee']} disableOnly>
                    <Toggle
                      icons={false}
                      checked={company?.enablesInternalCode}
                      className="enable-internalcode-toggle"
                      onChange={() => toggleOption('enablesInternalCode')}
                    />
                  </AuthRole>
                  <span>Habilita o código interno no menu</span>
                </ToggleContainer>
                <ToggleContainer>
                  <AuthRole blackList={['Employee']} disableOnly>
                    <Toggle
                      icons={false}
                      checked={company?.showTopSellingProducts}
                      className="show-top-selling-products"
                      onChange={() => toggleOption('showTopSellingProducts')}
                    />
                  </AuthRole>
                  <span>Mostrar produtos mais vendidos</span>
                </ToggleContainer>
                <GroupSubTitles>Pedidos sem Login</GroupSubTitles>
                <ToggleContainer>
                  <AuthRole blackList={['Employee']} disableOnly>
                    <Toggle
                      icons={false}
                      checked={company?.acceptsUnauthedOrders}
                      className="accepts-unauthed-orders-toggle"
                      onChange={() => toggleOption('acceptsUnauthedOrders')}
                    />
                  </AuthRole>
                  <span>Habilita pedidos sem autenticação.</span>
                </ToggleContainer>
                <GroupSubTitles>Nota Fiscal</GroupSubTitles>
                <ToggleContainer>
                  <AuthRole blackList={['Employee']} disableOnly>
                    <Toggle
                      icons={false}
                      checked={company?.showMenuDocumentInput}
                      className="show-document-input"
                      onChange={() => toggleOption('showMenuDocumentInput')}
                    />
                  </AuthRole>
                  <span>Habilita campo de CPF na nota</span>
                </ToggleContainer>
                {!company?.subscription?.plan?.showCase && (
                  <InnerGroupContainer>
                    <GroupSubTitles>Modo demonstração</GroupSubTitles>
                    <GroupDescription>
                      O modo demonstração apenas exibe os itens, não permitindo
                      pedidos.
                    </GroupDescription>
                    <ToggleContainer>
                      <AuthRole blackList={['Employee']} disableOnly>
                        <Toggle
                          icons={false}
                          checked={company?.showcaseDelivery}
                          onChange={() => toggleOption('showcaseDelivery')}
                          className="enable-showcase-delivery-toggle"
                        />
                      </AuthRole>
                      <span>Demonstração no delivery</span>
                    </ToggleContainer>
                    <ToggleContainer>
                      <AuthRole blackList={['Employee']} disableOnly>
                        <Toggle
                          icons={false}
                          checked={company?.showcaseTable}
                          onChange={() => toggleOption('showcaseTable')}
                          className="enable-showcase-table-toggle"
                        />
                      </AuthRole>
                      <span>Demonstração na mesa</span>
                    </ToggleContainer>
                  </InnerGroupContainer>
                )}
                <InnerGroupContainer>
                  <GroupSubTitles>Pizza</GroupSubTitles>
                  <GroupDescription>
                    Selecione o modo de cobrança referente as pizzas de
                    múltiplos sabores.
                  </GroupDescription>
                  <AuthRole disableOnly>
                    <RadioContainer
                      name="pizzaChargeType"
                      onChange={value =>
                        handleOnPizzaChargeTypeChange(value as PizzaCharges)
                      }
                    >
                      <Radio.Option
                        value={PizzaCharges.AVERAGE}
                        defaultChecked={
                          company?.pizzaCharge === PizzaCharges.AVERAGE
                        }
                      >
                        Valor médio
                        <MdRadioButtonUnchecked
                          size={20}
                          className="unchecked"
                        />
                        <MdRadioButtonChecked size={20} className="checked" />
                      </Radio.Option>
                      <Radio.Option
                        value={PizzaCharges.GREATER}
                        defaultChecked={
                          company?.pizzaCharge === PizzaCharges.GREATER
                        }
                      >
                        Maior valor
                        <MdRadioButtonUnchecked
                          size={20}
                          className="unchecked"
                        />
                        <MdRadioButtonChecked size={20} className="checked" />
                      </Radio.Option>
                      <Radio.Option
                        value={PizzaCharges.CUSTOM}
                        defaultChecked={
                          company?.pizzaCharge === PizzaCharges.CUSTOM
                        }
                      >
                        Customizado
                        <MdRadioButtonUnchecked
                          size={20}
                          className="unchecked"
                        />
                        <MdRadioButtonChecked size={20} className="checked" />
                      </Radio.Option>
                    </RadioContainer>
                  </AuthRole>
                </InnerGroupContainer>
              </GroupContainer>
              <GroupContainer id="settings-card-brands">
                <GroupTitle>Bandeiras aceitas</GroupTitle>
                <BrandsContainer>
                  {company?.paymentCards.map(brand => (
                    <CardBrand
                      brand={brand}
                      key={brand.paymentCardId}
                      onChange={togglePaymentCard}
                    />
                  ))}
                </BrandsContainer>
              </GroupContainer>
            </Options>
          ) : page === 3 ? (
            <UserSettings
              ref={optionsFormRef}
              initialData={user || {}}
              onSubmit={(data, _, event) => handleOnUserSaved(data, event)}
            >
              <GroupContainer id="settings-user">
                <Input title="Nome" name="name" />
                <Input title="Email" name="email" disabled />
              </GroupContainer>
              <GroupContainer id="settings-user-password">
                <GroupTitle>Alterar senha</GroupTitle>
                <Input
                  type="password"
                  title="Senha atual"
                  name="currentPassword"
                  hasPasswordEye
                />
                <Input
                  title="Nova senha"
                  name="password"
                  type="password"
                  hasPasswordEye
                />
                <Input
                  type="password"
                  name="confirmPassword"
                  title="Confirmar senha"
                  hasPasswordEye
                />
              </GroupContainer>
              <SaveButton type="submit" id="settings-user-save" />
              <DivPadding id="div-padding" />
            </UserSettings>
          ) : page === 4 ? (
            <AuthRole>
              <IntegrationContainer
                ref={integrationFormRef}
                onSubmit={handleOnIntegrationSaved}
              >
                <GroupContainer>
                  <GroupTitle>PDV</GroupTitle>
                  <ContentContainer id="contentContainer">
                    <ToggleContainer id="settings-integration-toggle">
                      <Toggle
                        icons={false}
                        checked={company?.pdvIntegration}
                        onChange={handleOnToggleIntegration}
                        onClick={() => setShowApiKey(false)}
                      />
                      <span>Integrar com o PDV</span>
                    </ToggleContainer>
                    {company?.pdvIntegration && (
                      <HorizontalWrapper>
                        <CompanyCodeContainer id="clientIdContainer">
                          <span>Código da Empresa</span>
                          <CompanyCode>
                            <span id="clientId">{clientId}</span>
                            <button
                              className="copyButton"
                              type="button"
                              onClick={() => handleCopy(clientId)}
                            >
                              <MdContentCopy size={20} title="Copiar" />
                            </button>
                          </CompanyCode>
                        </CompanyCodeContainer>
                        <CompanyCodeContainer id="apiCompanyKeyContainer">
                          <span>Código da API</span>
                          <CompanyCode>
                            <button
                              id="showCode"
                              type="button"
                              onClick={handleToggleShowApiKey}
                            >
                              <span>
                                {!showApiKey
                                  ? '[ clique aqui para ver o código ]'
                                  : apiKey}
                              </span>
                            </button>
                            {showApiKey && (
                              <button
                                className="copyButton"
                                type="button"
                                onClick={() => handleCopy(apiKey)}
                              >
                                <MdContentCopy size={20} title="Copiar" />
                              </button>
                            )}
                          </CompanyCode>
                        </CompanyCodeContainer>
                      </HorizontalWrapper>
                    )}
                  </ContentContainer>
                </GroupContainer>
                <GroupContainer>
                  <GroupTitle>Facebook Pixel</GroupTitle>
                  <ContentContainer id="fbPixelContainer">
                    <Input
                      type="text"
                      name="fbPixelId"
                      title="ID do Pixel"
                      defaultValue={company?.fbPixelId}
                    />
                    <Input
                      type="text"
                      name="fbMetaContent"
                      title="Código de verificação (meta tag)"
                      defaultValue={company?.fbMetaContent}
                      onInput={handleFbMetaContentInput}
                    />
                    <SaveButton loading={isSavingCompany} />
                  </ContentContainer>
                </GroupContainer>
              </IntegrationContainer>
            </AuthRole>
          ) : page === 5 ? (
            <ChatbotContainer>
              <GroupContainer>
                <GroupTitle>Bot do Whatsapp</GroupTitle>
                <CompanyCodeContainer>
                  {chatbotUrl && (
                    <>
                      <span>Instalar aplicação</span>
                      <CompanyCode>
                        <a
                          id="download-chatbot"
                          href={chatbotUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <span>Fazer o Download</span>
                        </a>
                      </CompanyCode>
                    </>
                  )}
                  <span>Configurar bot</span>
                  <CompanyCode>
                    <button
                      id="open-whatsapp-bot"
                      type="button"
                      onClick={handleOpenChatbot}
                    >
                      <span>Abrir/configurar bot</span>
                    </button>
                  </CompanyCode>
                </CompanyCodeContainer>
              </GroupContainer>
            </ChatbotContainer>
          ) : (
            <Options
              ref={bsTicketFormRef}
              onSubmit={() => null}
              initialData={{
                ...company,
              }}
            >
              <BsTicketContainer>
                <GroupContainer>
                  <GroupTitle>Pedidos</GroupTitle>
                  <div id="settings-bsticket-orders">
                    <GroupSubTitles>Totem</GroupSubTitles>
                    <ToggleContainer style={{ marginBottom: 32 }}>
                      <Toggle
                        icons={false}
                        checked={company?.bsTicketEnablesCustomerNameOnOrder}
                        onChange={() =>
                          toggleOption('bsTicketEnablesCustomerNameOnOrder')
                        }
                      />
                      <span>Solicitar nome do cliente.</span>
                    </ToggleContainer>
                    <GroupSubTitles>Impressão</GroupSubTitles>
                    <ToggleContainer>
                      <Toggle
                        icons={false}
                        checked={company?.bsTicketEnablesOrderPrint}
                        onChange={() =>
                          toggleOption('bsTicketEnablesOrderPrint')
                        }
                      />
                      <span>Imprimir pedido.</span>
                    </ToggleContainer>
                    <ToggleContainer>
                      <Toggle
                        icons={false}
                        checked={company?.bsTicketEnablesTicketPrint}
                        onChange={() =>
                          toggleOption('bsTicketEnablesTicketPrint')
                        }
                      />
                      <span>Imprimir ficha.</span>
                    </ToggleContainer>
                    <div>
                      <GroupDescription>Impressão de código</GroupDescription>
                      <RadioContainer
                        name="bsTicketPrintCodeType"
                        onChange={newCodeType =>
                          handleOnChangeBsTicketCodeType(
                            newCodeType as BsTicketPrintCodeType,
                          )
                        }
                      >
                        <Radio.Option value={BsTicketPrintCodeType.BARCODE}>
                          Código de barras
                          <MdRadioButtonUnchecked
                            size={20}
                            className="unchecked"
                          />
                          <MdRadioButtonChecked size={20} className="checked" />
                        </Radio.Option>
                        <Radio.Option value={BsTicketPrintCodeType.QRCODE}>
                          QR Code
                          <MdRadioButtonUnchecked
                            size={20}
                            className="unchecked"
                          />
                          <MdRadioButtonChecked size={20} className="checked" />
                        </Radio.Option>
                        <Radio.Option value={BsTicketPrintCodeType.NONE}>
                          Nenhum
                          <MdRadioButtonUnchecked
                            size={20}
                            className="unchecked"
                          />
                          <MdRadioButtonChecked size={20} className="checked" />
                        </Radio.Option>
                      </RadioContainer>
                    </div>
                  </div>
                </GroupContainer>
                <GroupContainer>
                  <GroupTitle>Produtos</GroupTitle>
                  <div id="settings-bsticket-products">
                    <GroupSubTitles>Ordenação</GroupSubTitles>
                    <OrderButton
                      id="bs-ticket-order-products-button"
                      onClick={handleOnOrderButtonBsTicketClicked}
                    />
                  </div>
                  <div id="settings-bsticket-stock">
                    <GroupSubTitles>Estoque</GroupSubTitles>
                    <ToggleContainer>
                      <Toggle
                        icons={false}
                        checked={company?.bsTicketEnablesStockControl}
                        onChange={() =>
                          toggleOption('bsTicketEnablesStockControl')
                        }
                      />
                      <span>Habilita controle de estoque.</span>
                    </ToggleContainer>
                  </div>
                </GroupContainer>
              </BsTicketContainer>
            </Options>
          )}
        </Main>
      </Content>
      {isTourVisible && (
        <Tour
          steps={
            page === 1
              ? tourData.company
              : page === 2
              ? tourData.options
              : page === 3
              ? tourData.user
              : tourData.integration
          }
          onFinish={handleTourFinish}
        />
      )}
      <OrderGroupModal
        id="bsTicketProductsOrderModal"
        isOpen={isBsTicketProductsOrderModalOpen}
        groups={bsTickectProductsToOrder}
        onClose={handleOnBsTicketProductsOrderModalClose}
        onConfirm={handleOnBsTicketProductsOrderModalConfirm}
      />
    </Container>
  );
};

export default SettingsPage;
