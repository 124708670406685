import styled from 'styled-components';

interface IContainerProps {
  online?: boolean;
}

export const Container = styled.div<IContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${({ online }) => (!online ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  backdrop-filter: blur(6px);
`;

export const Content = styled.div`
  background: ${({ theme }) => theme.palette.background};
  border-radius: 8px;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  pointer-events: none;

  svg {
    margin-right: 16px;
  }

  p {
    max-width: 180px;
    text-align: left;
  }
`;
