import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { FiCheck } from 'react-icons/fi';
import Spinner from 'react-spinner-material';

import IProductSku from '../../models/IProductSku';
import { PageNames } from '../../enums/pages';
import { LocalStorage } from '../../enums/localstorage';

import LoadingAnimation from '../../components/LoadingAnimation';
import SortByButtons, {
  SortByButtonsEnum,
} from '../../components/SortByButtons';
import ProductSku from '../../components/ProductSku';
import Search from '../../components/Search';

import { useCompany } from '../../hooks/company';
import { useProducts } from '../../hooks/products';
import { useToast } from '../../hooks/toast';
import { useSidebar } from '../../hooks/sidebar';

import {
  Main,
  Header,
  Content,
  PageName,
  Container,
  NoProductsFound,
  ProductsContainer,
  SaveSkuButton,
} from './styles';
import { search } from '../../utils/search';
import { ECategoryType } from '../../enums/categoryType';

const UpdateSkuPage: React.FC = () => {
  const { setSelectedPage, selectedPage } = useSidebar();
  const { addToast } = useToast();
  const { company } = useCompany();
  const { products, isProductsLoading, loadProducts, saveSku } = useProducts();

  const [searchCriteria, setSearchCriteria] = useState('');
  const [normalizedItems, setNormalizedItems] = useState<IProductSku[]>([]);
  const [hasUpdates, setHasUpdates] = useState(false);
  const [loading, setLoading] = useState(false);

  const [sortBy, setSortBy] = useState<SortByButtonsEnum>(
    (localStorage.getItem(
      LocalStorage.PRODUCTS_PREFERABLE_SORT,
    ) as SortByButtonsEnum) || SortByButtonsEnum.DATE_DESC,
  );

  useEffect(() => {
    setNormalizedItems(
      products.map(p => {
        return {
          id: p.id,
          name: p.title,
          sellerSku: p.sellerSku,
          hash: p.hash,
          image: p.imageUrl,
          unitPrice: p?.unitPrice,
          isPizza: p?.categories?.some(c => c.type === ECategoryType.PIZZA),
        };
      }),
    );
  }, [products]);

  useEffect(() => {
    async function loadData() {
      await loadProducts();
    }

    if (company) {
      loadData();
    }
  }, [loadProducts, company]);

  const handleOnSearchCriteriaChanged = useCallback((text: string) => {
    setSearchCriteria(text);
  }, []);

  const handleOnSkuChanged = useCallback((id: number, sku: string) => {
    setHasUpdates(true);
    setNormalizedItems(prevState => {
      const newState = [...prevState];

      const foundItem = prevState.findIndex(item => item.id === id);

      if (foundItem > -1) {
        newState[foundItem].sellerSku = sku;
        return newState;
      }

      return prevState;
    });
  }, []);

  const handleOnUnitPriceChanged = useCallback((id: number, price: number) => {
    setHasUpdates(true);
    setNormalizedItems(prevState => {
      const newState = [...prevState];

      const foundItem = prevState.findIndex(item => item.id === id);

      if (foundItem > -1) {
        newState[foundItem].unitPrice = price;
        return newState;
      }

      return prevState;
    });
  }, []);

  const searchedProducts = useMemo(() => {
    const filteredProducts = search(
      normalizedItems,
      (item: IProductSku) => item.name + item.sellerSku,
      searchCriteria,
    );

    const sortedProducts = filteredProducts.sort((a, b) => {
      if (sortBy === SortByButtonsEnum.DATE_DESC) {
        return a.id > b.id ? -1 : 1;
      }

      if (sortBy === SortByButtonsEnum.DATE_ASC) {
        return a.id < b.id ? -1 : 1;
      }

      if (sortBy === SortByButtonsEnum.ALPHABETICAL_ASC) {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      }
      if (sortBy === SortByButtonsEnum.ALPHABETICAL_DESC) {
        return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
      }

      return 0;
    });

    return sortedProducts;
  }, [searchCriteria, normalizedItems, sortBy]);

  const alteredItems = useMemo(() => {
    return normalizedItems.filter(i => i.sellerSku);
  }, [normalizedItems]);

  const handleOnSkuSaved = useCallback(async () => {
    setLoading(true);
    try {
      await saveSku(alteredItems);
      addToast({
        type: 'success',
        description: 'Códigos internos salvos com sucesso.',
      });
      setHasUpdates(false);
    } catch (err) {
      const errors = (err as any)?.response?.data?.errors?.messages;

      addToast({
        type: 'error',
        description:
          (Array.isArray(errors) && errors[0]) || 'Ocorreu um erro inesperado.',
      });
    }
    setLoading(false);
  }, [addToast, alteredItems, saveSku]);

  const handleOnSortByChanged = useCallback((sortBy: SortByButtonsEnum) => {
    setSortBy(sortBy);
    localStorage.setItem(LocalStorage.PRODUCTS_PREFERABLE_SORT, sortBy);
  }, []);

  useEffect(() => {
    setSelectedPage(PageNames.UPDATE_SKU);
  }, [setSelectedPage]);

  return (
    <Container>
      <Content>
        {hasUpdates && (
          <SaveSkuButton onClick={handleOnSkuSaved}>
            {loading ? (
              <Spinner radius={24} stroke={2} visible color="black" />
            ) : (
              <FiCheck />
            )}
            <span>Salvar</span>
          </SaveSkuButton>
        )}
        <Header>
          <PageName>{selectedPage}</PageName>
          <div className="right">
            <Search
              value={searchCriteria}
              onChange={handleOnSearchCriteriaChanged}
            />
            <SortByButtons sortBy={sortBy} onChange={handleOnSortByChanged} />
          </div>
        </Header>
        <Main>
          {isProductsLoading ? (
            <LoadingAnimation />
          ) : (
            <>
              {searchedProducts.length > 0 ? (
                <ProductsContainer className="has-custom-scroll-bar-2">
                  {searchedProducts.map(product => (
                    <ProductSku
                      onChangeSku={handleOnSkuChanged}
                      onChangeUnitPrice={handleOnUnitPriceChanged}
                      key={product.id}
                      product={product}
                    />
                  ))}
                </ProductsContainer>
              ) : (
                <>
                  {!searchCriteria ? (
                    <NoProductsFound>
                      <span>Ainda não há produtos cadastrados.</span>
                    </NoProductsFound>
                  ) : (
                    <NoProductsFound>
                      <span>Nenhum produto encontrado.</span>
                    </NoProductsFound>
                  )}
                </>
              )}
            </>
          )}
        </Main>
      </Content>
    </Container>
  );
};

export default UpdateSkuPage;
