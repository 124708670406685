export enum EPlanModule {
  BSFOOD = 'BSFOOD',
  BSTICKET = 'BSTICKET',
  BSSELFCHECKOUT = 'BSSELFCHECKOUT',
  // ALL = BSFOOD | BSTICKET | BSSELFCHECKOUT,
}

export default interface IPlan {
  id: number;
  name: string;
  price: number;
  label: string;
  order: boolean;
  renewal: string;
  active: boolean;
  maxItems: number;
  trialDays: number;
  maxOrders: number;
  internal: boolean;
  showCase: boolean;
  description: string;
  sendWhatsApp: boolean;
  apiIntegration: boolean;
  paymentMethods: string[];
  modules: EPlanModule;
}
