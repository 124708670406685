import styled from 'styled-components';

interface ICompanyLogoProps {
  hasLogo?: boolean;
}

interface IPreviewProps {
  selected?: boolean;
}

export const Container = styled.div`
  position: relative;
  padding-top: 32px;
  z-index: 10;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.palette.background};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Header = styled.header`
  display: flex;
  padding: 0 32px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 16px;

  div {
    border-color: ${({ theme }) => theme.palette.text} !important;
  }
  @media (max-width: 1024px) {
    h1,
    div {
      display: none;
    }
  }
`;

export const Content = styled.div`
  padding: 0 32px;
  padding-top: 52px;
  padding-bottom: 16px;
  flex: 1;
  width: 100%;
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;

  @media (max-width: 480px) {
    padding-bottom: 80px;
  }
`;

export const CompanyLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};
  border-radius: 8px;
  padding: 16px;

  h4 {
    margin-bottom: 16px;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;

    h4 {
      font-size: 15px;
      text-align: center;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }
`;

export const CompanyLogo = styled.label<ICompanyLogoProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px;

  width: 200px;
  height: 200px;
  min-width: 180px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};

  background-size: cover;

  border: ${({ hasLogo }) => (hasLogo ? '0' : '1px solid #ddd')};

  cursor: pointer;

  position: relative;
  overflow: hidden;

  span {
    opacity: 0;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    background: #0008;
    color: #fff;
    bottom: 0;
    font-size: 16px;
    transition: opacity 0.6s;
  }

  input {
    display: none;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }

  @media (max-width: 800px) {
    width: 30vw;
    height: 30vw;
    min-width: 30vw;
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;

  svg {
    margin: 0 12px;
    width: min(300px, 100%);
  }

  @media (max-width: 698px) {
    flex-direction: column;
    margin-top: 16px;

    svg {
      margin: 16px 0;
    }
  }
`;

export const Preview = styled.button<IPreviewProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  border-radius: 8px;

  span {
    width: 100%;
    font-size: 22px;
    position: absolute;
    top: -42px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .svg-preview {
    border-radius: 8px;
    box-shadow: ${({ theme, selected }) =>
      selected
        ? `0 0 0 2px ${theme.palette.background},0 0 0 6px var(--theme-page-color-picker-primary), ${theme.shadow.box_shadow}`
        : theme.shadow.box_shadow};

    .prefix__primary {
      fill: var(--theme-page-color-picker-primary) !important;
    }
  }

  .svg-check {
    display: ${({ selected }) => (selected ? 'block' : 'none')};
    position: absolute;
    fill: var(--theme-page-color-picker-primary-contrast);
    top: 4px;
    right: calc(-40% + 4px);
  }

  @media (min-width: 675px) and (max-width: 1974px) {
    margin-top: 64px;
  }

  @media (max-width: 700px) {
    span {
      top: -16px;
    }
    margin-top: 8px;
  }
`;

export const LoadingIconsContainer = styled.div`
  margin: 28px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 8px;
`;

export const AnimationContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  div {
    box-shadow: ${({ theme }) => theme.shadow.box_shadow};
    background: ${({ theme }) => theme.palette.background_white};
  }
`;

export const EditAnimationButton = styled.button`
  border: none;
  width: 58px;
  height: 58px;
  border-radius: 29px;
  background: ${({ theme }) => theme.palette.background_white};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;
