export enum LocalStorage {
  COMPANY = '@BSFOOD:company',
  PRODUCT_PREFERABLE_DISPLAY = '@BSFOOD:productPreferableDisplay',
  REMEMBERME = '@BSFOOD:rememberme',
  CUSTOMER = '@BSFOOD:customer',
  PREFERABLE_DISPLAY = '@BSFOOD:preferableDisplay',
  CREDENTIALS = '@BSFOOD:credentials',
  TOUR = '@BSFOOD:tour',
  LAST_ORDERS_REFRESH = '@BSFOOD:lastOrdersRefresh',
  LATEST_VERSION = '@BSFOOD:latestVersion',
  PRODUCTS_PREFERABLE_SORT = '@BSFOOD:productsPreferableSort',
  STOCK_PREFERABLE_SORT = '@BSFOOD:stockPreferableSort',
  ORDERS_ORIGIN = '@BSFOOD:ordersOrigin',
  ORDERS_TYPE = '@BSFOOD:ordersType',
}
