import React, { useCallback, useMemo, useState } from 'react';

import { FiChevronDown, FiChevronUp, FiTrash } from 'react-icons/fi';
import { useCompany } from '../../hooks/company';

import IOrderItem from '../../models/IOrderItem';
import { IGroupedItem } from '../KitchenPrintItem';
import IOrderItemComplements from '../../models/IOrderItemComplements';

import { formatToMoney } from '../../utils/format';

import {
  Total,
  Amount,
  Content,
  Summary,
  Wrapper,
  ItemName,
  ItemPrice,
  Container,
  Complement,
  ItemPrice2,
  TotalTitle,
  AmountTitle,
  ComplementName,
  ItemAdditionals,
  ComplementAmount,
  ItemAdditionalsTitle,
} from './styles';

interface IOrderItemProps {
  item: IOrderItem;
  isFinished: boolean;
  onDelete: (id: number) => void;
  hasCoupon: boolean;
}

const OrderItem: React.FC<IOrderItemProps> = ({
  item,
  onDelete,
  isFinished,
  hasCoupon,
}) => {
  const { company } = useCompany();
  const [isSummaryShown, setIsSummaryShown] = useState(false);

  const handleOnSummaryShow = useCallback(() => {
    if (
      item.complementsGroups.length > 0 ||
      item?.isCustomPizza ||
      item.comments
    ) {
      setIsSummaryShown(old => !old);
    }
  }, [item]);

  const getDivisor = useMemo(() => {
    return (groupAmount: number, complementAmount: number) => {
      if (groupAmount === complementAmount) {
        return '-';
      }

      switch (groupAmount) {
        case 2:
          return `${complementAmount}/2`;
        case 3:
          return `${complementAmount}/3`;
        case 4:
          return `${complementAmount}/4`;
        default:
          return '';
      }
    };
  }, []);

  const complementTitle = useMemo(() => {
    return (complement: IOrderItemComplements) => {
      const group = item.complementsGroups.find(
        g => g.id === complement.complementGroupId,
      );

      if (group && group.category === 'PIZZA') {
        return `${getDivisor(group.minAmount, complement.amount)} ${
          complement.title
        }`;
      }

      return `${complement.amount}x ${complement.title}`;
    };
  }, [getDivisor, item]);

  const formattedItem: IGroupedItem = useMemo(() => {
    const pizza: IOrderItemComplements[] = [];
    const optionals: IOrderItemComplements[] = [];
    const additionals: IOrderItemComplements[] = [];

    item.complementsGroups.forEach(g => {
      g.complements.forEach(c => {
        if (g.category === 'PIZZA') {
          pizza.push(c);
        } else if (c.unitPrice > 0) {
          additionals.push(c);
        } else {
          optionals.push(c);
        }
      });
    });

    return {
      item,
      pizzaSlices: item?.pizzaSizes?.reduce(
        (acc, current) => acc + current?.amount,
        0,
      ),
      pizza,
      optionals,
      additionals,
    };
  }, [item]);

  return (
    <Container canceled={formattedItem.item.deleted}>
      <Content>
        {/* <img
          src=""
          alt="Bar da Praia"
        /> */}
        <Wrapper>
          <ItemName>{formattedItem.item.title}</ItemName>
          <ItemPrice>{formatToMoney(formattedItem.item.unitPrice)}</ItemPrice>
          <ItemPrice2>{`${formattedItem.item.qty}x ${formattedItem.item.unitPrice}`}</ItemPrice2>
        </Wrapper>
        <Wrapper>
          <Amount>{`${formattedItem.item.qty}x`}</Amount>
          <AmountTitle>Quantidade</AmountTitle>
        </Wrapper>
        <Wrapper>
          <Total>{formatToMoney(formattedItem.item.total)}</Total>
          <TotalTitle>Total</TotalTitle>
        </Wrapper>
        <Wrapper>
          {!isFinished && !company?.pdvIntegration && !hasCoupon && (
            <FiTrash
              size={20}
              onClick={() => onDelete(formattedItem.item.id)}
            />
          )}
          {(item.complementsGroups.length > 0 ||
            formattedItem?.item?.isCustomPizza) && (
            <>
              {isSummaryShown ? (
                <FiChevronUp onClick={handleOnSummaryShow} size={20} />
              ) : (
                <FiChevronDown onClick={handleOnSummaryShow} size={20} />
              )}
            </>
          )}
        </Wrapper>
      </Content>
      {isSummaryShown && (
        <Summary visible={isSummaryShown}>
          <>
            {formattedItem.pizza.length > 0 && (
              <ItemAdditionals>
                <ItemAdditionalsTitle>Sabores da pizza</ItemAdditionalsTitle>
                {formattedItem.pizza.map(a => (
                  <Complement key={a.id}>
                    <div>
                      <ComplementName>{complementTitle(a)}</ComplementName>
                    </div>
                  </Complement>
                ))}
              </ItemAdditionals>
            )}
            {formattedItem?.item?.isCustomPizza && (
              <>
                {formattedItem?.item?.pizzaSizes && (
                  <ItemAdditionals>
                    <ItemAdditionalsTitle>Sabores</ItemAdditionalsTitle>
                    {formattedItem?.item?.pizzaSizes?.map(size => (
                      <Complement key={`pizzaSize-${size.id}`}>
                        <div>
                          <ComplementAmount>
                            {`${size.amount}/${
                              formattedItem?.pizzaSlices || 0
                            }`}
                          </ComplementAmount>
                          <ComplementName>{size.title}</ComplementName>
                        </div>
                      </Complement>
                    ))}
                  </ItemAdditionals>
                )}
                {formattedItem?.item?.pizzaCrusts && (
                  <ItemAdditionals>
                    <ItemAdditionalsTitle>Massa</ItemAdditionalsTitle>
                    {formattedItem?.item?.pizzaCrusts?.map(crust => (
                      <Complement key={`pizzaCrust-${crust.id}`}>
                        <div>
                          <ComplementName>{crust.title}</ComplementName>
                        </div>
                      </Complement>
                    ))}
                  </ItemAdditionals>
                )}
                {(formattedItem?.item?.pizzaEdges?.length || 0) > 0 && (
                  <ItemAdditionals>
                    <ItemAdditionalsTitle>Borda</ItemAdditionalsTitle>
                    {formattedItem?.item?.pizzaEdges?.map(edge => (
                      <Complement key={`pizzaEdge-${edge.id}`}>
                        <div>
                          <ComplementName>{edge.title}</ComplementName>
                        </div>
                      </Complement>
                    ))}
                  </ItemAdditionals>
                )}
              </>
            )}
            <ItemAdditionals>
              {formattedItem.additionals.length > 0 && (
                <>
                  <ItemAdditionalsTitle>Adicionais</ItemAdditionalsTitle>
                  {formattedItem.additionals.map(complement => (
                    <Complement key={complement.id}>
                      <div>
                        <ComplementAmount>
                          {`${complement.amount}x`}
                        </ComplementAmount>
                        <ComplementName>{complement.title}</ComplementName>
                      </div>
                    </Complement>
                  ))}
                </>
              )}
            </ItemAdditionals>
            <ItemAdditionals>
              {formattedItem.item.comments.length > 0 && (
                <>
                  <ItemAdditionalsTitle>Observações</ItemAdditionalsTitle>
                  <Complement>
                    <ComplementName>
                      {formattedItem.item.comments}
                    </ComplementName>
                  </Complement>
                </>
              )}
            </ItemAdditionals>
            <ItemAdditionals>
              {formattedItem.optionals.length > 0 && (
                <>
                  <ItemAdditionalsTitle>Opcionais</ItemAdditionalsTitle>
                  {formattedItem.optionals.map(complement => (
                    <Complement key={complement.id}>
                      <div>
                        <ComplementAmount>
                          {`${complement.amount}x`}
                        </ComplementAmount>
                        <ComplementName>{complement.title}</ComplementName>
                      </div>
                    </Complement>
                  ))}
                </>
              )}
            </ItemAdditionals>
          </>
        </Summary>
      )}
    </Container>
  );
};

export default OrderItem;
