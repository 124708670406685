import { keyframes } from 'styled-components';

export const appearFromBottom = keyframes`
from {
  transform: translateY(100%);
}
to{
  transform: translateY(0);
}
`;
