import styled, { css } from 'styled-components';

interface IContainerProps {
  visible: boolean;
}

export const Container = styled.div<IContainerProps>`
  height: 100vh;

  top: 0;
  right: 0;
  position: absolute;

  background: ${({ theme }) => theme.palette.background};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};

  overflow: hidden;
  transition: opacity 0.3s, width 0.3s;

  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
          width: 550px;

          @media (max-width: 550px) {
            width: 100%;
          }
        `
      : css`
          width: 0;
          opacity: 0;
        `}
`;

export const Header = styled.header`
  height: 30px;

  display: flex;
  align-items: center;

  padding: 32px;

  svg {
    cursor: pointer;
  }

  span {
    font-size: 24px;
    margin-left: 16px;
  }
`;

export const Information = styled.div`
  padding: 32px;

  height: calc(100% - 40px);
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const DateContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  margin-top: 16px;

  div + div {
    margin-left: 16px;

    @media (max-width: 550px) {
      margin-top: 16px;
      margin-left: 0px;
    }
  }

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  margin-top: 32px;

  div + div {
    margin-left: 16px;
  }

  input {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  margin-top: 16px;
`;

export const SelectContainer = styled.div`
  margin-top: 32px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 64px;
  margin-bottom: 32px;

  justify-content: flex-end;

  overflow: visible;

  @media (max-width: 480px) {
    margin-bottom: 80px;
  }
`;

export const Button = styled.button`
  width: 80%;
  height: 100px;

  display: flex;
  align-items: center;
  align-self: flex-end;

  border: 0;
  padding: 32px;

  margin-right: -128px;
  transition: transform 0.3s;
  border-radius: 16px 0 0 16px;

  color: #fff;
  background: #db1622;

  &:hover {
    transform: translateX(-5%);
  }

  span {
    font-size: 18px;
  }

  svg {
    height: 24px;
    width: 24px;
    margin-left: 32px;
  }

  @media (max-width: 970px) {
    margin-left: 32px;
  }

  @media (max-width: 480px) {
    margin-right: -64px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  svg {
    margin-left: -50%;
  }
`;

export const Error = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.error};
`;

export const SingleUseContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 16px;
  margin-bottom: 8px;

  > span {
    font-size: 14px;
    margin-top: 8px;

    color: ${({ theme }) => theme.palette.control};
  }
`;
