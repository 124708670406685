import React from 'react';
import { useAuthRoles } from '../../hooks/auth';
import { CustomerRolesTypes } from '../../models/IUser';

import { Container, IPageProps } from './styles';

interface ISidebarTabProps
  extends IPageProps,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  whiteList?: CustomerRolesTypes[];
  blackList?: CustomerRolesTypes[];
  isSubPageTab?: boolean;
}

export const SidebarTab: React.FC<ISidebarTabProps> = ({
  children,
  whiteList,
  blackList,
  isSubPageTab,
  ...rest
}) => {
  const userCanAccess = useAuthRoles({ whiteList, blackList });

  return (
    <Container
      {...rest}
      isSubPageTab={isSubPageTab}
      userCanAccess={userCanAccess}
      disabled={!userCanAccess}
      type="button"
    >
      {children}
    </Container>
  );
};
