import styled from 'styled-components';
import { Row } from '../styles';
import { selectStyles as defaultSelectStyles } from '../../../styles/select';

export const Container = styled(Row)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 1.5rem;

  width: min(570px, 100%);

  @media screen and (max-width: 610px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SelectLabel = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;

export const selectStyles = {
  ...defaultSelectStyles,
  control: (provided: any) => ({
    ...provided,
    border: '1px solid #aaa',
    width: '100%!important',
    borderColor: '#aaa',
    padding: '.5rem',
    boxShadow: 0,
    '&:hover': {
      borderColor: '#db1622!important',
    },
  }),
};
