export enum PaymentTypes {
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
  DEBIT_CARD = 'DEBIT_CARD',
  PIX = 'PIX',
  PIC_PAY = 'PIC_PAY',
  AME = 'AME',
  FB_PAYMENT = 'FB_PAYMENT',
  CHECK = 'CHECK',
  VOUCHER = 'VOUCHER',
  MONTHLY_PAYER = 'MONTHLY_PAYER',
}
