import styled, { css } from 'styled-components';

interface IInputProps {
  hasError: boolean;
  hasFocus: boolean;
  hasContent: boolean;
}

interface IContainerProps {
  maxLength?: number;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  position: relative;
  flex-direction: column;

  ${({ maxLength }) =>
    maxLength &&
    css`
      &::after {
        display: block;
        content: var(--current) '/${maxLength}';
        font-size: 12px;
        margin-left: auto;
      }
    `}
`;

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #aaa;
  scroll-margin-top: 32px;

  > a {
    color: #db1622;
    font-size: 14px;
    text-decoration: none;
    transition: opacity 0.3s;
  }

  > a:hover {
    opacity: 0.6;
  }
`;

export const StyledInput = styled.input<IInputProps>`
  border: 0;
  background: none;

  ${({ hasError, hasContent, hasFocus }) =>
    hasError
      ? css`
          border-bottom: 1px solid #c53030;
        `
      : css`
          border-bottom: 1px solid
            ${hasContent || hasFocus ? '#db1622' : '#aaa'};
        `}

  padding: 16px 8px 16px 0;

  scroll-margin: 40px;
`;

export const Error = styled.div`
  span {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.error};
  }
`;

export const Eye = styled.div`
  height: 20px;
  position: absolute;
  top: 36px;
  right: 12px;

  cursor: pointer;
`;
