import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const Content = styled.main`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  background: #f4f4f6;
  position: relative;
`;

export const Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  max-height: 100px;
  padding: 0 32px;
  padding-top: 24px;
  margin-bottom: 16px;
`;

export const PageName = styled.strong`
  font-size: 32px;
  margin-left: 8px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Main = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  width: 100%;

  padding: 0 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;

  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 480px) {
    padding-bottom: 80px;
  }
`;

export const DevicesPlaceholder = styled.div`
  width: calc(1 / 3 * 100% - (1 - 1 / 3) * 24px);
  height: 0;
`;

export const NoDeviceFound = styled.div`
  width: 100%;
  text-align: center;

  margin-top: 128px;
`;

export const Tabs = styled.div`
  padding: 0 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  button {
    padding: 0.5rem;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    background: ${({ theme }) => theme.palette.background};
    border-bottom: 1px solid ${({ theme }) => theme.palette.control};
    transition: border-color 400ms ease;

    :hover {
      background: ${({ theme }) => theme.palette.disabled};
    }

    &.active {
      border-bottom: 4px solid ${({ theme }) => theme.palette.primary};
      background: ${({ theme }) => theme.palette.background_white};
    }
  }
`;
