import React, { useRef, InputHTMLAttributes } from 'react';
import {
  MaskedCurrency,
  IMaskedCurrencyRef,
  IMaskedCurrencyProps,
} from 'react-easy-mask';

import { Link } from 'react-router-dom';

import { Container, StyledInput, Label } from './styles';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  title: string;
  hasForgotLink?: boolean;
}

interface IPriceInputProps
  extends Omit<IMaskedCurrencyProps, 'currency' | 'locale'> {
  name: string;
  title: string;
  hasForgotLink?: boolean;
  disabled?: boolean;
}

const SkuInput: React.FC<IInputProps> = ({
  name,
  title,
  disabled,
  hasForgotLink,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Container disabled={disabled}>
      <Label htmlFor={name} hasFocus={false}>
        {title}
        {hasForgotLink && <Link to="/forgot-password">Esqueceu a senha?</Link>}
      </Label>
      <StyledInput
        ref={inputRef}
        hasError={false}
        disabled={disabled}
        {...rest}
      />
    </Container>
  );
};

export const SkuPriceInput: React.FC<IPriceInputProps> = ({
  name,
  title,
  disabled,
  hasForgotLink,
  ...rest
}) => {
  const inputRef = useRef<IMaskedCurrencyRef>(null);

  return (
    <Container disabled={disabled}>
      <Label htmlFor={name} hasFocus={false}>
        {title}
        {hasForgotLink && <Link to="/forgot-password">Esqueceu a senha?</Link>}
      </Label>
      <StyledInput
        as={MaskedCurrency}
        ref={inputRef}
        locale="pt-BR"
        currency="BRL"
        hasError={false}
        {...rest}
      />
    </Container>
  );
};

export default SkuInput;
