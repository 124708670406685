import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  useMemo,
} from 'react';

interface AudioContextData {
  audio: HTMLAudioElement;
  play(): void;
  stop(): void;
}

const AudioContext = createContext<AudioContextData>({} as AudioContextData);

export const AudioProvider: React.FC = ({ children }) => {
  const audio = useMemo(
    () =>
      new Audio(
        'https://bsfoodstorage.blob.core.windows.net/assets/notification2.flac',
      ),
    [],
  );

  const [playing, setPlaying] = useState(false);

  const play = useCallback(() => {
    setPlaying(true);
  }, []);

  const stop = useCallback(() => {
    setPlaying(false);
  }, []);

  useEffect(() => {
    if (!playing) {
      audio.pause();
      audio.currentTime = 0;
    } else {
      audio.play().catch(() => audio.pause());
    }
  }, [playing, audio]);

  useEffect(() => {
    if (process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      audio.loop = true;
    } else {
      audio.loop = true;
    }

    audio.addEventListener('ended', () => setPlaying(false));

    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return (
    <AudioContext.Provider
      value={{
        audio,
        play,
        stop,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};

export function useAudio(): AudioContextData {
  const context = useContext(AudioContext);

  if (!context) {
    throw new Error('useAudio must be used within AudioProvider');
  }

  return context;
}
