import React, { useMemo } from 'react';

import { IFilters } from '../../models/ICashierReport';

import {
  Container,
  // PeriodFilterContainer,
  Title,
} from './styles';
import { formatCashierSummaryFilterLabels } from '../../utils/cashier';
import AppliedFilters, { AppliedFiltersProps } from '../AppliedFilters';
import { formatToLocalDate } from '../../utils/format';

interface ICashierAppliedFiltersProps {
  filters: IFilters;
}

const CashierAppliedFilters: React.FC<ICashierAppliedFiltersProps> = ({
  filters,
}) => {
  const mappedFilters = useMemo<AppliedFiltersProps['appliedFilters']>(() => {
    const result: AppliedFiltersProps['appliedFilters'] = [];

    Object.keys(filters).forEach(key => {
      if (key !== 'startAt' && key !== 'endAt') {
        result.push([
          {
            label: formatCashierSummaryFilterLabels(
              key as keyof typeof filters,
            ),
            value: filters[key as keyof typeof filters]?.toString() as string,
          },
        ]);
      }
    });

    if (filters?.startAt || filters?.endAt) {
      result.push([
        ...(filters?.startAt
          ? [
              {
                label: formatCashierSummaryFilterLabels('startAt'),
                value: formatToLocalDate(filters.startAt),
              },
            ]
          : []),
        ...(filters?.endAt
          ? [
              {
                label: formatCashierSummaryFilterLabels('endAt'),
                value: formatToLocalDate(filters.endAt),
              },
            ]
          : []),
      ]);
    }

    return result;
  }, [filters]);

  return (
    <Container>
      <Title>Filtros aplicados:</Title>
      <AppliedFilters appliedFilters={mappedFilters} />
    </Container>
  );
};

export default CashierAppliedFilters;
