import React, { createContext, useCallback, useContext, useState } from 'react';
import { PageNames } from '../enums/pages';

interface ISidebarContext {
  visible: boolean;
  selectedPage: PageNames;
  toggleVisible: () => void;
  setVisible: (value: boolean) => void;
  setSelectedPage: (page: PageNames) => void;
}

const Context = createContext<ISidebarContext>({} as ISidebarContext);

export const useSidebar = (): ISidebarContext => useContext(Context);

export const SidebarProvider: React.FC = ({ children }) => {
  const [selectedPage, setSelectedPage] = useState<PageNames>(
    PageNames.DASHBOARD,
  );

  const [visible, setVisible] = useState(false);

  const handleSelectedPageChange = useCallback(
    (page: PageNames) => setSelectedPage(page),
    [],
  );
  const handleVisibleChange = useCallback(
    (value: boolean) => setVisible(value),
    [],
  );

  const handleToggleVisible = useCallback(() => setVisible(old => !old), []);

  return (
    <Context.Provider
      value={{
        visible,
        selectedPage,
        setVisible: handleVisibleChange,
        toggleVisible: handleToggleVisible,
        setSelectedPage: handleSelectedPageChange,
      }}
    >
      {children}
    </Context.Provider>
  );
};
