import React from 'react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { IStockMovement } from '../../models/IStock';
import { formatToLocalDate } from '../../utils/format';
import { Label, Row, Table, TableBody, TableHead, Value } from './styles';

interface IStockMovementsTableProps {
  movements: IStockMovement[];
}

const StockMovementsTable: React.FC<IStockMovementsTableProps> = ({
  movements,
}) => {
  const theme = useTheme();

  return (
    <Table>
      <TableHead>
        <Row>
          <Label>Tipo</Label>
          <Label>Motivo</Label>
          <Label>Produto</Label>
          <Label>Qtde.</Label>
          <Label>Estoque</Label>
          <Label>Data</Label>
        </Row>
      </TableHead>
      <TableBody className="has-custom-scroll-bar-2">
        {new Array(50)
          .fill(movements)
          .flat()
          .map(movement => (
            <Row key={movement.id} transactionType={movement.transactionType}>
              <Value>
                {movement.transactionType === 'INCREASE' ? (
                  <FiArrowDown size={24} color={theme.palette.success} />
                ) : (
                  <FiArrowUp size={24} color={theme.palette.error} />
                )}
              </Value>
              <Value id="reason">{movement.reason}</Value>
              <Value>{movement.title}</Value>
              <Value>{movement.qty}</Value>
              <Value>{movement.previousQty}</Value>
              <Value>{formatToLocalDate(movement.createdAt.toString())}</Value>
            </Row>
          ))}
      </TableBody>
    </Table>
  );
};

export default StockMovementsTable;
