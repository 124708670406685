import React from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import { IStockCheck } from '../../models/IStock';
import {
  BodyContainer,
  BottomContainer,
  Button,
  ButtonsContainer,
  Container,
  GroupContainer,
  HeaderContainer,
  Info,
  InfoContainer,
  Label,
  Status,
  StockCheckId,
  Title,
  TopContainer,
} from './styles';
import { formatToFullLocalDate } from '../../utils/format';
import {
  BsTicketStockCheckStatusType,
  EBsTicketStockCheckStatusType,
} from '../../enums/bsticket';
import { stopPropagation } from '../../utils/html';

interface IStockCheckProps {
  stockCheck: IStockCheck;
  onEdit: () => void;
  onChangeStatus: (
    stockCheckId: number,
    status: BsTicketStockCheckStatusType,
  ) => void;
}

const StockCheck: React.FC<IStockCheckProps> = ({
  stockCheck,
  onEdit,
  onChangeStatus,
}) => {
  return (
    <Container
      onClick={stockCheck.status === 'OPEN' ? onEdit : () => null}
      status={stockCheck.status}
    >
      <TopContainer>
        <HeaderContainer>
          <StockCheckId>{stockCheck.id}</StockCheckId>
          <InfoContainer>
            <Title>{stockCheck.title}</Title>
            {stockCheck.description && <Info>{stockCheck.description}</Info>}
          </InfoContainer>
        </HeaderContainer>
        <BodyContainer>
          <GroupContainer>
            <InfoContainer>
              <Label>Aberta em</Label>
              <Info>{formatToFullLocalDate(stockCheck.createdAt)}</Info>
            </InfoContainer>
            <InfoContainer>
              <Label>Fechada em</Label>
              <Info>
                {stockCheck.closedAt
                  ? formatToFullLocalDate(stockCheck.closedAt)
                  : '-'}
              </Info>
            </InfoContainer>
          </GroupContainer>
          <InfoContainer>
            <Label>Usuário</Label>
            <Info>{stockCheck.user.name}</Info>
          </InfoContainer>
          <InfoContainer>
            <Label>Produtos lançados</Label>
            <Info>{stockCheck.products.length}</Info>
          </InfoContainer>
        </BodyContainer>
      </TopContainer>
      <BottomContainer>
        <Status status={stockCheck.status}>
          {EBsTicketStockCheckStatusType[stockCheck.status]}
        </Status>
        {stockCheck.status === 'OPEN' && (
          <ButtonsContainer>
            {stockCheck.products.length > 0 && (
              <Button
                type="button"
                onClick={stopPropagation(() =>
                  onChangeStatus(stockCheck.id, 'DONE'),
                )}
              >
                <FiCheck size={20} />
                Processar
              </Button>
            )}
            <Button
              id="cancel-button"
              type="button"
              onClick={stopPropagation(() =>
                onChangeStatus(stockCheck.id, 'CANCELED'),
              )}
            >
              <FiX size={20} />
              Cancelar
            </Button>
          </ButtonsContainer>
        )}
      </BottomContainer>
    </Container>
  );
};

export default StockCheck;
