import { Form } from '@unform/web';
import styled, { css } from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ReactModal from 'react-modal';
import { appearFromBottom } from '../../styles/animations';

interface IPageProps {
  selected: boolean;
}

interface IScrollContainer {
  visible?: boolean;
}

interface IItemSizeInputProps {
  error?: boolean;
}

interface ISizeContainerProps {
  isActive: boolean;
}

export const Container = styled.div`
  height: 100%;
  display: flex;
  overflow: hidden;

  height: -webkit-fill-available;
`;

export const Content = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${({ theme }) => theme.palette.background_secondary};
`;

export const Header = styled.header`
  width: 100%;
  max-height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;

  z-index: 1;
`;

export const ProductInfoHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

export const PageInfo = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 16px;
    @media (min-width: 1271px) {
      display: none;
    }
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PageName = styled.strong`
  font-size: 32px;
  @media (max-width: 1027px) {
    display: none;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PaginationName = styled.span`
  font-size: 12px;
  margin-left: 22%;
  transition-delay: 0;
  transform: translateY(50%);
  transition: all 200ms ease;
  opacity: 0;
  visibility: hidden;
`;

export const Information = styled(Form)`
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 32px;

  @media (max-width: 500px) {
    padding: 16px;
  }
`;

export const TextAreaContainer = styled.div`
  margin: 16px 0;
`;

export const Pagination = styled.div`
  display: flex;
  margin: 0 32px;
  margin-top: -16px;
  justify-content: center;
  border-bottom: 2px solid ${({ theme }) => theme.palette.primary};

  z-index: 1;

  @media (max-width: 500px) {
    margin: 0 16px;
  }
`;

export const Radio = styled.label`
  width: 150px;
  height: 175px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  margin-left: 16px;
  cursor: pointer;
  border-radius: 16px;
  border: 3px solid ${({ theme }) => theme.palette.control_light};
  svg {
    width: 30px;
    height: 30px;
  }
  .x-circle {
    width: 25px;
    height: 25px;
  }
  .two-people {
    width: 40px;
    height: 40px;
  }
  input {
    width: 25px;
    height: 25px;
    margin-bottom: 16px;
    color: ${props => props.theme.palette.primary};
  }
  @media (max-width: 550px) {
    width: 75px;
    height: 100px;
    span {
      display: none;
    }
  }
`;

export const Page = styled.div<IPageProps>`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  transition: all 0.2s;
  svg {
    min-width: 18px;
    min-height: 18px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
    transition-delay: 75ms;
    transition: all 200ms ease;
  }
  ${({ selected }) =>
    selected
      ? css`
          border-bottom: 7px solid ${({ theme }) => theme.palette.primary};
          font-weight: bold;
        `
      : css`
          border-bottom: 2px solid ${({ theme }) => theme.palette.primary};
        `}
  @media (min-width: 500px) {
    &:hover {
      width: 125%;
      svg {
        transition-delay: 0;
        transform: translateX(calc(-200% - 8px));
      }
      span {
        opacity: 1;
        transform: none;
        visibility: visible;
        transition-delay: 100ms;
      }
    }
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

export const MealSizeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const WeightContainer = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 24px;

  .weight-select {
    flex: 1;
    margin-left: 32px;

    @media (max-width: 500px) {
      margin-left: 16px;
    }
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
`;

export const Top = styled.div`
  display: flex;
  margin-bottom: 8px;
  @media (max-width: 1000px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const Right = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 32px;

  @media (max-width: 1000px) {
    margin-left: 0;

    gap: 24px;
    margin-top: 24px;
  }
`;

export const InformationContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    color: ${props => props.theme.palette.warning};
  }
  span {
    font-size: 12px;
    margin-left: 4px;
    font-weight: 600;
    color: ${props => props.theme.palette.warning};
  }
`;

export const HorizontalWrapper = styled.div`
  width: 100%;
  display: flex;
  > div {
    flex: 1;
  }
  > div + div {
    margin-left: 32px;
  }
  &.margin-top {
    margin-top: 24px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 16px;

    > div + div {
      margin-left: 0;
    }

    &.margin-top {
      margin-top: 0;
    }
  }
`;

export const Thumbnail = styled.div`
  width: 171.8px;
  height: 171.8px;
  min-width: 171.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.control_light};
  cursor: pointer;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background-size: cover;

  > .add-image {
    border: none;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    position: absolute;
    opacity: 0;
    font-size: 16px;
    transition: opacity 0.6s;
    color: ${({ theme }) => theme.palette.text_white};
    background: ${({ theme }) => theme.palette.black_transparency};
  }
  > .add-image + .add-image {
    bottom: -75px;
    position: absolute;
    color: ${({ theme }) => theme.palette.primary};
  }
  input {
    display: none;
  }
  &:hover {
    .add-image {
      opacity: 1;
    }
  }
`;

export const RemoveImageContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  position: absolute;
  padding: 6px;
  border: 0;
  border-radius: 0 0 0 8px;
  background: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.background_white};
`;

export const SelectContainer = styled.div`
  width: 100%;
`;

export const SelectLabel = styled.label`
  display: flex;
  font-size: 12px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.palette.control};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
  margin-top: auto;
  overflow: visible;

  @media (max-width: 480px) {
    margin-bottom: 64px;
  }
`;

export const Button = styled.button`
  width: 95%;
  height: 100px;
  display: flex;
  align-items: center;
  align-self: flex-end;
  border: 0;
  padding: 32px;
  margin-right: -128px;
  transition: transform 0.3s;
  border-radius: 16px 0 0 16px;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};
  &:hover {
    transform: translateX(-5%);
  }
  span {
    font-size: 18px;
  }
  svg {
    width: 24px;
    height: 24px;
    margin-left: 32px;
  }
  @media (min-width: 901px) {
    width: 35%;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  svg {
    margin-left: -50%;
  }
`;

export const ScrollContainer = styled.div<IScrollContainer>`
  height: calc(100vh - 223px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ visible }) =>
    !visible &&
    css`
      display: none;
    `}
`;

export const Complements = styled.div`
  width: 100%;
  padding: 32px;
  overflow-x: hidden;

  @media (max-width: 500px) {
    padding: 16px;
  }
`;

export const DispositionContainer = styled.div`
  margin: 32px;
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 24px;
  }
  button {
    display: flex;
    align-items: center;
    border: 0;
    padding: 8px;
    color: ${({ theme }) => theme.palette.text_white};
    background: ${({ theme }) => theme.palette.primary};
    span {
      font-size: 14px;
      margin-left: 8px;
    }
  }
`;

export const NoComplementsGroupsMessage = styled.div`
  width: 100%;
  display: flex;
  margin-top: 64px;
  text-align: center;
`;

export const StyledDragDropContext = styled(DragDropContext)`
  padding-top: 16px;
`;

export const StyledDraggable = styled(Draggable)``;

export const StyledDroppable = styled(Droppable)``;

export const DraggableContainer = styled.div`
  padding-top: 16px;
`;

export const CheckboxContainer = styled.div`
  padding: 32px;
  > div + div {
    margin-top: 32px;
  }
`;

export const AvailabilityContainer = styled.div`
  padding: 32px;
`;

export const Sale = styled.fieldset`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  padding: 8px 12px;
  position: relative;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.primary_light};
  @media (max-width: 800px) {
    margin-left: 0;
  }
  legend {
    font-weight: 600;
    font-size: 1.1rem;
    color: ${({ theme }) => theme.palette.black};
  }
  .wrapper {
    @media (min-width: 701px) {
      display: flex;
      align-items: center;
    }
    > *:last-child {
      margin-left: 8px;
      @media (max-width: 700px) {
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }
`;

export const RemoveSaleContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 12px;
  position: absolute;
  padding: 6px;
  cursor: pointer;
  border: 0;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.background_white};
`;

export const SizedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  h4 {
    margin-right: 8px;
  }
  .separator-container {
    width: 100%;
    display: flex;
    align-items: center;
    .separator {
      flex: 1;
      height: 2px;
      background-color: ${({ theme }) => theme.palette.primary};
    }
  }
`;

export const ProductSizes = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .item-size-price-error-container {
    display: flex;
    flex-direction: column;
    .item-size-price-error {
      font-size: 14px;
      color: ${({ theme }) => theme.palette.error};
    }
  }

  @media (max-width: 500px) {
    gap: 24px;
  }
`;

export const SizeContainer = styled.div<ISizeContainerProps>`
  ${({ isActive }) =>
    !isActive &&
    css`
      opacity: 0.5;
    `}
`;

export const SizeWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 16px;
  gap: 16px;

  #size-info {
    width: 100%;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const SizeInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  button {
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    padding: 6px;
    margin-top: inherit;
    margin-left: 8px;
    transition: background 2ms;

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
  }

  .size-item {
    flex: 1;
  }

  @media (max-width: 500px) {
    justify-content: flex-start;
    align-self: start;

    .size-item {
      flex: unset;
    }
  }
`;

export const ItemSizeInput = styled.div<IItemSizeInputProps>`
  display: flex;
  flex-direction: column;

  span {
    color: #30303070;
    font-size: 14px;
  }

  input {
    min-height: 41px;
    padding: 8px 16px;
    border: 0;
    box-sizing: border-box;
    box-shadow: ${({ error, theme }) =>
      error
        ? `0 0 0 2px ${theme.palette.error} inset`
        : ' 0 0 0 2px #30303070 inset'};
  }
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const QuickSaveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};

  padding: 8px 16px;
  margin-left: auto;
  border-radius: 4px;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.primary};
  background: transparent;

  padding: 7px 15px;
  margin-left: auto;
  border-radius: 4px;
`;

export const Modal = styled(ReactModal)`
  animation: ${appearFromBottom} 0.5s;
  background: ${({ theme }) => theme.palette.background_white};
  position: relative;
  border-radius: 8px;
`;

export const CropImageActions = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;

  justify-content: space-between;

  .cropImageButtonsContainer {
    display: flex;
    gap: 8px;
  }
`;

export const CropImageAction = styled.button`
  background: ${({ theme }) => theme.palette.primary};
  padding: 6px 16px;
  display: flex;
  align-items: center;
  border: 0;
  border-radius: 8px;

  &,
  svg {
    color: ${({ theme }) => theme.palette.text_white};
  }

  svg {
    margin-left: 8px;
  }
  margin-left: auto;
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-right: 1rem;

  span {
    font-size: 0.8rem;
  }

  &.margin {
    margin: 1rem 0;
  }
`;

export const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: auto;
`;

export const Label = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.control};
`;

export const ProductQuantity = styled.strong`
  font-size: 16px;
`;
