import styled from 'styled-components';
import { MenuItem } from '@szhsin/react-menu';
import { RemoveButton } from '../AddCategoryModal/styles';

export const MenuButton = styled(RemoveButton)`
  svg {
    fill: ${({ theme }) => theme.palette.text_white};
  }

  span {
    font-size: 1rem;
  }
`;

export const Item = styled(MenuItem)`
  display: flex;
  gap: 1rem;
  padding: 0.5rem 1rem !important;
  background: ${({ theme }) => theme.palette.background_white};

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  &:before {
    display: none !important;
  }

  &,
  &.szh-menu__item--active {
    background: ${({ theme }) => theme.palette.background_white};
    color: ${({ theme }) => theme.palette.text};
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);

    &.warn {
      background: ${({ theme }) => theme.palette.error};
      color: ${({ theme }) => theme.palette.text_white};
    }
  }
`;
