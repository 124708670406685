import React, { useCallback, useMemo, useState } from 'react';

import { FiSearch, FiX } from 'react-icons/fi';

import IComplementsGroup from '../../models/IComplementsGroup';

import ComplementsGroupItem from '../ComplementsGroupItem';

import {
  Search,
  Content,
  Container,
  Separator,
  BackButton,
  StyledModal,
  SearchContainer,
  ComplementsContainer,
} from './styles';

interface ISearchComplementsModalProps {
  isOpen: boolean;
  groups: IComplementsGroup[];
  onClose: () => void;
  onConfirm: (group: IComplementsGroup) => void;
}

StyledModal.setAppElement('#root');

const SearchComplementsModal: React.FC<ISearchComplementsModalProps> = ({
  isOpen,
  groups,
  onClose,
  onConfirm,
}) => {
  const [searchCriteria, setSearchCriteria] = useState('');

  const handleOnSearchCriteriaChanged = useCallback((value: string) => {
    setSearchCriteria(value);
  }, []);

  const handleOnSearchCriteriaReseted = useCallback(() => {
    setSearchCriteria('');
  }, []);

  const searchedGroups = useMemo(() => {
    return groups.filter(group => {
      const string_norm = searchCriteria
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      return group.title
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(string_norm.toLowerCase());
    });
  }, [groups, searchCriteria]);

  return (
    <StyledModal
      id="options-dialog"
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      isOpen={isOpen}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        },
      }}
    >
      <Container>
        <BackButton onClick={onClose}>
          <FiX size={24} />
          Fechar
        </BackButton>
        <SearchContainer>
          <Search>
            <FiSearch />
            <input
              type="text"
              placeholder="Pesquisar"
              value={searchCriteria}
              onChange={e => handleOnSearchCriteriaChanged(e.target.value)}
            />
            <FiX onClick={handleOnSearchCriteriaReseted} />
          </Search>
        </SearchContainer>
        <Content>
          <ComplementsContainer className="has-custom-scroll-bar-2">
            {searchedGroups.length > 0 ? (
              <>
                {searchedGroups.map(group => (
                  <div key={group.id}>
                    <ComplementsGroupItem
                      small
                      complementsGroup={group}
                      onClick={onConfirm}
                    />
                    <Separator />
                  </div>
                ))}
              </>
            ) : (
              <span>Nenhum grupo encontrado.</span>
            )}
          </ComplementsContainer>
        </Content>
      </Container>
    </StyledModal>
  );
};

export default SearchComplementsModal;
