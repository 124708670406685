import React, { useEffect } from 'react';

import { Switch, Route } from 'react-router-dom';

import AdminRoutes from './admin';
import { useAuth } from '../hooks/auth';

import PrivateRoute from '../components/PrivateRoute';

import SignInPage from '../pages/SignInPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';

const Routes: React.FC = () => {
  const { user, checkForUpdates } = useAuth();

  useEffect(() => {
    checkForUpdates();
  }, [checkForUpdates]);

  return (
    <Switch>
      <Route path="/signin" component={SignInPage} />
      <Route path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route path="/session-expired" component={SignInPage} />
      <Route path="/forgot-password" component={ForgotPasswordPage} />
      <PrivateRoute
        path="/"
        authed={!!user}
        component={AdminRoutes}
        redirect="signin"
      />
    </Switch>
  );
};

export default Routes;
