import React from 'react';

import { DateTime } from 'luxon';
import { FiX } from 'react-icons/fi';

import { useDiscountsCoupons } from '../../hooks/discounts_coupons';

import { formatToMoney } from '../../utils/format';

import {
  Card,
  Title,
  Header,
  Content,
  Container,
  Description,
  StyledModal,
  InfoContainer,
  CardsContainer,
  IsUniqueContainer,
} from './styles';

interface ICouponMetricsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CouponMetricsModal: React.FC<ICouponMetricsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { selectedCoupon } = useDiscountsCoupons();

  return (
    <StyledModal
      isOpen={isOpen}
      shouldCloseOnEsc
      style={{
        overlay: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
    >
      <Container>
        <Header>
          <Title>Detalhes do cupom</Title>
          <FiX size={24} onClick={onClose} />
        </Header>
        <Content>
          <InfoContainer>
            <Description>{selectedCoupon?.description}</Description>
          </InfoContainer>
          <IsUniqueContainer>
            <span>
              {selectedCoupon?.uniqueUse
                ? 'Cupom de uso único.'
                : 'Cupom sem limite de usos por cliente.'}
            </span>
          </IsUniqueContainer>
          <CardsContainer>
            <Card>
              <span>Usos do cupom</span>
              <strong>{selectedCoupon?.effectiveUses}</strong>
            </Card>
            <Card>
              <span>Máximo de usos</span>
              <strong>{selectedCoupon?.maxUses}</strong>
            </Card>
          </CardsContainer>
          <CardsContainer>
            <Card>
              <span>Data inicial</span>
              <strong>
                {DateTime.fromISO(
                  selectedCoupon?.initialDate || '',
                ).toLocaleString({
                  locale: 'pt-BR',
                })}
              </strong>
            </Card>
            <Card>
              <span>Data final</span>
              <strong>
                {DateTime.fromISO(
                  selectedCoupon?.finalDate || '',
                ).toLocaleString({
                  locale: 'pt-BR',
                })}
              </strong>
            </Card>
          </CardsContainer>
          <CardsContainer>
            <Card>
              <span>Valor mínimo</span>
              <strong>{formatToMoney(selectedCoupon?.minAmount || 0)}</strong>
            </Card>
            <Card>
              <span>Valor máximo do desconto</span>
              <strong>{formatToMoney(selectedCoupon?.maxAmount || 0)}</strong>
            </Card>
          </CardsContainer>
          <CardsContainer>
            <Card>
              <span>Total aplicado pelo cupom</span>
              <strong>
                {formatToMoney(selectedCoupon?.effectiveAmount || 0)}
              </strong>
            </Card>
          </CardsContainer>
        </Content>
      </Container>
    </StyledModal>
  );
};

export default CouponMetricsModal;
