import styled, { css } from 'styled-components';

interface IPrintButtonProps {
  type?: string;
}

interface IComplementsContainerProps {
  inverse?: boolean;
}

export const Container = styled.div`
  width: 90%;
  height: 100%;

  display: flex;
  flex-direction: column;

  margin: 0 auto;
`;

export const Header = styled.header`
  width: 100%;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 6pt;

  img {
    width: 52.5pt;
    height: 52.5pt;
  }
`;

export const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 9pt;
  text-align: center;

  margin-left: 12pt;
`;

export const CompanyName = styled.strong`
  font-size: 12pt;
`;

export const CompanyAddress = styled.span``;

export const CompanyNeighborhood = styled.span``;

export const CompanyCity = styled.span``;

export const Main = styled.main`
  margin-top: 12pt;
`;

export const OrderNumberContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OrderType = styled.div`
  text-align: center;
  padding: 0 6pt;
  font-weight: bold;

  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.black};
`;

export const PaymentContainer = styled.div`
  margin-left: 30%;

  margin-top: 12pt;
`;

export const OrderNumber = styled.strong``;

export const CustomerContainer = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 11pt;
  margin-top: 12pt;
  padding-bottom: 6pt;
  border-bottom: 1px solid ${({ theme }) => theme.palette.black};
`;

export const CustomerName = styled.div`
  font-size: 16pt;
`;

export const CustomerAddress = styled.span``;

export const CustomerNeighborhood = styled.span``;

export const CustomerCity = styled.span``;

export const CustomerPhone = styled.strong`
  margin-bottom: 4px;
  font-size: 11pt !important;
`;

export const CustomerDocument = styled.span``;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 11pt;
  margin-top: 24pt;
`;

export const ItemsTitle = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  width: 100%;

  padding-bottom: 6pt;
  border-bottom: 1px solid ${({ theme }) => theme.palette.black};

  .items-title-inner-container {
    span + span {
      margin-left: 11pt;
    }
  }
`;

export const OrderInfoItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  position: relative;

  span.value {
    font-size: 11pt;
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;

  margin-left: 56%;
`;

export const Items = styled.div``;

export const Item = styled.div`
  padding: 6pt 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.black};
`;

export const HorizontalWrapper = styled.div`
  display: flex;

  span + span {
    margin-left: 6pt;
  }

  span.amount {
    width: 30px;
  }
`;

export const ItemName = styled.span``;

export const ItemComments = styled.span``;

export const ItemTotal = styled.span``;

export const ItemAdditionals = styled.div`
  margin-left: 12pt;
`;

export const ItemAdditionalsTitle = styled.span`
  font-weight: bold;
`;

export const ItemOptionals = styled.div`
  margin-left: 12pt;
`;

export const ItemOptionalsTitle = styled.span`
  font-weight: bold;
`;

export const Complement = styled.div`
  display: flex;
  justify-content: space-between;

  margin-left: 12pt;

  div {
    display: flex;

    span + span {
      margin-left: 4px;
    }
  }
`;

export const ComplementAmount = styled.span``;

export const ComplementName = styled.span``;

export const Totals = styled.div`
  margin-left: 30%;
  margin-top: 12pt;
`;

export const Subtotal = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 10pt;
`;

export const Discount = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 10pt;
`;

export const DeliveryFee = styled.div`
  display: flex;
  justify-content: space-between;

  padding-bottom: 6pt;
  border-bottom: 1px solid ${({ theme }) => theme.palette.black};

  font-size: 10pt;
`;

export const Total = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 6pt;
`;

export const PaymentTitle = styled.span`
  display: flex;
  justify-content: center;

  border-bottom: 1px solid ${({ theme }) => theme.palette.black};

  font-size: 11pt;
`;

export const PaymentType = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 6pt;

  font-size: 10pt;
`;

export const TotalPrice = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 10pt;
`;

export const TotalChange = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 10pt;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  font-size: 9pt;
  margin-top: 12pt;
`;

export const ApprovedBy = styled.span``;

export const OrderDate = styled.div`
  margin-top: 12pt;
`;

export const PrintDate = styled.div``;

export const MadeBy = styled.div`
  margin-top: 12pt;
`;

export const PrintButton = styled.div<IPrintButtonProps>`
  position: absolute;

  top: 18pt;
  right: 24pt;

  button {
    display: flex;
    align-items: center;

    svg {
      margin-right: 12pt;
    }

    ${({ type }) =>
      type === 'MODAL'
        ? css`
            font-size: 18pt;

            border: 0;
            background: none;
          `
        : css`
            font-size: 18pt;

            border: 0;
            padding-bottom: 12pt;
            border-bottom: 4px solid #db1622;
            background: none;
          `}
  }

  @media (max-width: 680px) {
    display: none;
  }
`;

export const Comments = styled.strong``;

export const ComplementsContainer = styled.div<IComplementsContainerProps>`
  display: flex;
  flex-direction: ${({ inverse }) => (inverse ? 'column-reverse' : 'column')};
`;
