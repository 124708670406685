import React, { useEffect, useState } from 'react';
import { RiWifiOffLine } from 'react-icons/ri';
import { useNetwork } from '../../hooks/network';

import { Container, Content } from './styles';

const OfflineModal: React.FC = () => {
  const { online } = useNetwork();
  const [wasOffline, setWasOffline] = useState(false);

  useEffect(() => {
    if (!online) {
      document.body.style.setProperty('pointer-events', 'none');
      setWasOffline(true);
    } else {
      document.body.style.setProperty('pointer-events', 'unset');

      if (wasOffline) {
        window.location.reload();
      }
    }
  }, [online, wasOffline]);

  return (
    <Container online={online}>
      <Content>
        <RiWifiOffLine size={32} />
        <p>Você está offline! Verifique sua conexão e tente novamente.</p>
      </Content>
    </Container>
  );
};

export default OfflineModal;
