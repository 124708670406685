/* eslint-disable no-param-reassign */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MdSearch } from 'react-icons/md';
import Spinner from 'react-spinner-material';
import IProduct from '../../models/IProduct';
import ProductIcon from '../ProductIcon';

import {
  Container,
  Backdrop,
  ProductsContainer,
  Product,
  ProductImage,
  Search,
  Loading,
} from './styles';

interface IProps {
  open?: boolean;
  products: IProduct[];
  loading?: boolean;
  onClose?: () => void;
}

const ComplementsGroupProducts: React.FC<IProps> = ({
  open,
  loading,
  products,
  onClose,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const searchRef = useRef<HTMLInputElement | null>(null);

  const search = useMemo(
    () => (text: string, term: string) =>
      text
        ?.toLowerCase()
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '')
        ?.includes(term.toLowerCase()),
    [],
  );

  const searchedProducts = useMemo(() => {
    return products.filter(product => {
      const string_norm = searchTerm
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      return (
        search(product.title, string_norm) ||
        product.categories.find(c => search(c?.name, string_norm)) ||
        search(product?.subcategory?.name, string_norm)
      );
    });
  }, [searchTerm, products, search]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    },
    [],
  );

  const handleKeyDown = useCallback(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    if (!open) {
      setSearchTerm('');
      if (searchRef.current) {
        searchRef.current.value = '';
      }
    }
  }, [open]);

  return (
    <>
      <Backdrop open={open} onClick={onClose} />
      <Container open={open}>
        {loading ? (
          <Loading>
            <Spinner color="black" radius={36} stroke={2} visible />
          </Loading>
        ) : (
          <>
            <h2>Produtos</h2>
            <Search>
              <MdSearch size={24} />
              <input
                type="text"
                onChange={handleSearchChange}
                ref={searchRef}
              />
            </Search>
            <ProductsContainer className="has-custom-scroll-bar">
              {searchedProducts.map(product => (
                <Product key={product.id}>
                  <ProductImage>
                    <ProductIcon url={product.imageUrl} alt={product.title} />
                  </ProductImage>
                  {product.title}
                </Product>
              ))}
            </ProductsContainer>
          </>
        )}
      </Container>
    </>
  );
};

export default ComplementsGroupProducts;
