/* eslint-disable no-param-reassign */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MdKeyboardArrowRight, MdSearch } from 'react-icons/md';
import { FiX } from 'react-icons/fi';
import IProduct from '../../models/IProduct';
import ProductIcon from '../ProductIcon';

import {
  Container,
  ProductsContainer,
  Product,
  ProductImage,
  Search,
  StyledModal,
  Header,
  ClearSearchButton,
} from './styles';

interface IProps {
  open: boolean;
  products: IProduct[];
  onClose?: () => void;
  onSelect?: (id?: number) => void;
}

StyledModal.setAppElement('#root');

const ProductsSelectorModal: React.FC<IProps> = ({
  open,
  products,
  onClose,
  onSelect,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const searchRef = useRef<HTMLInputElement | null>(null);

  const search = useMemo(
    () => (text: string, term: string) =>
      text
        ?.toLowerCase()
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '')
        ?.includes(term.toLowerCase()),
    [],
  );

  const searchedProducts = useMemo(() => {
    if (!products) {
      return [];
    }
    return products.filter(product => {
      const string_norm = searchTerm
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      return (
        search(product.title, string_norm) ||
        product.categories?.find(c => search(c?.name, string_norm)) ||
        search(product?.subcategory?.name, string_norm)
      );
    });
  }, [searchTerm, products, search]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    },
    [],
  );

  const handleOnSearchClear = () => {
    setSearchTerm('');
    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  const handleKeyDown = useCallback(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    if (!open) {
      handleOnSearchClear();
    }
  }, [open]);

  return (
    <StyledModal
      id="options-dialog"
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      isOpen={open}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        },
      }}
    >
      <Container>
        <Header>
          <FiX size={24} onClick={onClose} />
          <h2>Selecione um produto</h2>
        </Header>
        <Search>
          <MdSearch size={24} />
          <input type="text" onChange={handleSearchChange} ref={searchRef} />
          <ClearSearchButton onClick={handleOnSearchClear}>
            <FiX size={18} />
          </ClearSearchButton>
        </Search>
        <ProductsContainer className="has-custom-scroll-bar">
          {searchedProducts.map(product => (
            <Product
              key={product.id}
              onClick={() => onSelect && onSelect(product.id)}
            >
              <ProductImage>
                <ProductIcon url={product.imageUrl} alt={product.title} />
              </ProductImage>
              {product.title}
              <MdKeyboardArrowRight size={16} className="arrow" />
            </Product>
          ))}
        </ProductsContainer>
      </Container>
    </StyledModal>
  );
};

export default ProductsSelectorModal;
