import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface IInputProps {
  hasError: boolean;
  hasFocus: boolean;
  hasContent: boolean;
}

interface IContainerProps {
  maxLength?: number;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  position: relative;
  flex-direction: column;

  ${({ maxLength }) =>
    maxLength &&
    css`
      &::after {
        display: block;
        content: var(--current) '/${maxLength}';
        font-size: 12px;
        margin-left: auto;
      }
    `}
`;

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #aaa;

  > a {
    text-decoration: none;
    color: #db1622;
    font-size: 14px;
    transition: opacity 0.3s;
  }

  > a:hover {
    opacity: 0.6;
  }
`;

export const StyledInput = styled.input<IInputProps>`
  border: 0;

  ${({ hasError, hasContent, hasFocus }) =>
    hasError
      ? css`
          border-bottom: 1px solid #c53030;
        `
      : css`
          border-bottom: 1px solid
            ${hasContent || hasFocus ? '#db1622' : '#aaa'};
        `}

  padding: 16px 36px 16px 0;

  background: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`;

export const Error = styled(Tooltip)`
  height: 20px;
  position: absolute;
  top: 36px;
  right: 12px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const Copy = styled.div`
  height: 20px;
  position: absolute;
  top: 36px;
  right: 12px;
  cursor: pointer;

  svg {
    margin: 0;
  }
`;
