import React, { useCallback } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';

import placeholder from '../../assets/avatar.png';
import IOperator from '../../models/IOperator';

import {
  Left,
  Image,
  Container,
  OperatorInfo,
  OperatorName,
  OperatorLogin,
  ImageContainer,
  ActivateOperatorButton,
  InfoContainer,
} from './styles';
import { ComboBadge } from '../Product/styles';

interface IOperatorProps {
  operator: IOperator;
  onOperatorClicked: (operator: IOperator) => void;
  onStatusChange(id: number): void;
}

const Operator: React.FC<IOperatorProps> = ({
  operator,
  onStatusChange,
  onOperatorClicked,
}) => {
  const handleOnStatusClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onStatusChange(operator.id);
    },
    [onStatusChange, operator],
  );

  const handleOnOperatorClicked = useCallback(() => {
    onOperatorClicked(operator);
  }, [operator, onOperatorClicked]);

  return (
    <Container onClick={handleOnOperatorClicked}>
      <Left>
        <InfoContainer>
          <ImageContainer>
            <Image src={placeholder} />
          </ImageContainer>
          <OperatorInfo>
            <OperatorName>{operator.name}</OperatorName>
            <OperatorLogin>{operator.login}</OperatorLogin>
          </OperatorInfo>
        </InfoContainer>
        {operator.isAdmin && <ComboBadge id="admin-badge">ADMIN</ComboBadge>}
      </Left>
      <ActivateOperatorButton
        active={operator.isActive}
        onClick={handleOnStatusClick}
      >
        {operator.isActive ? (
          <>
            <FiCheck />
            <span>Ativo</span>
          </>
        ) : (
          <>
            <FiX />
            <span>Inativo</span>
          </>
        )}
      </ActivateOperatorButton>
    </Container>
  );
};

export default Operator;
