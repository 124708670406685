import React, { useCallback, useEffect, useRef, useState } from 'react';

import { isNumberValid } from '../../utils/numbers';

import {
  Title,
  Buttons,
  Message,
  CodeInput,
  Container,
  StyledModal,
  CancelButton,
  ConfirmButton,
  InputContainer,
} from './styles';

interface IActivateDeviceDialog {
  title: string;
  isOpen: boolean;
  message?: string;
  onClose: () => void;
  onConfirm: (code: string) => void;
}

StyledModal.setAppElement('#root');

const ActivateDeviceDialog: React.FC<IActivateDeviceDialog> = ({
  title,
  isOpen,
  message,
  onClose,
  onConfirm,
}) => {
  const ref1 = useRef<HTMLInputElement>(null);
  const ref2 = useRef<HTMLInputElement>(null);
  const ref3 = useRef<HTMLInputElement>(null);
  const ref4 = useRef<HTMLInputElement>(null);

  const [error, setError] = useState(false);
  const [code, setCode] = useState(['', '', '', '']);

  useEffect(() => {
    setTimeout(() => {
      ref1.current?.focus();
    }, 600);

    if (!isOpen) {
      setCode(['', '', '', '']);
    }
  }, [isOpen]);

  const handleOnTextChanged = useCallback((text: string, index: number) => {
    if (isNumberValid(text)) {
      setCode(prevState => {
        const next = [...prevState];
        next[index] = text;
        return next;
      });

      switch (index) {
        case 0: {
          ref2.current?.focus();
          break;
        }

        case 1: {
          ref3.current?.focus();
          break;
        }

        case 2: {
          ref4.current?.focus();
          break;
        }

        default:
          break;
      }
    }
  }, []);

  const handleOnConfirm = useCallback(() => {
    const wholeCode = code.join('');

    if (wholeCode.length < 4) {
      setError(true);
      return;
    }

    onConfirm(wholeCode);
  }, [code, onConfirm]);

  return (
    <StyledModal
      id="device-activation-dialog"
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      isOpen={isOpen}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        },
      }}
    >
      <Container>
        {title && <Title>{title}</Title>}
        {message && (
          <Message error={error}>
            {error ? 'Verifique o código e tente novamente!' : message}
          </Message>
        )}
        <InputContainer>
          <CodeInput
            ref={ref1}
            type="text"
            maxLength={1}
            value={code[0]}
            onChange={e => handleOnTextChanged(e.target.value, 0)}
          />
          <CodeInput
            ref={ref2}
            type="text"
            maxLength={1}
            value={code[1]}
            onChange={e => handleOnTextChanged(e.target.value, 1)}
          />
          <CodeInput
            ref={ref3}
            type="text"
            maxLength={1}
            value={code[2]}
            onChange={e => handleOnTextChanged(e.target.value, 2)}
          />
          <CodeInput
            ref={ref4}
            type="text"
            maxLength={1}
            value={code[3]}
            onChange={e => handleOnTextChanged(e.target.value, 3)}
          />
        </InputContainer>
        <Buttons>
          <CancelButton onClick={onClose}>Cancelar</CancelButton>
          <ConfirmButton onClick={handleOnConfirm}>Confirmar</ConfirmButton>
        </Buttons>
      </Container>
    </StyledModal>
  );
};

export default ActivateDeviceDialog;
