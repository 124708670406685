import React from 'react';

import { AiOutlineOrderedList } from 'react-icons/ai';

import { Container } from './styles';

interface IOrderButtonProps {
  id?: string;
  onClick?: () => void;
}

const OrderButton: React.FC<IOrderButtonProps> = ({ ...props }) => {
  return (
    <Container {...props} type="button">
      <AiOutlineOrderedList />
      <span />
    </Container>
  );
};

export default OrderButton;
