import React from 'react';
import {
  AppliedFilter,
  AppliedFiltersContainer,
  Filter,
  Label,
  Value,
} from './styles';

type Filter = {
  label: string;
  value: string;
}[];

export interface AppliedFiltersProps {
  appliedFilters: Array<Filter>;
}

const AppliedFilters: React.FC<AppliedFiltersProps> = ({ appliedFilters }) => {
  return (
    <AppliedFiltersContainer>
      {appliedFilters.map((appliedFilter, index) => (
        <AppliedFilter key={index.toString()}>
          {appliedFilter.map(filter => (
            <Filter key={filter.label}>
              <Label>{filter.label}</Label>
              <Value>{filter.value}</Value>
            </Filter>
          ))}
        </AppliedFilter>
      ))}
    </AppliedFiltersContainer>
  );
};

export default AppliedFilters;
