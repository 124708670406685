import styled from 'styled-components';

interface IContainerProps {
  busy: boolean;
}

export const Container = styled.div<IContainerProps>`
  width: calc(1 / 5 * 100% - (1 - 1 / 3) * 24px);

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 16px;
  background: ${({ busy, theme }) =>
    busy ? theme.palette.status_busy : theme.palette.background_white};

  cursor: pointer;

  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.4s;

  &:hover {
    box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.2);
  }

  img {
    width: 40px;
  }

  span {
    margin-top: 8px;
  }

  span.card {
    font-size: 14px;
    margin-top: 0px;
  }

  @media (max-width: 1400px) {
    width: calc(1 / 2 * 100% - (1 - 1 / 3) * 16px);
  }

  @media (max-width: 525px) {
    width: 100%;
  }
`;
