/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useMemo, useState } from 'react';

import { FiTrash2, FiChevronDown } from 'react-icons/fi';

import IComplementsGroup from '../../models/IComplementsGroup';

import drag from '../../assets/icons/drag.svg';
import { formatToMoney } from '../../utils/format';

import {
  Top,
  Info,
  Item,
  Main,
  Actions,
  Wrapper,
  Container,
  GroupName,
  Mandatory,
  ItemAmount,
  DraggableIcon,
  IconContainer,
  ItemsContainer,
} from './styles';
import { AuthRole } from '../../hooks/auth';

interface IComplementsGroupProps {
  beingDragged: boolean;
  complementsGroup: IComplementsGroup;
  onDeleteClick: (id: number | string) => void;
}

const ComplementsGroup: React.FC<IComplementsGroupProps> = ({
  beingDragged,
  complementsGroup,
  onDeleteClick,
}) => {
  const [activeItems, setActiveItems] = useState(false);

  const itemAmount = useMemo(() => {
    if (complementsGroup.complements.length > 0) {
      if (complementsGroup.complements.length === 1) {
        return '1 item';
      }
      return `${complementsGroup.complements.length} items`;
    }
    return 'Nenhum item';
  }, [complementsGroup]);

  const handleChevronChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setActiveItems(event.target.checked);
    },
    [],
  );

  return (
    <Container
      activeItems={activeItems}
      beingDragged={beingDragged}
      items={complementsGroup.complements.length}
    >
      <Main>
        <Wrapper>
          <DraggableIcon>
            <img src={drag} alt="Arraste" />
          </DraggableIcon>
          <Info>
            <GroupName>{complementsGroup.title}</GroupName>
            <Mandatory>
              {complementsGroup.minAmount > 0
                ? 'OBRIGATÓRIO'
                : 'NÃO OBRIGATÓRIO'}
            </Mandatory>
          </Info>
        </Wrapper>
        <Actions>
          <Top>
            <AuthRole blackList={['Employee']}>
              <IconContainer
                onClick={() =>
                  onDeleteClick(
                    complementsGroup.id || complementsGroup.localId || 0,
                  )
                }
              >
                <FiTrash2 size={18} />
              </IconContainer>
            </AuthRole>
          </Top>
          <ItemAmount>
            <span>{itemAmount}</span>
            <input
              type="checkbox"
              id={`${complementsGroup.id}-list`}
              onChange={handleChevronChange}
            />
            <label htmlFor={`${complementsGroup.id}-list`}>
              <FiChevronDown size={32} />
            </label>
          </ItemAmount>
        </Actions>
      </Main>
      <ItemsContainer className="item-container">
        {complementsGroup.complements.length > 0 &&
          complementsGroup.complements.map(complement => (
            <Item key={complement.id || complement.localId}>
              <span>{complement.title}</span>
              <strong>{formatToMoney(complement.unitPrice)}</strong>
            </Item>
          ))}
      </ItemsContainer>
    </Container>
  );
};

export default ComplementsGroup;
