const theme = {
  palette: {
    background: '#f4f4f6',
    background_white: '#fff',
    background_dark: '#e3dada',
    background_info: '#fafafa',
    background_error: '#fafafa',
    background_success: '#fafafa',
    background_secondary: '#f4f4f6',
    primary: '#da123a',
    primary_light: '#f6adb2',
    secondary: '#04D361',
    secondary_dark: '#04BF58',
    text: '#1b1c1e',
    text_light: '#878b93',
    text_white: '#f9f7f7',
    info: '#3172b7',
    control: '#aaa',
    control_light: '#ccc',
    error: '#c53030',
    warning: '#e3ae0e',
    error_light: '#c5303060',
    success: '#27ae60',
    success_light: '#27ae6060',
    outline: 'rgba(0, 0, 0, .1)',
    background_input: '#fcfbfb',
    disabled: '#eeeeee',
    black_transparency: 'rgba(0, 0, 0, 0.8)',
    black: '#000',
    unselected: '#f4f4f4',
    status_busy: '#e74c3c',
    status_placed: '#3498db',
    status_placed_transparency: '#3498db30',
    status_approved: '#27ae60',
    status_approved_transparency: '#27ae6030',
    status_canceled: '#e74c3c',
    status_canceled_transparency: '#e74c3c30',
    status_in_prepare: '#e67e22',
    status_in_prepare_transparency: '#e67e2230',
    status_in_transit: '#34495e',
    status_in_transit_transparency: '#34495e30',
  },
  shadow: {
    box_shadow: '3px 3px 16px 0 rgba(0, 0, 0, 0.2)',
    box_shadow_sidebar: '-3px 7px 12px 0 rgba(0, 0, 0, 0.2)',
    box_shadow_hover: '3px 3px 8px 0 rgba(0, 0, 0, 0.2)',
    box_shadow_hover_primary: '3px 3px 8px 0 rgba(219, 22, 34, 0.2)',
    box_shadow_small: '3px 3px 8px 0 rgba(0, 0, 0, 0.1)',
    box_shadow_small_primary: '3px 3px 8px 0 rgba(219, 22, 34, 0.1)',
    box_shadow_secondary: '2px 2px 6px 0 rgba(0, 0, 0, 0.2)',
  },
};

export default theme;
