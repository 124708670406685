import React, { useState, useEffect, useCallback, useRef } from 'react';

import {
  Draggable,
  Droppable,
  DropResult,
  DragDropContext,
} from 'react-beautiful-dnd';

import { FiX } from 'react-icons/fi';

import { removeDuplicateValues } from '../../utils/arrays';

import { ReactComponent as DragSvg } from '../../assets/icons/drag.svg';

import {
  StyledModal,
  Container,
  Header,
  HeaderButtonsContainer,
  BackButton,
  SaveButton,
  Content,
  GroupContainer,
  Group,
  PositionContainer,
  SortByButton,
} from './styles';
import FormlessInput, { IFormlessInputRef } from '../FormlessInput';
import { clamp } from '../../utils/numbers';
import { digitsOnly } from '../../utils/string';

export interface IGroupProps {
  id: number | string;
  label: string;
  position: number;
  imageUrl?: string;
}

interface IOrderGroupModalProps {
  id: string;
  groups: IGroupProps[];
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (groups: IGroupProps[]) => void;
}

interface IHandleOnBlurInputProps {
  draggableId: string;
  sourceIndex: number;
  destinationIndex: number;
}

const OrderGroupModal: React.FC<IOrderGroupModalProps> = ({
  id,
  groups,
  isOpen,
  onClose,
  onConfirm,
}) => {
  const [newGroups, setNewGroups] = useState<IGroupProps[] | null>(null);

  const inputRefs = useRef<IFormlessInputRef[]>([]);

  const updateGroupsPositions = useCallback(() => {
    if (inputRefs.current) {
      inputRefs.current.forEach(ref => {
        ref.setValue(ref.getDataset()?.position);
      });
    }
  }, []);

  useEffect(() => {
    if (groups) {
      const normalizedGroups = removeDuplicateValues(
        groups,
        item => item.position || 0,
      );

      setNewGroups(() => {
        if (normalizedGroups.length !== groups.length) {
          groups.map((group, index) => ({
            ...group,
            position: index,
          }));
        }

        return groups;
      });
    }
  }, [groups, updateGroupsPositions]);

  useEffect(() => {
    updateGroupsPositions();
  }, [newGroups, updateGroupsPositions]);

  const reorderGroup = useCallback(
    (list: IGroupProps[], startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result.map((group, index) => ({
        ...group,
        position: index,
      }));
    },
    [],
  );

  const handleOnDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination) {
        setNewGroups(old => {
          if (!old) {
            return null;
          }

          const newGroups = reorderGroup(
            old,
            result.source.index,
            result?.destination?.index || 0,
          );

          return newGroups;
        });
      }
    },
    [reorderGroup],
  );

  const handleOnBlurInput = useCallback(
    ({
      draggableId,
      sourceIndex,
      destinationIndex,
    }: IHandleOnBlurInputProps) => {
      if (sourceIndex === destinationIndex) return;

      const clampedDestinationIndex = clamp(
        destinationIndex,
        0,
        groups.length - 1,
      );

      handleOnDragEnd({
        draggableId: draggableId.toString(),
        type: 'DEFAULT',
        source: {
          index: sourceIndex,
          droppableId: 'complements',
        },
        reason: 'DROP',
        mode: 'FLUID',
        destination: {
          droppableId: 'complements',
          index: clampedDestinationIndex,
        },
      });

      updateGroupsPositions();
    },
    [groups.length, handleOnDragEnd, updateGroupsPositions],
  );

  const handleOnSortByAlphabetical = useCallback(() => {
    setNewGroups(old => {
      if (!old) {
        return null;
      }

      const sortedGroups = old.sort((a, b) => {
        return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;
      });

      return sortedGroups.map((group, index) => ({
        ...group,
        position: index,
      }));
    });
  }, []);

  return (
    <StyledModal
      id={id}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      isOpen={isOpen}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        },
      }}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Container>
          <Header>
            <HeaderButtonsContainer>
              <BackButton type="button" onClick={onClose}>
                <FiX size={24} />
                Fechar
              </BackButton>
              <SortByButton type="button" onClick={handleOnSortByAlphabetical}>
                A-Z
              </SortByButton>
              <SaveButton
                type="submit"
                onClick={() => newGroups && onConfirm(newGroups)}
              >
                Salvar
              </SaveButton>
            </HeaderButtonsContainer>
            <span>Clique, segure e arraste para reordenar</span>
          </Header>
          <Content>
            {groups ? (
              <Droppable droppableId="complements" direction="vertical">
                {providedDroppable => (
                  <GroupContainer
                    className="has-custom-scroll-bar-2"
                    ref={providedDroppable.innerRef}
                    {...providedDroppable.droppableProps}
                  >
                    {newGroups
                      ?.sort((a, b) =>
                        (a.position || 0) > (b.position || 0)
                          ? 1
                          : (b.position || 0) > (a.position || 0)
                          ? -1
                          : 0,
                      )
                      ?.map(
                        (group, index) =>
                          group.id && (
                            <Draggable
                              key={group.id}
                              draggableId={group.id.toString()}
                              index={index}
                            >
                              {provided => {
                                const handleInputEvent = (value: string) => {
                                  handleOnBlurInput({
                                    draggableId: group.id.toString(),
                                    sourceIndex: group.position || index,
                                    destinationIndex: Number(value) - 1,
                                  });
                                };

                                return (
                                  <Group
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <DragSvg />
                                    <div id="groupInfo">
                                      <div id="groupHeader">
                                        {group.imageUrl && (
                                          <img
                                            src={group.imageUrl}
                                            alt={group.label}
                                          />
                                        )}
                                        <span style={{ width: '100%' }}>
                                          {group.label}
                                        </span>
                                      </div>
                                      <PositionContainer>
                                        <span>#</span>
                                        <FormlessInput
                                          ref={el => {
                                            if (el) {
                                              inputRefs.current[index] = el;
                                            }
                                          }}
                                          defaultValue={
                                            (group.position || index) + 1
                                          }
                                          data-position={
                                            (group.position || index) + 1
                                          }
                                          name="group_position"
                                          min={0}
                                          onBlur={e =>
                                            handleInputEvent(e.target.value)
                                          }
                                          onKeyUp={e =>
                                            e?.key === 'Enter' &&
                                            handleInputEvent(
                                              e.currentTarget.value,
                                            )
                                          }
                                          // eslint-disable-next-line no-return-assign
                                          onChange={e =>
                                            (e.target.value = digitsOnly(
                                              e.target.value,
                                            ))
                                          }
                                          style={{
                                            width: '64px',
                                            padding: 0,
                                          }}
                                        />
                                      </PositionContainer>
                                    </div>
                                  </Group>
                                );
                              }}
                            </Draggable>
                          ),
                      )}
                  </GroupContainer>
                )}
              </Droppable>
            ) : (
              <span>Não há nenhum item para ser reordenado.</span>
            )}
          </Content>
        </Container>
      </DragDropContext>
    </StyledModal>
  );
};

export default OrderGroupModal;
