import Modal from 'react-modal';
import styled, { css } from 'styled-components';

import { appearFromBottom } from '../../styles/animations';

interface IPrintingOptionProps {
  selected: boolean;
}

interface IPrintingOptionsContainer {
  inverse?: boolean;
}

export const StyledModal = styled(Modal)`
  animation: ${appearFromBottom} 0.5s;
`;

export const Content = styled.div`
  width: 500px;
  min-width: 250px;
  max-height: 573px;

  overflow: hidden;
  border-radius: 8px;

  box-shadow: ${({ theme }) => theme.shadow.box_shadow};
`;

export const PrintPreview = styled.div`
  display: flex;
  height: calc(100% - 150px);

  padding: 8px;

  margin-top: 48px;

  overflow-y: auto;

  background-color: #f3edbf;
`;

export const Printing = styled.div`
  width: 100%;
  margin-top: 8px;

  @media (max-width: 1075px) {
    margin-left: 0;
    margin-top: 64px;
  }

  @media (min-width: 546px) {
    z-index: 2;
  }

  @media (max-width: 545px) {
    width: 100%;
  }
`;

export const Divisor = styled.span`
  margin: 0 8px;
`;

export const PrintingOptionsContainer = styled.div<IPrintingOptionsContainer>`
  display: flex;
  align-items: center;

  margin-top: -8px;
  margin-left: 16px;

  left: 0;
  position: absolute;
  flex-direction: ${({ inverse }) => (inverse ? 'row-reverse' : 'row')};
  justify-content: ${({ inverse }) => (inverse ? 'flex-end' : 'flex-start')};
`;

export const PrintingOption = styled.button<IPrintingOptionProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  border: none;
  background: none;

  > svg {
    margin-right: 8px;
  }

  ${({ selected }) =>
    selected
      ? css`
          font-size: 18px;
          font-weight: 500;
        `
      : css`
          font-size: 16px;
          font-weight: 400;
        `}
`;

export const Dialog = styled.div`
  padding: 18px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 16px;
    text-align: center;
  }

  @media (max-width: 600px) {
    width: (100vw - 64px);
    height: calc(100vh / 3);
  }
`;

export const Image = styled.img`
  width: 60px;
  height: 60px;

  @media (max-width: 320px) {
    width: 45px;
    height: 45px;
  }
`;

export const CancelButton = styled.button`
  align-self: flex-end;

  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};

  border: none;
  font-size: 18px;
  padding: 6px 32px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
`;

export const ScrollContainer = styled.div`
  width: 500px;
  min-width: 250px;
  max-height: 573px;

  display: flex;
  flex-direction: column;

  position: relative;

  padding: 8px;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.background_white};

  overflow-y: auto;
  overflow-x: hidden;
`;
