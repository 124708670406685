import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  padding: 16px 40px;

  @media screen and (max-width: 480px) {
    padding: 1rem;
  }

  .page {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InputLabel = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const Input = styled.input`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.text_light};

  &::placeholder {
    color: ${({ theme }) => theme.palette.text_light};
  }
`;

export const SearchButton = styled.button`
  margin-top: 1rem;
  display: flex;
  padding: 1rem 3rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background: ${({ theme }) => theme.palette.primary};
  border: none;
  border-radius: 4px;
  margin-bottom: 2rem;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &,
  svg {
    color: ${({ theme }) => theme.palette.text_white};
  }
`;

export const Options = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: 0.25rem;
  margin-top: -0.5rem;
  justify-content: center;
  align-items: center;
  min-height: 2.5rem;
`;

interface IOptionProps {
  active?: boolean;
}

export const Option = styled.button<IOptionProps>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0.5rem 1rem;
  background: ${({ theme }) => theme.palette.background_white};
  font-weight: 500;
  white-space: nowrap;

  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.palette.text_white};
      background: ${theme.palette.primary};
    `}
`;
