import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding-top: 32px;
  z-index: 10;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.palette.background};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

export const Header = styled.header`
  display: flex;
  padding: 0 32px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 16px;

  div {
    border-color: ${({ theme }) => theme.palette.text} !important;
  }
  @media (max-width: 1024px) {
    h1,
    div {
      display: none;
    }
  }
`;

export const Warning = styled.div`
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  width: 100%;
  display: hidden;
  span {
    color: ${({ theme }) => theme.palette.warning};
    font-size: 16px;
  }
  svg {
    stroke: ${({ theme }) => theme.palette.warning};
  }

  @media (max-width: 600px) {
    span {
      font-size: 14px;
    }
  }
`;

export const HighlightsContainer = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: overlay;
  padding: 4px 32px 16px 32px;
`;

export const Highlight = styled.div`
  width: 100%;
  user-select: none;
  padding-bottom: 8px;

  .wrapper {
    position: relative;
    border-radius: 6px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    box-shadow: ${({ theme }) => theme.shadow.box_shadow_hover};
    background: ${({ theme }) => theme.palette.background_white};

    @media (max-width: 500px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .edit {
      position: absolute;
      right: 24px;
      font-weight: bold;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const HighlightTitle = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25vw;

  @media (max-width: 500px) {
    max-width: 50vw;
  }
`;

export const HighlightImage = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  .product-icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`;

export const HighlightInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const EditHighlightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  span {
    font-weight: bold;
    font-size: 18px;
  }

  @media (max-width: 500px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const EditHighlightButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const EditHighlight = styled.button`
  padding: 6px;
  border-radius: 32px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  svg {
    color: ${({ theme }) => theme.palette.text_light};
  }

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;

export const AddButtonWrapper = styled.div`
  position: relative;
  width: 40%;
  margin: 16px auto;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const AddButton = styled.button`
  margin: 0 auto;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.palette.control};
  padding: 4px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    min-width: 12vw;
    min-height: 1px;
    max-height: 1px;
    background: ${({ theme }) => theme.palette.control};
    top: 0;
    bottom: 0;
    margin: auto 0;
    @media (max-width: 500px) {
      min-width: 30vw;
    }
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  svg {
    color: ${({ theme }) => theme.palette.control};
  }
`;

export const NoHighlightsMessage = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 32px;
  text-align: center;

  svg {
    margin: 4px 2px;
  }
`;
