import styled from 'styled-components';

interface IContainer {
  open?: boolean;
}

export const Container = styled.div<IContainer>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px) brightness(0.6);
  will-change: backdrop-filter;

  opacity: ${({ open }) => (open ? '1' : '0')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};

  transition: opacity 100ms ease, visibility 150ms linear;
  will-change: opacity, visibility;
`;

export const Dialog = styled.div`
  width: 350px;

  padding: 18px;
  text-align: center;
  background: ${({ theme }) => theme.palette.background_white};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }

  @media (max-width: 600px) {
    width: calc(100vw - 64px);
    height: calc(100vh / 3);
  }
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  button {
    border: none;
    padding: 6px 28px;
    border-radius: 8px;
    font-size: 18px;
    box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  }
`;

export const ConfirmButton = styled.button`
  background: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.text_white};
`;

export const CancelButton = styled.button`
  background: ${({ theme }) => theme.palette.control};
  color: ${({ theme }) => theme.palette.text_white};
`;
