import { ICouponArtVariant } from '../../models/ICouponArt';

export const defaultVariant: ICouponArtVariant = {
  id: 1,
  name: '1080x1080',
  imageUri: '/couponArts/amigo/1080x1080.webp',
  imageWidth: 1080,
  imageHeight: 1080,
  logo: {
    width: 110,
    height: 110,
    x: 722,
    y: 945,
    rotation: 0,
  },
  description: {
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    rotation: 0,
    color: '',
    weight: 'normal',
    empty: true,
  },
  amount: {
    width: 180,
    height: 82,
    x: 817,
    y: 139,
    rotation: 0,
    color: '#FFFFFF',
    weight: 'bold',
    transform: 'uppercase',
  },
  ref: {
    width: 615,
    height: 120,
    x: 232,
    y: 461,
    rotation: 0,
    color: '#f6d648',
    weight: 'bold',
    transform: 'uppercase',
  },
  maxUses: {
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    rotation: 0,
    color: '',
    weight: 'normal',
    empty: true,
  },
  disclaimer: {
    width: 364,
    height: 19.5,
    x: -149,
    y: 532,
    rotation: -90,
    color: '#FFFFFF',
    weight: 'normal',
    transform: 'uppercase',
  },
  minAmount: {
    width: 136,
    height: 34,
    x: 100,
    y: 829,
    rotation: 0,
    color: '#FFFFFF',
    weight: 'medium',
  },
  maxDiscount: {
    width: 136,
    height: 34,
    x: 847,
    y: 829,
    rotation: 0,
    color: '#FFFFFF',
    weight: 'medium',
  },
};

export const storiesVariant: ICouponArtVariant = {
  id: 2,
  name: '1080x1920',
  imageUri: '/couponArts/amigo/1080x1920.webp',
  imageWidth: 1080,
  imageHeight: 1920,
  logo: {
    width: 110,
    height: 110,
    x: 721,
    y: 1615,
    rotation: 0,
  },
  description: {
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    rotation: 0,
    color: '',
    weight: 'normal',
    empty: true,
  },
  amount: {
    width: 224,
    height: 99,
    x: 745,
    y: 297,
    rotation: 0,
    color: '#FFFFFF',
    weight: 'bold',
    transform: 'uppercase',
  },
  ref: {
    width: 712,
    height: 172,
    x: 184,
    y: 757,
    rotation: 0,
    color: '#f6d648',
    weight: 'bold',
    transform: 'uppercase',
  },
  maxUses: {
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    rotation: 0,
    color: '',
    weight: 'normal',
    empty: true,
  },
  disclaimer: {
    width: 377,
    height: 18,
    x: -158,
    y: 830,
    rotation: -90,
    color: '#FFFFFF',
    weight: 'normal',
    transform: 'uppercase',
  },
  minAmount: {
    width: 136,
    height: 34,
    x: 305,
    y: 1443,
    rotation: 0,
    color: '#FFFFFF',
    weight: 'medium',
  },
  maxDiscount: {
    width: 136,
    height: 34,
    x: 639,
    y: 1443,
    rotation: 0,
    color: '#FFFFFF',
    weight: 'medium',
  },
};

export const amigo_variants: ICouponArtVariant[] = [
  defaultVariant,
  storiesVariant,
];
