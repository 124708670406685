import styled, { css } from 'styled-components';

interface IVisibilityProps {
  active: boolean;
}

export const Container = styled.div`
  height: 140px;
  width: calc(1 / 3 * 100% - (1 - 1 / 3) * 24px);

  display: flex;
  align-items: center;

  border: 0;
  background: #fff;
  margin-bottom: 24px;

  transition: box-shadow 0.4s;
  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1780px) {
    width: calc(1 / 2 * 100% - (1 - 1 / 3) * 16px);
  }

  @media (max-width: 1280px) {
    width: 100%;
    flex: 0 100%;
    margin-left: 0;
    margin-bottom: 16px;
  }

  @media (max-width: 800px) {
    > span {
      font-size: 18px;
    }
  }

  @media (max-width: 500px) {
    box-shadow: none;
  }
`;

export const DeleteContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-self: flex-end;
  padding: 8px;

  svg {
    padding-left: 32px;
    border-radius: 50%;

    padding: 4px;

    height: 27px;
    width: 27px;

    &:hover {
      background: #1c1d1f50;
    }
  }

  @media (max-width: 500px) {
    margin-left: auto;
  }
`;

export const Content = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  border: 0;
  background: none;
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #f4f4f4;

  height: 60px;
  width: 60px;
  min-width: 60px;
  border-radius: 50%;

  margin-left: 12px;

  img {
    height: 35px;
    width: 35px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;

  > strong {
    color: #000;
    font-size: 18px;
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    margin-right: 8px;
  }

  span {
    text-align: left;
    font-size: 14px;
  }
`;

export const Visibility = styled.button<IVisibilityProps>`
  height: 100%;
  width: 130px;
  font-size: 14px;
  cursor: pointer;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;

  ${({ active }) =>
    active
      ? css`
          background: #27ae6090;
          transition: background-color 0.2s;
          &:hover {
            background: #27ae6070;
          }
        `
      : css`
          background: #e74c3c90;
          transition: background-color 0.2s;
          &:hover {
            background: #e74c3c70;
          }
        `}

  padding: 16px;

  svg {
    margin-bottom: 16px;
  }

  @media (max-width: 500px) {
    width: auto;
    height: auto;
    display: flex;
    cursor: pointer;
    align-self: flex-end;
    padding: 8px;
    background: transparent;
    transition: unset;

    &:hover {
      background: transparent;
    }

    svg {
      padding-left: 32px;
      border-radius: 50%;

      padding: 4px;

      height: 27px;
      width: 27px;

      &:hover {
        background: #1c1d1f50;
      }
    }
  }
`;

export const TypesContainer = styled.div`
  display: flex;

  margin-top: 16px;

  font-size: 12px;

  div + div {
    margin-left: 8px;
  }
`;

export const CategoryBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #3498db40;
  padding: 4px;
`;

export const SubcategoryBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #1abc9c40;
  padding: 4px;
`;

export const ListContainer = styled.div`
  width: 100%;

  background: #fff;

  margin-bottom: 16px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: box-shadow 0.4s;

  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 500px) {
    box-shadow: none;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;

  width: 40%;

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const ListImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #f4f4f4;

  height: 60px;
  width: 60px;
  min-width: 60px;
  border-radius: 50%;

  margin-left: 16px;

  img {
    height: 30px;
    width: 30px;
  }

  @media (max-width: 400px) {
    margin-left: 0;
  }
`;

export const ListName = styled.strong`
  margin-left: 16px;
`;

export const ItemAmount = styled.div`
  text-align: left;
  width: 20%;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ListTypesContainer = styled.div`
  display: flex;

  font-size: 12px;

  width: 20%;

  div + div {
    margin-left: 8px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const ListVisibility = styled.button<IVisibilityProps>`
  height: 100%;
  width: 100px;
  font-size: 14px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: none;

  ${({ active }) =>
    active
      ? css`
          background: #27ae6090;
          transition: background-color 0.2s;
          &:hover {
            background: #27ae6070;
          }
        `
      : css`
          background: #e74c3c90;
          transition: background-color 0.2s;
          &:hover {
            background: #e74c3c70;
          }
        `}

  padding: 16px;

  svg {
    margin-bottom: 16px;
  }

  @media (max-width: 500px) {
    width: auto;
    height: auto;
    display: flex;
    cursor: pointer;
    align-self: flex-end;
    padding: 8px;
    background: transparent;

    &:hover {
      background: transparent;
    }

    svg {
      padding-left: 32px;
      border-radius: 50%;

      margin-bottom: 0px;

      padding: 4px;

      height: 27px;
      width: 27px;

      &:hover {
        background: #1c1d1f50;
      }
    }

    span {
      display: none;
    }
  }
`;
