import styled from 'styled-components';
import { Card, Container as BaseContainer } from '../styles';

export const Container = styled(BaseContainer)`
  .full {
    width: 100%;
  }

  .fill-3 {
    grid-column: span 3;
  }

  .fill-2 {
    grid-column: span 2;
  }

  span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  @media screen and (max-width: 800px) {
    span,
    h3 {
      font-size: 0.8rem;
    }
  }

  @media screen and (max-width: 400px) {
    span,
    h3 {
      font-size: 3vw;
    }
  }

  @media print {
    span,
    h3 {
      font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    }
  }
`;

export const DaySummary = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1.5rem;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }

  @media print {
    gap: 1rem;
  }
`;

export const DayRight = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media print {
    gap: 1rem;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const Items = styled(Card)`
  width: 60%;
  display: grid;
  grid-gap: 0.6rem 0.8rem;
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
  justify-items: stretch;

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  .grid-divider {
    grid-column: span 3;
  }

  & > .center {
    text-align: center;
    justify-content: center;
  }

  & > .start {
    text-align: start;
    justify-content: flex-start;
  }

  & > .end {
    text-align: end;
    justify-content: flex-end;
  }

  @media print {
    grid-gap: 2px;
    background: #000;
    padding: 0;
    border: 2px solid #000;

    & > * {
      background: white;
      white-space: normal;
      text-overflow: ellipsis;
      padding: 0.25rem 0.8rem;
    }
  }
`;

export const Divider = styled.div`
  width: 50%;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin: 0.8rem 0;
  margin-top: 2rem;
  border-radius: 8px;
`;

export const GridDivider = styled(Divider).attrs({ className: 'grid-divider' })`
  width: 100%;
  grid-column: span 3;
  margin: 0;
  margin-top: 0.8rem;

  @media print {
    display: none;
  }
`;

export const Payment = styled(Items)`
  width: 100%;
  display: grid;
  align-items: stretch;
  grid-template-columns: repeat(2, 1fr);

  .grid-divider {
    grid-column: span 2;
  }
`;

export const Devices = styled(Payment)`
  grid-template-columns: 1.5rem calc(50% - 1.675rem) 1fr;
  align-items: stretch;

  .grid-divider {
    grid-column: span 3;
  }

  span {
    display: flex;
    align-items: center;
  }

  span.end {
    justify-content: flex-end;
  }

  @media print {
    .name {
      padding-left: 0.25rem;
    }
  }
`;

export const GridHeader = styled.h3`
  font-size: 1rem;
  font-weight: 500;

  @media print {
    font-size: 0.9rem;
    background: #cecece;
  }
`;

export const GridFooter = styled(GridHeader)`
  @media print {
    &.big-on-print {
      font-size: 1.5rem;
      text-align: center;
    }
  }
`;

export const Title = styled.h3`
  font-size: 1rem;
  font-weight: 500;
  align-self: start;
  margin-bottom: 0.5rem;
`;

export const DeviceIconContainer = styled.span`
  position: relative;
  img {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
