import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
`;

export const Content = styled.main`
  overflow-y: auto;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  background: #f4f4f6;
  position: relative;
`;

export const Header = styled.header`
  width: 100%;
  max-height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 32px;
  padding-top: 24px;
  padding-bottom: 16px;
`;

export const PageInfo = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;

    @media (min-width: 1271px) {
      display: none;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PageName = styled.strong`
  font-size: 32px;
  margin-left: 8px;

  @media (max-width: 1027px) {
    display: none;
  }
`;

export const Main = styled.div`
  width: 100%;
  height: -webkit-fill-available;

  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  gap: 16px;

  overflow-y: auto;
  overflow-x: hidden;

  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const SearchIllustrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 16px;
  height: 30%;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  text-align: center;
`;

export const Title = styled.strong`
  font-size: 1.5rem;
`;

export const Message = styled.span`
  font-size: 1rem;
`;

export const Label = styled.strong`
  font-size: 1rem;
`;

export const FiltersContainer = styled.div.attrs({
  className: 'has-custom-scroll-bar-2',
})`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

export const CashiersList = styled(FiltersContainer)`
  padding: 1rem 32px;
  padding-top: 0;
  gap: 1rem;
  position: relative;

  @media (max-width: 480px) {
    padding-bottom: 96px;
  }
`;

export const AppliedFiltersContainer = styled.div`
  width: 100%;
  flex-direction: row;
  background: ${({ theme }) => theme.palette.background};
  position: sticky;
  top: -1px;
  padding-bottom: 1rem;
`;

export const CashierReportModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: min(80%, 100%);
  padding: 1rem;
  background: ${({ theme }) => theme.palette.background};
  margin: 1rem 0;
`;

export const CancelButton = styled.button`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.primary};
  font-size: 1rem;

  @media (max-width: 1027px) {
    margin-right: auto;
  }
`;
