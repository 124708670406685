import { IStep } from '../components/Tour';
import { chooseBsFoodOrVarejo } from '../utils/subdomain';

const company: IStep[] = [
  {
    text: 'Esta é a página Empresa!',
  },
  {
    id: 'settings-info',
    position: 'left',
    text: 'Aqui são listados os dados da empresa.',
  },
  {
    id: 'settings-logo',
    position: 'right',
    text: 'Aqui você pode escolher os logos de exibição e impressão.',
  },
  {
    id: 'settings-contact',
    position: 'top',
    text: 'Aqui você pode editar as informações de contato.',
  },
  {
    id: 'settings-address',
    position: 'top',
    text: 'Aqui você pode editar as informações de endereço.',
  },
  {
    id: 'settings-business-hours',
    position: 'top',
    text:
      'Aqui você edita os horários de atendimento, adiciona um novo ou exclui.',
  },
  {
    id: 'settings-welcome',
    position: 'top',
    text: 'Por aqui você pode alterar a mensagem de boas-vindas.',
  },
  {
    id: 'settings-prices',
    position: 'top',
    text: 'Aqui você pode editar informações sobre atendimento e chave PIX.',
  },
  {
    id: 'settings-save',
    position: 'top-left',
    text: 'Basta clicar aqui para salvar as alterações.',
  },
];

const options: IStep[] = [
  {
    text: 'Esta é a página Opções!',
  },
  {
    text: 'As alterações feitas nesta página são salvas automaticamente.',
  },
  {
    id: 'settings-payment',
    position: 'bottom',
    text: 'Aqui você pode escolher os tipos de pagamento aceitos.',
  },
  {
    id: 'settings-delivery',
    position: 'top',
    text: 'Aqui você pode escolher os tipos de entrega aceitas.',
  },
  {
    id: 'settings-delivery-area',
    position: 'top',
    text:
      'Aqui você pode escolher se quer ou não aceitar pedidos de outras cidades.',
  },
  {
    id: 'settings-print',
    position: 'top',
    text: 'Aqui você pode editar opções de impressão.',
  },
  {
    id: 'settings-show-card-brands',
    position: 'top',
    text:
      'Aqui você opta por mostrar ou não as bandeiras aceitas na finalização do pedido.',
  },
  {
    id: 'settings-menu',
    position: 'top',
    text: `Por aqui você pode editar diversas opções do seu ${chooseBsFoodOrVarejo(
      'cardápio',
      'catálogo',
    )}.`,
  },
  {
    id: 'settings-card-brands',
    position: 'top',
    text: 'Por aqui você pode selecionar as bandeiras de cartão aceitas.',
  },
];

const user: IStep[] = [
  {
    text: 'Esta é a página Meus Dados!',
  },
  {
    id: 'settings-user',
    position: 'bottom',
    text: 'Aqui você pode alterar seus dados.',
  },
  {
    id: 'settings-user-password',
    position: 'top',
    text: 'Aqui você pode alterar sua senha.',
  },
  {
    id: 'settings-user-save',
    position: 'top-left',
    text: 'Basta clicar aqui para salvar as alterações.',
  },
];

const integration: IStep[] = [
  {
    text: 'Esta é a página Integração!',
  },
  {
    text: 'Nesta página você pode fazer a integração com o PDV.',
  },
  {
    id: 'settings-integration-toggle',
    position: 'bottom',
    text: 'Basta clicar aqui para ativar/desativar a integração.',
  },
  {
    id: 'clientIdContainer',
    position: 'bottom',
    text: 'Aqui é exibido o código da sua empresa.',
  },
  {
    id: 'apiCompanyKeyContainer',
    position: 'bottom',
    text: 'Aqui é exibido o código para a API.',
  },
];

export default { company, options, user, integration };
