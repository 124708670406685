import { Form } from '@unform/web';
import styled, { css } from 'styled-components';

interface IContainerProps {
  visible: boolean;
}

export const Container = styled.div<IContainerProps>`
  height: 100vh;
  height: -webkit-fill-available;

  position: absolute;
  right: 0;
  top: 0;

  background: #f4f4f6;

  box-shadow: 1px 1px 12px 1px rgba(0, 0, 0, 0.1);

  transition: all 0.3s;

  overflow: hidden;

  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
          width: 550px;

          @media (max-width: 550px) {
            width: 100%;
          }
        `
      : css`
          opacity: 0;
          width: 0;
        `}
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  height: 30px;

  padding: 32px;

  svg {
    cursor: pointer;
  }

  span {
    font-size: 24px;
    margin-left: 16px;
  }
`;

export const Information = styled(Form)`
  padding: 32px;

  height: calc(100% - 40px);
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  div.has-margin-top {
    margin-top: 32px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 32px;

  justify-content: space-between;

  div {
    width: 45%;
  }
`;

export const Title = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 24px;
  }

  #newComplementButton {
    border: 0;
    color: #fff;
    background: #db1622;
  }

  button {
    display: flex;
    align-items: center;

    padding: 8px;

    span {
      font-size: 14px;
      margin-left: 8px;
    }
  }

  @media (max-width: 480px) {
    span {
      font-size: 20px;
    }

    #newComplementButton {
      span {
        display: none;
      }
    }
  }
`;

export const ComplementsButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const Complements = styled.div`
  width: 100%;
  margin-top: 64px;
`;

export const ComplementsContainer = styled.div`
  margin-top: 32px;

  max-height: 350px;

  overflow-y: scroll;
  overflow-x: hidden;

  > div {
    margin: 16px 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 64px;

  justify-content: flex-end;

  overflow: visible;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  align-self: flex-end;
  border: 0;
  width: 80%;
  height: 100px;
  padding: 32px;
  color: #fff;
  background: #db1622;
  margin-right: -128px;
  border-radius: 16px 0 0 16px;
  transition: transform 0.3s;

  &:hover {
    transform: translateX(-5%);
  }

  span {
    font-size: 18px;
  }

  svg {
    height: 24px;
    width: 24px;
    margin-left: 32px;
  }

  @media (max-width: 970px) {
    margin-left: 32px;
  }

  @media (max-width: 480px) {
    margin-right: -64px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  svg {
    margin-left: -50%;
  }

  > div {
    overflow: 'visible';
  }
`;

export const SelectContainer = styled.div`
  margin-top: 32px;
`;

export const SelectLabel = styled.label`
  display: flex;
  font-size: 12px;
  color: #aaa;
  margin-bottom: 16px;
`;

export const DispositionContainer = styled.div`
  margin-top: 16px;
`;
