export type EOrderStatus =
  | 'PLACED'
  | 'PREPARING'
  | 'IN_TRANSIT'
  | 'COMPLETED'
  | 'CANCELED';

export enum EOrderOrigin {
  BSFOOD = 'BSFOOD',
  BSTICKET = 'BSTICKET',
  BSSELFCHECKOUT = 'BSSELFCHECKOUT',
}
