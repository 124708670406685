import ReactModal from 'react-modal';
import styled from 'styled-components';

import { appearFromBottom } from '../../styles/animations';

export const StyledModal = styled(ReactModal)`
  animation: ${appearFromBottom} 0.5s;
`;

export const Scroll = styled.div`
  max-height: 90vh;
  width: min(100vw, 600px);

  overflow-y: scroll;
  overflow-x: hidden;

  padding: 32px;
  border-radius: 8px;

  .error-container {
    display: flex;
    flex-direction: column;

    .error {
      font-size: 14px;
      color: ${({ theme }) => theme.palette.error};
    }
  }
`;

export const Container = styled.div`
  max-height: 90vh;
  width: min(100vw, 600px);

  overflow: hidden;

  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};
  background-color: ${({ theme }) => theme.palette.background_white};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 16px;
`;

export const ModalTitle = styled.h3``;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 4px;

  border: 0;
  background: none;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 8px;
  margin-bottom: 24px;

  > div + div {
    margin-left: 32px;
  }
`;

export const Type = styled.div`
  margin-top: 16px;
  overflow: visible !important;
`;

export const ButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  margin-top: 24px;
`;

export const SaveButton = styled.button`
  flex: 1;
  height: 37px;
  margin-left: 16px;
  padding: 6px 18px;

  border: 0;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};
`;

export const CancelButton = styled.button`
  flex: 1;

  padding: 5px;

  background: none;
  color: ${({ theme }) => theme.palette.primary};
  border: 1px solid ${({ theme }) => theme.palette.primary};
`;

export const AdditionalsContainer = styled.div`
  .empty-message {
    font-size: 12px;
  }

  margin-bottom: 16px;
`;

export const InnerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 12px;
    margin-right: 16px;
    color: ${({ theme }) => theme.palette.control};
  }
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    padding: 6px 8px;

    span {
      font-size: 12px;
      margin-right: 0;
    }
  }
`;

export const AddNewButton = styled.button`
  font-size: 12px;

  border: 0;
  padding: 6px;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};
`;

export const Additionals = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 16px;
  gap: 12px;
  flex-wrap: wrap;
`;

export const Size = styled(InputsContainer)`
  flex-wrap: nowrap;
  justify-content: space-between;

  > div {
    width: calc(calc(100% - 78px) / 3.5);

    /* &:first-child {
      width: 30%;
    } */
  }
`;

export const PricesInputsContainer = styled(InputsContainer)`
  flex-direction: column;
  width: 100%;
  padding-left: 16px;
  margin-left: 0;
  border-left: 1px solid ${({ theme }) => theme.palette.control};

  > div {
    width: 100%;
  }
`;

export const Edge = styled(InputsContainer)`
  border-left: 1px solid ${({ theme }) => theme.palette.control};
  padding-left: 16px;
  justify-content: space-between;

  > div:first-child {
    width: calc(100% - 52px);
  }
`;

export const AddImageButton = styled.label`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  border: 0;
  background: ${({ theme }) => theme.palette.primary};

  input {
    display: none;
  }
`;

export const RemoveButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  background: ${({ theme }) => theme.palette.primary};
`;

interface INumberActiveInput {
  active?: boolean;
}

export const NumberAndActiveInputContainer = styled.div<INumberActiveInput>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    width: 100%;
  }

  opacity: ${({ active }) => (active ? '1' : '.6')};
`;

export const ActiveButton = styled(RemoveButton)`
  width: 32px;
  height: 32px;
`;
