import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px;
  background: none;
  margin: 0 16px;
  color: ${({ theme }) => theme.palette.primary};
  border: 1px solid ${({ theme }) => theme.palette.primary};

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.primary};

    &::after {
      content: 'Ordenar';
      margin-left: 8px;
      color: ${({ theme }) => theme.palette.primary};
    }
  }

  @media (max-width: 595px) {
    span {
      &::after {
        content: '';
        margin-left: 0;
      }
    }
  }

  @media (max-width: 425px) {
    margin-left: 8px;
    margin-right: 8px;
  }
`;
