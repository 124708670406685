import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const AppliedFiltersContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const FilterContainer = styled.div`
  padding: 4px 8px;
  border: 1px solid ${({ theme }) => theme.palette.control_light};
  border-radius: 4px;
`;

export const PeriodFilterContainer = styled.div`
  padding: 4px 8px;
  border: 1px solid ${({ theme }) => theme.palette.control_light};
  border-radius: 4px;
  gap: 16px;

  div {
    padding: 0;
    border: 0;
  }
`;

export const Title = styled.span`
  font-size: 1.25rem;
`;

export const Label = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.text_light};
`;

export const Value = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.text_light};
  font-weight: 500;
`;
