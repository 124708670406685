import styled, { css } from 'styled-components';

interface ITeammateStatusProps {
  active: boolean;
}

export const Container = styled.div`
  width: calc(50% - 8px);
  min-height: 128px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  background: ${({ theme }) => theme.palette.background_white};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  padding: 12px 24px;

  @media (max-width: 1080px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 480px) {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TeammateName = styled.p`
  font-size: 18px;
  font-weight: 500;

  strong {
    font-weight: 600;
  }
`;

export const TeammateEmail = styled.em`
  color: ${({ theme }) => theme.palette.text_light};
`;

export const TeammateStatus = styled.span<ITeammateStatusProps>`
  font-weight: 600;
  margin-top: 12px;

  ${({ active }) =>
    active
      ? css`
          color: ${({ theme }) => theme.palette.status_approved};

          &::before {
            content: 'Ativo';
          }
        `
      : css`
          color: ${({ theme }) => theme.palette.status_canceled};

          &::before {
            content: 'Inativo';
          }
        `}
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.palette.background_white};
  border: none;

  padding: 8px;
  border-radius: 100%;

  transition: filter 2ms ease;

  &:hover {
    filter: brightness(0.9);
  }
`;
