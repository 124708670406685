import Modal from 'react-modal';
import styled from 'styled-components';

import { appearFromBottom } from '../../styles/animations';

interface IMessageProps {
  error: boolean;
}

export const StyledModal = styled(Modal)`
  animation: ${appearFromBottom} 0.5s;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background: #fff;
  padding: 32px;

  border-radius: 16px;

  overflow-y: auto;
  overflow-x: hidden;

  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);

  min-width: 250px;
`;

export const Title = styled.h3`
  color: #1b1c1e;
  margin-bottom: 16px;
`;

export const Message = styled.span<IMessageProps>`
  color: ${({ error }) => (error ? '#c0392b' : '#1b1c1e90')};
  font-size: 14px;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 32px;

  input + input {
    margin-left: 8px;
  }
`;

export const CodeInput = styled.input`
  width: 40px;
  height: 40px;

  padding: 0 8px;

  border: 1px solid #ccc;
`;

export const Buttons = styled.div`
  display: flex;

  margin-top: 32px;
  margin-bottom: 16px;
`;

export const CancelButton = styled.button`
  flex: 1;

  padding: 8px;

  border: 0;
  background: none;
  border-bottom: 2px solid #c0392b;
`;

export const ConfirmButton = styled.button`
  flex: 1;
  margin-left: 16px;

  padding: 8px;

  border: 0;
  background: none;
  border-bottom: 2px solid #27ae60;
`;
