import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { FiArrowDown, FiArrowUp, FiX } from 'react-icons/fi';
import Select, { ControlProps, OptionProps, components } from 'react-select';
import { useTheme } from 'styled-components';
import {
  IMovementProduct,
  IStockMovementDTO,
  MovementStockTypes,
} from '../../models/IStock';
import { selectStyles } from '../../styles/select';
import FormlessInput from '../FormlessInput';
import Loading from '../Loading';
import {
  ButtonsContainer,
  CancelButton,
  CloseButton,
  Container,
  HalfInputContainer,
  Header,
  IconOptionContainer,
  Label,
  QuantitiesContainer,
  SaveButton,
  SelectContainer,
  SelectProductContainer,
  SelectedProduct,
  StyledModal,
} from './styles';
import ProductsSelectorModal from '../ProductsSelectorModal';
import { useProducts } from '../../hooks/products';

interface IAddStockMovementModalProps {
  isOpen: boolean;
  onConfirm: (stockMovement: IStockMovementDTO) => Promise<void>;
  onClose: () => void;
}

interface ISelect {
  label: string;
  value: MovementStockTypes;
  icon: ReactElement;
}

const AddStockMovementModal: React.FC<IAddStockMovementModalProps> = ({
  isOpen,
  onConfirm,
  onClose,
}) => {
  const { products } = useProducts();
  const theme = useTheme();

  const [isSelectProductModalOpen, setIsSelectProductModalOpen] = useState(
    false,
  );

  const [
    selectedProduct,
    setSelectedProduct,
  ] = useState<IMovementProduct | null>(null);
  const [movementType, setMovementType] = useState<ISelect | null>(null);
  const [movementReason, setMovementReason] = useState('');
  const [movementQty, setMovementQty] = useState<number | undefined>();

  const [selectedProductError, setSelectedProductError] = useState('');
  const [movementTypeError, setMovementTypeError] = useState('');
  const [movementReasonError, setMovementReasonError] = useState('');
  const [movementQtyError, setMovementQtyError] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const { Option, Control } = components;

  const formattedMovementTypes: ISelect[] = useMemo(
    () => [
      {
        label: 'ENTRADA',
        value: 'INCREASE',
        icon: <FiArrowDown size={20} color={theme.palette.success} />,
      },
      {
        label: 'SAÍDA',
        value: 'DECREASE',
        icon: <FiArrowUp size={20} color={theme.palette.error} />,
      },
    ],
    [theme],
  );

  const handleOnSelectProductModalClose = useCallback(() => {
    setIsSelectProductModalOpen(false);
  }, []);

  const handleOnSelectProduct = useCallback(
    (id?: number) => {
      const newProduct = products.find(product => product.id === id);

      setSelectedProduct(newProduct || null);

      setSelectedProductError('');
      setIsSelectProductModalOpen(false);
    },
    [products],
  );

  const handleMovementTypeChanged = useCallback((param: any) => {
    setMovementType(param as ISelect);
    setMovementTypeError('');
  }, []);

  const handleMovementReasonChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setMovementReason(value);
      setMovementReasonError('');
    },
    [],
  );

  const handleMovementQtyChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setMovementQty(Number(value));
      setMovementQtyError('');
    },
    [],
  );

  const hasErrors = useCallback(() => {
    let hasErrors = false;

    if (!selectedProduct) {
      setSelectedProductError('O produto é obrigatório.');

      hasErrors = true;
    }

    if (!movementType) {
      setMovementTypeError('O tipo de movimentação é obrigatório.');

      hasErrors = true;
    }

    if (movementReason.length < 4 || movementReason.length > 31) {
      setMovementReasonError(
        !movementReason
          ? 'O motivo da movimentação é obrigatório.'
          : 'O motivo da movimentação precisa ter entre 4 e 31 caracteres.',
      );

      hasErrors = true;
    }

    if (!movementQty) {
      setMovementQtyError('A quantidade é obrigatória.');

      hasErrors = true;
    }

    if (
      movementQty &&
      selectedProduct &&
      movementType?.value === 'DECREASE' &&
      movementQty > selectedProduct.availableStock
    ) {
      setMovementQtyError('A quantidade não pode ser maior que o estoque.');

      hasErrors = true;
    }

    return hasErrors;
  }, [movementQty, movementReason, movementType, selectedProduct]);

  const handleOnModalClose = useCallback(() => {
    setSelectedProduct(null);
    setMovementType(null);
    setMovementReason('');
    setMovementQty(undefined);

    setSelectedProductError('');
    setMovementTypeError('');
    setMovementReasonError('');
    setMovementQtyError('');

    onClose();
  }, [onClose]);

  const handleOnSaveMovement = useCallback(async () => {
    if (hasErrors()) return;

    setIsSaving(true);

    const newMovement: IStockMovementDTO = {
      transactionType: movementType?.label as MovementStockTypes,
      reason: movementReason,
      qty: movementQty as number,
      title: selectedProduct?.title || '',
    };

    onConfirm(newMovement);

    setIsSaving(false);

    setMovementType(null);
    setMovementReason('');

    handleOnModalClose();
  }, [
    hasErrors,
    movementType,
    movementReason,
    movementQty,
    selectedProduct,
    onConfirm,
    handleOnModalClose,
  ]);

  const IconOption = ({
    data,
    ...props
  }: OptionProps<typeof formattedMovementTypes[0]>) => (
    <Option {...props} data={data}>
      <IconOptionContainer>
        {data.icon}
        {data.label}
      </IconOptionContainer>
    </Option>
  );

  const IconOptionControl = ({
    selectProps,
    children,
    ...props
  }: ControlProps<typeof formattedMovementTypes[0]>) => (
    <Control {...props} selectProps={selectProps}>
      <IconOptionContainer style={{ gap: 0 }}>
        {selectProps.icon}
        {children}
      </IconOptionContainer>
    </Control>
  );

  return (
    <StyledModal
      isOpen={isOpen}
      shouldCloseOnEsc
      id="add-stock-movement-modal"
      onRequestClose={handleOnModalClose}
      shouldCloseOnOverlayClick
      style={{
        overlay: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <Container className="has-custom-scroll-bar-2">
        <Header>
          <h3>Nova movimentação</h3>
          <CloseButton onClick={handleOnModalClose}>
            <FiX size={20} />
          </CloseButton>
        </Header>
        <div className="error-container">
          <SelectProductContainer>
            <Label>Produto</Label>
            <SelectedProduct
              type="button"
              onClick={() => setIsSelectProductModalOpen(true)}
            >
              {selectedProduct ? (
                <>
                  {selectedProduct.imageUrl && (
                    <img
                      src={selectedProduct.imageUrl}
                      alt={selectedProduct.title}
                    />
                  )}
                  <span>{selectedProduct.title}</span>
                </>
              ) : (
                <span className="placeholder">Selecione um produto</span>
              )}
            </SelectedProduct>
            {selectedProductError && (
              <span className="error">{selectedProductError}</span>
            )}
          </SelectProductContainer>
          <SelectContainer>
            <span>Tipo de movimentação</span>
            <Select
              isClearable={false}
              styles={selectStyles}
              value={movementType}
              options={formattedMovementTypes}
              menuPortalTarget={document.body}
              onChange={handleMovementTypeChanged}
              placeholder="Selecione o tipo de movimentação"
              components={{ Option: IconOption, Control: IconOptionControl }}
              icon={movementType?.icon}
            />
            {movementTypeError && (
              <span className="error">{movementTypeError}</span>
            )}
          </SelectContainer>
          <FormlessInput
            title="Motivo"
            placeholder="Motivo da movimentação"
            name="movement_Reason"
            value={movementReason}
            onChange={handleMovementReasonChanged}
            minLength={4}
            maxLength={31}
          />
          {movementReasonError && (
            <span className="error">{movementReasonError}</span>
          )}
          {selectedProduct && (
            <QuantitiesContainer>
              <HalfInputContainer>
                <FormlessInput
                  title="Quantidade"
                  placeholder="Quantidade"
                  name="movement_qty"
                  value={movementQty}
                  onChange={handleMovementQtyChanged}
                  type="number"
                  min={0}
                />
                {movementQtyError && (
                  <span className="error">{movementQtyError}</span>
                )}
              </HalfInputContainer>
              <HalfInputContainer>
                <FormlessInput
                  id="disabled-input"
                  title="Estoque"
                  placeholder="Estoque"
                  name="movement_previous_qty"
                  value={selectedProduct.availableStock}
                  type="number"
                  min={0}
                  disabled
                  readOnly
                />
              </HalfInputContainer>
            </QuantitiesContainer>
          )}
        </div>
        <ButtonsContainer>
          <CancelButton onClick={handleOnModalClose}>Cancelar</CancelButton>
          <SaveButton onClick={handleOnSaveMovement}>
            {isSaving ? (
              <Loading color="text_white" stroke={2} radius={16} />
            ) : (
              'Salvar'
            )}
          </SaveButton>
        </ButtonsContainer>
      </Container>

      <ProductsSelectorModal
        products={products}
        open={isSelectProductModalOpen}
        onClose={handleOnSelectProductModalClose}
        onSelect={handleOnSelectProduct}
      />
    </StyledModal>
  );
};

export default AddStockMovementModal;
