import React, { useCallback } from 'react';

import { Container, StyledInput, Label } from './styles';

interface IDateInputProps {
  label: string;
  value: Date | null;
  fullWidth?: boolean;
  hasMarginTop?: boolean;
  minDate?: Date;
  onChange: (date: Date) => void;
}

const DateInput: React.FC<IDateInputProps> = ({
  label,
  value,
  fullWidth,
  hasMarginTop,
  minDate,
  onChange,
}) => {
  const handleOnDateChanged = useCallback(
    (date: Date | Date[]) => {
      if (date instanceof Date) {
        onChange(date);
      }
    },
    [onChange],
  );

  return (
    <Container
      fullWidth={fullWidth}
      hasMarginTop={hasMarginTop}
      className="datepicker-custom"
      style={fullWidth ? { width: '100%' } : {}}
    >
      <Label>{label}</Label>
      <StyledInput
        value={value}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="aaaa"
        onChange={handleOnDateChanged}
        minDate={minDate}
      />
    </Container>
  );
};

export default DateInput;
